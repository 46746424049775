import React, { useState, useEffect, Dispatch, SetStateAction } from 'react';

// UI
import {
  DialogTitle,
  DialogContent,
  Grid,
  Stack,
  Typography,
  Button,
  FormLabel,
  TextField,
  DialogActions,
  Dialog,
} from '@mui/material';

import { ReactComponent as DefaultImage } from 'assets/placeholder/128.svg';
import { ReactComponent as UploadIcon } from 'assets/icons/button/upload.svg';

import { LoadingIndicator } from 'app/components/LoadingIndicator';

// Redux & Sagas
import { useDispatch, useSelector } from 'react-redux';
import { useCustomerSlice } from '../../slice';
import { selectCustomer } from '../../slice/selectors';
import { RequestStatus } from 'constants/API';
import { ICustomerSchema } from '../../slice/types';

// Library
import styled from 'styled-components/macro';
import { useForm } from 'react-hook-form';
import { REGEX_CHECK_NUMBER } from 'constants/common';
import { useTranslation } from 'react-i18next';
import { useGlobalSlice } from 'app/pages/GlobalContainer/slice';
import { checkIsRequesting } from 'utils/helper';
import { Loader } from 'app/components/Loader';

export function DialogForm({ ...props }) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { actions } = useCustomerSlice();
  const customerSlice = useSelector(selectCustomer);
  const { setErrorMessages } = useGlobalSlice().actions;

  const [isEditMode, setIsEditMode] = useState(false);

  const { data, onClose } = props as {
    data: ICustomerSchema;
    onClose: () => Dispatch<SetStateAction<boolean>>;
  };
  const {
    reset,
    register,
    handleSubmit,
    watch,
    setError,
    formState: { errors },
  } = useForm();

  useEffect(() => {
    setIsEditMode(props.data ? true : false);
  }, [props.data]);

  useEffect(() => {
    reset({
      id: data?.id,
      url: data?.url,
      code: data?.code,
      name: data?.name,
      note: data?.note,
      phone: data?.phone,
      address: data?.address,
      description: data?.description,
      sale_partner: data?.sale_partner,
    });
  }, [isEditMode, data, reset]);

  useEffect(() => {
    if (
      customerSlice.createCustomerInfoStatus === RequestStatus.SUCCESS ||
      customerSlice.updateCustomerInfoStatus === RequestStatus.SUCCESS
    ) {
      reset();
      onClose();
    }
    if (
      customerSlice.createCustomerInfoStatus === RequestStatus.ERROR ||
      customerSlice.updateCustomerInfoStatus === RequestStatus.ERROR
    ) {
      if (customerSlice.listError) {
        for (var field_name in customerSlice.listError) {
          var message = customerSlice.listError[field_name];
          setError(field_name, {
            type: 'manual',
            message: message,
          });
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    customerSlice.createCustomerInfoStatus,
    customerSlice.updateCustomerInfoStatus,
    onClose,
  ]);

  function isImageByExtension(fileName) {
    const allowedExtensions = ['png', 'jpg', 'gif'];
    const fileExtension = fileName.split('.').pop().toLowerCase();
    return allowedExtensions.includes(fileExtension);
  }

  useEffect(() => {
    const file = watch('logo')?.[0];
    if (file) {
      if (file.size >= 10 * 1024 * 1024) {
        dispatch(setErrorMessages([`${file.name}${t(` file over 10mb!`)}`]));
        reset({ logo: undefined });
      } else if (!isImageByExtension(file.name)) {
        dispatch(
          setErrorMessages([`${file.name}${t(` file type does not match!`)}`]),
        );
        reset({ logo: undefined });
      }
    }
  }, [watch('logo')]);

  const onSave = formData => {
    if (formData.id) {
      dispatch(actions.updateCustomerInfoRequest(formData));
    } else {
      dispatch(actions.createCustomerInfoRequest(formData));
    }
  };

  const uploadedLogo = watch('logo');
  const isLoading = checkIsRequesting([
    customerSlice.updateCustomerInfoStatus,
    customerSlice.createCustomerInfoStatus,
  ]);

  return (
    <CustomerForm open={props.open} maxWidth="md" fullWidth scroll="paper">
      <Loader open={isLoading} />
      <DialogTitle>
        {isEditMode ? t('UPDATE CUSTOMER INFO') : t('ADD NEW CUSTOMER')}
      </DialogTitle>
      <DialogContent dividers>
        <Grid
          container
          mb={2}
          pt={2}
          spacing={2}
          component="form"
          id="customer-form"
          onSubmit={handleSubmit(onSave)}
        >
          <Grid item xs={12}>
            <Stack direction="row" spacing={2}>
              {uploadedLogo?.length > 0 ? (
                <img
                  src={URL.createObjectURL(uploadedLogo[0])}
                  alt={uploadedLogo[0].name}
                  className="img-thumbnail mb-2"
                  width={120}
                  height={120}
                />
              ) : props.data?.logo_url ? (
                <img
                  src={props.data.logo_url}
                  className="img-thumbnail mb-2"
                  alt={props.data?.name}
                  width={120}
                  height={120}
                />
              ) : (
                <DefaultImage />
              )}
              <input
                id="image-input-file"
                type="file"
                accept=".png, .jpg, .gif"
                style={{ display: 'none' }}
                {...register('logo')}
              />
              {errors.logo?.message}
              <Stack spacing={2} alignSelf="flex-end">
                <Typography>
                  {t('Support for a single upload, size limit')}: 10MB.
                  <br />
                  {t('Allowed file types ')}: PNG, JPG, GIF
                </Typography>
                <label htmlFor="image-input-file">
                  <Button
                    variant="outlined"
                    startIcon={<UploadIcon />}
                    component="span"
                  >
                    {t('Upload')}
                  </Button>
                </label>
              </Stack>
            </Stack>
          </Grid>

          <Grid item xs={4}>
            <FormLabel required>{t('Code')}</FormLabel>
            <TextField
              margin="dense"
              placeholder="Enter Code"
              variant="outlined"
              size="small"
              fullWidth
              error={errors.code ? true : false}
              {...register<keyof ICustomerSchema>('code', {
                required: t('This field is required') as string,
                maxLength: {
                  value: 255,
                  message: t(
                    'Please enter no more than {{count}} characters.',
                    { count: 255 },
                  ),
                },
              })}
              helperText={errors.code?.message}
            />
          </Grid>

          <Grid item xs={8}>
            <FormLabel required>{t('Customer Name')}</FormLabel>
            <TextField
              margin="dense"
              placeholder="Enter Name"
              variant="outlined"
              size="small"
              fullWidth
              error={errors.name ? true : false}
              {...register<keyof ICustomerSchema>('name', {
                required: t('This field is required') as string,
                maxLength: {
                  value: 255,
                  message: t(
                    'Please enter no more than {{count}} characters.',
                    { count: 255 },
                  ),
                },
              })}
              helperText={errors.name?.message}
            />
          </Grid>

          <Grid item xs={4}>
            <FormLabel required>{t('Phone')}</FormLabel>
            <TextField
              margin="dense"
              placeholder="Phone number"
              variant="outlined"
              size="small"
              fullWidth
              error={errors.phone ? true : false}
              {...register<keyof ICustomerSchema>('phone', {
                required: t('This field is required') as string,
                maxLength: {
                  value: 12,
                  message: t(
                    'Please enter no more than {{count}} characters.',
                    { count: 12 },
                  ),
                },
                pattern: {
                  value: REGEX_CHECK_NUMBER,
                  message: t('Please enter an integer'),
                },
              })}
              helperText={errors.phone?.message}
            />
          </Grid>
          <Grid item xs={8}>
            <FormLabel required>{t('URL')}</FormLabel>
            <TextField
              margin="dense"
              placeholder="ex: https://example.com"
              variant="outlined"
              size="small"
              fullWidth
              error={errors.url ? true : false}
              {...register<keyof ICustomerSchema>('url', {
                required: t('This field is required') as string,
                maxLength: {
                  value: 255,
                  message: t(
                    'Please enter no more than {{count}} characters.',
                    { count: 255 },
                  ),
                },
                validate: {
                  https: value =>
                    value.startsWith('https://') ||
                    (`${t('The URL must start with https')}://` as string),
                },
              })}
              helperText={errors.url?.message}
            />
          </Grid>

          <Grid item xs={4}>
            <FormLabel>{t('Sale Partner')}</FormLabel>
            <TextField
              margin="dense"
              placeholder="Sale Partner Name"
              variant="outlined"
              size="small"
              fullWidth
              error={errors.sale_partner ? true : false}
              {...register<keyof ICustomerSchema>('sale_partner', {
                maxLength: {
                  value: 40,
                  message: t(
                    'Please enter no more than {{count}} characters.',
                    { count: 40 },
                  ),
                },
              })}
              helperText={errors.sale_partner?.message}
            />
          </Grid>

          <Grid item xs={8}>
            <FormLabel required>{t('Address')}</FormLabel>
            <TextField
              margin="dense"
              placeholder="Enter Address"
              variant="outlined"
              size="small"
              fullWidth
              error={errors.address ? true : false}
              {...register<keyof ICustomerSchema>('address', {
                required: t('This field is required') as string,
                maxLength: {
                  value: 255,
                  message: t(
                    'Please enter no more than {{count}} characters.',
                    { count: 255 },
                  ),
                },
              })}
              helperText={errors.address?.message}
            />
          </Grid>

          <Grid item xs={12}>
            <FormLabel>{t('Description')}</FormLabel>
            <TextField
              margin="dense"
              variant="outlined"
              size="small"
              fullWidth
              multiline
              rows={2}
              error={errors.description ? true : false}
              {...register<keyof ICustomerSchema>('description')}
              helperText={errors.description?.message}
            />
          </Grid>

          <Grid item xs={12}>
            <FormLabel>{t('Note')}</FormLabel>
            <TextField
              margin="dense"
              variant="outlined"
              size="small"
              fullWidth
              multiline
              rows={3}
              error={errors.note ? true : false}
              {...register<keyof ICustomerSchema>('note', {
                maxLength: {
                  value: 2000,
                  message: t(
                    'Please enter no more than {{count}} characters.',
                    { count: 2000 },
                  ),
                },
              })}
              helperText={errors.note?.message}
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={() => {
            onClose();
            reset();
          }}
          variant="outlined"
        >
          {t('Cancel')}
        </Button>
        <Button
          color="primary"
          type="submit"
          form="customer-form"
          variant="contained"
          disabled={
            customerSlice.createCustomerInfoStatus ===
              RequestStatus.REQUESTING ||
            customerSlice.updateCustomerInfoStatus === RequestStatus.REQUESTING
          }
        >
          {(customerSlice.createCustomerInfoStatus ===
            RequestStatus.REQUESTING ||
            customerSlice.updateCustomerInfoStatus ===
              RequestStatus.REQUESTING) && (
            <Typography component="span" mr={1}>
              <LoadingIndicator small />
            </Typography>
          )}
          {isEditMode ? t('Update') : t('Create')}
        </Button>
      </DialogActions>
    </CustomerForm>
  );
}

const CustomerForm = styled(Dialog)`
  .MuiDialogContent-root {
    padding: 0px 24px !important;
  }
`;
