import { useEffect } from 'react';
// UI
import { Helmet } from 'react-helmet-async';
import { Container } from 'app/components/Container';
import { Wrapper, Content } from 'app/components/Wrapper';
import BasicInfo from './components/BasicInfo';
import { Paper } from '@mui/material';
import { PageTitle } from 'app/components/PageTitle';
// Library
import _ from 'lodash';
import { useForgotPasswordSlice } from 'app/pages/ForgotPasswordPage/slice/';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { selectMember } from 'app/pages/MemberPage/slice/selectors';
import { selectForgotPassword } from 'app/pages/ForgotPasswordPage/slice/selectors';
import { useMemberSlice } from 'app/pages/MemberPage/slice';
import { RequestStatus } from 'constants/API';
import { useGlobalSlice } from 'app/pages/GlobalContainer/slice';

/**
 *
 * ProfilePage
 *
 */
import { selectGlobal } from 'app/pages/GlobalContainer/slice/selectors';
import { checkIsRequesting } from 'utils/helper';
import { Loader } from 'app/components/Loader';
import { useTranslation } from 'react-i18next';
import useMember from '../MemberPage/useMember';
import { convertDate } from 'utils/moment';

export const ProfilePage = () => {
  const { userSessionData } = useSelector(selectGlobal);
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const profileForm = useForm();
  const changePasswordForm = useForm();

  const {
    setSuccessMessages,
    setErrorMessages,
    setBreadcumbs,
    setUserSessionData,
  } = useGlobalSlice().actions;
  const { changePasswordRequest, resetChangePasswordRequestStatus } =
    useForgotPasswordSlice().actions;

  const {
    actions: {
      resetMemberInfoRequestStatus,
      uploadMemberAvatarRequest,
      getMemberInfoRequest,
    },
  } = useMemberSlice();
  const { updateMember } = useMember();

  useEffect(
    () => () => {
      dispatch(resetMemberInfoRequestStatus());
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    ['componentWillUnMount'],
  );

  const {
    updateMemberInfoStatus,
    uploadMemberAvatarStatus,
    memberInfo,
    getMemberInfoStatus,
  } = useSelector(selectMember);
  const { changePasswordStatus } = useSelector(selectForgotPassword);

  const isLoading = checkIsRequesting([
    getMemberInfoStatus,
    uploadMemberAvatarStatus,
    updateMemberInfoStatus,
    changePasswordStatus,
  ]);

  useEffect(() => {
    if (updateMemberInfoStatus === RequestStatus.SUCCESS) {
      dispatch(setSuccessMessages([t('Member update successful!')]));
      dispatch(resetMemberInfoRequestStatus());
    }
    if (updateMemberInfoStatus === RequestStatus.ERROR) {
      dispatch(setErrorMessages([t('Member update fail!')]));
      dispatch(resetMemberInfoRequestStatus());
    }
    if (uploadMemberAvatarStatus === RequestStatus.SUCCESS) {
      dispatch(setSuccessMessages([t('Member avatar update successful!')]));
      dispatch(resetMemberInfoRequestStatus());

      const newMemberInfo = {
        ...userSessionData,
        profile: {
          ...userSessionData.profile,
          avatar: memberInfo?.avatar || '',
        },
      };
      profileForm.setValue('avatar', memberInfo?.avatar);
      dispatch(setUserSessionData(newMemberInfo));
    }
    if (uploadMemberAvatarStatus === RequestStatus.ERROR) {
      dispatch(setErrorMessages([t('This avatar is too big!')]));
      dispatch(resetMemberInfoRequestStatus());
    }
    if (changePasswordStatus === RequestStatus.SUCCESS) {
      dispatch(setSuccessMessages([t('Change password successful!')]));
      changePasswordForm.setValue('confirm_password', '');
      changePasswordForm.setValue('new_password', '');
      changePasswordForm.setValue('old_password', '');
      dispatch(resetChangePasswordRequestStatus());
    }
    if (changePasswordStatus === RequestStatus.ERROR) {
      dispatch(setErrorMessages([t('Wrong password!')]));
      changePasswordForm.setValue('confirm_password', '');
      changePasswordForm.setValue('new_password', '');
      changePasswordForm.setValue('old_password', '');
      dispatch(resetChangePasswordRequestStatus());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updateMemberInfoStatus, changePasswordStatus, uploadMemberAvatarStatus]);

  useEffect(() => {
    dispatch(getMemberInfoRequest(userSessionData.profile.id));
    dispatch(setBreadcumbs([{ title: t('User Profile') }]));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (memberInfo) {
      const data = _.cloneDeep(memberInfo);
      profileForm.reset(data);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getMemberInfoStatus]);

  const handleSaveData = formData => {
    formData.birthday = convertDate(formData.birthday);
    formData.join_date = convertDate(formData.join_date);
    formData.resign_date = convertDate(formData.resign_date);

    const formDataParams = { ...formData };
    // remove params of other form to avoid error when save data
    if (!formDataParams.first_name) {
      delete formDataParams.first_name;
    }
    if (!formDataParams.last_name) {
      delete formDataParams.last_name;
    }
    if (!formDataParams.role) {
      delete formDataParams.role;
    }
    delete formDataParams.avatar;

    updateMember({ id: memberInfo?.id, formData: formDataParams });
  };
  const handleChangePassword = formData => {
    dispatch(changePasswordRequest(formData));
  };
  const callAPIUploadImage = () => {
    let avatar = profileForm.getValues('avatar');
    if (avatar) {
      dispatch(uploadMemberAvatarRequest({ id: memberInfo?.id, avatar }));
    }
  };

  return (
    <>
      <Helmet>
        <title>{t('Profile Info')}</title>
        <meta name="description" content={t('Profile Info')} />
      </Helmet>
      <Container>
        <Wrapper>
          <Loader open={isLoading} />
          <Content spacing={2}>
            <PageTitle>{t('User Profile')}</PageTitle>
            <Paper style={{ padding: '24px 100px', borderRadius: '12px' }}>
              <BasicInfo
                memberForm={profileForm}
                handleSaveData={handleSaveData}
                handleChangePassword={handleChangePassword}
                changePasswordForm={changePasswordForm}
                callAPIUploadImage={callAPIUploadImage}
              />
            </Paper>
          </Content>
        </Wrapper>
      </Container>
    </>
  );
};
