import React, { useState, useEffect } from 'react';

// UI
import { Helmet } from 'react-helmet-async';

import {
  Box,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Stack,
  Paper,
  Badge,
  Typography,
  IconButton,
} from '@mui/material';

import { Container } from 'app/components/Container';
import { Wrapper, Content } from 'app/components/Wrapper';
import { ChevronLeft, ChevronRight } from '@mui/icons-material';

// import { DialogForm } from './components/Form';

// Redux & Sagas
import { useDispatch, useSelector } from 'react-redux';
import { RequestStatus } from 'constants/API';
// import { selectCustomer } from './slice/selectors';
// import { useCustomerSlice } from './slice';
// import { ICustomerSchema } from './slice/types';
import { useGlobalSlice } from 'app/pages/GlobalContainer/slice';
import queryString from 'query-string';
import { useProjectsSlice } from '../ProjectPage/slice';
import { selectProject } from '../ProjectPage/slice/selectors';
import useMember from '../MemberPage/useMember';

// Library
import { useLocation } from 'react-router';
import _ from 'lodash';
import { Loader } from 'app/components/Loader';
import {
  checkIsRequesting,
  getHalfYearMonths,
  hexToRgba,
  roundDecimal,
} from 'utils/helper';
import styled from 'styled-components';
import FilterBar from './components/FilterBar';
import moment from 'moment';
import { useAssignmentSummarySlice } from './slice';
import { AssignmentDetail } from './components/AssignmentDetail';
import { selectGlobal } from '../GlobalContainer/slice/selectors';
import { selectAssignmentSummary } from './slice/selectors';
import { useWindowDimensions } from 'utils/useWindowDimensions';
import roles from 'constants/roles';
import { AvatarMember } from 'app/components/Avatar';
import { MemberLink } from 'app/components/MemberLink';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import Path from 'config/clientPath';
import { ASSIGN_GET_MONTH_COUNT } from '../../../constants/common';
import { AssignProjectSummary } from './slice/types';
import { useForm } from 'react-hook-form';

export function AssignmentSummary() {
  const dispatch = useDispatch();
  const history = useHistory();
  const { t } = useTranslation();
  const FilterAssignForm = useForm();
  const params = new URLSearchParams(window.location.search);
  const { width: pageWidth } = useWindowDimensions(0);
  const { setBreadcumbs } = useGlobalSlice().actions;
  const {
    userSessionData: { userRole },
  } = useSelector(selectGlobal);

  const isCustomer = userRole === roles.IS_CUSTOMER;
  // const isCustomer = true;
  const location = useLocation();

  const [currentMonth, setCurrentMonth] = useState(moment().startOf('month'));

  const [currentTab, setCurrentTab] = React.useState<string | null>(
    params?.get('tab') ?? 'NORMAL_TAB',
  );

  const handleToggle = (event, currentTab) => {
    if (currentTab) {
      setMonths(getHalfYearMonths(currentMonth));
      params.set('tab', currentTab);
      setCurrentTab(currentTab);
    }
    history.push(
      `${
        isCustomer ? Path.ASSIGNABLE : Path.ASSIGNMENT_SUMMARY
      }?${params.toString()}`,
    );
  };

  const [months, setMonths] = useState<any>([]);
  const [activeMonth, setActiveMonth] = useState('');
  const [showAssignDetailModal, setShowAssignDetailModal] = useState(false);

  const {
    getAssignmentSummaryStatus,
    assignmentSummary,
    getAssignmentDetailStatus,
    assignProSummaryStatus,
    assignProSummary,
  } = useSelector(selectAssignmentSummary);

  const {
    actions: {
      getAssignmentSummaryRequest,
      getAssignmentDetailRequest,
      assignProSummaryRequest,
      resetAssignmentSummaryStatus,
      resetAssignmentSummaryState,
    },
  } = useAssignmentSummarySlice();
  const { getProjectListRequest } = useProjectsSlice().actions;
  const { getProjectListStatus, projectList } = useSelector(selectProject);
  const { getRoles, getDepartment } = useMember();

  const isLoading = checkIsRequesting([
    getAssignmentSummaryStatus,
    getAssignmentDetailStatus,
    getProjectListStatus,
    assignProSummaryStatus,
  ]);
  const commonMapping = (arr: any) => {
    return _.map(arr, it => _.pick(it, ['id', 'name', 'members']));
  };

  useEffect(() => {
    dispatch(
      setBreadcumbs([
        { title: isCustomer ? t('Assignable') : t('Assignment') },
      ]),
    );
    dispatch(
      getProjectListRequest({
        type: 'all',
        paging: false,
        status: 1,
      }),
    );
    getRoles();
    getDepartment();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (getAssignmentDetailStatus === RequestStatus.SUCCESS) {
      setShowAssignDetailModal(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getAssignmentDetailStatus]);

  useEffect(() => {
    if (getAssignmentSummaryStatus === RequestStatus.REQUESTING) {
      FilterAssignForm.setValue('month', moment());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getAssignmentSummaryStatus]);

  useEffect(() => {
    const _months = getHalfYearMonths(currentMonth);
    setMonths(_months);

    // let newData = {};
    // setAssignmentSummaryFake(assignmentSummaryFake);
    // assignmentSummaryFake.map((v, k) => {
    //   newData = { ...newData, [k]: v };
    //   return '';
    // });
    // reloadData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(currentMonth)]);

  const reloadData = () => {
    const params = queryString.parse(window.location.search);
    dispatch(
      getAssignmentSummaryRequest({
        from: _.head(months).dashMonthString,
        to: _.last(months).dashMonthString,
        search: params?.search || '',
        departments: params?.departments || '',
        roles: params?.roles || '',
      }),
    );
  };

  const calculateAverageAssignForMonth = (month: string) => {
    let countNonZeroAssignEffort = 0;
    let totalPerformance = 0;
    assignmentSummary?.forEach(member => {
      const dataMonth = member.data[month];
      if (dataMonth?.assign_effort > 0) {
        countNonZeroAssignEffort += 1;
        if (dataMonth?.commit_effort > 0) {
          totalPerformance += dataMonth.commit_effort / dataMonth.assign_effort;
        }
      }
    });
    if (totalPerformance === 0 || countNonZeroAssignEffort === 0) {
      return '0%';
    }
    let averageRatio = (totalPerformance * 100) / countNonZeroAssignEffort;
    return (roundDecimal(averageRatio) || 0) + '%';
  };

  useEffect(() => {
    // const params = new URLSearchParams(window.location.search);
    if (!_.isEmpty(months) && params.get('tab') !== 'PROJECT_TAB') reloadData();
    else if (params.get('tab') === 'PROJECT_TAB') {
      dispatch(
        assignProSummaryRequest({
          search: params?.get('search') || '',
          departments: params?.get('departments') || '',
          roles: params?.get('roles') || '',
          month:
            moment(FilterAssignForm.watch('month')).format('YYYY-MM') ||
            moment().format('YYYY-MM'),
        }),
      );
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.search, JSON.stringify(months)]);

  useEffect(
    () => () => {
      dispatch(resetAssignmentSummaryStatus());
      dispatch(resetAssignmentSummaryState());
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    ['componentWillUnMount'],
  );

  return (
    <>
      <Helmet>
        <title>{isCustomer ? t('Assignable') : t('Summary')}</title>
        <meta
          name="description"
          content={isCustomer ? t('Assignable') : t('Assignment Summary')}
        />
      </Helmet>
      <Container>
        <AssignmentDetail
          activeMonth={activeMonth}
          open={showAssignDetailModal}
          onClose={() => setShowAssignDetailModal(false)}
        ></AssignmentDetail>
        <Wrapper>
          <Loader open={isLoading} />
          <Content spacing={2} sx={{ position: 'relative' }}>
            <Box
              flexDirection="row"
              display="flex"
              justifyContent="space-between"
            >
              <Stack
                direction="row"
                spacing={2}
                alignItems="center"
                marginRight={1}
              >
                <Typography variant="h6" component="h6">
                  {isCustomer ? t('Assignable') : t('Summary')}
                </Typography>
                <Stack direction="row" alignItems="center" spacing={1}>
                  {currentTab === 'NORMAL_TAB' && (
                    <Stack direction="row" alignItems="center" spacing={1}>
                      <IconButton
                        onClick={diff_month => {
                          setCurrentMonth(
                            currentMonth
                              .clone()
                              .add(-ASSIGN_GET_MONTH_COUNT, 'month'),
                          );
                        }}
                      >
                        <ChevronLeft />
                      </IconButton>
                      {months.length && (
                        <Box>{`${_.head(months).slashMonthString} ~ ${
                          _.last(months).slashMonthString
                        }`}</Box>
                      )}

                      <IconButton
                        onClick={diff_month => {
                          setCurrentMonth(
                            currentMonth
                              .clone()
                              .add(ASSIGN_GET_MONTH_COUNT, 'month'),
                          );
                        }}
                      >
                        <ChevronRight />
                      </IconButton>
                    </Stack>
                  )}
                </Stack>
              </Stack>
            </Box>
            <FilterBar
              isCustomer={isCustomer}
              months={months}
              changeMonth={diff_month => {
                setCurrentMonth(currentMonth.clone().add(diff_month, 'month'));
              }}
              viewMode={currentTab}
              setViewMode={setCurrentTab}
              handleToggle={handleToggle}
              FilterAssignForm={FilterAssignForm}
            />
            <WrapTable>
              <TableContainer component={Paper}>
                <Table stickyHeader>
                  <TableHead>
                    <TableRow>
                      <TableCell
                        className="sticky-header"
                        sx={{
                          left: 0,
                        }}
                        colSpan={5}
                      ></TableCell>
                      {currentTab === 'NORMAL_TAB'
                        ? months.map(v => {
                            return (
                              <TableCell
                                sx={{ minWidth: 90 }}
                                align="center"
                                key={v.slashMonthString}
                                className={
                                  v.slashMonthString ===
                                  moment().format('YYYY/MM')
                                    ? 'current_month'
                                    : 'other_month'
                                }
                                width={pageWidth < 1600 ? 100 : 150}
                              >
                                {calculateAverageAssignForMonth(
                                  v.dashMonthString,
                                )}
                              </TableCell>
                            );
                          })
                        : commonMapping(projectList || []).map(v => {
                            const total_efforts = _.sumBy(v?.members, i => {
                              const startDate = moment(i.start_date);
                              const endDate = moment(i.end_date);
                              const cur_month = params.get('month')
                                ? moment(params.get('month'))
                                : moment();
                              if (
                                startDate.isSameOrBefore(cur_month, 'month') &&
                                (!i.end_date ||
                                  endDate.isSameOrAfter(cur_month, 'month'))
                              ) {
                                return i.effort_per_month;
                              } else return 0;
                            });
                            return (
                              <TableCell
                                sx={{ minWidth: 90, height: 52.5 }}
                                align="center"
                                key={v.id}
                                className={'other_month'}
                                width={pageWidth < 1600 ? 100 : 150}
                              >
                                <Typography fontSize={9} color="#000">
                                  {_.round(total_efforts, 2)} {t('Hours')}
                                </Typography>
                              </TableCell>
                            );
                          })}
                    </TableRow>
                    <TableRow>
                      <TableCell
                        className="sticky-header"
                        sx={{ minWidth: 60, left: 0 }}
                        width={60}
                      >
                        {t('No')}
                      </TableCell>
                      <TableCell
                        className="sticky-header"
                        sx={{ minWidth: 90, left: 60 }}
                        width={100}
                      >
                        {t('Code')}
                      </TableCell>
                      <TableCell
                        className="sticky-header"
                        sx={{ minWidth: 160, left: 150 }}
                      >
                        {t('Name')}
                      </TableCell>
                      <TableCell
                        className="sticky-header"
                        sx={{ minWidth: 120, left: 310 }}
                      >
                        {t('Departments')}
                      </TableCell>
                      <TableCell
                        className="sticky-header"
                        sx={{ minWidth: 90, left: 430 }}
                      >
                        {t('Role')}
                      </TableCell>
                      {currentTab === 'NORMAL_TAB'
                        ? months.map(v => {
                            return (
                              <TableCell
                                sx={{ minWidth: 100 }}
                                align="center"
                                key={v.slashMonthString}
                                className={
                                  v.slashMonthString ===
                                  moment().format('YYYY/MM')
                                    ? 'current_month'
                                    : 'other_month'
                                }
                                width={pageWidth < 1600 ? 100 : 150}
                              >
                                {v.slashMonthString}
                              </TableCell>
                            );
                          })
                        : commonMapping(projectList || [])?.map(v => (
                            <TableCell
                              sx={{ minWidth: 100 }}
                              align="center"
                              key={v.id}
                              width={pageWidth < 1600 ? 100 : 150}
                            >
                              {v.name}
                            </TableCell>
                          ))}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {currentTab === 'NORMAL_TAB'
                      ? assignmentSummary?.map((item, idx) => {
                          return (
                            <TableRow key={item.id}>
                              <TableCellBosy sx={{ left: 0 }}>
                                {idx + 1}
                              </TableCellBosy>
                              <TableCellBosy sx={{ left: 60 }}>{`${
                                item?.member_code || ''
                              }`}</TableCellBosy>
                              <TableCellBosy sx={{ left: 150 }}>
                                <Stack
                                  direction="row"
                                  spacing={'6px'}
                                  alignItems="center"
                                >
                                  <AvatarMember
                                    avatar={`${item?.avatar || ''}`}
                                    title={`${item?.full_name || ''}`}
                                    sx={{ width: 24, height: 24 }}
                                    status={item?.status}
                                  ></AvatarMember>
                                  <MemberLink
                                    member_id={item?.id}
                                    member_name={item?.full_name}
                                  ></MemberLink>
                                </Stack>
                              </TableCellBosy>
                              <TableCellBosy sx={{ left: 310 }}>{`${
                                item?.department_name || ''
                              }`}</TableCellBosy>
                              <TableCellBosy sx={{ left: 430 }}>{`${
                                item?.role_name || ''
                              }`}</TableCellBosy>
                              {months.map(v => {
                                const assign_effort =
                                  item?.data?.[v.dashMonthString]
                                    ?.assign_effort;
                                const commit_effort =
                                  item?.data?.[v.dashMonthString]
                                    ?.commit_effort;

                                let backgroundColor = '';
                                let assignable_hours =
                                  commit_effort - assign_effort;
                                assignable_hours =
                                  assignable_hours > 0 ? assignable_hours : 0;
                                let display_info = '-';
                                if (isCustomer) {
                                  display_info = assignable_hours
                                    ? assignable_hours + 'h'
                                    : '-';
                                  backgroundColor = hexToRgba(
                                    '#f0ad4e',
                                    +assignable_hours / 160,
                                  );
                                } else {
                                  display_info = assign_effort
                                    ? `${assign_effort}h / ${commit_effort}h`
                                    : '-';
                                  backgroundColor = hexToRgba(
                                    '#437dff',
                                    +assign_effort / 160,
                                  );
                                }
                                return (
                                  <TableCell
                                    align="center"
                                    key={v.slashMonthString}
                                    style={
                                      {
                                        // backgroundColor,
                                        // cursor: 'pointer',
                                        // cursor: assign_effort ? 'pointer' : 'unset',
                                      }
                                    }
                                    onClick={e => {
                                      e.preventDefault();
                                      if (assign_effort) {
                                        setActiveMonth(
                                          v.month.format('MM/YYYY'),
                                        );
                                        dispatch(
                                          getAssignmentDetailRequest({
                                            month: v.dashMonthString,
                                            id: item.id,
                                          }),
                                        );
                                      }
                                    }}
                                  >
                                    <Badge
                                      badgeContent={display_info}
                                      sx={{
                                        cursor: assign_effort
                                          ? 'pointer'
                                          : 'unset',
                                        '& .MuiBadge-badge': {
                                          backgroundColor:
                                            backgroundColor + ' !important',
                                        },
                                      }}
                                    ></Badge>
                                  </TableCell>
                                );
                              })}
                            </TableRow>
                          );
                        })
                      : _.map(
                          assignProSummary,
                          (v: AssignProjectSummary, k: number) => {
                            return (
                              <TableRow key={`assign_project_summary_${k}`}>
                                <TableCellBosy sx={{ left: 0 }}>
                                  {k + 1}
                                </TableCellBosy>
                                <TableCellBosy sx={{ left: 60 }}>
                                  {v?.member_code}
                                </TableCellBosy>
                                <TableCellBosy sx={{ left: 150 }}>
                                  {v.full_name}
                                </TableCellBosy>
                                <TableCellBosy sx={{ left: 310 }}>
                                  {v.department}
                                </TableCellBosy>
                                <TableCellBosy sx={{ left: 430 }}>
                                  {v.role}
                                </TableCellBosy>
                                {_.map(v?.projects, pro => {
                                  return (
                                    <TableCell
                                      key={`${v.id}_${pro.id}`}
                                      align="center"
                                    >
                                      <Badge
                                        badgeContent={pro?.assign_effort || '-'}
                                        max={999}
                                        sx={{
                                          '& .MuiBadge-badge': {
                                            backgroundColor: hexToRgba(
                                              '#437dff',
                                              +pro?.assign_effort / 160,
                                            ),
                                          },
                                        }}
                                      ></Badge>
                                    </TableCell>
                                  );
                                })}
                              </TableRow>
                            );
                          },
                        )}
                    {((_.isEmpty(assignmentSummary) &&
                      currentTab === 'NORMAL_TAB') ||
                      (_.isEmpty(assignProSummary) &&
                        currentTab === 'PROJECT_TAB')) && (
                      <TableRow>
                        <TableCell colSpan={9}>{t('No data')}</TableCell>
                      </TableRow>
                    )}
                  </TableBody>
                </Table>
              </TableContainer>
            </WrapTable>
          </Content>
        </Wrapper>
      </Container>
    </>
  );
}

const WrapTable = styled(Box)`
  & .MuiTableContainer-root {
    max-height: calc(100vh - 190px);
  }
  table {
    & .MuiBadge-badge {
      width: 90px;
    }
    tr th {
      color: rgb(0 0 0 / 85%);
      &.current_month {
        color: #437dff;
        font-weight: bold !important;
      }
      &.other_month {
        color: rgb(0 0 0 / 45%);
        background: #d9d9d9;
      }
      &.sticky-header {
        position: sticky;
        z-index: 99;
        background: #ffffff;
      }
    }
    thead tr:nth-child(2) th {
      top: 52.17px;
    }
    tr th,
    tr td {
    }
    tr td {
      color: rgb(0 0 0 / 85%);
    }
  }
`;
const TableCellBosy = styled(TableCell)`
  position: sticky;
  z-index: 98;
  background: #ffffff;
`;
