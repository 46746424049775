import React from 'react';

// UI
import {
  Box,
  Stack,
  InputLabel,
  FormControl,
  TextField,
  InputAdornment,
  Autocomplete,
  IconButton,
} from '@mui/material';

import { Search as SearchIcon } from '@mui/icons-material';

// Redux & Sagas
import { useSelector } from 'react-redux';

// Library
import { useForm, Controller } from 'react-hook-form';
import { useHistory, useLocation } from 'react-router-dom';
import Path from 'config/clientPath';
import queryString from 'query-string';
import { ICommonDataSchema } from 'types/common';
import styled from 'styled-components/macro';
import _ from 'lodash';
import DatePicker from '@mui/lab/DatePicker';
import moment from 'moment';
import { selectTimesheet } from 'app/pages/TimesheetPage/slice/selectors';
import { ChevronLeft, ChevronRight } from '@mui/icons-material';
import { useTranslation } from 'react-i18next';
import { RequestStatus } from 'constants/API';
import { handleSelection } from 'utils/helper';

export function FilterBar(props: { isCustomer: boolean }) {
  const { isCustomer } = props;
  const { t } = useTranslation();
  const location = useLocation();
  const { handleSubmit, control, watch, setValue, getValues } = useForm();

  const defaultProject = { id: -1, name: 'All' };
  const [selectedProject, setSelectedProject] =
    React.useState<ICommonDataSchema | null>(defaultProject);

  const { allProjects, allMembers, getAllProjectsStatus, getAllMembersStatus } =
    useSelector(selectTimesheet);
  const defaultMember = { id: -1, full_name: 'All' };
  const [selectedMember, setSelectedMember] =
    React.useState<any>(defaultMember);

  const [textSearch, setTextSearch] = React.useState('');
  const searchForm = React.useRef<HTMLFormElement>();
  const history = useHistory();

  const handleSearchCommon = (formData: any, textSearch: string) => {
    let newParams = {
      ...formData,
      project: selectedProject?.id || '',
      member: selectedMember?.id || '',
      month: formData?.month.format('YYYY-MM'),
      search: textSearch,
    };
    // remove query param when has not value or value is -1 (all value)
    const qs = queryString.stringify(_.omitBy(newParams, v => !v || v === -1));
    history.push(
      `${
        isCustomer ? Path.CUSTOMER_TIMESHEET_REPORT : Path.DAILY_REPORT_SUMMARY
      }?${qs}`,
    );
  };

  const handSearch = (formData: any) => {
    const params = queryString.parse(window.location.search);
    const text = params.search ?? '';
    setTextSearch(text);
    handleSearchCommon(formData, text);
  };

  const handleSearchByName = (event: React.ChangeEvent<HTMLInputElement>) => {
    const text = event?.target?.value;
    let formData = watch();
    handleSearchCommon(formData, text);
  };

  React.useEffect(() => {
    const params = queryString.parse(window.location.search);
    setValue('month', params?.month ? moment(params?.month) : moment());
    setTextSearch(params?.search ?? '');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.search]);

  React.useEffect(() => {
    if (getAllProjectsStatus === RequestStatus.SUCCESS) {
      handleSelection(
        queryString.parse(window.location.search),
        'project',
        allProjects,
        setSelectedProject,
        setValue,
        defaultProject,
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getAllProjectsStatus]);

  React.useEffect(() => {
    if (getAllMembersStatus === RequestStatus.SUCCESS) {
      handleSelection(
        queryString.parse(window.location.search),
        'member',
        allMembers,
        setSelectedMember,
        setValue,
        defaultMember,
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getAllMembersStatus]);

  const requestSubmit = () => {
    setTimeout(() => searchForm.current?.requestSubmit(), 10);
  };

  const resetProjectAndMember = () => {
    setSelectedMember(defaultMember);
    setSelectedProject(defaultProject);
  };

  const changeMonth = diff_month => {
    setValue(
      'month',
      (getValues('month') || moment()).clone().add(diff_month, 'month'),
    );
    resetProjectAndMember();
    requestSubmit();
  };
  return (
    <Box
      display="flex"
      justifyContent="space-between"
      component="form"
      method="GET"
      alignItems="center"
      ref={searchForm}
      onSubmit={handleSubmit(handSearch)}
    >
      <Box display="flex" flexDirection="row" justifyContent="space-between">
        <Stack direction="row" alignItems="center" spacing={1}>
          <InputLabel>{t('Month')}: </InputLabel>
          <IconButton
            onClick={() => {
              changeMonth(-1);
            }}
          >
            <ChevronLeft />
          </IconButton>
          <FormControl margin="dense" sx={{ width: 115 }}>
            <Controller
              control={control}
              name="month"
              render={({ field: { onChange, value } }) => {
                return (
                  <DatePicker
                    views={['month', 'year']}
                    value={value}
                    onChange={e => {
                      resetProjectAndMember();
                      onChange(e);
                      requestSubmit();
                    }}
                    inputFormat="YYYY/MM"
                    renderInput={params => (
                      <TextField
                        {...params}
                        fullWidth
                        hiddenLabel
                        size="small"
                        inputProps={{ ...params.inputProps, readOnly: true }}
                      />
                    )}
                  />
                );
              }}
            />
          </FormControl>
          <IconButton
            onClick={() => {
              changeMonth(1);
            }}
          >
            <ChevronRight />
          </IconButton>

          <InputLabel>{t('Project')}: </InputLabel>
          <FormControlCustom>
            <Controller
              control={control}
              name="project"
              render={({ field }) => {
                const { onChange } = field;
                return (
                  <Autocomplete
                    value={selectedProject || undefined}
                    isOptionEqualToValue={(option, value) => {
                      return value?.id && value?.id === option?.id
                        ? true
                        : false;
                    }}
                    renderOption={(props, option) => (
                      <Box component="li" {...props} key={`prj_${option.id}`}>
                        {option?.name}
                      </Box>
                    )}
                    options={[{ ...defaultProject }, ...allProjects]}
                    getOptionLabel={it => it?.name || ''}
                    onChange={(e, newValue) => {
                      setSelectedProject(newValue);
                      onChange(newValue?.name);
                      requestSubmit();
                    }}
                    renderInput={params => {
                      return (
                        <TextField
                          {...params}
                          size="small"
                          variant="outlined"
                          inputProps={{ ...params.inputProps, readOnly: true }}
                        />
                      );
                    }}
                    disableClearable
                  />
                );
              }}
            />
          </FormControlCustom>

          <InputLabel>{t('Assignee')}: </InputLabel>
          <FormControlCustom className="member_form">
            <Controller
              control={control}
              name="member"
              render={({ field }) => {
                const { onChange } = field;

                return (
                  <Autocomplete
                    value={selectedMember}
                    isOptionEqualToValue={(option, value) => {
                      return value?.id && value?.id === option?.id
                        ? true
                        : false;
                    }}
                    renderOption={(props, option) => (
                      <Box
                        component="li"
                        {...props}
                        key={`member_${option.id}`}
                      >
                        {option?.full_name}
                      </Box>
                    )}
                    options={[{ ...defaultMember }, ...allMembers]}
                    getOptionLabel={it => it?.full_name || ''}
                    onChange={(e, newValue) => {
                      setSelectedMember(newValue);
                      onChange(newValue?.full_name);
                      requestSubmit();
                    }}
                    renderInput={params => {
                      return (
                        <TextField
                          {...params}
                          size="small"
                          variant="outlined"
                          inputProps={{ ...params.inputProps, readOnly: true }}
                        />
                      );
                    }}
                    disableClearable
                  />
                );
              }}
            />
          </FormControlCustom>
        </Stack>
      </Box>

      <Box
        display="flex"
        flexDirection="row"
        justifyContent="space-between"
        sx={{ marginLeft: '6px' }}
      >
        {!isCustomer && (
          <Stack
            direction="row"
            spacing={2}
            alignItems="center"
            sx={{ flexShrink: 0 }}
          >
            <TextField
              type="search"
              placeholder="Search keyword"
              aria-label="Search"
              size="small"
              value={textSearch}
              onChange={(event: any) => {
                setTextSearch(event?.target?.value);
              }}
              sx={{
                background: '#fff',
              }}
              onKeyUp={(event: any) => {
                if (event.key === 'Enter') {
                  handleSearchByName(event);
                }
              }}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon />
                  </InputAdornment>
                ),
              }}
            />
          </Stack>
        )}
      </Box>
    </Box>
  );
}

export default FilterBar;

const FormControlCustom = styled(FormControl)`
  width: 150px;
  & .MuiAutocomplete-hasPopupIcon.MuiAutocomplete-hasClearIcon,
  .MuiAutocomplete-root {
    .MuiOutlinedInput-root {
      padding-right: 50px !important;
    }
  }
  &.member_form {
    width: 190px;
    & .MuiAutocomplete-hasPopupIcon.MuiAutocomplete-hasClearIcon,
    .MuiAutocomplete-root {
      .MuiOutlinedInput-root {
        padding-right: 50px !important;
      }
    }
  }
`;
