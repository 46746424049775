import {
  Box,
  Button,
  FormControl,
  Modal,
  TextField,
  Typography,
} from '@mui/material';
import { Loader } from 'app/components/Loader';
import { useRecruitmentSlice } from 'app/pages/RecruitmentsPage/slice';
import { selectRecruitments } from 'app/pages/RecruitmentsPage/slice/selectors';
import { INotes } from 'app/pages/RecruitmentsPage/slice/type';
import { useEffect } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import { checkIsRequesting } from 'utils/helper';

interface IModalNoteRequest {
  id: string;
  selectedData?: INotes | null;
  setSelectedData: (value: INotes | null) => void;
  isShowModalRequest: boolean;
  setIsShowModalRequest: (value: boolean) => void;
}

export function ModalNoteRequest(props: IModalNoteRequest) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const {
    id,
    setSelectedData,
    selectedData,
    isShowModalRequest,
    setIsShowModalRequest,
  } = props;
  const {
    control,
    handleSubmit,
    reset,
    watch,
    formState: { errors },
  } = useForm();

  const {
    actions: { updateNoteRequest, addNoteRequest },
  } = useRecruitmentSlice();

  const { updateNoteStatus, addNoteStatus } = useSelector(selectRecruitments);

  const handleNoteSubmit = async () => {
    if (id) {
      setIsShowModalRequest(false);
      if (selectedData) {
        const formData = {
          id: id,
          note_id: selectedData?.id,
          content: watch('content'),
        };
        dispatch(updateNoteRequest(formData));
      } else {
        const formData = {
          id: id,
          content: watch('content'),
        };
        dispatch(addNoteRequest(formData));
      }
      setSelectedData(null);
      reset({ content: '' });
    }
  };

  const handleClose = () => {
    setIsShowModalRequest(false);
    setSelectedData(null);
    reset({ content: '' });
  };

  useEffect(() => {
    if (selectedData) {
      const { content } = selectedData;
      reset({ content });
    } else {
      reset({ content: '' });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedData]);

  const isLoading = checkIsRequesting([updateNoteStatus, addNoteStatus]);

  return (
    <>
      <Modal
        keepMounted
        open={isShowModalRequest}
        aria-labelledby="keep-mounted-modal-title"
        aria-describedby="keep-mounted-modal-description"
      >
        <StyledDiv>
          <Loader open={isLoading} />
          <Typography
            variant="body1"
            component="p"
            sx={{
              p: '15px',
              borderBottom: '1px solid #ccc',
              fontWeight: '500',
              fontSize: '18px',
            }}
          >
            {selectedData ? t('EDIT NOTE') : t('ADD NOTE')}
          </Typography>

          <FormControl
            sx={{
              maxHeight: '300px',
              overflow: 'auto',
              p: '10px',
              width: '100%',
            }}
            onSubmit={handleSubmit(handleNoteSubmit)}
            component="form"
          >
            <Controller
              control={control}
              name="content"
              rules={{ required: t('This field is required') as string }}
              render={({ field }) => {
                const { onChange, value } = field;
                return (
                  <TextField
                    placeholder=""
                    multiline
                    minRows={5}
                    maxRows={5}
                    variant="outlined"
                    fullWidth
                    autoFocus
                    margin="dense"
                    onChange={onChange}
                    value={value}
                    error={!!errors.content}
                    helperText={errors.content?.message}
                  />
                );
              }}
            />
            <Box sx={displayflexend}>
              <Button variant="outlined" type="button" onClick={handleClose}>
                {t('Cancel')}
              </Button>

              <Button variant="contained" color="primary" type="submit">
                {t('Submit')}
              </Button>
            </Box>
          </FormControl>
        </StyledDiv>
      </Modal>
    </>
  );
}

const StyledDiv = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 50%;
  background-color: #fff;
  box-shadow: 0 0 24px rgba(0, 0, 0, 0.16);
  min-width: 500px;
  border-radius: 5px;
`;

const displayflexend = {
  display: 'flex',
  justifyContent: 'flex-end',
  alignItems: 'center',
  height: '50%',
  gap: '2%',
  background: 'white',
  py: 1,
  borderRadius: '0 0 10px 10px ',
};
