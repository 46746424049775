import React from 'react';

// UI
import { Box } from '@mui/material';

// Library
import styled from 'styled-components/macro';
import { TASK_STATUS_LIST } from 'constants/common';

export enum STATUS_DISPLAY_TYPE {
  ADMIN = 'admin',
  USER = 'user',
}
interface ITaskStatus {
  status: number | string;
  display_type: STATUS_DISPLAY_TYPE;
}

export function TaskStatus(props: ITaskStatus) {
  const { status, display_type } = props;
  const currentStatus = TASK_STATUS_LIST?.[status]?.[display_type];
  return (
    <Wrapper
      style={{
        color: currentStatus?.color,
        backgroundColor: currentStatus?.backgroundColor,
      }}
    >
      {currentStatus?.label}
    </Wrapper>
  );
}

const Wrapper = styled(Box)`
  border-radius: 6px;
  padding: 4px 12px;
  width: fit-content;
`;
