import {
  Badge,
  BadgeProps,
  Box,
  Button,
  FormControl,
  MenuItem,
  Select,
  Typography,
} from '@mui/material';
import { AvatarMember } from 'app/components/Avatar';
import { Container } from 'app/components/Container';
import { Content, Wrapper } from 'app/components/Wrapper';
import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';

import EmailIcon from '@mui/icons-material/Email';
import LocalPhoneIcon from '@mui/icons-material/LocalPhone';
import SourceIcon from '@mui/icons-material/Source';
import SpeakerNotesIcon from '@mui/icons-material/SpeakerNotes';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import DeleteIcon from '@mui/icons-material/Delete';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { useRecruitmentSlice } from '../RecruitmentsPage/slice';
import { selectRecruitments } from '../RecruitmentsPage/slice/selectors';
import styled from 'styled-components';
import { Controller, useForm } from 'react-hook-form';
import { CANDIDATE_STATUS, JOB_COLOR } from 'constants/common';
import _ from 'lodash';
import { RequestStatus } from 'constants/API';
import { Loader } from 'app/components/Loader';
import { checkIsRequesting } from 'utils/helper';
import { Tab1 } from './components/Tab1';
import { useGlobalSlice } from '../GlobalContainer/slice';
import { Tab2 } from './components/Tab2';
import { Tab3 } from './components/Tab3';
import { Tab4 } from './components/Tab4';
import Path from 'config/clientPath';
import queryString from 'query-string';
import { ModalNoteRequest } from './components/ModalNoteRequest';
import { INotes } from '../RecruitmentsPage/slice/type';
import { useMemberSlice } from '../MemberPage/slice';
import ConfirmDelete from 'app/components/ConfirmDelete';
interface dataTab {
  nameButton: string;
}

export function CandidateDetailPage() {
  const { t } = useTranslation();
  const history = useHistory();
  const dispatch = useDispatch();
  const location = useLocation();
  const params = queryString.parse(location.search);
  const { id }: { id: string } = useParams();
  const { control, watch, reset } = useForm();
  const [isShowModalRequest, setIsShowModalRequest] = useState<boolean>(false);
  const [isShowDialogDelete, setIsShowDialogDelete] = useState<boolean>(false);
  const [selectedData, setSelectedData] = useState<INotes | null>();

  const dataTabitems: dataTab[] = [
    { nameButton: t('Candidate Information') },
    { nameButton: t('Interaction Logs') },
    { nameButton: t('Interview Recordings') },
    { nameButton: t('Notes') },
  ];
  const {
    actions: { getTechnicalSkillRequest, getLanguageListRequest },
  } = useMemberSlice();

  const {
    infoCandidate,
    notes,
    getInfoCandidateStatus,
    getInteractionLogStatus,
    getInterviewRecordStatus,
    updateInfoCandidateStatus,
    deleteReCandidateState,
    addNoteStatus,
  } = useSelector(selectRecruitments);
  const {
    actions: {
      getInfoCandidateRequest,
      updateInfoCandidateRequest,
      resetCandiddateDetailState,
      getNotesRequest,
      resetCandiddateDetailStatusState,
      deleteReCandidateRequest,
      getInteractionLogRequest,
      getInterviewRecordRequest,
    },
  } = useRecruitmentSlice();
  const {
    actions: { setErrorMessages, setBreadcumbs, setSuccessMessages },
  } = useGlobalSlice();

  const handleChangeStatus = async (e, onChange) => {
    await onChange(e);
    dispatch(updateInfoCandidateRequest({ id: id, formData: watch() }));
    setTimeout(() => {
      dispatch(getInteractionLogRequest({ id: id }));
    }, 500);
  };

  const handleClickTab = (index: number) => {
    const newParams = { ...params, tab: index };
    history.push(
      `${Path.CANDIDATES}/${id}?${queryString.stringify(newParams)}`,
    );
  };

  const handleDeleteCandidate = () => {
    dispatch(deleteReCandidateRequest({ id: id }));
    setIsShowDialogDelete(false);
    history.push(`${Path.CANDIDATES}`);
  };

  useEffect(() => {
    dispatch(
      setBreadcumbs([
        { title: t('Candidates'), path: Path.CANDIDATES },
        { title: t('Candidate Detail') },
      ]),
    );
    dispatch(getInfoCandidateRequest({ id: id }));
    dispatch(getInteractionLogRequest({ id: id }));
    dispatch(getInterviewRecordRequest({ id: id }));
    dispatch(getNotesRequest({ id: id }));
    dispatch(getTechnicalSkillRequest({}));
    dispatch(getLanguageListRequest({}));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (getInfoCandidateStatus === RequestStatus.SUCCESS) {
      if (infoCandidate) {
        let formData = watch();
        formData.status = infoCandidate?.status;
        reset(formData);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getInfoCandidateStatus]);

  useEffect(() => {
    if (updateInfoCandidateStatus === RequestStatus.SUCCESS) {
      dispatch(getInfoCandidateRequest({ id: id }));
      dispatch(
        setSuccessMessages([t('Update information Candidate Success!')]),
      );
    }
    if (updateInfoCandidateStatus === RequestStatus.ERROR) {
      dispatch(setErrorMessages([t('Update information Candidate Error!')]));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updateInfoCandidateStatus]);

  useEffect(() => {
    if (addNoteStatus === RequestStatus.SUCCESS) {
      dispatch(getNotesRequest({ id: id }));
      dispatch(setSuccessMessages([t('Add Note Success!')]));
    }
    if (addNoteStatus === RequestStatus.ERROR) {
      dispatch(setErrorMessages([t('Add Note Error!')]));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [addNoteStatus]);

  useEffect(() => {
    if (getInfoCandidateStatus === RequestStatus.ERROR) {
      setTimeout(() => {
        dispatch(resetCandiddateDetailState());
      }, 500);
      history.push(`${Path.CANDIDATES}`);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getInfoCandidateStatus]);

  useEffect(
    () => () => {
      dispatch(resetCandiddateDetailState());
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    ['componentWillUnMount'],
  );

  useEffect(
    () => () => {
      dispatch(resetCandiddateDetailStatusState());
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [params.tab],
  );

  const isLoading = checkIsRequesting([
    updateInfoCandidateStatus,
    getInfoCandidateStatus,
    getInteractionLogStatus,
    getInterviewRecordStatus,
    deleteReCandidateState,
    addNoteStatus,
  ]);

  return (
    <>
      <Helmet>
        <title>{t('Candidate Detail')}</title>
        <meta name="description" content="Palette" />
      </Helmet>
      <Container>
        <Wrapper>
          <Content spacing={2}>
            <Loader open={isLoading} />
            <>
              <Box sx={backgroundWhiteTop}>
                <Box sx={{ display: 'flex', gap: '26px' }}>
                  <AvatarMember
                    title={'memberInfo?.full_name'}
                    avatar={infoCandidate?.profile_picture}
                    size={40}
                    sx={{ width: 70, height: 70 }}
                  />

                  <Box
                    sx={{
                      display: 'flex',
                      flexDirection: 'column',
                      alignItems: 'center',
                      gap: '5px',
                    }}
                  >
                    <Typography
                      variant="body1"
                      component="h2"
                      sx={{ color: '#3699FF', fontSize: '16px', width: '100%' }}
                    >
                      {infoCandidate?.fullname}
                    </Typography>
                    <Typography
                      variant="body1"
                      component="p"
                      sx={typographyText}
                    >
                      <EmailIcon sx={{ color: '#C4C4C4' }} />{' '}
                      {infoCandidate?.email}
                    </Typography>
                    <Typography
                      variant="body1"
                      component="p"
                      sx={typographyText}
                    >
                      <LocalPhoneIcon sx={{ color: '#C4C4C4' }} />{' '}
                      {infoCandidate?.phone}
                    </Typography>
                    <Typography
                      variant="body1"
                      component="p"
                      sx={{
                        color: '#5D5FEF',
                        fontSize: '12px',
                        width: '100%',
                        display: 'flex',
                        gap: '8px',
                        alignItems: 'center',
                      }}
                    >
                      <SourceIcon sx={{ color: '#5D5FEF' }} />{' '}
                      {infoCandidate?.source.name}
                    </Typography>

                    <FormControl sx={{ width: '100%', marginTop: '10px' }}>
                      <Controller
                        control={control}
                        name={`status`}
                        render={({ field }) => {
                          const { onBlur, value, onChange } = field;
                          return (
                            <Select
                              sx={{
                                width: '132px',
                                height: '30px',
                                borderRadius: '45px',
                                background: `${JOB_COLOR[watch('status')]}`,
                                color: 'white',
                                border: 'none',
                                fontWeight: '500',
                                boxShadow: 'none',
                                '.MuiOutlinedInput-notchedOutline': {
                                  border: 0,
                                },
                                '&.MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline':
                                  {
                                    border: 0,
                                  },
                                '&.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline':
                                  {
                                    border: 0,
                                  },
                              }}
                              fullWidth
                              size="small"
                              onBlur={onBlur}
                              onChange={e => {
                                handleChangeStatus(e, onChange);
                              }}
                              value={`${value}`}
                            >
                              {_.map(CANDIDATE_STATUS, (v, k) => (
                                <MenuItem key={k} value={`${k}`}>
                                  {v}
                                </MenuItem>
                              ))}
                            </Select>
                          );
                        }}
                      />
                    </FormControl>
                  </Box>
                </Box>

                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    gap: '10px',
                  }}
                >
                  <Button
                    variant="outlined"
                    startIcon={<SpeakerNotesIcon />}
                    sx={cssButton}
                    onClick={() => {
                      setIsShowModalRequest(true);
                    }}
                  >
                    {t('Add Note')}
                    <StyledBadge
                      badgeContent={notes.length === 0 ? 0 : notes.length}
                      color="info"
                    ></StyledBadge>
                  </Button>

                  <a
                    href={infoCandidate?.cv_file}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <Button
                      variant="outlined"
                      startIcon={<FileDownloadIcon />}
                      sx={cssButton}
                    >
                      {t('Download')}
                    </Button>
                  </a>
                  <Button
                    variant="outlined"
                    startIcon={<DeleteIcon />}
                    sx={cssButton}
                    onClick={() => {
                      setIsShowDialogDelete(true);
                    }}
                  >
                    {t('Delete')}
                  </Button>
                </Box>
              </Box>
              <Box
                sx={{
                  background: 'white',
                  mt: '0px !important',
                  p: '14px 14px 0 14px',
                  display: 'flex',
                  gap: '24px',
                  borderBottom: '1px solid #8F91AC',
                }}
              >
                {dataTabitems.map((item, index) => (
                  <ButtonTab
                    key={index}
                    onClick={() => handleClickTab(index)}
                    selected={
                      Number(params.tab)
                        ? Number(params.tab) === index
                        : 0 === index
                    }
                  >
                    {index === 3 ? (
                      <>
                        {item.nameButton}
                        <StyledBadge
                          badgeContent={notes.length === 0 ? 0 : notes.length}
                          color="info"
                        ></StyledBadge>
                      </>
                    ) : (
                      item.nameButton
                    )}
                  </ButtonTab>
                ))}
              </Box>

              {(Number(params.tab) === 0 || params.tab === undefined) && (
                <Tab1 id={id} />
              )}
              {Number(params.tab) === 1 && <Tab2 />}

              {Number(params.tab) === 2 && <Tab3 id={id} />}

              {Number(params.tab) === 3 && (
                <Tab4
                  id={id}
                  isShowModalRequest={isShowModalRequest}
                  setIsShowModalRequest={setIsShowModalRequest}
                  selectedData={selectedData}
                  setSelectedData={setSelectedData}
                />
              )}

              <ConfirmDelete
                open={isShowDialogDelete}
                onClose={() => setIsShowDialogDelete(false)}
                onConfirm={handleDeleteCandidate}
                title="candidate"
                name={''}
              />

              <ModalNoteRequest
                id={id}
                selectedData={selectedData}
                setSelectedData={setSelectedData}
                isShowModalRequest={isShowModalRequest}
                setIsShowModalRequest={setIsShowModalRequest}
              />
            </>
          </Content>
        </Wrapper>
      </Container>
    </>
  );
}

const backgroundWhiteTop = {
  background: 'white',
  borderRadius: '5px 5px 0 0',
  p: '20px',
  display: 'flex',
  gap: '20px',
  justifyContent: 'space-between',
};

const typographyText = {
  color: '#000',
  fontSize: '12px',
  width: '100%',
  display: 'flex',
  gap: '8px',
  alignItems: 'center',
};

const cssButton = {
  height: '30px',
  borderRadius: '45px',
  border: '1px solid #E2E3E4',
  color: '#939393',
  width: '140px',
  justifyContent: 'flex-start',
};

const StyledBadge = styled(Badge)<BadgeProps>(({ theme }) => ({
  '& .MuiBadge-badge': {
    right: -15,
    border: `2px solid `,
    padding: '0 4px',
    width: 'max-content',
  },
}));

const ButtonTab = styled.button<{ selected?: boolean }>`
  color: ${props => (props.selected ? '#3699ff' : '#464F60')};
  font-size: 14px;
  font-style: normal;
  font-weight: ${props => (props.selected ? '700' : '500')};
  line-height: 20px;
  letter-spacing: 0.28px;
  background: none;
  border: none;
  border-bottom: 2px solid;
  padding-bottom: 8px;
  border-color: ${props => (props.selected ? '#3699ff' : 'transparent')};
  cursor: pointer;
`;
