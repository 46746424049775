import {
  Box,
  Button,
  FormControl,
  FormHelperText,
  Modal,
  Stack,
  Typography,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { ReactComponent as CloudIcon } from 'assets/icons/candidates/cloud_icon.svg';
import DeleteIcon from '@mui/icons-material/Delete';
import { useDropzone } from 'react-dropzone';
import _ from 'lodash';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useGlobalSlice } from 'app/pages/GlobalContainer/slice';
import { useRecruitmentSlice } from 'app/pages/RecruitmentsPage/slice';
import { selectRecruitments } from 'app/pages/RecruitmentsPage/slice/selectors';

interface IModalUploadFile {
  id: string;
  setIsOpenModal: (value: boolean) => void;
  isOpenModal: boolean;
}

export function ModalUploadFile(props: IModalUploadFile) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [files, setFiles] = useState<any>([]);
  const [textError, setTextError] = useState<string | null>(null);
  const { isOpenModal, setIsOpenModal, id } = props;

  const { setErrorMessages } = useGlobalSlice().actions;
  const {
    actions: { uploadVideoRecordRequest },
  } = useRecruitmentSlice();

  const handleSubmitForm = async () => {
    if (files?.length === 0) {
      setTextError(t('This field is required'));
      return;
    } else if (validateTotalSize()) {
      setTextError(t('Total file size exceeds 100MB'));
      return;
    } else {
      setTextError(null);
      setIsOpenModal(false);
      setFiles([]);
      let formData: FormData = new FormData();
      files.forEach((file: any) => {
        if (file instanceof File) {
          formData.append('records', file);
        }
      });
      dispatch(uploadVideoRecordRequest({ id: id, formData }));
    }
  };

  const validateTotalSize = () => {
    const totalSize = files.reduce((acc, file) => acc + file.size, 0);
    if (totalSize >= 100 * 1024 * 1024) {
      return true;
    }
    return false;
  };

  function isImageByExtension(fileName) {
    const allowedExtensions = ['mp3', 'wav', 'm4a', 'mp4'];
    const fileExtension = fileName.split('.').pop().toLowerCase();
    return allowedExtensions.includes(fileExtension);
  }

  const handleRemoveFile = (indexToRemove: number) => {
    setFiles(prevFiles => {
      const updatedFiles = [...prevFiles];
      updatedFiles.splice(indexToRemove, 1);
      return updatedFiles;
    });
  };

  const onDrop = (acceptedFiles: any) => {
    const filteredFiles = acceptedFiles.filter(file => {
      // filter <100mb
      if (file.size >= 100 * 1024 * 1024) {
        dispatch(setErrorMessages([`${file.name}${t(` file over 100mb!`)}`]));
        return false;
      } else if (!isImageByExtension(file.name)) {
        dispatch(
          setErrorMessages([`${file.name}${t(` file type does not match!`)}`]),
        );
        return false;
      } else {
        return true;
      }
    });
    setFiles(prevFiles => [...prevFiles, ...filteredFiles]);
  };

  const { getRootProps } = useDropzone({
    onDrop,
  });

  const handleClose = () => {
    setIsOpenModal(false);
    setFiles([]);
    setTextError(null);
  };

  return (
    <>
      <Modal
        keepMounted
        open={isOpenModal}
        aria-labelledby="upload-modal-title"
        aria-describedby="upload-modal-description"
        disableAutoFocus={true}
      >
        <StyledDiv>
          <FormControl fullWidth>
            <Typography
              variant="body1"
              component="p"
              p="15px"
              borderBottom="1px solid #bdbdbd"
              fontWeight="500"
              fontSize="18px"
            >
              {t('UPLOAD')}
            </Typography>

            <Box
              {...getRootProps()}
              border="1px dashed #676767"
              borderRadius="4px"
              m="15px"
            >
              <Stack
                spacing="15px"
                display="flex"
                justifyContent="center"
                alignItems="center"
                p="40px"
                sx={{
                  cursor: 'pointer',
                }}
              >
                <Box>
                  <Box width="64px">
                    <CloudIcon />
                  </Box>
                </Box>
                <Typography
                  variant="body1"
                  component="p"
                  fontSize="16px"
                  fontWeight="600"
                >
                  {t('Drag & drop files or ')}
                  <Typography
                    color="#3699FF"
                    component="span"
                    fontSize="16px"
                    fontWeight="600"
                    sx={{ textDecoration: 'underline' }}
                  >
                    {t('Browse')}
                  </Typography>
                </Typography>
                <Typography variant="body1" component="p" fontSize="12px">
                  {t(
                    'Upload audio files (.mp3, .wav, .m4a, .mp4) under 100MB.',
                  )}
                </Typography>
              </Stack>
            </Box>
            {textError && (
              <Box px="15px" pb="15px">
                <FormHelperText sx={{ marginX: '0' }} component="span" error>
                  {textError}
                </FormHelperText>
              </Box>
            )}
            <Box
              p={!_.isEmpty(files) ? '15px' : ''}
              pt="0"
              maxHeight="300px"
              overflow="auto"
            >
              {files?.map((file: any, index: number) => (
                <Box
                  key={index}
                  mt={index === 0 ? '0' : '10px'}
                  display="flex"
                  justifyContent="space-between"
                  p="10px"
                  border="1px solid #3699FF"
                  borderRadius="4px"
                >
                  <Typography>
                    {file.name} ({(file.size / (1024 * 1024)).toFixed(2)} MB)
                  </Typography>

                  <Box
                    onClick={() => handleRemoveFile(index)}
                    color="red"
                    style={{
                      cursor: 'pointer',
                    }}
                  >
                    <DeleteIcon />
                  </Box>
                </Box>
              ))}
            </Box>

            <Stack
              direction="row"
              display="flex"
              justifyContent="flex-end"
              p="15px"
              borderTop="1px solid #BDBDBD"
              spacing={2}
            >
              <Button
                variant="contained"
                color="inherit"
                onClick={handleClose}
                type="button"
              >
                {t('Cancel')}
              </Button>
              <Button
                variant="contained"
                color="primary"
                type="button"
                onClick={() => {
                  handleSubmitForm();
                }}
              >
                {t('Submit')}
              </Button>
            </Stack>
          </FormControl>
        </StyledDiv>
      </Modal>
    </>
  );
}

const StyledDiv = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 50%;
  background-color: #fff;
  box-shadow: 0 0 24px rgba(0, 0, 0, 0.16);
  min-width: 500px;
`;
