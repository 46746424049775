import * as React from 'react';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import { Stack, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';

interface ILoader {
  open: boolean;
}

export function Loader(props: ILoader) {
  const { open } = props;

  return (
    <div>
      <Backdrop
        sx={{ color: '#fff', zIndex: theme => theme.zIndex.drawer + 1 }}
        open={open}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    </div>
  );
}

export function LoaderGenCV(props: ILoader) {
  const { open } = props;
  const { t } = useTranslation();
  const [displayText, setDisplayText] = React.useState(
    'AI is reading the resume...',
  );
  const [count, setCount] = React.useState(0);

  React.useEffect(() => {
    let timeoutId: NodeJS.Timeout;

    if (open) {
      timeoutId = setTimeout(() => {
        if (count > 0 && count % 3 === 0) {
          setDisplayText('AI is reading the resume...');
        } else {
          setDisplayText('Please wait...');
        }
        setCount(prevCount => prevCount + 3);
      }, 3000);
    }

    return () => {
      clearTimeout(timeoutId);
    };
  }, [open, count]);

  return (
    <div>
      <Backdrop
        sx={{ color: '#fff', zIndex: theme => theme.zIndex.drawer + 1500 }}
        open={open}
      >
        <Stack spacing={2} alignItems="center">
          <CircularProgress color="inherit" />
          <Typography fontSize="14px">{t(displayText)}</Typography>
        </Stack>
      </Backdrop>
    </div>
  );
}
