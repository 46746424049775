import React from 'react';

// UI
import { Avatar } from '@mui/material';
import _ from 'lodash';
import Path from 'config/clientPath';
import { Link } from 'react-router-dom';
// Library

export function AvatarMember(props) {
  const fullName = props.title ? props.title.split(' ') : [];
  const firstLetter = _.cloneDeep(fullName).shift()
    ? `${fullName.shift()?.charAt(0)}${
        _.cloneDeep(fullName).pop() ? fullName.pop().charAt(0) : ''
      }`
    : '';

  const size = props?.size || 24;
  const randomColor = [
    '#8E47FF',
    '#476AFF',
    '#2CEBB0',
    '#DCEB2C',
    '#FF9E1A',
    '#FF6A1C',
    '#502298',
    '#2744BF',
    '#7D8805',
    '#981643',
    '#FF4786',
  ];
  const [color, setColor] = React.useState('');

  React.useEffect(() => {
    if (props.title) {
      const randomColorLength = randomColor.length;
      let colorIndex = _.head(firstLetter)
        ? firstLetter.toUpperCase().charCodeAt(0) % randomColorLength
        : 0;
      if (firstLetter[1]) {
        colorIndex =
          (colorIndex +
            (firstLetter.toUpperCase().charCodeAt(1) % randomColorLength)) %
          randomColorLength;
      }
      setColor(randomColor[colorIndex]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.title]);

  if (!props.avatar) {
    return (
      <Link
        style={{ textDecoration: 'none' }}
        to={
          props.member_id
            ? `${Path.MEMBERS}/edit/${props.member_id}`
            : location => ({ ...location })
        }
      >
        <Avatar
          key={props?.key}
          sx={{
            backgroundColor: color,
            ...props?.sx,
          }}
          title={props.title}
          style={{ fontSize: Math.round(size * 0.45) }}
        >
          {firstLetter}
        </Avatar>
      </Link>
    );
  } else {
    return (
      <Link
        style={{ textDecoration: 'none' }}
        to={
          props.member_id
            ? `${Path.MEMBERS}/edit/${props.member_id}`
            : location => ({ ...location })
        }
      >
        <Avatar
          key={props?.key}
          title={props.title}
          sx={props?.sx}
          src={props?.avatar}
        ></Avatar>
      </Link>
    );
  }
}
