import React, { useState, useEffect } from 'react';

// UI
import { Helmet } from 'react-helmet-async';

import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  AvatarGroup,
  DialogContentText,
  DialogTitle,
  IconButton,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  Divider,
  CardContent,
  Card,
  Paper,
  Pagination,
} from '@mui/material';
import styled from 'styled-components/macro';
import { AddCircle as AddIcon } from '@mui/icons-material';
import { AvatarMember } from 'app/components/Avatar';
import { ReactComponent as EditIcon } from 'assets/icons/button/edit.svg';
import { ReactComponent as DeleteIcon } from 'assets/icons/button/delete.svg';

import { Container } from 'app/components/Container';

import CreateDialogForm from './components/CreateDialog';
import FilterBar from './components/FilterBar';
import ProjectSummary from './components/ProjectSummary';

import { Wrapper, Content } from 'app/components/Wrapper';

// Redux & Sagas
import { useDispatch, useSelector } from 'react-redux';
import { RequestStatus } from 'constants/API';
import { selectProject } from './slice/selectors';
import { useProjectsSlice } from './slice';
import { IProject } from './slice/types';
import { useGlobalSlice } from 'app/pages/GlobalContainer/slice';
import { useMemberSlice } from 'app/pages/MemberPage/slice';

// Library
import _ from 'lodash';
import { Link, useLocation } from 'react-router-dom';
import Path from 'config/clientPath';
import { PROJECT_TYPE } from 'constants/ProjectMetadata';
import { STATUS_LIST } from 'constants/common';
import CollapseTable from './components/CollapseTable';
import { useHistory } from 'react-router-dom';
import queryString from 'query-string';
import { checkIsRequesting } from 'utils/helper';
import { selectMember } from '../MemberPage/slice/selectors';
import { Loader } from 'app/components/Loader';

export function ProjectPage({ ...props }) {
  const dispatch = useDispatch();
  const {
    getProjectListRequest,
    getProjectSummaryRequest,
    deleteProjectRequest,
    resetProjectRequestStatus,
  } = useProjectsSlice().actions;

  const {
    projectList,
    getProjectListStatus,
    getProjectSummaryStatus,
    createProjectStatus,

    deleteProjectStatus,
  } = useSelector(selectProject);
  const { getMemberListStatus, deleteAssignmentInfoStatus } =
    useSelector(selectMember);

  const { setBreadcumbs, setSuccessMessages } = useGlobalSlice().actions;

  const location = useLocation();
  const history = useHistory();
  const [showConfirm, setShowConfirm] = useState(false);
  const [isCreateDialogShow, setIsCreateDialogShow] = useState<boolean>(false);

  const [dataItem, setDataItem] = useState<IProject | null>();
  const [page, setPage] = React.useState(1);

  const [currentProjectTab, setCurrentProjectTab] = React.useState<
    string | null
  >('LIST_TAB');
  const handleChangeProjectTab = (event, currentProjectTab) => {
    let params = queryString.parse(window.location.search);
    if (currentProjectTab) {
      params = { ...params, tab: currentProjectTab };
      setCurrentProjectTab(currentProjectTab);
    }
    if (page !== 1) {
      params = { ...params, page: 1 };
    }
    const qs = queryString.stringify(params);
    history.push(`${Path.PROJECTS}?${qs}`);
  };
  const {
    actions: { getProjectRoleListRequest, getMemberListRequest },
  } = useMemberSlice();

  const isLoading = checkIsRequesting([
    getProjectListStatus,
    getMemberListStatus,
    getProjectSummaryStatus,
  ]);

  const reloadData = () => {
    const params = new URLSearchParams(window.location.search);
    dispatch(
      getProjectListRequest({
        code: params.get('code'),
        search: params.get('name'),
        project_type: params.get('project_type'),
        status: params.get('status'),
        page: Math.abs(_.toInteger(params.get('page')) || 1),
        type: 'all',
        paging: true,
      }),
    );
    dispatch(getProjectSummaryRequest({}));
  };
  React.useEffect(() => {
    if (deleteAssignmentInfoStatus === RequestStatus.SUCCESS) {
      reloadData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [deleteAssignmentInfoStatus]);
  useEffect(() => {
    if (
      createProjectStatus === RequestStatus.SUCCESS ||
      deleteProjectStatus === RequestStatus.SUCCESS
    ) {
      dispatch(resetProjectRequestStatus());
      reloadData();
    }
    if (deleteProjectStatus === RequestStatus.SUCCESS) {
      dispatch(setSuccessMessages(['Delete project successful!']));
    }
    if (createProjectStatus === RequestStatus.SUCCESS) {
      dispatch(setSuccessMessages(['Create project successful!']));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [createProjectStatus, deleteProjectStatus]);
  useEffect(() => {
    if (
      getProjectListStatus === RequestStatus.SUCCESS ||
      getProjectSummaryStatus === RequestStatus.SUCCESS
    ) {
      dispatch(resetProjectRequestStatus());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getProjectListStatus]);

  useEffect(() => {
    dispatch(setBreadcumbs([{ title: 'Projects' }]));
    dispatch(getProjectRoleListRequest({}));
    dispatch(getMemberListRequest({ paging: false }));
    const params = new URLSearchParams(window.location.search);
    setPage(_.toInteger(params.get('page') || 1));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    const newPage = Math.abs(_.toInteger(params.get('page')));
    const newProjectTab = params.get('tab');
    if (page !== newPage && newPage) {
      setPage(newPage ? newPage : 1);
      reloadData();
    }
    setCurrentProjectTab(newProjectTab ? newProjectTab : 'LIST_TAB');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.search]);

  useEffect(() => {
    reloadData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleChangePage = (
    event: React.ChangeEvent<unknown> | null,
    newPage: number,
  ) => {
    const params = queryString.parse(window.location.search);
    const qs = queryString.stringify({ ...params, page: +newPage });
    history.push(`${Path.PROJECTS}?${qs}`);
  };

  return (
    <>
      <Helmet>
        <title>Project Management</title>
        <meta name="description" content="Customer Management" />
      </Helmet>
      <Container>
        <Wrapper>
          <Loader open={isLoading} />
          <Content spacing={2}>
            <Box
              display="flex"
              flexDirection="row"
              justifyContent="space-between"
            >
              <Typography variant="h6" component="h6">
                Project list
              </Typography>
              <Button
                variant="contained"
                startIcon={<AddIcon fontSize="small" />}
                onClick={() => {
                  setIsCreateDialogShow(true);
                }}
              >
                Add new
              </Button>
            </Box>
            <CreateDialogForm
              open={isCreateDialogShow}
              onClose={() => {
                setIsCreateDialogShow(false);
              }}
            />

            <ProjectSummary />

            <FilterBar
              currentProjectTab={currentProjectTab}
              handleChangeProjectTab={handleChangeProjectTab}
            />

            {/* <DialogForm open={show} onClose={handleClose} data={dataItem} /> */}

            <Dialog open={showConfirm}>
              <DialogTitle>Delete project info</DialogTitle>

              <DialogContent>
                <DialogContentText>
                  Do you want to delete project bellow?
                </DialogContentText>
                <Typography variant="subtitle1" component="div">
                  Code: {dataItem?.code}
                </Typography>
                <Typography variant="subtitle1" component="div">
                  Name: {dataItem?.name}
                </Typography>
              </DialogContent>
              <DialogActions>
                <Button onClick={() => setShowConfirm(false)}>Close</Button>
                <Button
                  color="error"
                  onClick={() => {
                    setShowConfirm(false);
                    dispatch(deleteProjectRequest(dataItem?.id));
                  }}
                >
                  Delete
                </Button>
              </DialogActions>
            </Dialog>
            {currentProjectTab === 'LIST_TAB' && (
              <>
                <WrapTable sx={{ width: '100%', overflow: 'hidden' }}>
                  <TableContainer component={Paper}>
                    <Table stickyHeader>
                      <TableHead>
                        <TableRow>
                          <TableCell sx={{ minWidth: 60 }} width={60}>
                            ID
                          </TableCell>
                          <TableCell sx={{ minWidth: 120 }} width={140}>
                            Code
                          </TableCell>
                          <TableCell sx={{ minWidth: 120 }}>Name</TableCell>
                          <TableCell sx={{ minWidth: 120 }}>Customer</TableCell>
                          <TableCell sx={{ minWidth: 120 }}>
                            Project Type
                          </TableCell>
                          <TableCell sx={{ minWidth: 60 }}>Size (mm)</TableCell>
                          <TableCell sx={{ minWidth: 110 }}>
                            Start Date
                          </TableCell>
                          <TableCell sx={{ minWidth: 110 }}>End Date</TableCell>
                          <TableCell sx={{ minWidth: 90 }} width={120}>
                            Status
                          </TableCell>
                          <TableCell width={90}>Action</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {projectList?.data?.map((item, idx) => {
                          return (
                            <TableRow key={`team_row_${idx}`}>
                              <TableCell>{idx + 1}</TableCell>
                              <TableCell>{item.code}</TableCell>
                              <TableCell>
                                <Stack>
                                  <Box>{item.name}</Box>
                                  <Box>
                                    <Stack direction="row">
                                      {_.get(item, 'members') && (
                                        <AvatarGroup max={6}>
                                          {_.get(item, 'members').map(
                                            (it, idx) => (
                                              <AvatarMember
                                                key={idx}
                                                title={it?.member_name}
                                                avatar={it?.avatar}
                                                member_id={it?.member}
                                                sx={{ width: 18, height: 18 }}
                                              ></AvatarMember>
                                            ),
                                          )}
                                        </AvatarGroup>
                                      )}
                                    </Stack>
                                  </Box>
                                </Stack>
                              </TableCell>
                              <TableCell>{item.customer.name}</TableCell>
                              <TableCell>
                                {_.get(
                                  _.find(PROJECT_TYPE, {
                                    id: item.project_type,
                                  }),
                                  'name',
                                )}
                              </TableCell>
                              <TableCell>{item.size}</TableCell>
                              <TableCell>{item.start_date}</TableCell>
                              <TableCell>{item.end_date}</TableCell>
                              <TableCell>{STATUS_LIST[item.status]}</TableCell>
                              <TableCell align="right">
                                <Stack direction="row" spacing={1}>
                                  <IconButton
                                    component={Link}
                                    to={`${Path.PROJECTS}/edit/${item.id}`}
                                  >
                                    <EditIcon />
                                  </IconButton>
                                  <IconButton
                                    color="error"
                                    onClick={() => {
                                      setDataItem(item);
                                      setShowConfirm(true);
                                    }}
                                  >
                                    <DeleteIcon />
                                  </IconButton>
                                </Stack>
                              </TableCell>
                            </TableRow>
                          );
                        })}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </WrapTable>
                <Stack spacing={2} alignItems={'flex-end'}>
                  <Pagination
                    page={page}
                    color="primary"
                    count={projectList?.total_page || 0}
                    variant="outlined"
                    shape="rounded"
                    onChange={handleChangePage}
                  />
                </Stack>
              </>
            )}
            {currentProjectTab === 'USER_TAB' && (
              <>
                <HeaderTaskLog>
                  <CardContent style={{ paddingRight: '0px' }}>
                    <StackAssignmentRow direction="row">
                      <WrapAssignment pl={0}>
                        No. <Divider orientation="vertical" />
                      </WrapAssignment>
                      <WrapAssignment>
                        Member
                        <Divider orientation="vertical" />
                      </WrapAssignment>
                      <WrapAssignment>
                        Type
                        <Divider orientation="vertical" />
                      </WrapAssignment>
                      <WrapAssignment>
                        Role
                        <Divider orientation="vertical" />
                      </WrapAssignment>
                      <WrapAssignment>
                        Effort/Month
                        <Divider orientation="vertical" />
                      </WrapAssignment>
                      <WrapAssignment>
                        Start
                        <Divider orientation="vertical" />
                      </WrapAssignment>
                      <WrapAssignment>
                        End
                        <Divider orientation="vertical" />
                      </WrapAssignment>
                      <WrapAssignment>Action </WrapAssignment>
                    </StackAssignmentRow>
                  </CardContent>
                </HeaderTaskLog>
                {projectList?.data?.map((item, idx) => (
                  <CollapseTable
                    key={`team_row_${idx}`}
                    idx={idx}
                    initInfo={item}
                  />
                ))}
                <Stack spacing={2} alignItems={'flex-end'}>
                  <Pagination
                    page={page}
                    color="primary"
                    count={projectList?.total_page || 0}
                    variant="outlined"
                    shape="rounded"
                    onChange={handleChangePage}
                  />
                </Stack>
              </>
            )}
          </Content>
        </Wrapper>
      </Container>
    </>
  );
}

const HeaderTaskLog = styled(Card)`
  background-color: #f0f7ff !important;
  & .MuiCardContent-root:last-child {
    overflow: auto;
    padding-bottom: 16px;
  }
`;

const StackAssignmentRow = styled(Stack)`
  & div {
    font-size: 14px;
    font-weight: 500;
    &:first-child {
      width: 50px;
      min-width: 50px;
    }
    &:nth-child(2) {
      flex: 1;
      min-width: 150px;
    }
    &:nth-child(3) {
      width: 160px;
      min-width: 90px;
    }
    &:nth-child(4) {
      width: 180px;
      min-width: 110px;
    }
    &:nth-child(5) {
      width: 140px;
      min-width: 80px;
    }
    &:nth-child(6) {
      width: 180px;
      min-width: 140px;
    }
    &:nth-child(7) {
      width: 180px;
      min-width: 140px;
    }
    &:nth-child(8) {
      width: 120px;
      min-width: 120px;
    }
  }
`;

const WrapAssignment = styled(Box)`
  display: flex;
  justify-content: space-between;
  padding-left: 16px;
`;

const WrapTable = styled(Box)`
  & .MuiAvatar-root:last-child {
    margin-left: -3px !important;
  }
  & .MuiTableContainer-root {
    max-height: calc(100vh - 340px);
  }
`;
