import { Container } from 'app/components/Container';
import { PROJECT_SCOPE } from 'constants/ProjectMetadata';
import _ from 'lodash';
import * as React from 'react';
import { useEffect } from 'react';
import { Helmet } from 'react-helmet-async';
// Redux & Sagas
import { useDispatch, useSelector } from 'react-redux';
import * as smoothscroll from 'smoothscroll-polyfill';
import styled from 'styled-components';
import { About } from './Components/About';
import { Customers } from './Components/Customers';
import { FooterSection } from './Components/FooterSection';
import MainSection from './Components/MainSection';
import { Members } from './Components/Members';
import { Projects } from './Components/Projects';
import { Technology } from './Components/Technology';
import { Colors, FontSizes } from './Components/Variables';
import { useLandingSlice } from './slice';
import { selectLanding } from './slice/selectors';

smoothscroll.polyfill();

export function HomePage() {
  const dispatch = useDispatch();
  const {
    actions: {
      getCustomerListRequest,
      getProjectListRequest,
      getMemberListRequest,
      getTechnologyListRequest,
      resetRequestStatus,
    },
  } = useLandingSlice();
  const { listCustomer, listProject, listMember, listTechnology } =
    useSelector(selectLanding);

  useEffect(() => {
    dispatch(getCustomerListRequest({}));
    dispatch(getProjectListRequest({}));
    dispatch(getMemberListRequest({}));
    dispatch(getTechnologyListRequest({}));
    return () => {
      dispatch(resetRequestStatus());
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const mappingData = arr => {
    return arr?.map(item => {
      return {
        ...item,
        scope: item.scope
          .filter(it => it)
          .map(it =>
            _.get(
              _.find(PROJECT_SCOPE, {
                id: _.toInteger(it),
              }),
              'name',
            ),
          ),
      };
    });
  };
  return (
    <>
      <Helmet>
        <title>Palette</title>
        <meta name="description" content="Palette" />
      </Helmet>

      <LandingPage className="home">
        <MainSection />
        <About />
        <Customers customers={listCustomer} />
        <Projects projects={mappingData(listProject)} />
        <Technology technologies={listTechnology} />
        <Members members={listMember} />
        <FooterSection />
      </LandingPage>
    </>
  );
}

const LandingPage = styled(Container)`
  font-size: ${FontSizes.root};
  color: ${Colors.darkBlue};
  max-width: 1920px;
  background: ${Colors.white};
  .heading-text {
    color: ${Colors.blueHeading};
  }
  .blue-text {
    color: ${Colors.darkBlue};
  }
  .white-text {
    color: ${Colors.white};
  }
  .orange-text {
    color: ${Colors.orangeHeading};
  }
  .text-36 {
    font-size: ${FontSizes.text36};
  }
  .text-48 {
    font-size: ${FontSizes.text48};
  }
  .text-24 {
    font-size: ${FontSizes.text24};
  }
  .text-20 {
    font-size: ${FontSizes.text20};
  }
  .text-14px {
    font-size: ${FontSizes.text36};
  }
  .mb-10 {
    margin-bottom: 10px;
  }
  .mb-20 {
    margin-bottom: 20px;
  }
  .mb-30 {
    margin-bottom: 30px;
  }
  .center {
    text-align: center;
  }
  .nowrap {
    white-space: nowrap;
  }
`;
