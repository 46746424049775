import React from 'react';

// UI
import {
  Box,
  Stack,
  InputLabel,
  FormControl,
  Select,
  MenuItem,
  Button,
  Menu,
  Fade,
} from '@mui/material';

import {
  KeyboardArrowDown as KeyboardArrowDownIcon,
  Add,
} from '@mui/icons-material';

// Redux & Sagas
import useRecruitment from 'app/pages/RecruitmentsPage/useRecruitment';

// Library
import { useForm, Controller } from 'react-hook-form';
import { useHistory } from 'react-router-dom';
import _ from 'lodash';
import Path from 'config/clientPath';
import { useTranslation } from 'react-i18next';
import { JOB_STATUS } from 'constants/common';
import { handleParamSearch } from 'utils/helper';

export function FilterBar({ onClickAdd, showActionStatus, selectedRows }) {
  const history = useHistory();
  const { t } = useTranslation();
  const { handleSubmit, reset, control, watch } = useForm();

  const { updateStatusJobs } = useRecruitment();

  const searchForm = React.useRef<HTMLFormElement>();

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const changeAnchorEL = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handSearch = _formData => {
    const params = new URLSearchParams(window.location.search);
    const formData = params.get('page')
      ? {
          ..._formData,
          page:
            (watch('state') !== params.get('state') && params.get('state')) ||
            (watch('state') && !params.get('state'))
              ? 1
              : params.get('page'),
        }
      : _formData;
    history.push(`${Path.JOB_LIST}?${handleParamSearch(formData)}`);
  };

  const handleClick = state => {
    updateStatusJobs(selectedRows, parseInt(state));
    handleClose();
  };

  React.useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    reset({
      state: params.get('state') || '',
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [window.location.search]);

  React.useEffect(() => {
    const subscription = watch((value, { name, type }) => {
      if (name === 'state') {
        requestSubmit();
      }
    });
    return () => subscription.unsubscribe();
  }, [watch]);

  const requestSubmit = () => {
    setTimeout(() => searchForm.current?.requestSubmit(), 10);
  };

  return (
    <Box
      display="flex"
      flexDirection="row"
      justifyContent="space-between"
      component="form"
      method="GET"
      ref={searchForm}
      onSubmit={handleSubmit(handSearch)}
    >
      <Stack direction="row" alignItems="center" spacing={1}>
        <InputLabel>{t('Status')}: </InputLabel>
        <FormControl>
          <Controller
            control={control}
            name="state"
            render={({ field }) => {
              const { onBlur, onChange, value } = field;
              return (
                <Select
                  fullWidth
                  displayEmpty
                  size="small"
                  variant="standard"
                  onBlur={onBlur}
                  onChange={onChange}
                  value={`${value}`}
                  IconComponent={KeyboardArrowDownIcon}
                >
                  <MenuItem value="">{t('All')}</MenuItem>
                  {_.map(JOB_STATUS, (v, k) => (
                    <MenuItem value={k} key={k}>
                      {t(v.name)}
                    </MenuItem>
                  ))}
                </Select>
              );
            }}
          />
        </FormControl>
      </Stack>

      <Stack direction="row" spacing={2} alignItems="center">
        <>
          <Button
            id="fade-button"
            aria-controls={open ? 'fade-menu' : undefined}
            aria-haspopup="true"
            aria-expanded={open ? 'true' : undefined}
            onClick={changeAnchorEL}
            variant="contained"
            endIcon={<KeyboardArrowDownIcon />}
            color="inherit"
          >
            {t('Jobs Status')}
          </Button>
          <Menu
            id="fade-menu"
            MenuListProps={{
              'aria-labelledby': 'fade-button',
            }}
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            TransitionComponent={Fade}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'right',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
          >
            {_.map(JOB_STATUS, (v, k) => (
              <MenuItem
                key={k}
                value={`${k}`}
                disabled={showActionStatus}
                onClick={() => handleClick(k)}
                sx={{ width: '135px' }}
              >
                {t(v.name)}
              </MenuItem>
            ))}
          </Menu>
        </>

        <Button variant="contained" startIcon={<Add />} onClick={onClickAdd}>
          {t('Add New Job')}
        </Button>
      </Stack>
    </Box>
  );
}

export default FilterBar;
