import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Stack,
  Typography,
} from '@mui/material';
import { Container } from 'app/components/Container';
import { Content, Wrapper } from 'app/components/Wrapper';
import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';
import AddIcon from '@mui/icons-material/Add';
import FilterBar from './components/FilterBar';
import React, { useEffect, useState } from 'react';
import moment from 'moment';
import queryString from 'query-string';
import { useHistory, useLocation } from 'react-router-dom';
import Path from 'config/clientPath';
import { ViewModeList } from './components/ViewModeList';
import { useTimesheetSlice } from './slice';
import { useDispatch, useSelector } from 'react-redux';
import { selectGlobal } from '../GlobalContainer/slice/selectors';
import TaskLogDialog from './components/TaskLogDialog';
import { useTagSlice } from '../TagPage/slice';
import { selectTimesheet } from './slice/selectors';
import { checkIsRequesting } from 'utils/helper';
import { Loader } from 'app/components/Loader';
import { RequestStatus } from 'constants/API';
import { useGlobalSlice } from '../GlobalContainer/slice';
import { ITimesheet } from './slice/types';
import { selectTag } from '../TagPage/slice/selectors';
import { CalendarViewMode } from './components/CalendarViewMode';
import { REPORT_TYPE } from 'constants/common';
import { useMemberSlice } from '../MemberPage/slice';
import { selectMember } from '../MemberPage/slice/selectors';

export function TimesheetPage() {
  const { t } = useTranslation();
  const history = useHistory();
  const dispatch = useDispatch();
  const location = useLocation();
  const paramSearch = queryString.parse(window.location.search);
  const [viewMode, setViewMode] = useState<string>('list');
  const [currentDate, setCurrentDate] = useState<moment.Moment>(moment());
  const [currentDateCalendar, setCurrentDateCalendar] = useState<string | null>(
    null,
  );
  const weekPickerRef = React.useRef<any>();
  const weekStart = currentDate.clone().startOf('week');
  const [activeDate, setActiveDate] = useState(
    currentDate.format('YYYY/MM/DD'),
  );
  const [isShowModalTaskLog, setIsShowModalTaskLog] = useState<boolean>(false);
  const [isShowDeleteTaskDialog, setIsShowDeleteTaskDialog] =
    useState<boolean>(false);
  const [seletedTaskLogData, setSeletedTaskLogData] = useState<ITimesheet>();
  const [isShowProject, setIsShowProject] = useState<number[]>([]);

  const { getMemberWorkReportStatus } = useSelector(selectMember);
  const {
    actions: { getMemberWorkReportRequest, resetTaskListStatus },
  } = useMemberSlice();
  const {
    actions: {
      getDateRangeWorkReportRequest,
      getTimesheetRequest,
      resetTimesheetRequestStatus,
      deleteTimesheetRequest,
    },
  } = useTimesheetSlice();
  const {
    userSessionData: { profile },
  } = useSelector(selectGlobal);
  const { getTagStatus } = useSelector(selectTag);
  const {
    actions: { getTagRequest, resetTagRequestStatus },
  } = useTagSlice();
  const {
    actions: { getAssignProjectsRequest },
  } = useTimesheetSlice();
  const {
    addTimesheetStatus,
    updateTimesheetStatus,
    deleteTimesheetStatus,
    getTimesheetStatus,
    getDateRangeWorkReportStatus,
    errorMess,
  } = useSelector(selectTimesheet);
  const {
    actions: { setSuccessMessages, setErrorMessages, setBreadcumbs },
  } = useGlobalSlice();

  const onSuccess = (messages: string[]) => {
    dispatch(setSuccessMessages(messages));
    reloadData();
    dispatch(resetTimesheetRequestStatus());
  };

  const displayToast = (
    status: any,
    messagesSuccess: string[],
    messagesError: string[],
  ) => {
    if (status === RequestStatus.SUCCESS) {
      let params = queryString.parse(window.location.search);
      const newViewMode = params?.viewMode ? params?.viewMode : 'list';
      onSuccess(messagesSuccess);
      if (newViewMode === 'calendar') {
        reloadDataCalendar();
      }
    }
    if (status === RequestStatus.ERROR) {
      dispatch(setErrorMessages(messagesError));
    }
  };

  const onChangeFilterDate = newDate => {
    handleChangePage('', newDate);
  };

  const onChangeToday = () => {
    handleChangePage('', moment());
  };

  const handleChangePage = (
    _viewMode = '',
    _currentDate: moment.Moment | null = null,
  ) => {
    let params = queryString.parse(window.location.search);
    if (_viewMode) {
      params = { ...params, viewMode: _viewMode };
    }
    if (_currentDate) {
      setCurrentDate(_currentDate);
      params = { ...params, date: _currentDate.format('YYYY-MM-DD') };
    }
    const qs = queryString.stringify({ ...params });
    history.push(`${Path.TIMESHEET}?${qs}`);
  };

  const changeDataByQuery = () => {
    let params = queryString.parse(window.location.search);
    const newDate = params?.date ? moment(params?.date) : moment();
    setCurrentDate(newDate);
    setActiveDate(newDate.format('YYYY/MM/DD'));
    setViewMode(params?.viewMode ? params?.viewMode : 'list');
  };

  const reloadDataCalendar = () => {
    let params = queryString.parse(window.location.search);
    const newDate = (params?.date ? moment(params?.date) : moment()).format(
      'YYYY-MM-DD',
    );
    dispatch(
      getAssignProjectsRequest({
        date: newDate,
      }),
    );
    const tags = params?.tags || null;
    // recalculate date params
    const monthStartDate = moment(newDate).startOf('month');
    const weekDayOfStartMonth = monthStartDate.day();
    const prevMonthAppearStartDate = monthStartDate
      .clone()
      .subtract(weekDayOfStartMonth, 'days');
    const monthEndDate = moment(newDate).endOf('month');
    const weekDayOfEndMonth = monthEndDate.day();
    const nextMonthAppearEndDate = monthEndDate
      .clone()
      .add(6 - weekDayOfEndMonth, 'days');

    dispatch(
      getMemberWorkReportRequest({
        date_start: prevMonthAppearStartDate.format('yyyy-MM-DD'),
        date_end: nextMonthAppearEndDate.format('yyyy-MM-DD'),
        report_type: REPORT_TYPE.DAY,
        tags,
      }),
    );
  };

  const reloadData = () => {
    let params = queryString.parse(window.location.search);
    const newDate = params?.date ? moment(params?.date) : moment();
    const newViewMode = params?.viewMode ? params?.viewMode : 'list';
    const newWeekStart = newDate.clone().startOf('week');
    dispatch(
      getAssignProjectsRequest({
        date: newDate.format('yyyy-MM-DD'),
      }),
    );
    if (newViewMode === 'list') {
      const tags = params?.tags || null;
      let timesheetParams = {
        report_date: newDate.format('yyyy-MM-DD'),
        tags,
      };
      timesheetParams['member'] = profile.id;
      timesheetParams['paging'] = false;
      dispatch(getTimesheetRequest(timesheetParams));
      dispatch(
        getDateRangeWorkReportRequest({
          date_start: newWeekStart.format('yyyy-MM-DD'),
          date_end: newDate
            .clone()
            .endOf('week')
            .add(1, 'days')
            .format('yyyy-MM-DD'),
          tags,
        }),
      );
    }
  };

  const handleDeleteTask = () => {
    if (seletedTaskLogData) {
      dispatch(deleteTimesheetRequest(seletedTaskLogData?.id));
      setSeletedTaskLogData(undefined);
      setIsShowDeleteTaskDialog(false);
    }
  };

  useEffect(() => {
    let params = queryString.parse(window.location.search);
    const newViewMode = params?.viewMode ? params?.viewMode : 'list';
    changeDataByQuery();
    if (newViewMode === 'calendar') {
      reloadDataCalendar();
      setIsShowProject([]);
    } else {
      setCurrentDateCalendar(null);
      reloadData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.search]);

  useEffect(() => {
    // when change date and tag => auto collapse
    setIsShowProject([]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [paramSearch?.date, paramSearch?.tags]);

  useEffect(() => {
    dispatch(getTagRequest({}));
    dispatch(setBreadcumbs([{ title: t('Task Logs') }]));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // toast add task log project
  useEffect(() => {
    displayToast(addTimesheetStatus, ['Create task log success'], errorMess);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [addTimesheetStatus]);

  // delete update task log project
  useEffect(() => {
    displayToast(
      updateTimesheetStatus,
      ['Update task log success'],
      ['Oops.. Something went wrong!'],
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updateTimesheetStatus]);

  // delete add task log project
  useEffect(() => {
    displayToast(
      deleteTimesheetStatus,
      ['Delete task log success'],
      ['Oops.. Something went wrong!'],
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [deleteTimesheetStatus]);

  useEffect(
    () => () => {
      dispatch(resetTimesheetRequestStatus());
      dispatch(resetTagRequestStatus());
      dispatch(resetTaskListStatus());
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    ['componentWillUnMount'],
  );

  const isLoading = checkIsRequesting([
    addTimesheetStatus,
    updateTimesheetStatus,
    deleteTimesheetStatus,
    getTimesheetStatus,
    getDateRangeWorkReportStatus,
    getTagStatus,
    getMemberWorkReportStatus,
  ]);

  return (
    <>
      <Helmet>
        <title>{t('Timesheet | Palette')}</title>
        <meta name="description" content="Palette" />
      </Helmet>
      <Container>
        <Wrapper>
          <Content spacing={2}>
            <Loader open={isLoading} />
            <Box display="flex" flexDirection="row" alignItems="center">
              <Typography variant="h6" component="h6">
                {t('Task Logs')}
              </Typography>
              <Stack style={{ marginLeft: 'auto' }} direction="row" spacing={2}>
                <Button
                  variant="contained"
                  color="primary"
                  size="large"
                  onClick={() => {
                    setIsShowModalTaskLog(true);
                    setCurrentDateCalendar(null);
                  }}
                >
                  <AddIcon />
                  <Box ml={0.5}>{t('Log Task')}</Box>
                </Button>
              </Stack>
            </Box>

            <Dialog open={isShowDeleteTaskDialog} maxWidth="xs" fullWidth>
              <DialogTitle>
                <Typography variant="h5" component="div">
                  {t('Delete Task log')}
                </Typography>
              </DialogTitle>
              <DialogContent>
                <DialogContentText>
                  {t('Do you want to delete this task log ?')}
                </DialogContentText>
                <Typography variant="subtitle1">
                  {seletedTaskLogData?.description}
                </Typography>
              </DialogContent>
              <DialogActions>
                <Button onClick={() => setIsShowDeleteTaskDialog(false)}>
                  {t('Cancel')}
                </Button>
                <Button color="error" onClick={handleDeleteTask}>
                  {t('Delete')}
                </Button>
              </DialogActions>
            </Dialog>

            <TaskLogDialog
              isShowModalTaskLog={isShowModalTaskLog}
              setIsShowModalTaskLog={setIsShowModalTaskLog}
              currentDate={currentDate}
              seletedTaskLogData={seletedTaskLogData || null}
              setSeletedTaskLogData={setSeletedTaskLogData}
              currentDateCalendar={currentDateCalendar || null}
              viewMode={viewMode}
            />

            <FilterBar
              viewMode={viewMode}
              currentDate={currentDate}
              onChangeFilterDate={onChangeFilterDate}
              onChangeToday={onChangeToday}
              weekPickerRef={weekPickerRef}
            />

            {viewMode === 'calendar' ? (
              <>
                <CalendarViewMode
                  activeDate={activeDate}
                  setSeletedTaskLogData={setSeletedTaskLogData}
                  setIsShowModalTaskLog={setIsShowModalTaskLog}
                  setIsShowDeleteTaskDialog={setIsShowDeleteTaskDialog}
                  setCurrentDateCalendar={setCurrentDateCalendar}
                />
              </>
            ) : (
              <>
                <ViewModeList
                  weekStart={weekStart}
                  activeDate={activeDate}
                  handleChangePage={handleChangePage}
                  setSeletedTaskLogData={setSeletedTaskLogData}
                  setIsShowModalTaskLog={setIsShowModalTaskLog}
                  setIsShowDeleteTaskDialog={setIsShowDeleteTaskDialog}
                  isShowProject={isShowProject}
                  setIsShowProject={setIsShowProject}
                />
              </>
            )}
          </Content>
        </Wrapper>
      </Container>
    </>
  );
}
