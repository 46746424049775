import React, { useEffect } from 'react';
// UI
import { Helmet } from 'react-helmet-async';

import {
  Box,
  Typography,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  TableContainer,
  Paper,
  Stack,
  Pagination,
} from '@mui/material';
import { AvatarMember } from 'app/components/Avatar';
import styled from 'styled-components/macro';

import { Wrapper, Content } from 'app/components/Wrapper';
import { Container } from 'app/components/Container';
import moment from 'moment';
import _ from 'lodash';
import { Link, useHistory } from 'react-router-dom';

// Redux & Sagas
import { useDispatch, useSelector } from 'react-redux';
import { useGlobalSlice } from 'app/pages/GlobalContainer/slice';
import Path from 'config/clientPath';
import FilterBar from './components/FilterBar';
import { useLocation } from 'react-router';
import { Loader } from 'app/components/Loader';
import { checkIsRequesting } from 'utils/helper';
import queryString from 'query-string';
import { useTimesheetSlice } from '../TimesheetPage/slice';
import { selectTimesheet } from '../TimesheetPage/slice/selectors';
import { STATUS_DISPLAY_TYPE, TaskStatus } from 'app/components/TaskStatus';
import { BillableTag } from 'app/components/BillableTag';
import { ProjectLink } from 'app/components/ProjectLink';
import { useTranslation } from 'react-i18next';

export function TimesheetApprovalPage() {
  const dispatch = useDispatch();
  const [month, setMonth] = React.useState<moment.Moment | null>(null);

  const location = useLocation();
  const history = useHistory();

  const { setBreadcumbs } = useGlobalSlice().actions;
  const { t } = useTranslation();

  const {
    approvalList,
    approveRequestStatus,
    getAllProjectsStatus,
    getAllMembersStatus,
    getApprovalListStatus,
    getExportCSVStatus,
  } = useSelector(selectTimesheet);

  const {
    actions: {
      resetTimesheetApprovalStatus,
      getApprovalListRequest,
      getAllProjectsRequest,
      getAllMembersRequest,
      getExportCSVRequest,
    },
  } = useTimesheetSlice();

  const reloadData = () => {
    const params = queryString.parse(window.location.search);
    const newCurrentDate = params?.month ? moment(params?.month) : moment();
    dispatch(
      getApprovalListRequest({
        page: Math.abs(_.toInteger(params?.page) || 1),
        month: newCurrentDate.clone().startOf('month').format('yyyy-MM'),
        member: params?.member || '',
        project: params?.project || '',
      }),
    );
  };

  const handleChangePage = (
    event: React.ChangeEvent<unknown> | null,
    newPage: number,
  ) => {
    let params = queryString.parse(window.location.search);
    const qs = queryString.stringify({ ...params, page: +newPage });
    history.push(`${Path.DAILY_REPORT_APPROVAL}?${qs}`);
  };

  useEffect(() => {
    dispatch(setBreadcumbs([{ title: t('Approval') }]));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(
    () => () => {
      dispatch(resetTimesheetApprovalStatus());
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    ['componentWillUnMount'],
  );

  useEffect(() => {
    if (month) {
      const monthString = month.format('yyyy-MM');
      dispatch(
        getAllProjectsRequest({
          month: monthString,
        }),
      );
      dispatch(
        getAllMembersRequest({
          month: monthString,
        }),
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(month)]);

  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    const monthParam = params.get('month');
    const newMonth = monthParam ? moment(monthParam) : moment();
    setMonth(newMonth);
    reloadData();
    dispatch(
      getExportCSVRequest({
        month: monthParam ? monthParam : moment()?.format('YYYY-MM'),
        member: params?.get('member') || '',
        project: params?.get('project') || '',
      }),
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.search]);

  const isLoading = checkIsRequesting([
    getAllProjectsStatus,
    getAllMembersStatus,
    approveRequestStatus,
    getApprovalListStatus,
    getExportCSVStatus,
  ]);

  return (
    <>
      <Helmet>
        <title>{t('Timesheet Approval')}</title>
        <meta name="description" content="Palette" />
      </Helmet>
      <Container>
        <Wrapper>
          <Loader open={isLoading} />
          <Content spacing={2}>
            <Box
              display="flex"
              flexDirection="row"
              justifyContent="space-between"
            >
              <Typography variant="h6" component="h6">
                {t('Approval')}
              </Typography>
            </Box>

            <FilterBar />
            <WrapTable>
              <TableContainer component={Paper}>
                <Table stickyHeader>
                  <TableHead>
                    <TableRow>
                      <TableCell sx={{ minWidth: 60 }}>{t('No.')}</TableCell>
                      <TableCell sx={{ minWidth: 140 }}>
                        {t('Project')}
                      </TableCell>
                      <TableCell sx={{ minWidth: 160 }}>
                        {t('Member')}
                      </TableCell>
                      <TableCell sx={{ minWidth: 90 }}>{t('Role')}</TableCell>
                      <TableCell sx={{ minWidth: 70 }}>{t('Rate')}</TableCell>
                      <TableCell sx={{ minWidth: 90 }}>
                        {t('Billable')}
                      </TableCell>
                      <TableCell sx={{ minWidth: 80 }}>
                        {t('Efforts')}
                      </TableCell>
                      <TableCell sx={{ minWidth: 90 }}>{t('Detail')}</TableCell>
                      <TableCell sx={{ minWidth: 150 }}>
                        {t('Status')}
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {approvalList?.data.map((task, key) => {
                      const currentMember = task?.member;
                      return (
                        <TableRow key={`approval_row_${key}`}>
                          <TableCell width={60}>
                            {approvalList?.page_size
                              ? approvalList?.page_size *
                                  (approvalList?.current_page - 1) +
                                key +
                                1
                              : key + 1}
                          </TableCell>
                          <TableCell>
                            <ProjectLink
                              project_id={task?.project?.id}
                              project_name={task?.project?.name}
                            ></ProjectLink>
                          </TableCell>

                          <TableCell>
                            <Stack
                              direction="row"
                              spacing={'6px'}
                              alignItems="center"
                            >
                              <AvatarMember
                                avatar={`${currentMember?.avatar || ''}`}
                                title={`${currentMember?.full_name || ''}`}
                                sx={{ width: 24, height: 24, fontSize: 12 }}
                              ></AvatarMember>
                              <Box>{currentMember?.full_name}</Box>
                            </Stack>
                          </TableCell>
                          <TableCell>{task?.role_name}</TableCell>
                          <TableCell>{task?.rate}</TableCell>
                          <TableCell>
                            <BillableTag billable={task?.type}></BillableTag>
                          </TableCell>
                          <TableCell>{task?.effort + 'h'}</TableCell>
                          <TableCell>
                            <Link
                              to={`${
                                Path.TIMESHEET_APPROVAL_REVIEW
                              }?month=${month?.format('YYYY-MM')}&project=${
                                task?.project?.id
                              }&member=${currentMember.id}`}
                              style={{
                                textDecoration: 'none',
                                color: '#0000ee',
                              }}
                            >
                              {t('View Detail')}
                            </Link>
                          </TableCell>
                          <TableCell width={180}>
                            {task?.status && (
                              <TaskStatus
                                status={task?.status}
                                display_type={STATUS_DISPLAY_TYPE.ADMIN}
                              ></TaskStatus>
                            )}
                          </TableCell>
                        </TableRow>
                      );
                    })}
                  </TableBody>
                  {_.isEmpty(approvalList?.data) && (
                    <TableBody>
                      <TableRow>
                        <TableCell colSpan={9}>{t('No data')}</TableCell>
                      </TableRow>
                    </TableBody>
                  )}
                </Table>
              </TableContainer>
            </WrapTable>
            {!_.isEmpty(approvalList?.data) && (
              <Stack spacing={2} alignItems={'flex-end'}>
                <Pagination
                  page={approvalList?.current_page || 1}
                  color="primary"
                  count={approvalList?.total_page || 0}
                  variant="outlined"
                  shape="rounded"
                  onChange={handleChangePage}
                />
              </Stack>
            )}
          </Content>
        </Wrapper>
      </Container>
    </>
  );
}

const WrapTable = styled(Box)`
  & .MuiTableContainer-root {
    max-height: calc(100vh - 222px);
  }
`;
