import { IUserSessionData } from 'app/pages/GlobalContainer/slice/types';
import moment from 'moment';
import {
  ACCESS_TOKEN_KEY,
  CACHED_URL,
  RECORD_START_TIME,
  RECORD_DURATION,
  SHOW_SIDEBAR,
} from '../constants/localStorage';

const USER_CACHED_DATA = 'USER_CACHED_DATA';

// <Access Token>
export const getToken = () => localStorage.getItem(ACCESS_TOKEN_KEY);

export const setToken = (token: string) =>
  localStorage.setItem(ACCESS_TOKEN_KEY, token);

export const removeToken = (): void => {
  localStorage.removeItem(ACCESS_TOKEN_KEY);
};
export const isHavingToken = () => !!getToken();
// </Access Token>

// <User Profile>
export const getUserCachedDataFromLocalStorage =
  (): IUserSessionData | null => {
    const data = localStorage.getItem(USER_CACHED_DATA);
    try {
      return data ? JSON.parse(data) : null;
    } catch (e) {
      return null;
    }
  };
export const setUserCachedDataFromLocalStorage = (
  userSessionData: IUserSessionData,
) => localStorage.setItem(USER_CACHED_DATA, JSON.stringify(userSessionData));

export const removeUserCachedDataFromLocalStorage = () => {
  localStorage.removeItem(USER_CACHED_DATA);
};

export const removeStorageData = () => {
  removeUserCachedDataFromLocalStorage();
  removeToken();
};
// </User Profile>

export const getCachedUrl = () => localStorage.getItem(CACHED_URL);

export const removeCachedUrl = () => localStorage.removeItem(CACHED_URL);

export const setRecordStartTime = (time: moment.Moment) =>
  localStorage.setItem(RECORD_START_TIME, time.format());
export const removeRecordStartTime = () =>
  localStorage.removeItem(RECORD_START_TIME);
export const getRecordStartTime = (): moment.Moment | null => {
  if (localStorage.getItem(RECORD_START_TIME)) {
    return moment(localStorage.getItem(RECORD_START_TIME));
  }
  return null;
};

export const setRecordDuration = (duration: string) =>
  localStorage.setItem(RECORD_DURATION, duration);
export const getRecordDuration = (): string | null =>
  localStorage.getItem(RECORD_DURATION);
export const resetRecordDuration = () =>
  localStorage.removeItem(RECORD_DURATION);

export const setIsShowSidebar = (isShowSidebar: boolean) =>
  localStorage.setItem(SHOW_SIDEBAR, isShowSidebar ? 'true' : 'false');
export const isShowSidebar = (): boolean =>
  (localStorage.getItem(SHOW_SIDEBAR) || 'false') === 'true';
