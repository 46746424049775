import React from 'react';

// UI
import { Grid, Card, CardContent, Stack, Typography, Box } from '@mui/material';

import { ReactComponent as DateRangeIcon } from 'assets/icons/project/daterange.svg';
import { ReactComponent as TasksIcon } from 'assets/icons/project/tasks.svg';
import { ReactComponent as HoursIcon } from 'assets/icons/project/hours.svg';
import { ReactComponent as MembersIcon } from 'assets/icons/project/members.svg';

// Redux & Sagas
import { useSelector } from 'react-redux';
import { selectProject } from 'app/pages/ProjectPage/slice/selectors';

export function ProjectSummary() {
  const { projectInfo } = useSelector(selectProject);

  return (
    <Grid container>
      <Grid item xs>
        <Grid container spacing={3}>
          <Grid item xs={3}>
            <Card>
              <CardContent
                component={Stack}
                direction="row"
                spacing={2}
                alignItems="center"
              >
                <DateRangeIcon />
                <Box>
                  <Typography>Project time</Typography>
                  <Typography variant="h6" color="#2E5ED9">
                    {`${projectInfo?.start_date || ''} ~ ${
                      projectInfo?.end_date || ''
                    }`}
                  </Typography>
                </Box>
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={3}>
            <Card>
              <CardContent
                component={Stack}
                direction="row"
                spacing={2}
                alignItems="center"
              >
                <TasksIcon />
                <Box>
                  <Typography>Total Tasks</Typography>
                  <Typography variant="h5" color="#13A8A8">
                    {projectInfo?.total_tasks || 0}
                  </Typography>
                </Box>
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={3}>
            <Card>
              <CardContent
                component={Stack}
                direction="row"
                spacing={2}
                alignItems="center"
              >
                <HoursIcon />
                <Box>
                  <Typography>Total Hours</Typography>
                  <Typography variant="h5" color="#7637F2">
                    {projectInfo?.total_hours || 0}
                  </Typography>
                </Box>
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={3}>
            <Card>
              <CardContent
                component={Stack}
                direction="row"
                spacing={2}
                alignItems="center"
              >
                <MembersIcon />
                <Box>
                  <Typography>Total Members</Typography>
                  <Typography variant="h5" color="#F03F37">
                    {projectInfo?.total_members || 0}
                  </Typography>
                </Box>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}

export default ProjectSummary;
