import {
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { Close as CloseIcon } from '@mui/icons-material';
import { useForm } from 'react-hook-form';
import { useEffect } from 'react';
import _ from 'lodash';
import TaskRow from './TaskRow';
import { useTimesheetSlice } from '../slice';
import { useDispatch, useSelector } from 'react-redux';
import { ITimesheet } from '../slice/types';
import { selectTimesheet } from '../slice/selectors';
import { RequestStatus } from 'constants/API';
import { LoadingIndicator } from 'app/components/LoadingIndicator';
interface ITaskLogDialog {
  seletedTaskLogData: ITimesheet | null;
  isShowModalTaskLog: boolean;
  setIsShowModalTaskLog: (value: boolean) => void;
  currentDate: moment.Moment;
  setSeletedTaskLogData: (value: ITimesheet | undefined) => void;
  currentDateCalendar: string | null;
  viewMode: string;
}

interface IProject {
  id: number;
  code: string;
  name: string;
}

interface ITag {
  id: number;
  name: string;
  color?: string;
}

interface IFormData {
  totalHours: number;
  logs: [
    {
      project: IProject | null;
      ticketId: string;
      description: string;
      tags: ITag | null;
      hours: any | null;
    },
  ];
}

export default function TaskLogDialog(props: ITaskLogDialog) {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const { addTimesheetStatus, updateTimesheetStatus } =
    useSelector(selectTimesheet);

  const {
    isShowModalTaskLog,
    setIsShowModalTaskLog,
    currentDate,
    seletedTaskLogData,
    setSeletedTaskLogData,
    currentDateCalendar,
    viewMode,
  } = props;
  const {
    control,
    trigger,
    reset,
    watch,
    formState: { errors },
  } = useForm<IFormData>({});
  const {
    actions: { addTimesheetRequest, updateTimesheetRequest },
  } = useTimesheetSlice();

  let formData = watch();

  const registerSubmit = async () => {
    const canSubmit = await trigger();
    if (canSubmit) {
      if (seletedTaskLogData) {
        let dataUpdate = {
          subject: formData?.logs[0]?.ticketId?.toString(),
          description: formData?.logs[0]?.description?.toString(),
          hours: parseFloat(formData?.logs[0]?.hours?.toString()),
          report_date:
            seletedTaskLogData?.report_date ?? seletedTaskLogData?.date,
          tags: formData?.logs[0]?.tags ? [formData?.logs[0]?.tags] : [],
          project: formData?.logs[0]?.project,
        };
        dispatch(
          updateTimesheetRequest({
            objectId: seletedTaskLogData?.id,
            formData: dataUpdate,
          }),
        );
      } else {
        const dataAdd = formData?.logs.map(log => {
          return {
            subject: log.ticketId,
            description: log.description || '',
            hours: parseFloat(log.hours),
            report_date:
              viewMode === 'list'
                ? currentDate.format('YYYY-MM-DD')
                : currentDateCalendar ?? currentDate.format('YYYY-MM-DD'),
            tags: log?.tags ? [{ id: log.tags.id }] : [],
            project: { id: log?.project?.id },
          };
        });
        dispatch(addTimesheetRequest(dataAdd));
      }
      setSeletedTaskLogData(undefined);
      setIsShowModalTaskLog(false);
    }
  };

  const appendTaskLog = async () => {
    let newData = watch();
    await newData.logs.push({
      project: null,
      ticketId: '',
      description: '',
      tags: null,
      hours: null,
    });
    reset(newData, {
      keepErrors: true,
      keepDirty: true,
      keepIsSubmitted: false,
      keepTouched: false,
      keepIsValid: false,
      keepSubmitCount: false,
    });
  };

  const removeTaskLog = (index: any) => {
    let newData = watch();
    newData.logs.splice(index, 1);
    reset(newData);
  };

  useEffect(() => {
    let taskLogs: IFormData = {
      totalHours: 0,
      logs: [
        {
          project: null,
          ticketId: '',
          description: '',
          tags: null,
          hours: null,
        },
      ],
    };
    if (seletedTaskLogData) {
      const { project, description, tags, hours, subject, duration } =
        seletedTaskLogData;
      taskLogs = {
        ...taskLogs,
        logs: [
          {
            project: project,
            ticketId: subject ?? '',
            description: description,
            tags: tags?.[0] ?? tags,
            hours: hours ?? duration,
          },
        ],
      };
    }
    const totalHours = taskLogs?.logs?.reduce(
      (acc, log) => acc + Number(log.hours || 0),
      0,
    );
    reset({
      ...taskLogs,
      totalHours,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isShowModalTaskLog]);

  return (
    <>
      <Dialog open={isShowModalTaskLog} maxWidth="xl" fullWidth scroll="paper">
        <DialogTitle style={{ borderBottom: '1px solid #F0F0F0' }}>
          <Stack direction="row" justifyContent="left" alignItems="left">
            <Typography
              variant="h5"
              component="div"
              fontWeight={500}
              style={{ textTransform: 'uppercase' }}
            >
              {t('Daily report')}{' '}
              <span style={{ color: '#437dff', fontWeight: 400 }}>
                {viewMode === 'list'
                  ? currentDate.format('YYYY-MM-DD')
                  : currentDateCalendar ?? currentDate.format('YYYY-MM-DD')}
              </span>
            </Typography>
            <IconButton
              style={{ position: 'absolute', right: '12px', top: '8px' }}
              onClick={() => {
                setIsShowModalTaskLog(false);
                setSeletedTaskLogData(undefined);
              }}
            >
              <CloseIcon style={{ color: 'black' }} />
            </IconButton>
          </Stack>
          <Stack
            direction="row"
            justifyContent="right"
            alignItems="right"
            marginTop={3}
          >
            <Typography
              component="p"
              style={{ textTransform: 'uppercase', fontWeight: 'bold' }}
            >
              {t('Total Hours')}:
              <span style={{ color: '#437dff' }}> {formData.totalHours}</span>
            </Typography>
          </Stack>
        </DialogTitle>
        <DialogContent sx={{ maxHeight: '500px' }}>
          <form>
            <Stack spacing={2}>
              <Box
                display="flex"
                flexDirection="row"
                justifyContent="space-between"
              ></Box>
              <Paper>
                <TableContainer>
                  <Table stickyHeader>
                    <TableHead>
                      <TableRow>
                        <TableCell sx={{ minWidth: 20 }} width={20}>
                          {t('No')}.
                        </TableCell>
                        <TableCell
                          sx={{ minWidth: 100, maxWidth: 100 }}
                          width={100}
                        >
                          {t('Project')}
                        </TableCell>
                        <TableCell sx={{ minWidth: 100 }} width={100}>
                          {t('Ticket ID')}
                        </TableCell>
                        <TableCell sx={{ minWidth: 100 }} width={200}>
                          {t('Ticket Detail')}
                        </TableCell>
                        <TableCell
                          sx={{ minWidth: 100, maxWidth: 100 }}
                          width={100}
                        >
                          {t('Tag')}
                        </TableCell>
                        <TableCell sx={{ minWidth: 100 }} width={100}>
                          {'Duration (hours)'}
                        </TableCell>
                        <TableCell
                          sx={{ minWidth: 80 }}
                          width={80}
                          style={{ textAlign: 'center' }}
                        >
                          {t('Action')}
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {_.size(formData) === 0 ? (
                        <TableRow>
                          <TableCell colSpan={8}>{t('No data')}</TableCell>
                        </TableRow>
                      ) : (
                        <>
                          {formData?.logs?.map((log, idx) => {
                            return (
                              <TaskRow
                                key={`task_row_${idx}`}
                                taskIdx={idx}
                                control={control}
                                errors={errors}
                                removeTaskLog={removeTaskLog}
                                watch={watch}
                                reset={reset}
                              />
                            );
                          })}
                        </>
                      )}
                    </TableBody>
                  </Table>
                </TableContainer>
                {seletedTaskLogData && (
                  <Box
                    display="flex"
                    flexDirection="row"
                    justifyContent="flex-end"
                    p={2}
                  >
                    <Button
                      color="primary"
                      type="button"
                      variant="contained"
                      onClick={() => {
                        registerSubmit();
                      }}
                    >
                      {(updateTimesheetStatus === RequestStatus.REQUESTING ||
                        addTimesheetStatus === RequestStatus.REQUESTING) && (
                        <Typography component="span" mr={1}>
                          <LoadingIndicator small />
                        </Typography>
                      )}
                      {t('Update')}
                    </Button>
                  </Box>
                )}
                {!seletedTaskLogData && (
                  <Box
                    display="flex"
                    flexDirection="row"
                    justifyContent="space-between"
                    p={2}
                  >
                    <Button
                      variant="outlined"
                      onClick={() => {
                        appendTaskLog();
                      }}
                    >
                      {t('Add new task')}
                    </Button>
                    <Button
                      color="primary"
                      type="button"
                      variant="contained"
                      onClick={() => {
                        registerSubmit();
                      }}
                    >
                      {(updateTimesheetStatus === RequestStatus.REQUESTING ||
                        addTimesheetStatus === RequestStatus.REQUESTING) && (
                        <Typography component="span" mr={1}>
                          <LoadingIndicator small />
                        </Typography>
                      )}
                      {t('Create')}
                    </Button>
                  </Box>
                )}
              </Paper>
            </Stack>
          </form>
        </DialogContent>
      </Dialog>
    </>
  );
}
