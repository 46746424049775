import React from 'react';

// UI
import {
  Stack,
  Box,
  TableRow,
  TableCell,
  TableBody,
  IconButton,
} from '@mui/material';
import { AvatarMember } from 'app/components/Avatar';
import {
  KeyboardArrowUp as KeyboardArrowUpIcon,
  KeyboardArrowDown as KeyboardArrowDownIcon,
} from '@mui/icons-material';

// Library
import styled from 'styled-components/macro';
import { MemberLink } from 'app/components/MemberLink';
import { parseNumber } from 'utils/helper';

interface IProjectsCollapse {
  datesInMonth: any[];
  projectTasks: any;
  taskHours: any;
  projectId: number;
}

export function ProjectsCollapse(props: IProjectsCollapse) {
  const { projectTasks, projectId, datesInMonth, taskHours } = props;
  const [open, setOpen] = React.useState(false);
  const { projectLogs, memberLogs } = taskHours;

  return (
    <>
      <TableBody className="project_header">
        <TableRow>
          <TableCell>
            <Stack
              direction="row"
              sx={{ cursor: 'pointer' }}
              onClick={() => {
                setOpen(!open);
              }}
              alignItems="center"
              spacing={1}
            >
              <IconButton
                aria-label="expand row"
                size="small"
                style={{ padding: '2px' }}
              >
                {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
              </IconButton>
              <ProjectTitle>{projectTasks?.name || ''}</ProjectTitle>
            </Stack>
          </TableCell>
          {datesInMonth.map((v, k) => {
            const hour = projectLogs?.[`${projectId}_${v.dateString}`];
            return (
              <TableCell align="center" key={k} className="no_wrap">
                {hour ? hour + 'h' : '-'}
              </TableCell>
            );
          })}
          <TableCell>
            <Total style={{ fontWeight: 700 }}>
              {projectLogs?.[`${projectId}_total`]
                ? projectLogs?.[`${projectId}_total`] + 'h'
                : '-'}
            </Total>
          </TableCell>
        </TableRow>
      </TableBody>
      {open && (
        <TableBody className="project_content">
          {projectTasks?.member?.map(memberTasks => {
            const memberId = memberTasks?.id;
            return (
              <TableRow key={memberTasks?.memberId}>
                <TableCell>
                  <Stack
                    direction="row"
                    spacing={'6px'}
                    alignItems="center"
                    style={{ paddingLeft: '31.5px' }}
                  >
                    <AvatarMember
                      avatar={`${memberTasks?.avatar || ''}`}
                      title={`${memberTasks?.full_name || ''}`}
                      sx={{ width: 24, height: 24 }}
                    ></AvatarMember>

                    <ProjectName>
                      <MemberLink
                        member_id={memberId}
                        member_name={memberTasks?.full_name}
                      ></MemberLink>
                    </ProjectName>
                  </Stack>
                </TableCell>
                {datesInMonth.map((v, k) => {
                  const hour =
                    memberLogs?.[`${projectId}_${memberId}_${v.dateString}`];
                  return (
                    <TableCell align="center" key={k} className="no_wrap">
                      {hour ? hour + 'h' : '-'}
                    </TableCell>
                  );
                })}
                <TableCell>
                  <Total style={{ fontWeight: 500 }}>
                    {memberLogs?.[`${projectId}_${memberId}_total`]
                      ? parseNumber(
                          memberLogs?.[`${projectId}_${memberId}_total`],
                        ) + 'h'
                      : '-'}
                  </Total>
                </TableCell>
              </TableRow>
            );
          })}
        </TableBody>
      )}
    </>
  );
}

const ProjectName = styled(Box)`
  font-size: 12px;
  font-weight: 400;
`;

const ProjectTitle = styled(Box)`
  font-size: 14px;
`;

const Total = styled(Box)`
  color: #437dff;
`;
