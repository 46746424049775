import { useEffect, useState } from 'react';
import _ from 'lodash';

import { CloudUpload } from '@mui/icons-material';
import {
  Box,
  IconButton,
  MenuItem,
  Select,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import { Visibility, Download, Delete } from '@mui/icons-material';
import styled from 'styled-components';

import { CANDIDATE_STATUS } from 'constants/common';

import { useHistory } from 'react-router-dom';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import useRecruitment from 'app/pages/RecruitmentsPage/useRecruitment';
import { RecruitmentCandidateSchema } from 'app/pages/RecruitmentsPage/slice/type';
import { saveFile } from 'utils/helper';
import { Colors } from 'app/pages/HomePage/Components/Variables';
import Path from 'config/clientPath';

export function ListViewMode({
  setStatusJob,
  setIsOpenModal,
  setIsOpen,
  handleClose,
  setCVInfo,
}) {
  const { recruitmentCandidate, updateReCandidate } = useRecruitment();
  const { control, getValues } = useForm();
  const history = useHistory();
  const [active, setActive] = useState('All');
  const { t } = useTranslation();

  const [data, setData] = useState<RecruitmentCandidateSchema[]>();

  const JobStatusList = _.sortBy(
    _.map(Object.assign({}, CANDIDATE_STATUS, { '': 'All' }), (value, key) => ({
      key,
      value,
    })),
    ['key'],
  );

  const numberOfStatus = index => {
    return recruitmentCandidate?.data?.filter(
      record => record.status === parseInt(index),
    ).length;
  };

  useEffect(() => {
    if (active === 'All' && recruitmentCandidate) {
      setData([...recruitmentCandidate?.data]);
    } else if (data) {
      const filterData = recruitmentCandidate?.data?.filter(
        record =>
          record.status ===
          parseInt(JobStatusList.find(item => item.value === active).key),
      );
      if (filterData) {
        setData([...filterData]);
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [active, recruitmentCandidate]);

  return (
    <>
      <Stack direction="row" height="72px" overflow="auto">
        {_.map(JobStatusList, (status, index) => {
          return (
            <BoxWrapper
              display="flex"
              flexDirection="column"
              alignItems="center"
              key={`${status.key}`}
              minWidth="200px"
              width={`calc(100% / 8)`}
              className={`${
                index === 0
                  ? ''
                  : index === JobStatusList.length - 1
                  ? 'last'
                  : ''
              } ${active === status.value && 'active'} ${
                parseInt(
                  _.findKey(CANDIDATE_STATUS, value => value === active),
                ) === index && 'pre_active'
              }`}
              zIndex={active === status.value ? '10' : ''}
            >
              <TextDefault
                className={`${
                  index === 0
                    ? 'first'
                    : index === JobStatusList.length - 1
                    ? 'last'
                    : ''
                } ${active === status.value && 'bgColor'} `}
                color={active === status.value ? '#FFF' : ''}
                onClick={() => setActive(status.value)}
                style={{ overflowX: 'auto' }}
              >
                <Box display="flex" justifyContent="space-between">
                  <Stack direction="row" spacing={1} alignItems="center">
                    <Typography fontSize="14px" fontWeight="500">
                      {status.key && `${parseInt(status.key) + 1}.`}{' '}
                      {t(status.value)}
                    </Typography>
                    {
                      <Box
                        display="flex"
                        alignItems="center"
                        justifyContent="center"
                        width={16}
                        height={16}
                        borderRadius="50%"
                        bgcolor={
                          numberOfStatus(status.key) !== 0 || status.key === ''
                            ? 'red'
                            : ''
                        }
                      >
                        <Typography
                          fontSize="11px"
                          fontWeight="600"
                          color={Colors.white}
                          lineHeight="16px"
                        >
                          {status.value === 'All'
                            ? recruitmentCandidate?.data?.length
                            : numberOfStatus(status.key) !== 0
                            ? numberOfStatus(status.key)
                            : ''}
                        </Typography>
                      </Box>
                    }
                  </Stack>
                  <CloudUpload
                    sx={{
                      '&:hover': {
                        opacity: 0.7,
                      },
                    }}
                    style={{
                      fill: `${
                        active === status.value
                          ? Colors.white
                          : Colors.spanishGray
                      }`,
                    }}
                    onClick={() => {
                      setIsOpenModal(true);
                      setStatusJob(status.key ? status.key : '0');
                    }}
                  />
                </Box>
              </TextDefault>
            </BoxWrapper>
          );
        })}
      </Stack>

      <TableContainer>
        <Table stickyHeader>
          <TableHeadCus>
            <TableRow>
              <TableCell>{t('Candidate Name')}</TableCell>
              <TableCell>{t('Source')}</TableCell>
              <TableCell sx={{ textAlign: 'center' }}>
                {t('Applied Date')}
              </TableCell>
              <TableCell sx={{ textAlign: 'center' }}>
                {t('Experience')}
              </TableCell>
              <TableCell sx={{ textAlign: 'center' }}>{t('Status')}</TableCell>
              <TableCell sx={{ textAlign: 'center' }}>{t('Action')}</TableCell>
            </TableRow>
          </TableHeadCus>
          <TableBody sx={{ background: '#fff' }}>
            {_.isEmpty(data) ? (
              <TableRow>
                <TableCell colSpan={6}>{t('No data')}</TableCell>
              </TableRow>
            ) : (
              <>
                {_.map(data, (record: RecruitmentCandidateSchema) => {
                  return (
                    <TableRow key={`list_mode_${record.id}`}>
                      <TableCell>
                        <Typography
                          color={Colors.blueText}
                          fontSize="inherit"
                          onClick={() =>
                            history.push(`${Path.CANDIDATES}/${record.id}`)
                          }
                          sx={{ cursor: 'pointer' }}
                        >
                          {record.full_name}
                        </Typography>
                      </TableCell>
                      <TableCell>{record.source.name}</TableCell>
                      <TableCell sx={{ textAlign: 'center' }}>
                        {record.applied_date}
                      </TableCell>
                      <TableCell sx={{ textAlign: 'center' }}>
                        {record.years_of_experience
                          ? `${record.years_of_experience} Years`
                          : ''}
                      </TableCell>
                      <TableCell>
                        <Controller
                          control={control}
                          name={`status`}
                          render={({ field }) => {
                            const { onBlur, onChange, value } = field;
                            return (
                              <Select
                                fullWidth
                                displayEmpty
                                size="small"
                                onBlur={onBlur}
                                onChange={e => {
                                  onChange(e);
                                  updateReCandidate({
                                    id: record.id,
                                    status: getValues('status').toString(),
                                  });
                                }}
                                value={record.status}
                              >
                                {_.map(CANDIDATE_STATUS, (v, k) => (
                                  <MenuItem key={k} value={`${k}`}>
                                    {t(v)}
                                  </MenuItem>
                                ))}
                              </Select>
                            );
                          }}
                        />
                      </TableCell>
                      <TableCell>
                        <Stack
                          direction="row"
                          display="flex"
                          justifyContent="center"
                        >
                          <IconButton
                            onClick={() =>
                              history.push(`${Path.CANDIDATES}/${record.id}`)
                            }
                          >
                            <Visibility />
                          </IconButton>
                          <IconButton
                            onClick={() => {
                              saveFile(record.cv_file);
                            }}
                          >
                            <Download />
                          </IconButton>
                          <IconButton
                            onClick={() => {
                              setCVInfo(record);
                              setIsOpen(true);
                              handleClose();
                            }}
                          >
                            <Delete />
                          </IconButton>
                        </Stack>
                      </TableCell>
                    </TableRow>
                  );
                })}
              </>
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
}

const TextDefault = styled(Typography)`
  font-size: 14px !important;
  font-weight: 500 !important;
  line-height: 20px;
  background-color: #fff;
  border: 1px solid #c1c9d2;
  width: -webkit-fill-available;
  padding: 16px 24px;
  stroke-width: 1px;
  stroke: var(--Gray-200, #c1c9d2);
  filter: drop-shadow(0px 1px 2px #fff);
  border-right: none;
  cursor: pointer;
  height: fit-content;

  :not(.first) {
    border-left: none;
  }

  &.last {
    border-right: 1px solid #c1c9d2;
  }

  &.bgColor {
    background: #3699ff;
  }
`;

const TableHeadCus = styled(TableHead)`
  .MuiTableCell-head {
    color: #687182;
    font-weight: 700;
    font-size: 12px;
    background-color: #d1d5db;
  }
`;

const BoxWrapper = styled(Box)`
  position: relative;

  :not(.last):before {
    content: '';
    width: 30px;
    height: 23px;
    background: transparent;
    position: absolute;
    -moz-transform: rotate(65deg) skewX(44deg);
    -webkit-transform: rotate(65deg) skewX(44deg);
    transform: rotate(65deg) skewX(39deg);
    transform-origin: top right;
    top: 27px;
    margin: 0;
    right: -13px;
    z-index: 10;
    border-right: #c1c9d2 solid 1px;
    border-top: #c1c9d2 solid 1px;
  }

  &.active:before {
    border-bottom: 23px solid #3699ff;
  }
  :not(.first).pre_active:before {
    background: #fff;
    z-index: 12;
  }
`;
