import APIs from 'APIs';
import { StatusCode } from 'constants/API';
import { call, put, takeLatest } from 'redux-saga/effects';
import Slice from '.';

function* getJobListFlow({ payload }) {
  try {
    const response = yield call(APIs.recruitmentRequest, payload);
    if (response.status === StatusCode.SUCCESS_COMMON) {
      yield put({
        type: Slice.getJobListSuccess.type,
        payload: response.data,
      });
    } else {
      yield put({ type: Slice.getJobListFail.type });
    }
  } catch (error) {
    yield put({ type: Slice.getJobListFail.type });
  }
}

function* createJobFlow({ payload }) {
  try {
    const response = yield call(APIs.createJobRequest, payload);
    if (response.status === StatusCode.SUCCESS_CREATED) {
      yield put({
        type: Slice.createJobSuccess.type,
        payload: response.data,
      });
    } else {
      yield put({ type: Slice.createJobFail.type });
    }
  } catch (error) {
    yield put({ type: Slice.createJobFail.type });
  }
}

function* updateJobFlow({ payload }) {
  try {
    const { objectId, formData } = payload;
    const response = yield call(APIs.updateJobRequest, objectId, formData);
    if (response.status === StatusCode.SUCCESS_COMMON) {
      yield put({
        type: Slice.updateJobSuccess.type,
        payload: response.data,
      });
    } else {
      yield put({
        type: Slice.updateJobFail.type,
        payload: response.data,
      });
    }
  } catch (error) {
    yield put({ type: Slice.updateJobFail.type });
  }
}

function* deleteJobFlow({ payload }) {
  try {
    const response = yield call(APIs.deleteJobRequest, payload);
    if (response.status === StatusCode.NO_CONTENT) {
      yield put({
        type: Slice.deleteJobSuccess.type,
      });
    } else {
      yield put({ type: Slice.deleteJobFail.type });
    }
  } catch (error) {
    yield put({ type: Slice.deleteJobFail.type });
  }
}

function* getJobCategoriesFlow({ payload }) {
  try {
    const response = yield call(APIs.getJobCategoriesRequest, payload);
    if (response.status === StatusCode.SUCCESS_COMMON) {
      yield put({
        type: Slice.getJobCategoriesSuccess.type,
        payload: response.data,
      });
    } else {
      yield put({ type: Slice.getJobCategoriesFail.type });
    }
  } catch (error) {
    yield put({ type: Slice.getJobCategoriesFail.type });
  }
}

function* updateJobShowFlow({ payload }) {
  try {
    const response = yield call(APIs.updateJobStatusRequest, payload);
    if (response.status === StatusCode.SUCCESS_COMMON) {
      yield put({
        type: Slice.updateJobStatusSuccess.type,
        payload: response.data,
      });
    } else {
      yield put({
        type: Slice.updateJobStatusFail.type,
        payload: response.data,
      });
    }
  } catch (error) {
    yield put({ type: Slice.updateJobStatusFail.type });
  }
}

//JOB DETAIL
function* recruimentCandidatesFlow({ payload }) {
  try {
    const response = yield call(APIs.recruitmentCandidates, payload);
    if (response.status === StatusCode.SUCCESS_COMMON) {
      yield put({
        type: Slice.reCandidateSuccess.type,
        payload: response.data,
      });
    } else {
      yield put({ type: Slice.reCandidateFail.type });
    }
  } catch (error) {
    yield put({ type: Slice.reCandidateFail.type });
  }
}

function* updateRecruimentCandidatesFlow({ payload }) {
  try {
    const response = yield call(APIs.updateReCandidate, payload);
    if (response.status === StatusCode.SUCCESS_COMMON) {
      yield put({
        type: Slice.updateReCandidateSuccess.type,
        payload: response.data,
      });
    } else {
      yield put({ type: Slice.updateReCandidateFalse.type });
    }
  } catch (error) {
    yield put({ type: Slice.updateReCandidateFalse.type });
  }
}

function* deleteRecruimentCandidatesFlow({ payload }) {
  try {
    const response = yield call(APIs.deleteRecruitment, payload);
    if (response.status === StatusCode.NO_CONTENT) {
      yield put({
        type: Slice.deleteReCandidateSuccess.type,
        payload: response.data,
      });
    } else {
      yield put({ type: Slice.deleteReCandidateFalse.type });
    }
  } catch (error) {
    yield put({ type: Slice.deleteReCandidateFalse.type });
  }
}

function* getSourceCVFlow({ payload }) {
  try {
    const response = yield call(APIs.getSourceCV, payload);
    if (response.status === StatusCode.SUCCESS_COMMON) {
      yield put({
        type: Slice.getSourceCVSuccess.type,
        payload: response.data,
      });
    } else {
      yield put({ type: Slice.getSourceCVFalse.type });
    }
  } catch (error) {
    yield put({ type: Slice.getSourceCVFalse.type });
  }
}

function* uploadCVsFlow({ payload }) {
  try {
    const response = yield call(APIs.uploadCVs, payload.id, payload.payload);
    if (response.status === StatusCode.SUCCESS_COMMON) {
      yield put({
        type: Slice.uploadCVsSuccess.type,
        payload: response.data,
      });
    } else {
      if (response.status === StatusCode.BAD_REQUEST) {
        yield put({
          type: Slice.uploadCVsFalse.type,
          payload: response.data,
        });
      } else {
        yield put({
          type: Slice.uploadCVsFalse.type,
        });
      }
    }
  } catch (error) {
    yield put({ type: Slice.uploadCVsFalse.type });
  }
}

function* createCandidateFlow({ payload }) {
  try {
    const response = yield call(APIs.createCandidate, payload);
    if (response.status === StatusCode.SUCCESS_CREATED) {
      yield put({
        type: Slice.createCandidateSuccess.type,
        payload: response.data,
      });
    } else {
      yield put({ type: Slice.createCandidateFalse.type });
    }
  } catch (error) {
    yield put({ type: Slice.createCandidateFalse.type });
  }
}

function* updateCandidateFlow({ payload }) {
  try {
    const response = yield call(
      APIs.updateCandidate,
      payload.id,
      payload.payload,
    );
    if (response.status === StatusCode.SUCCESS_COMMON) {
      yield put({
        type: Slice.updateCandidateSuccess.type,
        payload: response.data,
      });
    } else {
      yield put({ type: Slice.updateCandidateFalse.type });
    }
  } catch (error) {
    yield put({ type: Slice.updateCandidateFalse.type });
  }
}

//Candidate List
function* getCandidateListFlow({ payload }) {
  try {
    const response = yield call(APIs.getCandidatesHistoryRequest, payload);
    if (response.status === StatusCode.SUCCESS_COMMON) {
      yield put({
        type: Slice.getCandidateListSuccess.type,
        payload: response.data,
      });
    } else {
      yield put({ type: Slice.getCandidateListFail.type });
    }
  } catch (error) {
    yield put({ type: Slice.getCandidateListFail.type });
  }
}

function* getInfoCandidateRequestFlow({ payload }) {
  try {
    const response = yield call(APIs.getInfoCandidateDetail, payload);
    if (response.status === StatusCode.SUCCESS_COMMON) {
      yield put({
        type: Slice.getInfoCandidateSucess,
        payload: response.data,
      });
    } else {
      yield put({ type: Slice.getInfoCandidateFail.type });
    }
  } catch (error) {
    yield put({ type: Slice.getInfoCandidateFail.type });
  }
}

function* updateInfoCandidateRequestFlow({ payload }) {
  try {
    const { id, formData } = payload;
    const response = yield call(APIs.updateInfoCandidates, id, formData);
    if (response.status === StatusCode.SUCCESS_COMMON) {
      yield put({
        type: Slice.updateInfoCandidateSuccess,
        payload: response.data,
      });
    } else {
      yield put({ type: Slice.updateInfoCandidateFail.type });
    }
  } catch (error) {
    yield put({ type: Slice.updateInfoCandidateFail.type });
  }
}

function* getInteractionLogRequestFlow({ payload }) {
  try {
    const response = yield call(APIs.getInteractionLogs, payload);
    if (response.status === StatusCode.SUCCESS_COMMON) {
      yield put({
        type: Slice.getInteractionLogSuccess,
        payload: response.data,
      });
    } else {
      yield put({ type: Slice.getInteractionLogFail.type });
    }
  } catch (error) {
    yield put({ type: Slice.getInteractionLogFail.type });
  }
}

function* getInterviewRecordRequestFlow({ payload }) {
  try {
    const response = yield call(APIs.getInterviewRecords, payload);
    if (response.status === StatusCode.SUCCESS_COMMON) {
      yield put({
        type: Slice.getInterviewRecordSucess,
        payload: response.data,
      });
    } else {
      yield put({ type: Slice.getInterviewRecordFail.type });
    }
  } catch (error) {
    yield put({ type: Slice.getInterviewRecordFail.type });
  }
}

function* uploadVideoRecordRequestFlow({ payload }) {
  try {
    const { id, formData } = payload;
    const response = yield call(APIs.uploadVideoRecords, id, formData);
    if (response.status === StatusCode.SUCCESS_COMMON) {
      yield put({
        type: Slice.uploadVideoRecordSucess,
      });
    } else {
      yield put({ type: Slice.uploadVideoRecordFail.type });
    }
  } catch (error) {
    yield put({ type: Slice.uploadVideoRecordFail.type });
  }
}

function* deleteVideoRecordsFlow({ payload }) {
  try {
    const { id, record_id } = payload;
    const response = yield call(APIs.deleteVideoRecords, id, record_id);
    if (response.status === StatusCode.NO_CONTENT) {
      yield put({
        type: Slice.deleteVideoRecordSucess.type,
      });
    } else {
      yield put({ type: Slice.deleteVideoRecordFail.type });
    }
  } catch (error) {
    yield put({ type: Slice.deleteVideoRecordFail.type });
  }
}

function* getNotesFlow({ payload }) {
  try {
    const response = yield call(APIs.getNotes, payload);
    if (response.status === StatusCode.SUCCESS_COMMON) {
      yield put({
        type: Slice.getNotesSuccess,
        payload: response.data,
      });
    } else {
      yield put({ type: Slice.getNotesFail.type });
    }
  } catch (error) {
    yield put({ type: Slice.getNotesFail.type });
  }
}

function* updateNoteFlow({ payload }) {
  try {
    const response = yield call(APIs.updateNote, payload);
    if (response.status === StatusCode.SUCCESS_COMMON) {
      yield put({
        type: Slice.updateNoteSuccess,
        payload: response.data,
      });
    } else {
      yield put({ type: Slice.updateNoteFail.type });
    }
  } catch (error) {
    yield put({ type: Slice.updateNoteFail.type });
  }
}

function* deleteNoteFlow({ payload }) {
  try {
    const response = yield call(APIs.deleteNote, payload);
    if (response.status === StatusCode.NO_CONTENT) {
      yield put({
        type: Slice.deleteNoteSuccess,
        payload: response.data,
      });
    } else {
      yield put({ type: Slice.deleteNoteFail.type });
    }
  } catch (error) {
    yield put({ type: Slice.deleteNoteFail.type });
  }
}

function* addNoteFlow({ payload }) {
  try {
    const response = yield call(APIs.addNote, payload);
    if (response.status === StatusCode.SUCCESS_CREATED) {
      yield put({
        type: Slice.addNoteSuccess,
        payload: response.data,
      });
    } else {
      yield put({ type: Slice.addNoteFail.type });
    }
  } catch (error) {
    yield put({ type: Slice.addNoteFail.type });
  }
}

function* recruitmentWatcher() {
  yield takeLatest(Slice.getJobListRequest, getJobListFlow);
  yield takeLatest(Slice.createJobRequest, createJobFlow);
  yield takeLatest(Slice.updateJobRequest, updateJobFlow);
  yield takeLatest(Slice.getJobCategoriesRequest, getJobCategoriesFlow);
  yield takeLatest(Slice.deleteJobRequest, deleteJobFlow);
  yield takeLatest(Slice.updateJobStatusRequest, updateJobShowFlow);
  yield takeLatest(Slice.reCandidateRequest, recruimentCandidatesFlow);
  yield takeLatest(
    Slice.updateReCandidateRequest,
    updateRecruimentCandidatesFlow,
  );
  yield takeLatest(
    Slice.deleteReCandidateRequest,
    deleteRecruimentCandidatesFlow,
  );
  yield takeLatest(Slice.getSourceCVRequest, getSourceCVFlow);
  yield takeLatest(Slice.uploadCVsRequest, uploadCVsFlow);
  yield takeLatest(Slice.createCandidateRequest, createCandidateFlow);
  yield takeLatest(Slice.updateCandidateRequest, updateCandidateFlow);
  yield takeLatest(Slice.getCandidateListRequest, getCandidateListFlow);
  yield takeLatest(Slice.getInfoCandidateRequest, getInfoCandidateRequestFlow);
  yield takeLatest(
    Slice.updateInfoCandidateRequest,
    updateInfoCandidateRequestFlow,
  );
  yield takeLatest(
    Slice.getInteractionLogRequest,
    getInteractionLogRequestFlow,
  );
  yield takeLatest(
    Slice.getInterviewRecordRequest,
    getInterviewRecordRequestFlow,
  );
  yield takeLatest(
    Slice.uploadVideoRecordRequest,
    uploadVideoRecordRequestFlow,
  );
  yield takeLatest(Slice.deleteVideoRecordRequest, deleteVideoRecordsFlow);
  yield takeLatest(Slice.getNotesRequest, getNotesFlow);
  yield takeLatest(Slice.updateNoteRequest, updateNoteFlow);
  yield takeLatest(Slice.deleteNoteRequest, deleteNoteFlow);
  yield takeLatest(Slice.addNoteRequest, addNoteFlow);
}

export default recruitmentWatcher;
