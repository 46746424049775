import { useDropzone } from 'react-dropzone';
import _ from 'lodash';
import {
  Box,
  Button,
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  Typography,
} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import { Controller, useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { useGlobalSlice } from 'app/pages/GlobalContainer/slice';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import useRecruitment from 'app/pages/RecruitmentsPage/useRecruitment';
import { Colors } from 'app/pages/HomePage/Components/Variables';

export const ModalUploadByAI = ({ statusJob, setCloseModal }) => {
  const dispatch = useDispatch();
  const { id }: { id: string } = useParams();
  const { t } = useTranslation();
  const { setErrorMessages } = useGlobalSlice().actions;
  const {
    handleSubmit,
    control,
    getValues,
    setValue,
    formState: { errors },
  } = useForm();
  const { uploadCVs, sourceCV } = useRecruitment();

  const onDrop = acceptedFiles => {
    acceptedFiles.filter(file => {
      if (file.size > 10 * 1024 * 1024) {
        dispatch(
          setErrorMessages([t('File size exceeds maximum limit! (10MB)')]),
        );
        return false;
      } else {
        setValue('cv_file', file);
        return true;
      }
    });
  };
  const { getRootProps } = useDropzone({
    accept: { 'application/pdf': ['.pdf'] },
    onDrop: onDrop,
    multiple: false,
  });

  const handleRemoveFile = () => {
    setValue('cv_file', '');
  };

  const handleSubmitForm = async data => {
    // setCvFrom(data.source);
    let formData: FormData = new FormData();

    formData.append('cv_file', data.cv_file);
    formData.append('source', data.source);
    formData.append('status', statusJob.toString());

    // uploadCVs({ id: id, payload: formData });
    dispatch(setErrorMessages(['Chuc nang dang phat trien ☠️']));
    setCloseModal();
  };

  return (
    <FormControl
      component="form"
      onSubmit={handleSubmit(handleSubmitForm)}
      fullWidth
    >
      <FormControl error={'cv_file' in (errors || {})}>
        <Controller
          control={control}
          name="cv_file"
          rules={{ required: true }}
          render={() => {
            return (
              <>
                <Box
                  {...getRootProps()}
                  border="1px dashed #676767"
                  borderRadius="4px"
                  m="15px"
                  mb="0"
                >
                  <Stack
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                    p="40px"
                    sx={{
                      cursor: 'pointer',
                    }}
                  >
                    <Box>
                      <Box width="64px">
                        <CloudUploadIcon
                          sx={{
                            width: '64px',
                            height: '64px',
                            color: Colors.blueText,
                          }}
                        />
                      </Box>
                    </Box>
                    <Typography
                      variant="body1"
                      component="p"
                      fontSize="16px"
                      fontWeight="600"
                    >
                      {t('Drag & drop files or')}{' '}
                      <Typography
                        color={Colors.blueText}
                        component="span"
                        fontSize="16px"
                        fontWeight="600"
                        sx={{ textDecoration: 'underline' }}
                      >
                        {t('Browse')}
                      </Typography>
                    </Typography>
                    <Typography variant="body1" component="p" fontSize="12px">
                      {t('Support PDF only')}
                    </Typography>
                  </Stack>
                </Box>
                {errors.cv_file && (
                  <Box px="15px">
                    <FormHelperText sx={{ marginX: '0' }}>
                      {t('This field is required')}
                    </FormHelperText>
                  </Box>
                )}
              </>
            );
          }}
        />
      </FormControl>

      <Box p="15px" mt="5px">
        <FormControl error={'source' in (errors || {})}>
          <InputLabel id="select-label" required>
            {t('CV source')}
          </InputLabel>
          <Controller
            control={control}
            name={`source`}
            rules={{ required: true }}
            render={({ field }) => {
              const { onBlur, onChange, value } = field;
              return (
                <>
                  <Select
                    style={{ minWidth: '94px', marginTop: 3 }}
                    autoWidth
                    displayEmpty
                    size="small"
                    value={`${value}`}
                    onBlur={onBlur}
                    label="source"
                    labelId="select-label"
                    id="simple-select"
                    onChange={onChange}
                  >
                    {_.map(sourceCV, v => (
                      <MenuItem key={v.id} value={`${v.id}`}>
                        {v.name}
                      </MenuItem>
                    ))}
                  </Select>
                  {errors.source && (
                    <FormHelperText sx={{ marginX: '0' }}>
                      {t('This field is required')}
                    </FormHelperText>
                  )}
                </>
              );
            }}
          />
        </FormControl>
      </Box>

      <Box
        p={!_.isEmpty(getValues('cv_file')) ? '15px' : ''}
        pt="0"
        maxHeight="300px"
        overflow="auto"
        display={!_.isEmpty(getValues('cv_file')) ? 'block' : 'none'}
      >
        <Box
          display="flex"
          justifyContent="space-between"
          p="10px"
          border="1px solid #3699FF"
          borderRadius="4px"
        >
          <Typography>{getValues('cv_file')?.name}</Typography>

          <Box
            onClick={handleRemoveFile}
            color="red"
            style={{
              cursor: 'pointer',
            }}
          >
            <DeleteIcon />
          </Box>
        </Box>
      </Box>
      <Stack
        direction="row"
        display="flex"
        justifyContent="flex-end"
        p="15px"
        borderTop="1px solid #BDBDBD"
        spacing={2}
      >
        <Button
          variant="contained"
          color="inherit"
          onClick={setCloseModal}
          type="button"
        >
          {t('Cancel')}
        </Button>
        <Button variant="contained" color="primary" type="submit">
          {t('Submit')}
        </Button>
      </Stack>
    </FormControl>
  );
};
