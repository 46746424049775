import React, { Dispatch } from 'react';

// UI
import {
  Box,
  Divider,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Stack,
  Drawer,
  Toolbar,
  ListItemButton,
} from '@mui/material';

import {
  Email as EmailIcon,
  Badge as BadgeIcon,
  Code as CodeIcon,
} from '@mui/icons-material';
import { AvatarMember } from 'app/components/Avatar';
import { ReactComponent as ProfileIcon } from 'assets/icons/profile/profile.svg';
import { ReactComponent as LogoutIcon } from 'assets/icons/profile/logout.svg';

// Redux & Sagas
import { Action } from 'redux';
import { IUserSessionData } from 'app/pages/GlobalContainer/slice/types';

// Library
import Path from 'config/clientPath';
import { useHistory } from 'react-router';
import { Link } from 'react-router-dom';
import { removeStorageData } from 'utils/localStorage';
import _ from 'lodash';

interface IProps {
  open: boolean;
  anchor: 'right' | 'left';
  userSessionData: IUserSessionData;
  onClose: Dispatch<Action<boolean>>;
}

export function ProfileBar(props: IProps) {
  const { userSessionData, ...restProps } = props;
  const { email, full_name, avatar, role, skill } = userSessionData.profile;

  const history = useHistory();

  const handleLogout = () => {
    removeStorageData();
    history.push(Path.LOGIN);
  };

  return (
    <Drawer
      {...restProps}
      sx={{
        '& .MuiDrawer-paper': { width: 280 },
      }}
    >
      <Toolbar />
      <Stack spacing={2}>
        <Box display="flex" justifyContent="center">
          <AvatarMember
            title={full_name}
            avatar={avatar}
            sx={{ width: 120, height: 120, fontSize: 30 }}
            size={120}
          ></AvatarMember>
        </Box>

        <List>
          <ListItem>
            <ListItemText
              primary={full_name}
              primaryTypographyProps={{ component: 'h5', variant: 'h5' }}
            />
          </ListItem>
          <ListItem>
            <ListItemIcon>
              <EmailIcon />
            </ListItemIcon>
            <ListItemText primary={email} />
          </ListItem>
          {role && (
            <ListItem>
              <ListItemIcon>
                <BadgeIcon />
              </ListItemIcon>
              <ListItemText primary={role} />
            </ListItem>
          )}
          {!_.isEmpty(skill) && (
            <ListItem>
              <ListItemIcon>
                <CodeIcon />
              </ListItemIcon>
              <ListItemText primary={_.map(skill, 'name').join(', ')} />
            </ListItem>
          )}
        </List>
        <Divider />
        <List>
          <ListItemButton component={Link} to={Path.PROFILE}>
            <ListItemIcon>
              <ProfileIcon />
            </ListItemIcon>
            <ListItemText
              primary="My Profile"
              secondary="Update Account Infomation"
            />
          </ListItemButton>
          <ListItemButton
            component={Link}
            to={Path.MEMBERS + `/edit/${userSessionData.profile.id}`}
          >
            <ListItemIcon>
              <ProfileIcon />
            </ListItemIcon>
            <ListItemText
              primary="Member Detail"
              secondary="Update Member Infomation"
            />
          </ListItemButton>
          <ListItemButton onClick={() => handleLogout()}>
            <ListItemIcon>
              <LogoutIcon />
            </ListItemIcon>
            <ListItemText primary="Logout" />
          </ListItemButton>
        </List>
      </Stack>
    </Drawer>
  );
}
