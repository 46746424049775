import * as React from 'react';
import Path from 'config/clientPath';

// UI
import { Helmet } from 'react-helmet-async';
import styled from 'styled-components/macro';
import { useDispatch, useSelector } from 'react-redux';
import {
  Container,
  CardContent,
  Typography,
  Button,
  TextField,
  Stack,
  Paper,
  Box,
} from '@mui/material';
import { Link, useHistory } from 'react-router-dom';

import LoginPageBackground from 'assets/page-login-bg.svg';
import { ReactComponent as Logo } from 'assets/logo.svg';

// Redux & Sagas
import { useLoginSlice } from './slice';
import { selectLogin } from './slice/selectors';
import { RequestStatus } from 'constants/API';
import { isHavingToken, setToken } from 'utils/localStorage';
import { useGlobalSlice } from '../GlobalContainer/slice';
import { checkIsRequesting } from 'utils/helper';
import { useGoogleLogin } from '@react-oauth/google';
import { Loader } from 'app/components/Loader';

// Library
import { useForm } from 'react-hook-form';

import { useRole } from 'utils/role-hook';
import Roles from 'constants/roles';
import APIs from 'APIs';

interface ILoginForm {
  username: string;
  password: string;
}

export function LoginPage() {
  const dispatch = useDispatch();
  const history = useHistory();
  const { loginRequest, resetLoginStatus, loginWithGgRequest } =
    useLoginSlice().actions;
  const { setErrorMessages, setIsGlobalLoading } = useGlobalSlice().actions;
  const { accessToken, loginStatus, errorMess, userRole, loginGgStatus } =
    useSelector(selectLogin);
  const refUserRole = React.useRef('');

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const isLoading = checkIsRequesting([loginStatus, loginGgStatus]);

  const { hasRole } = useRole();

  const handleLoginSubmit = formData => {
    dispatch(setIsGlobalLoading(true));
    dispatch(loginRequest(formData));
  };

  const getDefaultRoutePath = () => {
    // if user is customer => assignable page is default route
    if (refUserRole.current === Roles.IS_CUSTOMER) {
      return Path.ASSIGNABLE;
    }

    if (hasRole(Roles.CUSTOMER_ALL, true)) {
      return Path.CUSTOMERS;
    }

    if (hasRole(Roles.PROJECT_ALL, true)) {
      return Path.PROJECTS;
    }

    if (hasRole(Roles.MEMBER_ALL, true)) {
      return Path.MEMBERS;
    }

    if (hasRole(Roles.IS_ADMIN, true)) {
      return Path.DAILY_REPORT_SUMMARY;
    }

    if (hasRole(Roles.IS_NORMAL_USER, true)) {
      return Path.TIMESHEET;
    }

    if (hasRole(Roles.TEAM_ALL, true)) {
      return Path.TEAM;
    }

    if (hasRole(Roles.TEAM_ALL, true)) {
      return Path.TEAM;
    }

    // Default route to path
    // return Path.HOME;
    return Path.TIMESHEET;
  };

  const loginWithGoogle = useGoogleLogin({
    flow: 'implicit',
    hosted_domain: process.env.REACT_APP_HOSTED_DOMAIN,
    onSuccess: codeResponse => {
      fetch(`${APIs.loginURL}=${codeResponse.access_token}`).then(async () => {
        await dispatch(
          loginWithGgRequest({ access_token: codeResponse.access_token }),
        );
      });
    },
    onError: () => {
      dispatch(setErrorMessages(['Login failed with google account!']));
    },
  });

  const checkAndNavigate = React.useCallback(() => {
    if (isHavingToken()) {
      history.push(getDefaultRoutePath());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [history]);

  React.useEffect(() => {
    if (
      loginStatus !== RequestStatus.REQUESTING ||
      loginGgStatus !== RequestStatus.REQUESTING
    )
      dispatch(setIsGlobalLoading(false));
    if (
      loginStatus === RequestStatus.SUCCESS ||
      loginGgStatus === RequestStatus.SUCCESS
    ) {
      setToken(accessToken);

      refUserRole.current = userRole;
      dispatch(resetLoginStatus());
      checkAndNavigate();
    }
    if (
      loginStatus === RequestStatus.ERROR ||
      loginGgStatus === RequestStatus.ERROR
    ) {
      dispatch(setErrorMessages(errorMess));
      dispatch(resetLoginStatus());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loginStatus, loginGgStatus]);

  React.useEffect(
    () => () => {
      dispatch(resetLoginStatus());
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    ['componentWillUnMount'],
  );

  React.useEffect(() => {
    checkAndNavigate();
  }, [checkAndNavigate]);

  return (
    <>
      <Helmet>
        <title>Palette</title>
        <meta name="description" content="Palette" />
        <style>{`
          body, #root{
            background-image: url(${LoginPageBackground});
            background-position: center;
            background-repeat: no-repeat;
            background-size: cover;
          }
        `}</style>
      </Helmet>
      <Container>
        <Loader open={isLoading} />

        <LoginForm variant="outlined">
          <Typography gutterBottom variant="h4" component="h1" align="center">
            <Logo />
          </Typography>
          <Typography gutterBottom variant="h5" component="h3" align="center">
            Here you can login
          </Typography>
          <CardContent className="body">
            <Box
              component="form"
              id="login_form"
              onSubmit={handleSubmit(handleLoginSubmit)}
            >
              <Stack spacing={2}>
                <Stack spacing={1}>
                  <Typography gutterBottom>User name</Typography>
                  <TextField
                    {...register<keyof ILoginForm>('username', {
                      required: 'This field is required.',
                    })}
                    type="email"
                    placeholder="Enter user name"
                    fullWidth
                    variant="outlined"
                    size="small"
                    error={errors.username ? true : false}
                    helperText={errors.username?.message}
                  />
                </Stack>
                <Stack spacing={1}>
                  <Typography gutterBottom>Password</Typography>
                  <TextField
                    {...register<keyof ILoginForm>('password', {
                      required: 'This field is required.',
                    })}
                    type="password"
                    placeholder="Enter your password"
                    fullWidth
                    variant="outlined"
                    size="small"
                    error={errors.password ? true : false}
                    helperText={errors.password?.message}
                  />
                </Stack>
                <Stack direction="row" justifyContent="space-between">
                  <Button
                    variant="contained"
                    color="primary"
                    size="large"
                    type="submit"
                    form="login_form"
                  >
                    Login
                  </Button>
                  <Button
                    variant="outlined"
                    color="primary"
                    size="large"
                    onClick={() => loginWithGoogle()}
                  >
                    Sign in with Google
                  </Button>
                  <Link to={Path.FORGOT_PASSWORD}>Forgot password?</Link>
                </Stack>
              </Stack>
            </Box>
          </CardContent>
        </LoginForm>
      </Container>
    </>
  );
}

const LoginForm = styled(Paper)`
  width: 480px;
  margin: 20% auto;

  h1 {
    text-align: center;
    padding: 15px 0;
    margin: 0;
  }
  .body {
    padding-top: 0;
  }
  .MuiInputBase-formControl {
    fieldset,
    &:hover fieldset {
      border-color: #eef0f8;
    }
  }
`;
