import { Box, Stack, Typography } from '@mui/material';
import { ReactComponent as MobileLogo } from 'assets/bottom_logo.svg';
import { ReactComponent as Digitrans } from 'assets/icons/landing/digitrans.svg';
import { ReactComponent as Internect } from 'assets/icons/landing/internect.svg';
import { ReactComponent as InternectChina } from 'assets/icons/landing/internect_China.svg';
import { ReactComponent as InternectSin } from 'assets/icons/landing/internect_Sin.svg';
import { ReactComponent as InternectThai } from 'assets/icons/landing/internect_Thai.svg';
import { ReactComponent as Splean } from 'assets/icons/landing/Splean.svg';
import Background from 'assets/img/footer_bg.png';
import { ReactComponent as Logo } from 'assets/logo_ft.svg';
import React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { Footer } from './Footer';
import { Colors, device } from './Variables';

const partners = [
  {
    name: 'Internnect JP',
    href: 'http://internnect.co.jp',
    img: <Internect />,
  },
  {
    name: 'Splean',
    href: 'http://internnect.co.jp',
    img: <Splean />,
  },
  {
    name: 'Internnect Thai',
    href: 'http://internnect.co.jp',
    img: <InternectThai />,
  },
  {
    name: 'Internnect China',
    href: 'http://internnect.co.jp',
    img: <InternectChina />,
  },
  {
    name: 'Internnect Singapore',
    href: 'http://internnect.co.jp',
    img: <InternectSin />,
  },
  {
    name: 'Digitrans',
    href: 'http://internnect.co.jp',
    img: <Digitrans />,
  },
];

export const FooterSection = () => {
  return (
    <>
      <FooterWrapper>
        <Wrapper>
          <LogoBox>
            <Logo />
            <MobileLogo />
          </LogoBox>
          <FooterColumn
            direction="row"
            spacing={5}
            pb={20}
            alignItems="flex-end"
            alignContent="flex-end"
          >
            <Box>
              <FooterHeading variant="h5">Company Info</FooterHeading>
              <Typography
                variant="h6"
                component="h6"
                fontSize="16px"
                className="nowrap blue-text"
              >
                株式会社Internnect
              </Typography>
              <Typography
                component="p"
                fontSize="16px"
                className="nowrap blue-text"
              >
                代表取締役 岡本 龍一
              </Typography>
              <Typography
                component="p"
                fontSize="16px"
                className="nowrap blue-text"
              >
                <BoldSpan>Tel:</BoldSpan> 080-4638-6374
              </Typography>
              <Typography
                component="p"
                fontSize="16px"
                className="nowrap blue-text"
              >
                <BoldSpan>Email：</BoldSpan>info@internnect.co.jp
              </Typography>
            </Box>
            <Box>
              <Typography component="p" fontSize="16px" className="blue-text">
                <BoldSpan>Head Office:</BoldSpan>〒290-0003
                千葉県市原市辰巳台東3-20-4
              </Typography>
              <Typography component="p" fontSize="16px" color={Colors.darkBlue}>
                <BoldSpan>Tokyo Office:</BoldSpan>〒107-0052 東京都港
                区赤坂6-19-54-604
              </Typography>
            </Box>
            <Box>
              <FooterHeading variant="h5">We belong together</FooterHeading>
              <LogoStack direction="row" spacing={2}>
                {partners.map((it, idx) => (
                  <Img key={idx}>
                    <Link
                      to={{
                        pathname: it.href,
                      }}
                      target="_blank"
                      rel="noreferrer"
                    >
                      {it.img}
                    </Link>
                  </Img>
                ))}
              </LogoStack>
            </Box>
          </FooterColumn>
        </Wrapper>
      </FooterWrapper>
      <Footer />
    </>
  );
};

const FooterWrapper = styled.section`
  background: url(${Background}) center;
  background-size: cover;

  p {
    line-height: 2.5;
  }
  @media ${device.mobileXL} {
    background: ${Colors.skyBlue};
  }
`;
const BoldSpan = styled.span`
  font-weight: bold;
`;
const Img = styled.span`
  display: flex;
  align-items: center;
  justify-content: center;
  background: ${Colors.white};
  padding: 5px;
  flex: 1 1 26%;
  height: 56px;
  margin-bottom: 15px;
  &:nth-of-type(4n) {
    margin-left: 0;
  }
  &:nth-of-type(4n),
  &:nth-of-type(5n),
  &:nth-of-type(5n) {
    margin-bottom: 15px;
  }

  @media (max-width: 626px) {
    &:nth-of-type(2n + 1) {
      margin-left: 16px;
    }
  }
  @media ${device.mobileXL} {
    &:nth-of-type(4n) {
      margin-left: 16px;
    }
    &:nth-of-type(2n + 1) {
      margin-left: 0;
    }
    width: 158px;
  }
  @media (max-width: 400px) {
  }
`;
const LogoBox = styled(Box)`
  margin: 50px auto;
  padding: 100px;
  text-align: center;
  svg:nth-child(2) {
    display: none;
  }
  @media ${device.tablet} {
    padding: 30px;
    margin: 0 auto;
    svg:nth-child(2) {
      display: inline-block;
    }
    svg:first-child {
      display: none;
    }
  }
`;

const FooterHeading = styled(Typography)`
  &.MuiTypography-h5 {
    font-size: 24px;
    color: ${Colors.darkBlue};
    font-weight: 600;
    padding: 15px 0 50px;
    @media ${device.mobileL} {
      padding: 15px 0;
      font-size: 18px;
    }
  }
`;
const Wrapper = styled.div`
  margin: 0px auto;
  max-width: 1024px;
  @media ${device.laptop} {
    padding: 20px;
  }
  @media ${device.mobileL} {
    padding: 20px 0;
  }
`;

const FooterColumn = styled(Stack)`
  &.css-13ah4bd-MuiTypography-root {
    line-height: 2;
  }
  @media (max-width: 1023px) {
    &.css-fj3u1l-MuiStack-root {
      flex-wrap: wrap;

      padding: 20px;
      > div {
        padding: 0 15px;
        margin-left: 0;
      }

      > div:first-child {
        order: 2;
      }
      > div:last-child {
        order: 1;
      }
      > div:nth-child(2) {
        order: 3;
      }
      .MuiBox-root {
        padding: 15px;
      }
    }
  }
  @media ${device.tablet} {
    flex-direction: column;
    flex-wrap: nowrap;
    align-items: flex-start;
    justify-content: flex-start;
    &.css-fj3u1l-MuiStack-root {
      .MuiBox-root {
        padding: 0;
      }
      > div:nth-child(2) {
        margin-left: 15px;
      }
    }
    > div:last-child {
      width: 100%;
    }
  }
  @media only screen and (max-width: 739px) {
    &.css-fj3u1l-MuiStack-root {
      > div:nth-child(2) {
        margin-left: 0;
      }
    }
  }
`;

const LogoStack = styled(Stack)`
  flex-wrap: wrap;
  @media (max-width: 626px) {
    &.css-e53awj-MuiStack-root {
      display: grid;
      grid-template-columns: repeat(3, calc(100% / 3));
    }
  }
  @media ${device.mobileXL} {
    &.css-e53awj-MuiStack-root {
      grid-template-columns: repeat(2, calc(100% / 2));
    }
  }
  @media (max-width: 400px) {
    &.css-e53awj-MuiStack-root {
      display: flex;
      justify-content: space-between;
    }
  }
`;
