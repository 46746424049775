import React, { useEffect, useState } from 'react';
import {
  Box,
  Typography,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  TableContainer,
  Paper,
  Stack,
  Button,
  Card,
  CardContent,
  Pagination,
} from '@mui/material';
import { Link } from 'react-router-dom';
import styled from 'styled-components/macro';
import { ProjectLink } from 'app/components/ProjectLink';
import moment from 'moment';
import _ from 'lodash';
import { useHistory } from 'react-router-dom';

// Redux & Sagas
import { useDispatch, useSelector } from 'react-redux';
import { useGlobalSlice } from 'app/pages/GlobalContainer/slice';
import Path from 'config/clientPath';
import { RequestStatus } from 'constants/API';
import { useLocation } from 'react-router';
import queryString from 'query-string';
import { STATUS_DISPLAY_TYPE, TaskStatus } from 'app/components/TaskStatus';
import { BillableTag } from 'app/components/BillableTag';
import { selectTimesheet } from 'app/pages/TimesheetPage/slice/selectors';
import { useTimesheetSlice } from 'app/pages/TimesheetPage/slice';
import { selectGlobal } from 'app/pages/GlobalContainer/slice/selectors';
import { useTranslation } from 'react-i18next';

interface IProps {
  month: moment.Moment | null;
}

export function RequestApproveTask(props: IProps) {
  const { month } = props;
  const dispatch = useDispatch();
  const [isRequestTask, setIsRequestTask] = useState<boolean>(false);
  const location = useLocation();
  const history = useHistory();
  const { t } = useTranslation();

  const {
    approvalList,
    approveRequestStatus,
    requestApprovalStatus,
    cancelRequestStatus,
    getApprovalListStatus,
  } = useSelector(selectTimesheet);

  const {
    actions: {
      getApprovalListRequest,
      requestApprovalRequest,
      cancelRequestRequest,
    },
  } = useTimesheetSlice();

  const { setSuccessMessages, setErrorMessages } = useGlobalSlice().actions;

  const {
    userSessionData: { profile },
  } = useSelector(selectGlobal);

  const onSuccess = (messages: string[]) => {
    dispatch(setSuccessMessages(messages));
    reloadData();
  };

  useEffect(() => {
    if (requestApprovalStatus === RequestStatus.SUCCESS) {
      onSuccess(['Request Approval success']);
    }
    if (requestApprovalStatus === RequestStatus.ERROR) {
      dispatch(setErrorMessages(['Oops.. Something went wrong!']));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [requestApprovalStatus]);

  useEffect(() => {
    if (cancelRequestStatus === RequestStatus.SUCCESS) {
      onSuccess(['Cancel Request success']);
    }
    if (cancelRequestStatus === RequestStatus.ERROR) {
      dispatch(setErrorMessages(['Oops.. Something went wrong!']));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cancelRequestStatus]);

  useEffect(() => {
    if (getApprovalListStatus === RequestStatus.SUCCESS) {
      const hasRequestTask = approvalList?.data?.some(
        item => item.status !== 3,
      );
      setIsRequestTask(hasRequestTask ?? false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getApprovalListStatus]);

  useEffect(() => {
    if (approveRequestStatus === RequestStatus.SUCCESS) {
      dispatch(setSuccessMessages(['Approve task success']));
      reloadData();
    }
    if (approveRequestStatus === RequestStatus.ERROR) {
      dispatch(setErrorMessages(['Approve task errors']));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [approveRequestStatus]);

  const reloadData = () => {
    const params = new URLSearchParams(window.location.search);
    const newMonth = params.get('month')
      ? moment(params.get('month'))
      : moment();
    dispatch(
      getApprovalListRequest({
        month: newMonth?.clone().format('yyyy-MM'),
        member: profile?.id,
        page: Math.abs(_.toInteger(params.get('page')) || 1),
      }),
    );
  };

  useEffect(() => {
    reloadData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.search]);

  const handleChangePage = (
    event: React.ChangeEvent<unknown> | null,
    newPage: number,
  ) => {
    let params = queryString.parse(window.location.search);
    const qs = queryString.stringify({ ...params, page: +newPage });
    history.push(`${Path.DAILY_REPORT_MONTHLY_SUMMARY}?${qs}`);
  };

  return (
    <Card>
      <CardContent>
        <Box>
          <Stack
            direction="row"
            display="flex"
            spacing={1}
            alignItems="center"
            justifyContent="space-between"
            mb={2}
          >
            <Typography variant="h6" component="h6">
              {t('Projects')}
            </Typography>
            {isRequestTask && !_.isEmpty(approvalList?.data) && (
              <Stack direction="row" spacing={1}>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={() => {
                    dispatch(
                      requestApprovalRequest({
                        month: month?.format('YYYY-MM'),
                      }),
                    );
                  }}
                >
                  {t('Request approve all')}
                </Button>
                <Button
                  variant="contained"
                  style={{ backgroundColor: '#dedede', color: '#000' }}
                  onClick={() => {
                    dispatch(
                      cancelRequestRequest({
                        month: month?.format('YYYY-MM'),
                      }),
                    );
                  }}
                >
                  {t('Cancel request all')}
                </Button>
              </Stack>
            )}
          </Stack>

          <WrapTable>
            <TableContainer component={Paper}>
              <Table stickyHeader>
                <TableHead>
                  <TableRow>
                    <TableCell>{t('No')}</TableCell>
                    <TableCell>{t('Project')}</TableCell>
                    <TableCell>{t('Role')}</TableCell>
                    <TableCell>{t('Billable')}</TableCell>
                    <TableCell>{t('Efforts')}</TableCell>
                    <TableCell>{t('Detail')}</TableCell>
                    <TableCell>{t('Status')}</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {approvalList?.data.map((task, key) => {
                    return (
                      <TableRow key={`approval_row_${key}`}>
                        <TableCell width={60}>
                          {approvalList?.page_size
                            ? approvalList?.page_size *
                                (approvalList?.current_page - 1) +
                              key +
                              1
                            : key + 1}
                        </TableCell>
                        <TableCell>
                          <ProjectLink
                            project_id={task?.project?.id}
                            project_name={task?.project?.name}
                          ></ProjectLink>
                        </TableCell>

                        <TableCell>{task?.role_name}</TableCell>

                        <TableCell>
                          <BillableTag billable={task?.type}></BillableTag>
                        </TableCell>
                        <TableCell>{task?.effort + 'h'}</TableCell>
                        <TableCell>
                          <Link
                            to={`${
                              Path.TIMESHEET_REPORT_REVIEW
                            }?month=${month?.format('YYYY-MM')}&project=${
                              task?.project?.id
                            }`}
                            style={{ textDecoration: 'none', color: '#0000ee' }}
                          >
                            {t('View Detail')}
                          </Link>
                        </TableCell>
                        <TableCell width={180}>
                          {task?.status && (
                            <TaskStatus
                              status={task?.status}
                              display_type={STATUS_DISPLAY_TYPE.ADMIN}
                            ></TaskStatus>
                          )}
                        </TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
                {_.isEmpty(approvalList?.data) && (
                  <TableBody>
                    <TableRow>
                      <TableCell colSpan={9}>{t('No data')}</TableCell>
                    </TableRow>
                  </TableBody>
                )}
              </Table>
            </TableContainer>
          </WrapTable>
          {!_.isEmpty(approvalList?.data) && (
            <Stack spacing={2} alignItems={'flex-end'}>
              <Pagination
                page={approvalList?.current_page || 1}
                color="primary"
                count={approvalList?.total_page || 0}
                variant="outlined"
                shape="rounded"
                onChange={handleChangePage}
              />
            </Stack>
          )}
        </Box>
      </CardContent>
    </Card>
  );
}

const WrapTable = styled(Box)`
  margin-bottom: 16px;
`;
