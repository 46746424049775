import { Collapse, Table, TableBody, TableCell, TableRow } from '@mui/material';
import RowCell from './RowCell';
import { IHierarchyData } from 'types/common';
import { isChildrenDepartment } from 'utils/helper';
import { IDepartmentsSchema } from '../slice/types';

interface IRowChildren {
  childs: any;
  parentId: number;
  cssPaddingLeft: number;
  hierarchyData: IHierarchyData[] | [];
  setHierarchyData: (data: IHierarchyData[] | []) => void;
  setDepartmentDataChange: (data: IDepartmentsSchema | undefined) => void;
  setIsOpenModal: (data: boolean) => void;
  setIsShowConfirm: (data: boolean) => void;
}

export default function RowChildren(props: IRowChildren) {
  const {
    childs,
    parentId,
    cssPaddingLeft,
    hierarchyData,
    setHierarchyData,
    setDepartmentDataChange,
    setIsOpenModal,
    setIsShowConfirm,
  } = props;
  const newCssPaddingLeft = cssPaddingLeft + 20;
  return (
    <>
      {childs?.map((child: any, ind: any) => {
        return (
          <>
            <TableRow key={`child_${parentId}_${ind}`}>
              <TableCell colSpan={4} sx={{ padding: 0, border: 'none' }}>
                <Collapse
                  in={isChildrenDepartment(hierarchyData, parentId)}
                  unmountOnExit
                >
                  <Table>
                    <TableBody>
                      <RowCell
                        childs={child}
                        isParent={false}
                        parentId={child.id}
                        cssPaddingLeft={newCssPaddingLeft}
                        hierarchyData={hierarchyData}
                        setHierarchyData={setHierarchyData}
                        setDepartmentDataChange={setDepartmentDataChange}
                        setIsOpenModal={setIsOpenModal}
                        setIsShowConfirm={setIsShowConfirm}
                      />
                    </TableBody>
                  </Table>
                </Collapse>
              </TableCell>
            </TableRow>

            {child?.children?.length > 0 && (
              <RowChildren
                childs={child?.children}
                parentId={child?.id}
                cssPaddingLeft={newCssPaddingLeft}
                hierarchyData={hierarchyData}
                setHierarchyData={setHierarchyData}
                setDepartmentDataChange={setDepartmentDataChange}
                setIsOpenModal={setIsOpenModal}
                setIsShowConfirm={setIsShowConfirm}
              />
            )}
          </>
        );
      })}
    </>
  );
}
