import React from 'react';

// UI
import {
  Stack,
  Box,
  Typography,
  TableContainer,
  Paper,
  Table,
} from '@mui/material';

import SkillTable from './SkillTable';
import LanguageTable from './LanguageTable';

// Redux & Sagas
import { useSelector } from 'react-redux';
import { selectMember } from 'app/pages/MemberPage/slice/selectors';

// Library
import { UseFormReturn } from 'react-hook-form';

interface ISkillsFormProps {
  memberForm: UseFormReturn;
}

export default function SkillsForm(props: ISkillsFormProps) {
  const { skill_metadata, language_metadata, skillManagement } =
    useSelector(selectMember);

  let startIndex = 0;

  React.useEffect(
    () => () => {
      props.memberForm.reset();
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    ['componentWillUnMount'],
  );

  return (
    <Stack spacing={2} minHeight={`calc(100vh - 220px)`}>
      <Box
        display="flex"
        flexDirection="row"
        justifyContent="space-between"
        alignItems="center"
      >
        <Typography variant="h6">Technical Skill Sheet</Typography>
      </Box>
      <TableContainer component={Paper} variant="outlined" square>
        <Table size="small">
          {skill_metadata?.map((it, idx) => {
            if (idx > 0) {
              // customize number row for display per skills
              // startIndex += SKILLS[idx - 1].rows * 4;
              startIndex += skill_metadata[idx - 1].skills.length;
            }
            return (
              <SkillTable
                idx={idx}
                key={`st_${idx}`}
                title={it.name}
                rows={2}
                memberForm={props.memberForm}
                dataKey="technical_skills"
                startIndex={startIndex}
                data={it.skills}
              />
            );
          })}
        </Table>
      </TableContainer>

      <Box
        display="flex"
        flexDirection="row"
        justifyContent="space-between"
        alignItems="center"
      >
        <Typography variant="h6">Project Management Skill Sheet</Typography>
      </Box>
      <TableContainer component={Paper} variant="outlined" square>
        <Table size="small">
          {skillManagement?.map((it, idx) => {
            if (idx > 0) {
              startIndex += skillManagement[idx - 1].skills.length;
            }
            return (
              <SkillTable
                idx={idx}
                key={`st_${idx}`}
                title={it.name}
                rows={2}
                memberForm={props.memberForm}
                dataKey="management_skills"
                startIndex={startIndex}
                data={it.skills}
              />
            );
          })}
        </Table>
      </TableContainer>

      <Box
        display="flex"
        flexDirection="row"
        justifyContent="space-between"
        alignItems="center"
      >
        <Typography variant="h6">Languages</Typography>
        <Typography variant="subtitle2">
          ◎：Proficiency ／ ◯：Business Level ／ △： Beginner
        </Typography>
      </Box>
      <TableContainer component={Paper} variant="outlined" square>
        <Table size="small">
          {language_metadata?.map((it, idx) => (
            <LanguageTable
              key={`lang_${idx}`}
              title={it.name}
              memberForm={props.memberForm}
              startIndex={idx}
              dataId={it.id}
            />
          ))}
        </Table>
      </TableContainer>
    </Stack>
  );
}
