import {
  Autocomplete,
  Box,
  Button,
  FormControl,
  MenuItem,
  Select,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import DescriptionIcon from '@mui/icons-material/Description';
import { COLOR_BUTTON, GENDER_TYPE } from 'constants/common';
import { useTranslation } from 'react-i18next';
import { useEffect, useState } from 'react';
import styled from 'styled-components';
import { ReactComponent as HandHoldingUSDollar } from 'assets/icons/candidates/hand_holding_us_dollar.svg';
import { ReactComponent as LanguagesProficiency } from 'assets/icons/candidates/languages_proficiency.svg';
import { ReactComponent as BigIcon } from 'assets/icons/candidates/big_icon.svg';
import { ReactComponent as BirthdayIcon } from 'assets/icons/candidates/birthday_icon.svg';
import { ReactComponent as Briefcase } from 'assets/icons/candidates/brief_case.svg';
import { ReactComponent as CloudIcon } from 'assets/icons/candidates/cloud_icon.svg';
import { ReactComponent as CoinsIcon } from 'assets/icons/candidates/coins_icon.svg';
import { ReactComponent as EducationIcon } from 'assets/icons/candidates/education_icon.svg';
import { ReactComponent as ExpectedPosition } from 'assets/icons/candidates/expected_position.svg';
import { ReactComponent as GenderIcon } from 'assets/icons/candidates/gender_icon.svg';
import { ReactComponent as UserIcon } from 'assets/icons/candidates/user_icon.svg';
import AssignmentTurnedInIcon from '@mui/icons-material/AssignmentTurnedIn';
import AttachFileIcon from '@mui/icons-material/AttachFile';
import { Controller, useForm } from 'react-hook-form';
import { HistoryRounded } from '@mui/icons-material';
import { DatePicker } from '@mui/lab';
import { useDispatch, useSelector } from 'react-redux';
import { selectMember } from 'app/pages/MemberPage/slice/selectors';
import { selectRecruitments } from 'app/pages/RecruitmentsPage/slice/selectors';
import { useRecruitmentSlice } from 'app/pages/RecruitmentsPage/slice';
import { useHistory } from 'react-router-dom';
import Path from 'config/clientPath';
import PDFObject from 'pdfobject';
import { Loader } from 'app/components/Loader';
import { checkIsRequesting } from 'utils/helper';
import moment from 'moment';
import { IFormDataTab1 } from 'app/pages/RecruitmentsPage/slice/type';
import UISettings from 'styles/setting';
import _ from 'lodash';

interface ITab1 {
  id: string;
}

export function Tab1(props: ITab1) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const history = useHistory();
  const { id } = props;
  const [isEdit, setIsEdit] = useState(false);
  const {
    control,
    watch,
    reset,
    handleSubmit,
    formState: { errors },
  } = useForm<IFormDataTab1>();

  const {
    technicalSkills,
    language_metadata,
    getTechnicalSkillStatus,
    getLanguageListStatus,
  } = useSelector(selectMember);

  const { infoCandidate, getInfoCandidateStatus, updateInfoCandidateStatus } =
    useSelector(selectRecruitments);
  const {
    actions: { updateInfoCandidateRequest },
  } = useRecruitmentSlice();

  useEffect(() => {
    if (infoCandidate) {
      const {
        current_salary,
        current_level,
        years_of_experience,
        expected_salary,
        applied_date,
        skills,
        date_of_birth,
        gender,
        expected_position,
        education,
        language_proficiency,
        experience_summary,
        cv_file,
      } = infoCandidate;

      reset({
        current_salary,
        current_level,
        years_of_experience,
        expected_salary,
        applied_date,
        skills,
        date_of_birth,
        gender,
        expected_position,
        education,
        language_proficiency,
        experience_summary,
      });
      if (!/^((?!chrome|android).)*safari/i.test(navigator.userAgent)) {
        const pdfContainer = document.getElementById(
          'pdf-container',
        ) as HTMLEmbedElement;
        const embedElement = pdfContainer?.querySelector(
          'embed',
        ) as HTMLEmbedElement;
        if (embedElement) {
          const embedSrc = embedElement.getAttribute('src');
          // check pdf not change , not get pdf
          if (embedSrc !== cv_file) {
            PDFObject.embed(cv_file, pdfContainer);
          }
        } else {
          PDFObject.embed(cv_file, pdfContainer);
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [infoCandidate]);

  const handleSubmitForm = async () => {
    const { applied_date, skills, date_of_birth, language_proficiency } =
      watch();

    let formData = {
      ...watch(),
      applied_date: applied_date
        ? moment(applied_date).format(UISettings.dateFormat)
        : null,
      skills: skills?.map(it => it.id).join(','),
      date_of_birth: date_of_birth
        ? moment(date_of_birth).format(UISettings.dateFormat)
        : null,
      language_proficiency: language_proficiency?.map(it => it.id).join(','),
    };
    dispatch(updateInfoCandidateRequest({ id: id, formData }));
    setIsEdit(false);
  };

  const isLoading = checkIsRequesting([
    updateInfoCandidateStatus,
    getInfoCandidateStatus,
    getTechnicalSkillStatus,
    getLanguageListStatus,
  ]);

  return (
    <>
      <Loader open={isLoading} />
      <Box
        sx={{
          background: 'white',
          mt: '0px !important',
          p: '14px 20px',
        }}
      >
        <FormControl
          component="form"
          onSubmit={handleSubmit(handleSubmitForm)}
          fullWidth
        >
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              borderBottom: '1px solid #BDBDBD',
            }}
          >
            <Typography
              variant="body1"
              component="p"
              sx={{
                fontWeight: '500',
                fontSize: '14px',
                color: '#333',
                mt: 1,
              }}
            >
              <DescriptionIcon sx={{ color: COLOR_BUTTON.DELETE_EDIT_ICON }} />
              {t('Summary')}
            </Typography>
            <Box
              sx={{
                marginBottom: '15px',
                display: 'flex',
                gap: '10px',
                cursor: 'pointer',
              }}
            >
              {!isEdit && (
                <Button
                  color={'inherit'}
                  variant="contained"
                  onClick={() => {
                    setIsEdit(true);
                  }}
                  type="button"
                >
                  {t('Edit')}
                </Button>
              )}
              {isEdit && (
                <Stack spacing={1} direction="row">
                  <Button
                    color={'inherit'}
                    variant="contained"
                    onClick={() => {
                      setIsEdit(false);
                      reset();
                    }}
                  >
                    {t('Cancel')}
                  </Button>
                  <Button color="primary" variant={'contained'} type="submit">
                    {t('Save')}
                  </Button>
                </Stack>
              )}
            </Box>
          </Box>

          <Box sx={{ padding: '18px 0 10px 0' }}>
            <BoxDisplayGrid>
              <TextDisplay>
                <HandHoldingUSDollar /> {t('Current Salary')}
              </TextDisplay>
              {isEdit ? (
                <Controller
                  control={control}
                  name="current_salary"
                  rules={{
                    maxLength: {
                      value: 255,
                      message: t('Text length does not exceed 255 words'),
                    },
                  }}
                  render={({ field }) => {
                    const { onChange, value } = field;
                    return (
                      <TextField
                        size="small"
                        variant="outlined"
                        sx={{ width: '60ch' }}
                        onChange={onChange}
                        value={value}
                        error={!!errors.current_salary}
                        helperText={errors.current_salary?.message}
                      />
                    );
                  }}
                />
              ) : (
                <>{infoCandidate?.current_salary}</>
              )}
            </BoxDisplayGrid>
            <BoxDisplayGrid>
              <TextDisplay>
                <UserIcon /> {t('Current Job Level')}
              </TextDisplay>
              {isEdit ? (
                <Controller
                  control={control}
                  name="current_level"
                  rules={{
                    maxLength: {
                      value: 255,
                      message: t('Text length does not exceed 255 words'),
                    },
                  }}
                  render={({ field }) => {
                    const { onChange, value } = field;
                    return (
                      <TextField
                        size="small"
                        variant="outlined"
                        sx={{ width: '60ch' }}
                        value={value}
                        onChange={onChange}
                        error={!!errors.current_level}
                        helperText={errors.current_level?.message}
                      />
                    );
                  }}
                />
              ) : (
                <>{infoCandidate?.current_level}</>
              )}
            </BoxDisplayGrid>
            <BoxDisplayGrid>
              <TextDisplay>
                <Briefcase />
                {t('Years of experience')}
              </TextDisplay>
              {isEdit ? (
                <>
                  <Controller
                    control={control}
                    name="years_of_experience"
                    rules={{
                      maxLength: {
                        value: 255,
                        message: t('Text length does not exceed 255 words'),
                      },
                    }}
                    render={({ field }) => {
                      const { onChange, value } = field;
                      return (
                        <TextField
                          size="small"
                          variant="outlined"
                          sx={{ width: '60ch' }}
                          value={value}
                          onChange={onChange}
                          error={!!errors.years_of_experience}
                          helperText={errors.years_of_experience?.message}
                        />
                      );
                    }}
                  />
                </>
              ) : (
                <>{infoCandidate?.years_of_experience}</>
              )}
            </BoxDisplayGrid>
            <BoxDisplayGrid>
              <TextDisplay>
                <CoinsIcon /> {t('Expected Salary')}
              </TextDisplay>
              {isEdit ? (
                <Controller
                  control={control}
                  name="expected_salary"
                  rules={{
                    maxLength: {
                      value: 255,
                      message: t('Text length does not exceed 255 words'),
                    },
                  }}
                  render={({ field }) => {
                    const { onChange, value } = field;
                    return (
                      <TextField
                        size="small"
                        variant="outlined"
                        sx={{ width: '60ch' }}
                        value={value}
                        onChange={onChange}
                        error={!!errors.expected_salary}
                        helperText={errors.expected_salary?.message}
                      />
                    );
                  }}
                />
              ) : (
                <>{infoCandidate?.expected_salary}</>
              )}
            </BoxDisplayGrid>
            <BoxDisplayGrid>
              <TextDisplay>
                <AssignmentTurnedInIcon
                  sx={{ color: '#939393', width: '10px' }}
                />
                {t('Applied Date')}
              </TextDisplay>
              {isEdit ? (
                <Controller
                  control={control}
                  name={`applied_date`}
                  render={({ field: { onChange, value } }) => (
                    <DatePicker
                      value={value}
                      onChange={onChange}
                      inputFormat={UISettings.dateFormat}
                      renderInput={params => (
                        <TextField
                          {...params}
                          fullWidth
                          hiddenLabel
                          size="small"
                          sx={{ width: '60ch' }}
                          error={!!errors.applied_date}
                          helperText={errors.applied_date?.message}
                          inputProps={{ ...params.inputProps, readOnly: true }}
                        />
                      )}
                    />
                  )}
                />
              ) : (
                <>{infoCandidate?.applied_date}</>
              )}
            </BoxDisplayGrid>
            <BoxDisplayGrid>
              <TextDisplay>
                <BigIcon />
                {t('Skills')}
              </TextDisplay>
              {isEdit ? (
                <Controller
                  name="skills"
                  control={control}
                  render={({ field }) => {
                    const { onChange, value } = field;
                    return (
                      <Autocomplete
                        multiple
                        id="checkboxes-tags-demo"
                        options={technicalSkills ?? []}
                        disableCloseOnSelect
                        limitTags={4}
                        getOptionLabel={option => option.name}
                        renderOption={(props, option) => (
                          <li {...props}>{option.name}</li>
                        )}
                        isOptionEqualToValue={(option: any, value: any) =>
                          option.id === value.id
                        }
                        value={value ?? []}
                        onChange={(e, newValue) => {
                          onChange(newValue);
                        }}
                        renderInput={params => (
                          <TextField
                            {...params}
                            size="small"
                            sx={{ width: '60ch' }}
                          />
                        )}
                      />
                    );
                  }}
                />
              ) : (
                <>
                  {infoCandidate?.skills
                    ? infoCandidate?.skills.map(item => item.name).join(', ')
                    : null}
                </>
              )}
            </BoxDisplayGrid>
          </Box>

          <Box sx={{ paddingTop: '18px', borderTop: '1px dashed #BDBDBD' }}>
            <BoxDisplayGrid>
              <TextDisplay>
                <BirthdayIcon /> {t('Birthday')}
              </TextDisplay>
              {isEdit ? (
                <Controller
                  control={control}
                  name={`date_of_birth`}
                  render={({ field: { onChange, value } }) => (
                    <DatePicker
                      value={value}
                      onChange={onChange}
                      inputFormat={UISettings.dateFormat}
                      maxDate={moment()}
                      renderInput={params => (
                        <TextField
                          {...params}
                          fullWidth
                          hiddenLabel
                          size="small"
                          sx={{ width: '60ch' }}
                          error={!!errors.date_of_birth}
                          helperText={errors.date_of_birth?.message}
                          inputProps={{ ...params.inputProps, readOnly: true }}
                        />
                      )}
                    />
                  )}
                />
              ) : (
                <>{infoCandidate?.date_of_birth}</>
              )}
            </BoxDisplayGrid>
            <BoxDisplayGrid>
              <TextDisplay>
                <GenderIcon /> {t('Gender')}
              </TextDisplay>
              {isEdit ? (
                <Controller
                  control={control}
                  name="gender"
                  render={({ field }) => {
                    const { onBlur, onChange, value } = field;
                    return (
                      <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        sx={{ width: '20ch', height: '36px' }}
                        onBlur={onBlur}
                        value={value}
                        onChange={onChange}
                      >
                        {_.map(GENDER_TYPE, (gender, idx) => (
                          <MenuItem value={idx} key={idx}>
                            {t(gender)}
                          </MenuItem>
                        ))}
                      </Select>
                    );
                  }}
                />
              ) : (
                <>{t(GENDER_TYPE[infoCandidate?.gender]) || ''}</>
              )}
            </BoxDisplayGrid>
            <BoxDisplayGrid>
              <TextDisplay>
                <ExpectedPosition />
                {t('Expected Position')}
              </TextDisplay>
              {isEdit ? (
                <Controller
                  control={control}
                  name="expected_position"
                  rules={{
                    maxLength: {
                      value: 255,
                      message: t('Text length does not exceed 255 words'),
                    },
                  }}
                  render={({ field }) => {
                    const { onChange, value } = field;
                    return (
                      <TextField
                        size="small"
                        variant="outlined"
                        sx={{ width: '60ch' }}
                        value={value}
                        onChange={onChange}
                        error={!!errors.expected_position}
                        helperText={errors.expected_position?.message}
                      />
                    );
                  }}
                />
              ) : (
                <>{infoCandidate?.expected_position}</>
              )}
            </BoxDisplayGrid>
            <BoxDisplayGrid>
              <TextDisplay>
                <EducationIcon />
                {t('Education')}
              </TextDisplay>
              {isEdit ? (
                <Controller
                  control={control}
                  name="education"
                  rules={{
                    maxLength: {
                      value: 255,
                      message: t('Text length does not exceed 255 words'),
                    },
                  }}
                  render={({ field }) => {
                    const { onChange, value } = field;
                    return (
                      <TextField
                        size="small"
                        variant="outlined"
                        sx={{ width: '60ch' }}
                        value={value}
                        onChange={onChange}
                        error={!!errors.education}
                        helperText={errors.education?.message}
                      />
                    );
                  }}
                />
              ) : (
                <>{infoCandidate?.education}</>
              )}
            </BoxDisplayGrid>

            <BoxDisplayGrid>
              <TextDisplay>
                <LanguagesProficiency />
                {t('Languages Proficiency')}
              </TextDisplay>
              {isEdit ? (
                <Controller
                  name="language_proficiency"
                  control={control}
                  render={({ field }) => {
                    const { onChange, value } = field;
                    return (
                      <Autocomplete
                        multiple
                        id="checkboxes-tags-demo"
                        options={language_metadata ?? []}
                        isOptionEqualToValue={(option: any, value: any) =>
                          option.id === value.id
                        }
                        disableCloseOnSelect
                        getOptionLabel={option => option.name}
                        renderOption={(props, option, getTagProps) => (
                          <li {...props}>{option.name}</li>
                        )}
                        value={value ?? []}
                        onChange={(e, newValue) => {
                          onChange(newValue);
                        }}
                        renderInput={params => (
                          <TextField
                            {...params}
                            size="small"
                            sx={{ width: '60ch' }}
                          />
                        )}
                      />
                    );
                  }}
                />
              ) : (
                <>
                  {infoCandidate?.language_proficiency
                    ? infoCandidate?.language_proficiency
                        .map(item => item.name)
                        .join(', ')
                    : null}
                </>
              )}
            </BoxDisplayGrid>

            <BoxDisplayGrid whiteSpace="pre-line">
              <TextDisplay>
                <HistoryRounded sx={{ width: '10px' }} />
                {t('Work history')}
              </TextDisplay>
              {isEdit ? (
                <Controller
                  control={control}
                  name="experience_summary"
                  rules={{
                    maxLength: {
                      value: 255,
                      message: t('Text length does not exceed 255 words'),
                    },
                  }}
                  render={({ field }) => {
                    const { onChange, value } = field;
                    return (
                      <TextField
                        rows={5}
                        margin="dense"
                        fullWidth
                        multiline
                        variant="outlined"
                        sx={{ width: '60ch' }}
                        value={value}
                        onChange={onChange}
                        error={!!errors.experience_summary}
                        helperText={errors.experience_summary?.message}
                      />
                    );
                  }}
                />
              ) : (
                <>{infoCandidate?.experience_summary}</>
              )}
            </BoxDisplayGrid>

            <BoxDisplayGrid
              sx={{ borderTop: '1px dashed #BDBDBD', marginTop: 2 }}
            >
              <TextDisplay>
                <LanguagesProficiency />
                {t('Applied Job')}
              </TextDisplay>
              <Typography
                color="primary"
                sx={{ cursor: 'pointer', marginTop: 1 }}
                onClick={() => {
                  history.push(`${Path.JOB_LIST}/${infoCandidate?.job?.id}`);
                }}
              >
                {infoCandidate?.job?.title}
              </Typography>
            </BoxDisplayGrid>
          </Box>
        </FormControl>
        <BoxDisplayGrid
          sx={{
            borderBottom: '1px solid #BDBDBD',
            marginTop: 2,
            paddingBottom: '5px',
          }}
        >
          <TextDisplay>
            <AttachFileIcon sx={{ color: COLOR_BUTTON.DELETE_EDIT_ICON }} />
            {t('Attached CV')}
            <div style={{ width: '17px' }}>
              <CloudIcon />
            </div>
          </TextDisplay>
        </BoxDisplayGrid>
      </Box>
      {infoCandidate?.cv_file && (
        <>
          {/^((?!chrome|android).)*safari/i.test(navigator.userAgent) ? (
            <div>
              {t(
                'This browser does not support inline PDFs. Please see on PDF web:',
              )}
              <a
                href={infoCandidate?.attached_cv}
                target="_blank"
                rel="noreferrer"
              >
                {t('View PDF')}
              </a>
            </div>
          ) : (
            <div
              id="pdf-container"
              style={{ height: '1123px', marginTop: '0' }}
            ></div>
          )}
        </>
      )}
    </>
  );
}

const BoxDisplayGrid = styled(Box)`
  display: flex;
  align-items: center;
  margin-bottom: 8px;
`;

const TextDisplay = styled.p`
  display: flex;
  align-items: center;
  gap: 6px;
  font-size: 12px;
  margin: 0px !important;
  svg {
    fill: #939393;
  }
  width: 24%;
`;
