import { selectGlobal } from 'app/pages/GlobalContainer/slice/selectors';
import path from 'config/clientPath';
import { ROUTES_INFO } from 'constants/common';
import { CACHED_URL } from 'constants/localStorage';
import React, { PropsWithChildren } from 'react';
import { useSelector } from 'react-redux';
import { Redirect, Route } from 'react-router-dom';
import { getDefaultRoute } from 'utils/helper';
import { isHavingToken } from 'utils/localStorage';
import { useRole } from 'utils/role-hook';

type IRouteProps = {
  component: React.ComponentType;
  location: {
    pathname: string;
  };
  path: string;
  exact?: boolean;
};

const ProtectedRoute = ({
  component: Component,
  location,
  ...rest
}: IRouteProps) => {
  const isLoggedIn = isHavingToken();
  /**
   *  Navigate to desired path after logging in
   */
  if (!isLoggedIn) {
    // const { location } = rest;
    localStorage.setItem(CACHED_URL, location.pathname);
  }

  const { hasRole, hasOneRole } = useRole();
  const cachedUrl = localStorage.getItem(CACHED_URL);

  const {
    userSessionData: { userRole },
  } = useSelector(selectGlobal);

  return (
    <Route
      {...rest}
      render={(props: PropsWithChildren<any>) => {
        /**
         *  For common navigation in app
         */
        if (isLoggedIn && !cachedUrl) {
          return <Component {...props} />;
        }
        /**
         *  For navigation to cached path RIGHT after logging in
         */
        if (isLoggedIn && cachedUrl) {
          const cachedRoute = ROUTES_INFO[cachedUrl];
          // remove the cached path (if it exists) after achieve it for navigating purpose
          localStorage.removeItem(CACHED_URL);
          // get info of route and check role if that route has info of role
          if (cachedRoute) {
            // if that route is no use has one role and user is not access to that route
            // then redirect to home page if user can not access to cached url
            if (
              !cachedRoute.useHasOneRole &&
              !hasRole(cachedRoute.role, cachedRoute.useRegex)
            ) {
              return <Redirect to={{ pathname: getDefaultRoute(userRole) }} />;
            }
            if (
              cachedRoute.useHasOneRole &&
              !hasOneRole(cachedRoute.roles, cachedRoute.useRegex)
            ) {
              return <Redirect to={{ pathname: getDefaultRoute(userRole) }} />;
            }
          }

          return <Redirect to={{ pathname: cachedUrl }} />;
        }
        /**
         *  If not logined yet
         */
        return (
          <Redirect
            to={{
              pathname: path.LOGIN,
            }}
          />
        );
      }}
    />
  );
};

ProtectedRoute.defaultProps = {
  location: {
    pathname: '/',
  },
};

export default ProtectedRoute;
