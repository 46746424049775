import React from 'react';

// UI
import { Box, CircularProgress } from '@mui/material';

// Library
import styled from 'styled-components/macro';

interface IPartialLoader {
  open: boolean;
}

export function PartialLoader(props: IPartialLoader) {
  return props.open ? (
    <WrapLoader>
      <CircularProgress color="inherit" />
    </WrapLoader>
  ) : (
    <></>
  );
}

const WrapLoader = styled(Box)`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  position: absolute;
  background: rgb(0 0 0 / 5%);
  z-index: 2;
`;
