import { useDispatch, useSelector } from 'react-redux';
import { useMemberSlice } from './slice';
import { selectMember } from './slice/selectors';
import { useForgotPasswordSlice } from '../ForgotPasswordPage/slice';
import { selectForgotPassword } from '../ForgotPasswordPage/slice/selectors';

const useMember = () => {
  const dispatch = useDispatch();
  const { forgotPasswordRequest, resetForgotPasswordStatus } =
    useForgotPasswordSlice().actions;
  const { forgotPasswordStatus } = useSelector(selectForgotPassword);
  const {
    actions: {
      getDepartmentRequest,
      getLevelsRequest,
      addMemberRequest,
      resetMemberStatus,
      updateMemberInfoRequest,
      getSkillListRequest,
      resetCreateStatus,
      getMemberInfoRequest,
      getMemberRoleListRequest,
      uploadMemberAvatarRequest,
    },
  } = useMemberSlice();

  const {
    getDepartmentStatus,
    departments,
    getProjectRoleListStatus,
    project_role_metadata,
    getLevelsStatus,
    levels,
    addMemberStatus,
    messAddMember,
    updateMemberInfoStatus,
    memberInfo,
    skillsList,
    member_role_list,
  } = useSelector(selectMember);

  const getDepartment = () => {
    dispatch(getDepartmentRequest({}));
  };
  const getRoles = () => {
    dispatch(getMemberRoleListRequest({}));
  };
  const getLevels = () => {
    dispatch(getLevelsRequest({}));
  };
  const addMember = formData => {
    dispatch(addMemberRequest(formData));
  };
  const resetStatus = () => {
    dispatch(resetMemberStatus());
  };
  const resetCreateStatusRequest = () => {
    dispatch(resetCreateStatus());
  };
  const updateMember = ({ id, formData }) => {
    dispatch(updateMemberInfoRequest({ objectId: id, formData: formData }));
  };
  const forgotPass = payload => {
    dispatch(forgotPasswordRequest({ email: payload }));
  };
  const resetForgotPass = () => {
    dispatch(resetForgotPasswordStatus());
  };
  const getSkill = () => {
    dispatch(getSkillListRequest({}));
  };
  const getMemberInfo = id => {
    dispatch(getMemberInfoRequest(id));
  };
  const uploadMemberAvatar = (id, avatar) => {
    dispatch(uploadMemberAvatarRequest({ id, avatar }));
  };

  return {
    getDepartment,
    getDepartmentStatus,
    departments,
    getRoles,
    getProjectRoleListStatus,
    project_role_metadata,
    getLevels,
    getLevelsStatus,
    levels,
    addMember,
    addMemberStatus,
    messAddMember,
    resetStatus,
    updateMember,
    updateMemberInfoStatus,
    memberInfo,
    forgotPass,
    forgotPasswordStatus,
    resetForgotPass,
    skillsList,
    getSkill,
    resetCreateStatusRequest,
    getMemberInfo,
    member_role_list,
    uploadMemberAvatar,
  };
};

export default useMember;
