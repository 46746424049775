// UI
import React from 'react';

import {
  Stack,
  Box,
  Paper,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Button,
} from '@mui/material';
import AssignmentRow from './AssignmentRow';

// Redux & Sagas
// Library
import _ from 'lodash';
import { IProject } from 'app/pages/ProjectPage/slice/types';
interface IAssigmentProps {
  showProject: boolean;
  showMember: boolean;
  showHeader: boolean;
  projectInfo: IProject | null;
}

export default function Assigment(props: IAssigmentProps) {
  const [projectAssignment, setProjectAssignment] = React.useState([]);

  React.useEffect(() => {
    setProjectAssignment(_.get(props.projectInfo, 'members'));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.projectInfo]);

  return (
    <Stack spacing={2}>
      <Box
        display="flex"
        flexDirection="row"
        justifyContent="space-between"
      ></Box>
      <Paper>
        <TableContainer>
          <Table>
            {props.showHeader && (
              <TableHead>
                <TableRow>
                  <TableCell sx={{ minWidth: 60 }}>No.</TableCell>
                  {props.showProject && (
                    <TableCell sx={{ minWidth: 120 }}>Project</TableCell>
                  )}
                  {props.showMember && (
                    <TableCell sx={{ minWidth: 120 }}>Member</TableCell>
                  )}
                  <TableCell sx={{ minWidth: 90 }} width={100}>
                    Type
                  </TableCell>
                  <TableCell sx={{ minWidth: 90 }} width={100}>
                    Role
                  </TableCell>
                  <TableCell sx={{ minWidth: 70 }} width={100}>
                    Effort/Month
                  </TableCell>
                  <TableCell sx={{ minWidth: 120 }} width={80}>
                    Start
                  </TableCell>
                  <TableCell sx={{ minWidth: 120 }} width={80}>
                    End
                  </TableCell>
                  <TableCell sx={{ minWidth: 120 }} width={80}>
                    Action
                  </TableCell>
                </TableRow>
              </TableHead>
            )}

            <TableBody>
              {_.size(projectAssignment) === 0 && (
                <TableRow>
                  <TableCell colSpan={8}>No data</TableCell>
                </TableRow>
              )}
              {projectAssignment &&
                projectAssignment.map((assignment, prjIndex) => {
                  return (
                    <AssignmentRow
                      key={`assignment_row_${prjIndex}`}
                      prjIndex={prjIndex}
                      assignment={assignment}
                      prjID={props.projectInfo?.id}
                    />
                  );
                })}
            </TableBody>
          </Table>
        </TableContainer>
        <Box
          display="flex"
          flexDirection="row"
          justifyContent="space-between"
          p={2}
        >
          <Button
            variant="outlined"
            onClick={() => {
              setProjectAssignment(_.concat(projectAssignment, {}));
            }}
          >
            Add new assignment
          </Button>
        </Box>
      </Paper>
    </Stack>
  );
}
