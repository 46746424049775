import React from 'react';

// UI
import {
  Grid,
  TextField,
  FormControl,
  RadioGroup,
  FormControlLabel,
  Radio,
  FormHelperText,
  OutlinedInput,
  InputAdornment,
  Button,
  FormLabel,
  Checkbox,
  Stack,
  Autocomplete,
  Box,
} from '@mui/material';

import DatePicker from '@mui/lab/DatePicker';
import LoadingButton from '@mui/lab/LoadingButton';

import InputAvatar from 'app/components/InputImage';
import SaveIcon from '@mui/icons-material/Save';

// Redux & Sagas
import { useDispatch, useSelector } from 'react-redux';
import { Controller } from 'react-hook-form';
import { useProjectsSlice } from 'app/pages/ProjectPage/slice';
import { RequestStatus } from 'constants/API';
import { convertDate } from 'utils/moment';
import { selectCustomer } from 'app/pages/CustomerPage/slice/selectors';
import { useCustomerSlice } from 'app/pages/CustomerPage/slice';

import { IProject } from 'app/pages/ProjectPage/slice/types';
import { selectProject } from 'app/pages/ProjectPage/slice/selectors';

// Library
import _ from 'lodash';
import { useForm } from 'react-hook-form';
import { PROJECT_SCOPE, PROJECT_TYPE } from 'constants/ProjectMetadata';
import { ICommonDataSchema } from 'types/common';

export default function BasicInfo() {
  const dispatch = useDispatch();
  const { updateProjectRequest } = useProjectsSlice().actions;
  const projectForm = useForm();
  const {
    register,
    handleSubmit,
    reset,
    control,
    setValue,
    getValues,
    watch,
    formState: { errors },
  } = projectForm;

  const { updateProjectStatus, projectInfo, getProjectStatus } =
    useSelector(selectProject);
  const defaultCustomer = { id: -1, name: '' };
  const [selectedCustomer, setSelectedCustomer] =
    React.useState<ICommonDataSchema | null>(defaultCustomer);
  const commonMapping = (arr: any): ICommonDataSchema[] => {
    return arr.map(it => _.pick(it, ['id', 'name']));
  };
  const {
    actions: { getCustomerListRequest },
  } = useCustomerSlice();
  const { listCustomer } = useSelector(selectCustomer);

  React.useEffect(() => {
    dispatch(getCustomerListRequest({}));
    if (typeof getValues('scope') === 'object') {
      setValue(
        'scope',
        Object.keys(getValues('scope')).map(key => getValues('scope')[key]),
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  React.useEffect(() => {
    if (getProjectStatus === RequestStatus.SUCCESS) {
      projectForm.reset(_.cloneDeep(projectInfo));
      setSelectedCustomer(_.cloneDeep(projectInfo).customer);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getProjectStatus]);

  const handleResetData = () => {
    reset();
  };
  const handleSaveData = formData => {
    formData.start_date = convertDate(formData.start_date);
    formData.end_date = convertDate(formData.end_date);
    formData.scope = formData.scope.filter(o => o);
    formData.customer = {
      id: _.toInteger(formData?.customer?.id),
    };
    dispatch(updateProjectRequest(formData));
  };

  const handleSelect = (e: any) => {
    const { value, checked } = e.target;
    if (checked) {
      setValue(
        'scope',
        _.uniq([...getValues('scope'), _.toInteger(value)].filter(o => o)),
      );
    } else {
      const remaining = _.uniq(
        getValues('scope').filter(
          (item: number) => item !== _.toInteger(value),
        ),
      );
      setValue('scope', remaining);
    }
  };

  return (
    <form onSubmit={handleSubmit(handleSaveData)}>
      <Grid container spacing={4}>
        <Grid item xs={4} display="flex" justifyContent="flex-end">
          <InputAvatar form={projectForm} inputName="image_url" />
        </Grid>
        <Grid item xs={7}>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <FormLabel>Code</FormLabel>
              <TextField
                variant="outlined"
                fullWidth
                hiddenLabel
                size="small"
                margin="dense"
                error={errors.code ? true : false}
                {...register<keyof IProject>('code', {
                  required: 'This is required.',
                  maxLength: 60,
                })}
                helperText={errors.code?.message}
              />
            </Grid>

            <Grid item xs={6}>
              <FormLabel>Project type</FormLabel>
              <FormControl fullWidth margin="dense">
                <Controller
                  control={control}
                  name="project_type"
                  render={({ field }) => {
                    const { onBlur, onChange, value } = field;
                    return (
                      <RadioGroup
                        row
                        value={`${value}`}
                        onBlur={onBlur}
                        onChange={onChange}
                      >
                        {PROJECT_TYPE.map(it => {
                          return (
                            <FormControlLabel
                              key={it.name}
                              value={`${it.id}`}
                              control={<Radio />}
                              label={it.name}
                            />
                          );
                        })}
                      </RadioGroup>
                    );
                  }}
                />
              </FormControl>
            </Grid>

            <Grid item xs={6}>
              <FormLabel>Project Name</FormLabel>
              <TextField
                variant="outlined"
                fullWidth
                hiddenLabel
                size="small"
                margin="dense"
                error={errors.name ? true : false}
                {...register<keyof IProject>('name', {
                  required: 'This is required.',
                  maxLength: 60,
                })}
                helperText={errors.name?.message}
              />
            </Grid>

            <Grid item xs={6}>
              <FormLabel>Customer</FormLabel>
              <FormControl fullWidth margin="dense">
                <Controller
                  control={control}
                  name="customer.id"
                  rules={{ required: 'This is required.' }}
                  render={({ field }) => {
                    const { onChange } = field;
                    return (
                      <Autocomplete
                        value={selectedCustomer}
                        isOptionEqualToValue={(option, value) => {
                          return value?.id && value?.id === option?.id
                            ? true
                            : false;
                        }}
                        renderOption={(props, option) => (
                          <Box
                            component="li"
                            {...props}
                            key={`customer_${option.id}`}
                          >
                            {option?.name}
                          </Box>
                        )}
                        options={[
                          { ...defaultCustomer },
                          ...commonMapping(listCustomer?.data ?? []),
                        ]}
                        getOptionLabel={it => it?.name || ''}
                        onChange={(e, newValue) => {
                          setSelectedCustomer(newValue);
                          onChange(newValue?.id);
                        }}
                        renderInput={params => {
                          return (
                            <TextField
                              {...params}
                              size="small"
                              variant="outlined"
                            />
                          );
                        }}
                      />
                    );
                  }}
                />
                <FormHelperText error>
                  {errors.customer?.id.message}
                </FormHelperText>
              </FormControl>
            </Grid>

            <Grid item xs={12}>
              <FormLabel>Scope</FormLabel>
              <FormControl fullWidth margin="dense">
                <Stack direction="row">
                  {watch('scope') &&
                    PROJECT_SCOPE.map((it, idx) => {
                      return (
                        <FormControlLabel
                          control={
                            <Controller
                              name={`scope.${idx}`}
                              render={({ field }) => {
                                return (
                                  <Checkbox
                                    {...field}
                                    value={it.id}
                                    checked={getValues('scope').includes(it.id)}
                                    onChange={handleSelect}
                                  />
                                );
                              }}
                              control={control}
                            />
                          }
                          label={it.name}
                          key={it.id}
                        />
                      );
                    })}
                </Stack>
              </FormControl>
            </Grid>

            <Grid item xs={6}>
              <FormLabel>Start Date</FormLabel>
              <FormControl fullWidth margin="dense">
                <Controller
                  control={control}
                  name="start_date"
                  defaultValue={null}
                  render={({ field: { onChange, value } }) => (
                    <DatePicker
                      value={value}
                      onChange={onChange}
                      inputFormat="DD-MM-YYYY"
                      renderInput={params => (
                        <TextField
                          {...params}
                          fullWidth
                          hiddenLabel
                          size="small"
                        />
                      )}
                    />
                  )}
                />
              </FormControl>
            </Grid>

            <Grid item xs={6}>
              <FormLabel>Due Date</FormLabel>
              <FormControl fullWidth margin="dense">
                <Controller
                  control={control}
                  name="end_date"
                  defaultValue={null}
                  render={({ field: { onChange, value } }) => (
                    <DatePicker
                      value={value}
                      onChange={onChange}
                      inputFormat="DD-MM-YYYY"
                      renderInput={params => (
                        <TextField
                          {...params}
                          fullWidth
                          hiddenLabel
                          size="small"
                        />
                      )}
                    />
                  )}
                />
              </FormControl>
            </Grid>

            <Grid item xs={6}>
              <FormLabel>Size</FormLabel>
              <OutlinedInput
                size="small"
                margin="dense"
                placeholder="Enter size"
                fullWidth
                endAdornment={
                  <InputAdornment position="end">man-month</InputAdornment>
                }
                error={errors.size ? true : false}
                {...register<keyof IProject>('size', {
                  required: 'This is required.',
                  maxLength: 10,
                })}
              />
            </Grid>

            <Grid item xs={6}>
              <FormLabel>Status</FormLabel>
              <FormControl fullWidth margin="dense">
                <Controller
                  control={control}
                  name="status"
                  render={({ field }) => {
                    const { onBlur, onChange, value } = field;
                    return (
                      <RadioGroup
                        row
                        value={`${value}`}
                        onBlur={onBlur}
                        onChange={onChange}
                      >
                        <FormControlLabel
                          value={`1`}
                          control={<Radio />}
                          label="Active"
                        />
                        <FormControlLabel
                          value={`2`}
                          control={<Radio />}
                          label="Deactive"
                        />
                      </RadioGroup>
                    );
                  }}
                />
              </FormControl>
            </Grid>

            <Grid item xs={12}>
              <FormLabel>Slack Hook URL</FormLabel>
              <TextField
                variant="outlined"
                fullWidth
                hiddenLabel
                size="small"
                margin="dense"
                error={errors.report_slack_hook_url ? true : false}
                {...register<keyof IProject>('report_slack_hook_url', {
                  maxLength: 2000,
                })}
                helperText={errors.report_slack_hook_url?.message}
              />
            </Grid>

            <Grid item xs={12}>
              <FormLabel>Description</FormLabel>
              <TextField
                variant="outlined"
                fullWidth
                hiddenLabel
                size="small"
                margin="dense"
                multiline
                rows={5}
                error={errors.description ? true : false}
                {...register<keyof IProject>('description', {
                  maxLength: 2000,
                })}
                helperText={errors.description?.message}
              />
            </Grid>

            <Grid item xs={12} display="flex" justifyContent="flex-end">
              <Button color="error" onClick={handleResetData}>
                Reset data
              </Button>
              <LoadingButton
                type="submit"
                variant="contained"
                loadingPosition="start"
                startIcon={<SaveIcon />}
                loading={updateProjectStatus === RequestStatus.REQUESTING}
              >
                Save change
              </LoadingButton>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </form>
  );
}
