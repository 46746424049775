import React, { useEffect, useState } from 'react';
// UI
import { Helmet } from 'react-helmet-async';

import {
  Box,
  Stack,
  Typography,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  TableContainer,
  Paper,
  Toolbar,
  Button,
} from '@mui/material';

import { Wrapper, Content } from 'app/components/Wrapper';
import { Container } from 'app/components/Container';
import { ProjectLink } from 'app/components/ProjectLink';
// Redux & Sagas
import { useDispatch, useSelector } from 'react-redux';
import { useGlobalSlice } from 'app/pages/GlobalContainer/slice';
import Path from 'config/clientPath';
import { useMemberSlice } from '../MemberPage/slice';
import { selectMember } from '../MemberPage/slice/selectors';
import { useLocation } from 'react-router';
import { Loader } from 'app/components/Loader';
import { checkIsRequesting } from 'utils/helper';
import moment from 'moment';
import { selectTimesheet } from '../TimesheetPage/slice/selectors';
import { useTimesheetSlice } from '../TimesheetPage/slice';
import queryString from 'query-string';
import _ from 'lodash';
import FilterBar from './components/FilterBar';
import { TagComponent } from 'app/components/TagComponent';
import {
  APPROVED_TASK_STATUS,
  FEEDBACK_TASK_STATUS,
  REQUESTING_TASK_STATUS,
  TASK_STATUS_LIST,
} from 'constants/common';
import { RequestStatus } from 'constants/API';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

export function ApprovalReview() {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const location = useLocation();
  const { setBreadcumbs, setSuccessMessages, setErrorMessages } =
    useGlobalSlice().actions;
  const [currentTaskStatus, setCurrentTaskStatus] = useState(0);
  const [month, setMonth] = useState<moment.Moment | null>(null);

  const {
    timesheetList,
    getTimesheetStatus,
    approveReviewRequestStatus,
    getAllProjectsStatus,
  } = useSelector(selectTimesheet);
  const {
    actions: { getAllProjectsRequest },
  } = useTimesheetSlice();
  const { memberInfo, getMemberInfoStatus } = useSelector(selectMember);

  const {
    actions: {
      getTimesheetRequest,
      approveReviewRequestRequest,
      resetTimesheetApprovalReviewStatus,
    },
  } = useTimesheetSlice();

  const {
    actions: { getMemberInfoRequest, resetApprovalReviewMemberStatus },
  } = useMemberSlice();

  const reloadData = () => {
    const params = queryString.parse(window.location.search);
    const newCurrentDate = params?.month ? moment(params?.month) : moment();
    setMonth(newCurrentDate);
    const project = params?.project || '';
    const member = params?.member || '';
    const search = params?.search?.trim() || '';
    dispatch(
      getTimesheetRequest({
        date_start: newCurrentDate
          .clone()
          .startOf('month')
          .format('yyyy-MM-DD'),
        date_end: newCurrentDate.clone().endOf('month').format('yyyy-MM-DD'),
        member,
        project,
        search,
        paging: false,
      }),
    );
  };

  useEffect(() => {
    const successMess =
      currentTaskStatus === APPROVED_TASK_STATUS
        ? 'Approve task success'
        : 'Reject task success';
    const errorMess =
      currentTaskStatus === APPROVED_TASK_STATUS
        ? 'Approve task errors'
        : 'Reject task errors';
    if (approveReviewRequestStatus === RequestStatus.SUCCESS) {
      dispatch(setSuccessMessages([successMess]));
      reloadData();
    }
    if (approveReviewRequestStatus === RequestStatus.ERROR) {
      dispatch(setErrorMessages([errorMess]));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [approveReviewRequestStatus]);

  const approveRequest = status => {
    setCurrentTaskStatus(status);
    const monthParams = month || moment();
    let params = queryString.parse(window.location.search);
    dispatch(
      approveReviewRequestRequest({
        month: monthParams.clone().format('yyyy-MM'),
        status: status,
        member: params?.member || '',
        project: params?.project || '',
      }),
    );
  };

  useEffect(
    () => () => {
      dispatch(resetTimesheetApprovalReviewStatus());
      dispatch(resetApprovalReviewMemberStatus());
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    ['componentWillUnMount'],
  );

  const isLoading = checkIsRequesting([
    getTimesheetStatus,
    getAllProjectsStatus,
    approveReviewRequestStatus,
    getMemberInfoStatus,
  ]);

  useEffect(() => {
    reloadData();
    const params = queryString.parse(window.location.search);
    const monthString = params?.month;
    dispatch(
      getAllProjectsRequest({
        month: monthString ? monthString : moment()?.format('YYYY-MM'),
      }),
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.search]);

  useEffect(() => {
    dispatch(
      setBreadcumbs([
        { title: 'Approval', path: Path.DAILY_REPORT_APPROVAL },
        { title: 'Approval Detail' },
      ]),
    );
    let params = queryString.parse(window.location.search);
    dispatch(getMemberInfoRequest(params?.member));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const currentStatus = _.head(timesheetList)?.status;
  return (
    <>
      <Helmet>
        <title>{t('Task Logs Review')}</title>
        <meta name="description" content="Palette" />
      </Helmet>
      <Container>
        <Wrapper>
          <Loader open={isLoading} />
          <Content spacing={2}>
            <Box
              display="flex"
              flexDirection="row"
              justifyContent="space-between"
            >
              <Typography variant="h6" component="h6">
                {t('Task Logs Review')}
                {memberInfo?.full_name ? ` / ${memberInfo?.full_name}` : ''}
              </Typography>
            </Box>

            <FilterBar />
            <Box>
              {!_.isEmpty(timesheetList) ? (
                <Toolbar
                  sx={{
                    pl: { sm: 2 },
                    pr: { xs: 1, sm: 1 },
                    ...{
                      bgcolor: '#BDD8FF',
                      borderRadius: '8px 8px 0 0',
                    },
                  }}
                >
                  <Stack
                    direction="row"
                    display="flex"
                    spacing={1}
                    alignItems="center"
                    justifyContent="space-between"
                    style={{ width: '100%' }}
                  >
                    <Box>
                      {t('Total')}:{' '}
                      <Box component="span" style={{ fontWeight: 600 }}>
                        {` ${_.sumBy(timesheetList, v => +v.hours)}h (${
                          TASK_STATUS_LIST[currentStatus]?.admin?.label || ''
                        })`}
                      </Box>
                    </Box>
                    <Stack direction="row" spacing={1}>
                      {currentStatus === REQUESTING_TASK_STATUS && (
                        <Button
                          variant="contained"
                          style={{ backgroundColor: '#01A887' }}
                          onClick={() => {
                            approveRequest(APPROVED_TASK_STATUS);
                          }}
                        >
                          {'Approve'}
                        </Button>
                      )}
                      {(currentStatus === REQUESTING_TASK_STATUS ||
                        currentStatus === APPROVED_TASK_STATUS) && (
                        <Button
                          variant="contained"
                          style={{ backgroundColor: '#dedede', color: '#000' }}
                          onClick={() => {
                            approveRequest(FEEDBACK_TASK_STATUS);
                          }}
                        >
                          {'Reject'}
                        </Button>
                      )}
                    </Stack>
                  </Stack>
                </Toolbar>
              ) : (
                ''
              )}
              <WrapTable>
                <TableContainer component={Paper}>
                  <Table stickyHeader>
                    <TableHead>
                      <TableRow>
                        <TableCell sx={{ minWidth: 60 }}>{t('No')}</TableCell>
                        <TableCell sx={{ minWidth: 110 }}>
                          {t('Project')}
                        </TableCell>
                        <TableCell sx={{ minWidth: 140 }}>
                          {t('Date')}
                        </TableCell>
                        <TableCell sx={{ minWidth: 80 }}>
                          {t('Duration')}
                        </TableCell>
                        <TableCell>Tag</TableCell>
                        <TableCell sx={{ minWidth: 110 }}>
                          {t('Description')}
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {timesheetList?.map((task, key) => {
                        return (
                          <TableRow key={`approval_row_${key}`}>
                            <TableCell width={60}>{key + 1}</TableCell>
                            <TableCell>
                              <ProjectLink
                                project_id={task?.project?.id}
                                project_name={task?.project?.name}
                              ></ProjectLink>
                            </TableCell>

                            <TableCell>
                              {moment(task?.report_date).format(
                                'YYYY-MM-DD ddd',
                              )}
                            </TableCell>

                            <TableCell>{task?.hours + 'h'}</TableCell>
                            <TableCell>
                              <Stack spacing={1}>
                                {task?.tags.map((v, k) => (
                                  <TagComponent data={v} key={k} />
                                ))}
                              </Stack>
                            </TableCell>
                            <TableCell width={180}>
                              {task?.description}
                            </TableCell>
                          </TableRow>
                        );
                      })}
                    </TableBody>
                    {_.isEmpty(timesheetList) && (
                      <TableBody>
                        <TableRow>
                          <TableCell colSpan={9}>{t('No data')}</TableCell>
                        </TableRow>
                      </TableBody>
                    )}
                  </Table>
                </TableContainer>
              </WrapTable>
            </Box>
          </Content>
        </Wrapper>
      </Container>
    </>
  );
}

const WrapTable = styled(Box)`
  & .MuiTableContainer-root {
    max-height: calc(100vh - 285px);
  }
`;
