import { Autocomplete, FormControl, TextField } from '@mui/material';
import { Controller, UseFormReturn } from 'react-hook-form';
import _ from 'lodash';
import { useEffect, useRef } from 'react';

interface IProps {
  useForm: UseFormReturn;
  options: any;
  multiple?: boolean;
  limitTags?: number;
  nameField: string;
  disabled?: boolean;
  isFieldDiff?: string;
  callApi?: () => void;
}
export const AutoCompleteField = (props: IProps) => {
  const {
    useForm,
    options,
    multiple,
    limitTags,
    nameField,
    disabled,
    isFieldDiff,
    callApi,
  } = props;
  const {
    control,
    formState: { errors },
  } = useForm;
  const selectionMade = useRef(false);

  return (
    <FormControl margin="dense">
      <Controller
        name={nameField}
        control={control}
        defaultValue={multiple ? [] : null}
        render={({ field }) => (
          <Autocomplete
            key={!multiple && field?.value}
            multiple={multiple}
            options={
              multiple
                ? _.differenceBy(options, field.value, isFieldDiff)
                : options
            }
            size="small"
            filterSelectedOptions
            limitTags={limitTags ? limitTags : 2}
            getOptionLabel={option => option?.name}
            renderOption={(props, option) => <li {...props}>{option?.name}</li>}
            value={field.value}
            onChange={(e, newValue) => {
              selectionMade.current = true;
              field.onChange(newValue);
              if (multiple && callApi && newValue.length < field.value.length) {
                callApi();
              }
            }}
            // onBlur={e => {
            //   if (callApi) {
            //     callApi();
            //   }
            // }}
            onClose={() => {
              if (selectionMade.current) {
                selectionMade.current = false;
                if (callApi) {
                  callApi();
                }
              } else {
                return;
              }
            }}
            disabled={disabled}
            renderInput={params => (
              <TextField
                {...params}
                error={!!errors[nameField]}
                helperText={errors[nameField]?.message}
              />
            )}
          />
        )}
      />
    </FormControl>
  );
};
