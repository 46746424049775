import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
  Typography,
} from '@mui/material';
import { useTranslation } from 'react-i18next';

const ConfirmDelete = ({ open, onClose, onConfirm, name, title }) => {
  const { t } = useTranslation();

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle sx={{ textTransform: 'capitalize' }}>
        {t('Delete {{title}} Info', { title })}
      </DialogTitle>
      <DialogContent>
        <DialogContentText>
          {t('Do you want to delete {{title}} info below?', { title })}
        </DialogContentText>
        {name && (
          <Typography variant="subtitle1" component="div">
            {t('Name')}: {name}
          </Typography>
        )}
      </DialogContent>
      <DialogActions sx={{ padding: 3 }}>
        <Button onClick={onClose} color="inherit" variant="contained">
          {t('Cancel')}
        </Button>

        <Button onClick={onConfirm} color="error" variant="contained">
          {t('Delete')}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ConfirmDelete;
