import React, { useEffect, useState } from 'react';

// UI
import { Box, Stack, Typography } from '@mui/material';

// Library
import styled from 'styled-components/macro';
import moment from 'moment';
import _ from 'lodash';
import { HourTag } from 'app/components/HourTag';
import { useSelector } from 'react-redux';
import { RequestStatus } from 'constants/API';
import { selectMember } from 'app/pages/MemberPage/slice/selectors';
import { TasksModal } from './TasksModal';
import { useTranslation } from 'react-i18next';
import { ITimesheet } from '../slice/types';
import { parseNumber } from 'utils/helper';

interface ICalendarViewMode {
  activeDate: string;
  setIsShowDeleteTaskDialog: (value: boolean) => void;
  setIsShowModalTaskLog: (value: boolean) => void;
  setSeletedTaskLogData: (value: ITimesheet | undefined) => void;
  setCurrentDateCalendar: (value: string | null) => void;
}

export function CalendarViewMode(props: ICalendarViewMode) {
  const {
    activeDate,
    setSeletedTaskLogData,
    setIsShowModalTaskLog,
    setIsShowDeleteTaskDialog,
    setCurrentDateCalendar,
  } = props;
  const { t } = useTranslation();
  const [taskLogByDay, setTaskLogByDay] = useState<any>();
  const [allDates, setAllDates] = useState<any>([]);
  const [selectViewMoreData, setSelectViewMoreData] = useState<any>([]);
  const [showAllTaskModal, setShowAllTaskModal] = useState(false);
  const [dateString, setDateString] = useState<string>('');
  const countDatesCurrentMonth = moment(activeDate).daysInMonth();
  const monthStartDate = moment(activeDate).startOf('month');
  const weekDayOfStartMonth = monthStartDate.day();
  const prevMonthAppearStartDate = monthStartDate
    .clone()
    .subtract(weekDayOfStartMonth, 'days');
  const monthEndDate = moment(activeDate).endOf('month');
  const weekDayOfEndMonth = monthEndDate.day();

  const { memberTasks, getMemberWorkReportStatus } = useSelector(selectMember);

  const getDayOfMonth = tempDate => {
    return +tempDate.format('DD') === 1
      ? tempDate.format('MMM D')
      : +tempDate.format('DD');
  };
  const getAllDates = () => {
    const prevMonthDates = _.times(
      weekDayOfStartMonth,
      distanceFromStartMonth => {
        const tempDate = prevMonthAppearStartDate
          .clone()
          .add(distanceFromStartMonth, 'days');
        return {
          dateString: tempDate.format('YYYY-MM-DD'),
          dayOfMonth: getDayOfMonth(tempDate),
          isCurrentMonth: false,
          isPreviousMonth: true,
          reportDateString: tempDate.format('MM/DD'),
        };
      },
    );

    // day of Sunday: 0, Saturday: 6
    const nextMonthDates = _.times(
      6 - weekDayOfEndMonth,
      distanceFromStartMonth => {
        const tempDate = monthEndDate
          .clone()
          .add(1 + distanceFromStartMonth, 'days');
        return {
          dateString: tempDate.format('YYYY-MM-DD'),
          dayOfMonth: getDayOfMonth(tempDate),
          isCurrentMonth: false,
          isNextMonth: true,
          reportDateString: tempDate.format('MM/DD'),
        };
      },
    );

    const currentMonthDates = _.times(
      countDatesCurrentMonth,
      distanceFromStartMonth => {
        const tempDate = monthStartDate
          .clone()
          .add(distanceFromStartMonth, 'days');
        return {
          dateString: tempDate.format('YYYY-MM-DD'),
          dayOfMonth: getDayOfMonth(tempDate),
          isCurrentMonth: true,
          reportDateString: tempDate.format('MM/DD'),
        };
      },
    );

    const _allDates = [
      ...prevMonthDates,
      ...currentMonthDates,
      ...nextMonthDates,
    ];
    return _allDates;
  };

  const getDayClassName = day => {
    let className = '';
    className += day.isCurrentMonth ? '' : ' cl_outside';
    className =
      className +
      (day.dateString === moment().format('YYYY-MM-DD')
        ? ' cl_current_date'
        : '');
    return className;
  };

  useEffect(() => {
    if (getMemberWorkReportStatus === RequestStatus.SUCCESS) {
      const newAllDates = getAllDates();
      setAllDates(newAllDates);
      const groupedByDate = memberTasks?.reduce((acc, current) => {
        const { date } = current;
        if (!acc[date]) {
          acc[date] = [];
        }
        acc[date].push(current);
        return acc;
      }, {});
      if (groupedByDate) {
        setTaskLogByDay(groupedByDate);
        if (dateString) {
          if (dateString.includes('_')) {
            const [dateSelect, taskIdx, taskId] = dateString.split('_');
            if (
              groupedByDate[dateSelect] &&
              groupedByDate[dateSelect][taskIdx] &&
              groupedByDate[dateSelect][taskIdx]?.id === Number(taskId)
            ) {
              setSelectViewMoreData([groupedByDate[dateSelect][taskIdx]]);
            } else {
              setSelectViewMoreData([]);
            }
          } else {
            setSelectViewMoreData(groupedByDate[dateString]);
          }
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getMemberWorkReportStatus]);

  return (
    <Wrapper>
      <Container>
        <TasksModal
          selectViewMoreData={selectViewMoreData}
          open={showAllTaskModal}
          setSeletedTaskLogData={setSeletedTaskLogData}
          setIsShowModalTaskLog={setIsShowModalTaskLog}
          setIsShowDeleteTaskDialog={setIsShowDeleteTaskDialog}
          setDateString={setDateString}
          onClose={() => setShowAllTaskModal(false)}
        ></TasksModal>
        <CalendarHead>
          {_.times(7, day => {
            return (
              <WeeklyHead>
                <Typography variant="h6">
                  {moment.weekdays(day).substr(0, 2)}
                </Typography>
              </WeeklyHead>
            );
          })}
        </CalendarHead>
        <CalendarMonthly>
          {allDates.map(day => {
            const totalHours = _.sumBy(taskLogByDay[day?.dateString], o =>
              _.toNumber(o.duration),
            );
            return (
              <CalendarDay
                key={day.dateString}
                className={getDayClassName(day)}
                onClick={() => {
                  setCurrentDateCalendar(day.dateString);
                  setIsShowModalTaskLog(true);
                }}
              >
                <Stack spacing={'5px'}>
                  <Label>
                    <Typography
                      variant="h6"
                      color={
                        day.dateString === moment().format('YYYY-MM-DD')
                          ? '#fff'
                          : '#000'
                      }
                    >
                      {day.dayOfMonth}
                    </Typography>

                    <HourWork>
                      <HourTag
                        data={{
                          backgroundColor: '#BDD8FF',
                          label: `${parseNumber(totalHours) || 0}h`,
                        }}
                        size="small"
                      ></HourTag>
                    </HourWork>
                  </Label>
                  {taskLogByDay &&
                    taskLogByDay[day?.dateString] &&
                    taskLogByDay[day.dateString]?.map((task, k) => {
                      return (
                        <>
                          {k <= 1 && (
                            <Event
                              style={{
                                backgroundColor:
                                  task?.tags[0]?.color ?? '#8c8c8c',
                                cursor: 'pointer',
                              }}
                              key={k}
                              onClick={event => {
                                event.stopPropagation();
                                setShowAllTaskModal(true);
                                setSelectViewMoreData([task]);
                                setDateString(
                                  `${day?.dateString}_${k}_${task.id}`,
                                );
                              }}
                            >
                              {task.subject}
                            </Event>
                          )}
                        </>
                      );
                    })}
                  {taskLogByDay &&
                    taskLogByDay[day?.dateString] &&
                    taskLogByDay[day?.dateString]?.length > 2 && (
                      <Event
                        className="view_more_task"
                        onClick={event => {
                          event.stopPropagation();
                          setShowAllTaskModal(true);
                          setSelectViewMoreData(taskLogByDay[day?.dateString]);
                          setDateString(day?.dateString);
                        }}
                      >
                        {t('View more')}
                      </Event>
                    )}
                </Stack>
              </CalendarDay>
            );
          })}
        </CalendarMonthly>
      </Container>
    </Wrapper>
  );
}

const Wrapper = styled(Box)``;

const Container = styled(Box)`
  border-top: 1px solid #ccc;
  border-left: 1px solid #ccc;
`;

const CalendarHead = styled(Box)`
  display: flex;
  height: 32px;
  align-items: center;
  background: #f0f7ff;
`;

const WeeklyHead = styled(Box)`
  border-bottom: 1px solid #ccc;
  border-right: 1px solid #ccc;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding-right: 12px;
  flex: 1;
`;

const CalendarMonthly = styled(Box)`
  background: #fff;
  position: relative;
  width: 100%;
  display: grid;
  grid-template-columns: repeat(7, 1fr);
  grid-auto-rows: 130px;
`;

const CalendarDay = styled(Box)`
  cursor: pointer;
  border-bottom: 1px solid #ccc;
  border-right: 1px solid #ccc;
  min-width: 0;
  min-height: 0;
  overflow: hidden;
  &.cl_outside h6 {
    color: rgba(0, 0, 0, 0.3);
  }
  &.cl_current_date {
    background-color: #437dff;
  }
`;

const Label = styled(Box)`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 16px;
  margin-top: 5px;
`;

const Event = styled(Box)`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  border-radius: 4px;
  background: #8c8c8c;
  height: 26px;
  line-height: 26px;
  margin-right: 3px !important;
  color: #fff;
  font-size: 14px;
  padding: 0 8px;
  &.view_more_task {
    cursor: pointer;
  }
`;

const HourWork = styled(Box)``;
