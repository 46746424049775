import React from 'react';

// UI
import { Link } from 'react-router-dom';
import Path from 'config/clientPath';
// Redux & Sagas
import { useSelector } from 'react-redux';
import { selectProject } from 'app/pages/ProjectPage/slice/selectors';
import { selectGlobal } from 'app/pages/GlobalContainer/slice/selectors';
import Roles from 'constants/roles';
import styled from 'styled-components/macro';
// Library
import _ from 'lodash';
export function ProjectLink(props) {
  const { project_meta_data, projectList } = useSelector(selectProject);
  const { userSessionData } = useSelector(selectGlobal);
  const haveProjectEditRole = _.includes(
    userSessionData.roles,
    Roles.PROJECT_VIEW,
  );
  return (
    <Wrapper>
      <Link
        to={
          haveProjectEditRole
            ? `${Path.PROJECTS}/edit/${props.project_id}`
            : location => ({ ...location })
        }
      >
        {props.project_name
          ? props.project_name
          : _.get(
              _.find(
                project_meta_data ? project_meta_data : projectList?.data,
                {
                  id: _.toInteger(props.project_id),
                },
              ),
              'name',
            )}
      </Link>
    </Wrapper>
  );
}
const Wrapper = styled('div')`
  a {
    color: inherit;
    text-decoration: inherit;
    :hover {
      text-decoration: underline;
    }
  }
`;
