import React, { useEffect } from 'react';

// UI
import {
  DialogTitle,
  DialogContent,
  Grid,
  FormLabel,
  InputLabel,
  FormControl,
  Select,
  MenuItem,
  DialogActions,
  Button,
  Dialog,
  FormHelperText,
  Box,
  Stack,
  Typography,
} from '@mui/material';
import { Save, CloudUpload, Delete } from '@mui/icons-material';
import UISettings from 'styles/setting';
import { LoadingButton } from '@mui/lab';

// Redux & Sagas
import { useDispatch } from 'react-redux';
import { RequestStatus } from 'constants/API';

//Library
import _ from 'lodash';
import { useForm, Controller } from 'react-hook-form';
import styled from 'styled-components/macro';
import { useGlobalSlice } from 'app/pages/GlobalContainer/slice';
import moment from 'moment';
import { useTranslation } from 'react-i18next';

import { useDropzone } from 'react-dropzone';
import { IJobList } from 'app/pages/RecruitmentsPage/slice/type';
import useRecruitment from 'app/pages/RecruitmentsPage/useRecruitment';
import { Colors } from 'app/pages/HomePage/Components/Variables';
import { TextFieldRegister } from 'app/components/TextFieldRegister';
import { DatePickerFiled } from 'app/components/DatePickerField';
import { REGEX_CHECK_NUMBER } from 'constants/common';

interface IDialogForm {
  open: boolean;
  data: IJobList | null | undefined;
  setShow: React.Dispatch<React.SetStateAction<boolean>>;
}

export function DialogForm(props: IDialogForm) {
  const { open, data, setShow } = props;
  const dispatch = useDispatch();

  const { t } = useTranslation();
  const {
    reset,
    register,
    handleSubmit,
    setValue,
    getValues,
    watch,
    control,
    formState: { errors },
  } = useForm();

  const {
    jobCategories,
    updateJobStatus,
    getJobList,
    createJobStatus,
    resetJobInfo,
    updateJob,
    createNewJob,
  } = useRecruitment();
  const { setErrorMessages, setSuccessMessages } = useGlobalSlice().actions;

  const reloadData = () => {
    const params = new URLSearchParams(window.location.search);
    getJobList({
      state: params.get('state'),
      page: Math.abs(_.toInteger(params.get('page')) || 1),
    });
  };

  const handleClose = () => {
    setShow(false);
    reset();
  };

  useEffect(() => {
    if (data) {
      reset(data);
    } else reset({});
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  useEffect(() => {
    const handleSuccess = message => {
      dispatch(setSuccessMessages([message]));
      resetJobInfo();
      reloadData();
      handleClose();
    };
    if (updateJobStatus === RequestStatus.SUCCESS) {
      handleSuccess(t('Job update successful!'));
    }
    if (createJobStatus === RequestStatus.SUCCESS) {
      handleSuccess(t('Job create successful!'));
    }
    if (
      createJobStatus === RequestStatus.ERROR ||
      updateJobStatus === RequestStatus.ERROR
    ) {
      dispatch(setErrorMessages([t('Oops.. Something went wrong!')]));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [createJobStatus, updateJobStatus]);

  const onDrop = acceptedFiles => {
    acceptedFiles.forEach(file => {
      if (file.size >= 100 * 1024 * 1024) {
        dispatch(setErrorMessages([t('Please choose file < 100mb!')]));
        return false;
      } else {
        setValue('summary_file', acceptedFiles);
        return true;
      }
    });
  };

  const handleRemoveFile = () => {
    setValue('summary_file', '');
  };
  const { getRootProps } = useDropzone({
    accept: {
      'application/pdf': ['.pdf'],
      'application/pages': ['.pages'],
      'application/docx': ['.docx'],
      'application/doc': ['.doc'],
      'application/xlxs': ['.xlxs'],
      'application/xls': ['.xls'],
    },
    onDrop: onDrop,
    multiple: false,
  });

  const onSave = async d => {
    let formData: FormData = new FormData();

    const clonedObject = _.omit(d, [
      'start_date',
      'end_date',
      'summary_file',
      'category',
    ]);
    _.forEach(clonedObject, (v, k) => {
      formData.append(k, v);
    });
    formData.append(
      'category',
      getValues('category.id') ? getValues('category.id') : d.category.id,
    );
    const dateFieldToAppend = ['start_date', 'end_date'];
    dateFieldToAppend.forEach(field => {
      formData.append(
        field,
        moment(getValues(field)).format(UISettings.dateFormat),
      );
    });

    if (_.isEmpty(watch('summary_file'))) {
      formData.append('summary_file', '');
    } else if (typeof watch('summary_file') !== 'string') {
      watch('summary_file').forEach(file => {
        if (file instanceof File) {
          formData.append('summary_file', file);
        }
      });
    }

    if (props.data && props.data.id > 0) {
      updateJob(props.data.id, formData);
    } else {
      createNewJob(formData);
    }
  };

  return (
    <JobForm open={open} fullWidth maxWidth="md" scroll="paper">
      <DialogTitle sx={{ textTransform: 'uppercase' }}>
        {data ? t('Edit Job') : t('Add New Job')}
      </DialogTitle>
      <DialogContent dividers>
        <Grid
          container
          spacing={2}
          pt={1}
          component="form"
          id="job-detail-form"
          onSubmit={handleSubmit(onSave)}
        >
          <Grid item={true} xs={12}>
            <InputLabel required>{t('Title')}</InputLabel>

            <TextFieldRegister
              register={register<keyof IJobList>('title', {
                required: {
                  value: true,
                  message: t('This field is required'),
                },
                maxLength: {
                  value: 255,
                  message: t(
                    'Please enter no more than {{count}} characters.',
                    { count: 255 },
                  ),
                },
              })}
              error={!!errors.title}
              helperText={errors.title?.message}
            />
          </Grid>
          <Grid item={true} xs={6}>
            <InputLabel sx={{ marginBottom: '7.2px' }} required>
              {t('Category')}
            </InputLabel>
            <Controller
              control={control}
              name="category.id"
              rules={{
                required: t('This field is required') as string,
              }}
              render={({ field }) => {
                const { onBlur, onChange, value } = field;
                return (
                  <Select
                    fullWidth
                    displayEmpty
                    size="small"
                    onBlur={onBlur}
                    onChange={onChange}
                    value={`${value}`}
                    error={errors.category}
                    MenuProps={{
                      style: {
                        maxHeight: 'calc(100% - 700px)',
                        minHeight: '200px',
                      },
                    }}
                  >
                    {_.map(jobCategories, (item, index) => (
                      <MenuItem key={`category_job_${index}`} value={item.id}>
                        {item.name}
                      </MenuItem>
                    ))}
                  </Select>
                );
              }}
            />
            {errors?.category && (
              <FormHelperText sx={{ color: Colors.muiError }}>
                {t('This field is required')}
              </FormHelperText>
            )}
          </Grid>
          <Grid item={true} xs={6}>
            <Grid container xs={12} direction="row" spacing={'5'}>
              <Grid item xs={6}>
                <FormLabel required>{t('Employee Number')}</FormLabel>
                <TextFieldRegister
                  register={register<keyof IJobList>('open_positions', {
                    required: {
                      value: true,
                      message: t('This field is required'),
                    },
                    maxLength: {
                      value: 3,
                      message: t(
                        'Please enter no more than {{count}} characters.',
                        { count: 3 },
                      ),
                    },
                    pattern: {
                      value: REGEX_CHECK_NUMBER,
                      message: t('Please enter an integer'),
                    },
                  })}
                  error={!!errors.open_positions}
                  helperText={errors.open_positions?.message}
                />
              </Grid>

              <Grid item xs={6}>
                <InputLabel required>{t('Level')}</InputLabel>
                <TextFieldRegister
                  register={register<keyof IJobList>('level', {
                    required: {
                      value: true,
                      message: t('This field is required'),
                    },
                    maxLength: {
                      value: 255,
                      message: t(
                        'Please enter no more than {{count}} characters.',
                        { count: 255 },
                      ),
                    },
                  })}
                  error={!!errors.level}
                  helperText={errors.level?.message}
                />
              </Grid>
            </Grid>
          </Grid>

          <Grid item xs={5}>
            <InputLabel required>{t('Salary')}</InputLabel>
            <TextFieldRegister
              register={register<keyof IJobList>('salary', {
                required: {
                  value: true,
                  message: t('This field is required'),
                },
                maxLength: {
                  value: 255,
                  message: t(
                    'Please enter no more than {{count}} characters.',
                    { count: 255 },
                  ),
                },
              })}
              error={!!errors.salary}
              helperText={errors.salary?.message}
            />
          </Grid>

          <Grid item xs={7}>
            <FormLabel required>{t('Date')}</FormLabel>
            <Grid container xs={12} direction="row" spacing={'5'}>
              <Grid item xs={6}>
                <FormControl fullWidth margin="dense">
                  <Controller
                    control={control}
                    name="start_date"
                    rules={{
                      required: {
                        value: true,
                        message: t('This field is required'),
                      },
                    }}
                    render={({ field: { onChange, value } }) => (
                      <DatePickerFiled
                        value={value}
                        onChange={onChange}
                        noEntry={true}
                        error={!!errors.start_date}
                        helperText={errors.start_date?.message}
                        shouldDisableDate={date => {
                          if (watch('end_date')) {
                            return (
                              moment(date).format(UISettings.dateFormat) >
                              moment(watch('end_date')).format(
                                UISettings.dateFormat,
                              )
                            );
                          }
                          return false;
                        }}
                      />
                    )}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={6}>
                <FormControl
                  fullWidth
                  margin="dense"
                  error={'end_date' in (errors || {})}
                >
                  <Controller
                    control={control}
                    name={`end_date`}
                    rules={{
                      required: {
                        value: true,
                        message: t('This field is required'),
                      },
                    }}
                    render={({ field: { onChange, value } }) => (
                      <DatePickerFiled
                        value={value}
                        onChange={onChange}
                        noEntry={true}
                        error={!!errors.end_date}
                        helperText={errors.end_date?.message}
                        shouldDisableDate={date => {
                          const probationStart = getValues('start_date');
                          if (probationStart) {
                            return (
                              moment(date).format(UISettings.dateFormat) <
                              moment(probationStart).format(
                                UISettings.dateFormat,
                              )
                            );
                          }
                          return false;
                        }}
                      />
                    )}
                  />
                </FormControl>
              </Grid>
            </Grid>
          </Grid>

          <Grid item xs={12}>
            <InputLabel>{t('Job Description File')}</InputLabel>
            <FormControl fullWidth margin="dense">
              <Controller
                control={control}
                name={`summary_file`}
                defaultValue={data ? data?.summary_file || '' : null}
                render={() => {
                  return (
                    <>
                      <Box
                        {...getRootProps()}
                        border={`1px dashed ${Colors.inputMuiColor}`}
                        borderRadius="4px"
                        mb="16px"
                      >
                        <Stack
                          spacing="15px"
                          display="flex"
                          justifyContent="center"
                          alignItems="center"
                          p="40px"
                          sx={{
                            cursor: 'pointer',
                          }}
                        >
                          <CloudUpload
                            sx={{
                              width: '64px',
                              height: '64px',
                              color: Colors.blueText,
                            }}
                          />
                          <Typography
                            variant="body1"
                            component="p"
                            fontSize="16px"
                            fontWeight="600"
                          >
                            {t('Drag & drop files or')}{' '}
                            <Typography
                              color={Colors.blueText}
                              component="span"
                              fontSize="16px"
                              fontWeight="600"
                              sx={{ textDecoration: 'underline' }}
                            >
                              {t('Browse')}
                            </Typography>
                          </Typography>
                          <Typography
                            variant="body1"
                            component="p"
                            fontSize="12px"
                          >
                            {t('Upload File')}
                          </Typography>
                        </Stack>
                      </Box>
                    </>
                  );
                }}
              />
            </FormControl>

            <Box
              p={!_.isEmpty(watch('summary_file')) ? '15px' : ''}
              pt="0"
              maxHeight="300px"
              overflow="auto"
              sx={{
                display: _.isEmpty(watch('summary_file')) ? 'none' : 'block',
              }}
            >
              {typeof watch('summary_file') === 'string' ? (
                <Box
                  display="flex"
                  justifyContent="space-between"
                  p="10px"
                  border="1px solid #3699FF"
                  borderRadius="4px"
                >
                  <Typography>
                    {watch('summary_file').split('/').pop()}
                  </Typography>

                  <Box
                    onClick={() => {
                      setValue('summary_file', '');
                    }}
                    color="red"
                    style={{
                      cursor: 'pointer',
                    }}
                  >
                    <Delete />
                  </Box>
                </Box>
              ) : (
                _.map(watch('summary_file'), (file, index) => (
                  <Box
                    key={index}
                    mt={index === 0 ? '0' : '10px'}
                    display="flex"
                    justifyContent="space-between"
                    p="10px"
                    border="1px solid #3699FF"
                    borderRadius="4px"
                  >
                    <Typography>{file.name}</Typography>

                    <Box
                      onClick={() => handleRemoveFile()}
                      color="red"
                      style={{
                        cursor: 'pointer',
                      }}
                    >
                      <Delete />
                    </Box>
                  </Box>
                ))
              )}
            </Box>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button variant="outlined" onClick={() => handleClose()}>
          {t('Cancel')}
        </Button>
        <LoadingButton
          variant="contained"
          color="primary"
          loadingPosition="start"
          startIcon={<Save />}
          loading={
            updateJobStatus === RequestStatus.REQUESTING ||
            createJobStatus === RequestStatus.REQUESTING
          }
          type="submit"
          onClick={handleSubmit(onSave)}
        >
          {data ? 'Update' : 'Create'}
        </LoadingButton>
      </DialogActions>
    </JobForm>
  );
}

const JobForm = styled(Dialog)`
  .MuiDialogContent-root {
    padding: 0px 24px !important;
  }
  .MuiDialog-paperWidthMd {
    width: 700px !important;
  }
`;
