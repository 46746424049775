import { Box, Stack, Typography } from '@mui/material';
import { ReactComponent as ArrowIcon } from 'assets/icons/landing/arrow_icon.svg';
import { ReactComponent as ComputerIcon } from 'assets/icons/landing/computer_icon.svg';
import { ReactComponent as DescriptionIcon } from 'assets/icons/landing/description_icon.svg';
import { ReactComponent as HumanIcon } from 'assets/icons/landing/person_icon.svg';
import { ReactComponent as TimeIcon } from 'assets/icons/landing/schedule_icon.svg';
import { ReactComponent as PhaseIcon } from 'assets/icons/landing/view_list_icon.svg';
import ProjectImg from 'assets/img/product.png';
import moment from 'moment';
import React, { SyntheticEvent } from 'react';
import Slider from 'react-slick';
import styled from 'styled-components';
import { IProject } from '../slice/types';
import { Heading } from './Heading';
import { Colors, device } from './Variables';
interface Props {
  projects: IProject[] | null;
}

export const Projects = (props: Props) => {
  const { projects } = props;

  const settings = {
    infinite: true,
    slidesToShow: 1,
    speed: 500,
    initialSlide: 0,
    adaptiveHeight: true,
  };
  const handleFallback = (e: SyntheticEvent<HTMLImageElement>) => {
    e.currentTarget.src = ProjectImg;
  };

  return (
    <Box id="Projects">
      <Heading>Our Projects</Heading>
      <SliderBox>
        <Slider {...settings}>
          {projects &&
            projects.map((it, index) => (
              <Box key={index}>
                <Grid>
                  <ProjectBox>
                    <Box>
                      <Head className="white-text text-36">{it.name}</Head>
                      <IconStack direction="row" alignItems="center">
                        <Tag>#mobile app</Tag>
                        <Tag>#web app</Tag>
                        <Tag>#android</Tag>
                        <Tag>#ios</Tag>
                        <Tag>#blockchain</Tag>
                      </IconStack>
                      <Stack
                        direction="row"
                        alignItems="flex-start"
                        spacing={2}
                        className="mb-10"
                      >
                        <HumanIcon />
                        <Box>
                          <Typography
                            variant="h5"
                            component="h5"
                            className="white-text text-20 mb-10"
                          >
                            Customer
                          </Typography>
                          <Para className="white-text">{it.name}</Para>
                        </Box>
                      </Stack>
                      <Stack
                        direction="row"
                        alignItems="flex-start"
                        spacing={2}
                        className="mb-10"
                      >
                        <DescriptionIcon />
                        <Box>
                          <Typography
                            variant="h5"
                            component="h5"
                            className="white-text text-20 mb-10"
                          >
                            Description
                          </Typography>
                          <Para className="white-text">{it.description}</Para>
                        </Box>
                      </Stack>
                      <Stack
                        direction="row"
                        alignItems="flex-start"
                        spacing={2}
                        className="mb-10"
                      >
                        <ComputerIcon />
                        <Box>
                          <Typography
                            variant="h5"
                            component="h5"
                            className="white-text text-20 mb-10"
                          >
                            Technology
                          </Typography>
                          <Para className="white-text">{it.name}</Para>
                        </Box>
                      </Stack>
                      <Stack
                        direction="row"
                        alignItems="flex-start"
                        spacing={2}
                        className="mb-10"
                      >
                        <TimeIcon />
                        <Box>
                          <Typography
                            variant="h5"
                            component="h5"
                            className="white-text text-20 mb-10"
                          >
                            Time
                          </Typography>
                          <Para className="white-text">
                            {it.end_date && it.start_date
                              ? `${moment(it.end_date).diff(
                                  it.start_date,
                                  'month',
                                )} months`
                              : ''}
                          </Para>
                        </Box>
                      </Stack>
                      <Stack
                        direction="row"
                        alignItems="flex-start"
                        spacing={2}
                        className="mb-10"
                      >
                        <PhaseIcon />
                        <Box className="max-300">
                          <Typography
                            variant="h5"
                            component="h5"
                            className="white-text text-20 mb-10"
                          >
                            Phases
                          </Typography>
                          <IconStack
                            direction="row"
                            alignItems="center"
                            className="vertical"
                          >
                            {renderIcon(it.scope)}
                          </IconStack>
                        </Box>
                      </Stack>
                    </Box>
                  </ProjectBox>
                  <ProjectBox className="transparent">
                    <ImgCover>
                      <img
                        className="pc-display"
                        src={it?.image_url ?? ProjectImg}
                        alt={it.description}
                        onError={handleFallback}
                      />
                    </ImgCover>

                    <img
                      className="mb-display"
                      src={it?.image_url ?? ProjectImg}
                      alt={it.description}
                      onError={handleFallback}
                    />
                  </ProjectBox>
                </Grid>
              </Box>
            ))}
        </Slider>
      </SliderBox>
    </Box>
  );
};

const renderIcon = (scope: any): any => {
  if (scope instanceof Array) {
    const length = scope.length;
    return scope.map((item, index) => {
      return (
        <>
          <Tag className="blue-bg center">{item}</Tag>
          {length > 1 && index < length - 1 ? <ArrowIcon /> : null}
        </>
      );
    });
  } else {
    return <Tag className="blue-bg center">{scope}</Tag>;
  }
};
const Tag = styled.span`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 8px;
  margin: 0 8px 10px;
  color: ${Colors.white};
  background: ${Colors.lightBlue};
  border-radius: 8px;
  white-space: nowrap;
  &.blue-bg {
    display: flex;
    align-items: center;
    white-space: normal;
    max-width: 136px;
    height: 50px;
    margin: 10px 0;
    background: ${Colors.blueHeading};
    @media ${device.mobileL} {
      margin: 0 0 10px;
      max-width: none;
      width: 100%;
      padding: 10px 20px;
    }
  }
`;
const ProjectBox = styled(Box)`
  background: ${Colors.darkBlue};
  > div {
    padding: 80px 50px 80px 80px;
  }

  .mb-display {
    display: none !important;
  }
  .pc-display {
    /* max-height: 300px; */
  }
  &.transparent {
    padding: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    overflow: hidden;
    > img {
    }
  }
  .css-ag7rrr-MuiTypography-root {
    font-weight: 600;
  }
  .mb-10 > svg {
    max-width: 40px;
  }
  .css-1d9cypr-MuiStack-root {
    max-width: 300px;
    @media ${device.tablet} {
      max-width: 100%;
    }
  }
  @media ${device.tablet} {
    order: 2;
    &.transparent {
      order: 1;
      position: static;
      height: 400px;
    }

    > div {
      padding: 20px;
    }
    .mb-10 > svg {
      min-width: 40px;
    }

    .mb-display {
      display: block !important;
      height: 400px;
    }
    .pc-display {
      display: none;
    }
    .css-ag7rrr-MuiTypography-root {
      margin-bottom: 0;
    }
    .text-36 {
      font-size: 24px;
    }
    .text-20 {
      font-size: 18px;
    }
  }

  @media ${device.mobileL} {
    .max-300.MuiBox-root {
      width: 100%;
    }
  }
`;
const Para = styled(Typography)`
  padding: 10px 0;
  line-height: 1.2;
`;
const Head = styled(Typography)`
  padding-bottom: 20px;
`;
const Grid = styled.div`
  display: grid;
  grid-auto-flow: column;
  grid-auto-columns: 1fr;
  @media ${device.tablet} {
    display: flex;
    flex-direction: column;
  }
`;
const IconStack = styled(Stack)`
  max-width: 600px;
  flex-wrap: wrap;
  margin: 20px 0;

  @media ${device.tablet} {
  }
  @media only screen and (max-width: 426px) {
    &.css-1d9cypr-MuiStack-root.vertical {
      flex-direction: column;
      margin-top: 20px;
    }

    svg {
      transform: rotate(90deg);
      position: relative;
      top: -5px;
    }
  }
`;
const SliderBox = styled(Box)`
  .slick-prev {
    bottom: -25px;
    left: calc(50% - 50px);
    z-index: 9;
    top: auto;
    width: 50px;
    height: 50px;
    background: ${Colors.lightBlue};
  }
  .slick-next {
    bottom: -25px;
    left: 50%;
    top: auto;
    right: auto;
    width: 50px;
    height: 50px;
    background: ${Colors.blueHeading};
  }
  .slick-next:before,
  .slick-prev:before {
    font-family: inherit;
  }
  .slick-prev:before {
    color: ${Colors.blueHeading};
  }
  .slick-next:focus,
  .slick-next:hover {
    background: ${Colors.blueHeading};
  }
  .slick-prev:focus,
  .slick-prev:hover {
    background: ${Colors.lightBlue};
  }
  .slick-track {
    display: flex !important;
    -webkit-box-align: stretch;
    -webkit-align-items: stretch;
    -ms-flex-align: stretch;
    align-items: stretch;
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
  }
  .slick-slide {
    height: 100 !important;
  }

  @media ${device.tablet} {
    .slick-prev {
      top: 200px;
      bottom: auto;
      left: 0;
      right: auto;
      width: 40px;
      height: 40px;
    }
    .slick-next {
      top: 200px;
      bottom: auto;
      right: 0;
      left: auto;
      width: 40px;
      height: 40px;
    }
  }
`;

const ImgCover = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  padding: 0 !important;
  display: flex;
  @media ${device.tablet} {
    display: none;
  }
`;
