import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormLabel,
  Grid,
  IconButton,
  Stack,
  Typography,
} from '@mui/material';
import styled from 'styled-components';
import UISettings from 'styles/setting';
import { Clear } from '@mui/icons-material';

import { useHistory } from 'react-router-dom';

import { useMemo } from 'react';
import _ from 'lodash';
import moment from 'moment';

import { useTranslation } from 'react-i18next';
import { CANDIDATE_STATUS } from 'constants/common';
import Path from 'config/clientPath';
import { handleParamSearch } from 'utils/helper';
import { Controller, UseFormReturn } from 'react-hook-form';
import { DatePickerFiled } from 'app/components/DatePickerField';
import useRecruitment from 'app/pages/RecruitmentsPage/useRecruitment';
import { AutoCompleteField } from 'app/components/AutocompleteField';

interface IProps {
  filterCandidatesForm: UseFormReturn;
  isShowModal: boolean;
  setIsShowModal: React.Dispatch<React.SetStateAction<boolean>>;
}

export function FilterModal(props: IProps) {
  const { isShowModal, setIsShowModal, filterCandidatesForm } = props;
  const {
    control,
    handleSubmit,
    getValues,
    setValue,
    trigger,
    formState: { errors },
  } = filterCandidatesForm;
  const { t } = useTranslation();
  const history = useHistory();
  const params = new URLSearchParams(window.location.search);
  const { jobCategories, skillsList, language_metadata } = useRecruitment();

  const candidatesArr = useMemo(
    () =>
      Object.keys(CANDIDATE_STATUS).map(key => ({
        key,
        name: CANDIDATE_STATUS[key],
      })),
    [],
  );

  const ressetValueForm = () => {
    setValue('start_date', null);
    setValue('end_date', null);
    setValue('status', []);
    setValue('category', null);
    setValue('skills', []);
    setValue('languages', []);
    trigger('category');
  };

  const handleClose = () => {
    setIsShowModal(false);
  };

  const handleFilter = d => {
    setIsShowModal(false);
    const ps = {
      start_date: d.start_date
        ? moment(d.start_date).format(UISettings.dateFormat)
        : undefined,
      end_date: d.end_date
        ? moment(d.end_date).format(UISettings.dateFormat)
        : undefined,
      status: _.map(d?.status, it => it?.key),
      category: d.category?.id,
      skills: _.map(d?.skills, it => it?.id),
      languages: _.map(d?.languages, it => it?.id),
      search: params.get('search') ?? '',
      page: 1,
    };
    history.push(`${Path.CANDIDATES}?${handleParamSearch(ps)}`);
  };

  return (
    <Dialog open={isShowModal} maxWidth="sm" fullWidth scroll="paper">
      <DialogTitle sx={{ py: 1, px: 2 }}>
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
        >
          <Typography
            variant="body1"
            component="p"
            fontWeight="500"
            fontSize="18px"
            sx={{ textTransform: 'uppercase' }}
          >
            {t('Filter Candidates')}
          </Typography>
          <IconButton onClick={handleClose}>
            <Clear />
          </IconButton>
        </Stack>
      </DialogTitle>
      <DialogContent dividers>
        <Grid
          container
          spacing={2}
          component="form"
          id="filter-candidate-form"
          onSubmit={handleSubmit(handleFilter)}
        >
          <Grid item xs={12}>
            <FormLabel>{t('Applied Date')}</FormLabel>
            <Stack direction="row" width={400} alignItems="center">
              <FormControl fullWidth margin="dense" sx={{ mr: 1 }}>
                <Controller
                  control={control}
                  name="start_date"
                  render={({ field: { onChange, value } }) => (
                    <DatePickerFiled
                      value={value}
                      onChange={onChange}
                      noEntry={true}
                      error={!!errors.start_date}
                      helperText={errors.start_date?.message}
                      shouldDisableDate={date => {
                        if (getValues('end_date')) {
                          return (
                            moment(date).format(UISettings.dateFormat) >
                            moment(getValues('end_date')).format(
                              UISettings.dateFormat,
                            )
                          );
                        }
                        return false;
                      }}
                    />
                  )}
                />
              </FormControl>
              <FormControl
                fullWidth
                margin="dense"
                error={'end_date' in (errors || {})}
              >
                <Controller
                  control={control}
                  name="end_date"
                  render={({ field: { onChange, value } }) => (
                    <DatePickerFiled
                      value={value}
                      onChange={onChange}
                      noEntry={true}
                      error={!!errors.end_date}
                      helperText={errors.end_date?.message}
                      shouldDisableDate={date => {
                        const probationStart = getValues('start_date');
                        if (probationStart) {
                          return (
                            moment(date).format(UISettings.dateFormat) <
                            moment(probationStart).format(UISettings.dateFormat)
                          );
                        }
                        return false;
                      }}
                    />
                  )}
                />
              </FormControl>
            </Stack>
          </Grid>
          <Grid item xs={5}>
            <Stack>
              <FormLabel>{t('Job Category')}</FormLabel>
              <AutoCompleteField
                nameField="category"
                options={jobCategories}
                useForm={filterCandidatesForm}
              />
            </Stack>
          </Grid>
          <GridItem item xs={7}>
            <FormLabel>{t('Status')}</FormLabel>
            <AutoCompleteField
              nameField="status"
              multiple
              options={candidatesArr}
              useForm={filterCandidatesForm}
              isFieldDiff="key"
            />
          </GridItem>
          <GridItem item xs={12}>
            <FormLabel>{t('Skills')}</FormLabel>
            <AutoCompleteField
              nameField="skills"
              multiple
              limitTags={4}
              options={skillsList || []}
              useForm={filterCandidatesForm}
              isFieldDiff="id"
            />
          </GridItem>
          <GridItem item xs={12}>
            <FormLabel>{t('Languages')}</FormLabel>
            <AutoCompleteField
              nameField="languages"
              multiple
              limitTags={3}
              options={language_metadata || []}
              useForm={filterCandidatesForm}
              isFieldDiff="id"
            />
          </GridItem>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button variant="outlined" onClick={ressetValueForm}>
          {t('Reset')}
        </Button>
        <Button
          color="primary"
          type="submit"
          form="filter-candidate-form"
          variant="contained"
        >
          {t('Filter')}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

const GridItem = styled(Grid)`
  display: flex;
  flex-direction: column !important;
`;
