import { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from 'utils/@reduxjs/toolkit';
import { useInjectReducer, useInjectSaga } from 'utils/redux-injectors';
import tagSaga from './saga';
import { TagState } from './types';
import { RequestStatus } from 'constants/API';

export const initialState: TagState = {
  getTagStatus: RequestStatus.IDLE,
  tagList: null,
};

const slice = createSlice({
  name: 'tag',
  initialState,
  reducers: {
    someAction(state, action: PayloadAction<any>) {},
    getTagRequest: (state, action: PayloadAction<any>) => {
      if (action) state.getTagStatus = RequestStatus.REQUESTING;
    },
    getTagSuccess: (state, action: PayloadAction<any>) => {
      if (action) {
        state.getTagStatus = RequestStatus.SUCCESS;
        state.tagList = action.payload;
      }
    },
    getTagFail: (state, action: PayloadAction<any>) => {
      if (action) state.getTagStatus = RequestStatus.ERROR;
    },
    resetTagRequestStatus: state => {
      state.getTagStatus = RequestStatus.IDLE;
      state.tagList = null;
    },
  },
});

export default slice.actions;

export const useTagSlice = () => {
  useInjectReducer({ key: slice.name, reducer: slice.reducer });
  useInjectSaga({ key: slice.name, saga: tagSaga });
  return { actions: slice.actions };
};
