import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import { selectRecruitments } from 'app/pages/RecruitmentsPage/slice/selectors';
import { CANDIDATE_STATUS } from 'constants/common';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import styled from 'styled-components';

export function Tab2() {
  const { t } = useTranslation();
  const { interactionLogs } = useSelector(selectRecruitments);

  return (
    <>
      <TableContainer
        sx={{ height: 'calc(100vh - 350px)', marginTop: '0px !important' }}
      >
        <Table>
          <TableHead
            sx={{ position: 'sticky', top: 0, bgcolor: 'gray', zIndex: 10 }}
          >
            <TableRow sx={{ backgroundColor: '#f3f6f9' }}>
              <TableCellStyle>{t('Date')}</TableCellStyle>
              <TableCellStyle>{t('Description')}</TableCellStyle>
              <TableCellStyle>{t('Status')}</TableCellStyle>
              <TableCellStyle>{t('Created by')}</TableCellStyle>
            </TableRow>
          </TableHead>
          <TableBody sx={{ bgcolor: 'white' }}>
            {interactionLogs?.length === 0 ? (
              <TableRow>
                <TableCell colSpan={4} sx={{ textAlign: 'left' }}>
                  {t('No data')}
                </TableCell>
              </TableRow>
            ) : (
              <>
                {interactionLogs?.map((item, index) => (
                  <TableRow key={`interactionLogs_${index}`}>
                    <TableCell>
                      <TableData>{item.created_at}</TableData>
                    </TableCell>
                    <TableCell>
                      <TableData>{item.description}</TableData>
                    </TableCell>
                    <TableCell>
                      <TableData>
                        {CANDIDATE_STATUS[item.current_status]}
                      </TableData>
                    </TableCell>
                    <TableCell>
                      <TableData>{item.created_by.full_name} </TableData>
                    </TableCell>
                  </TableRow>
                ))}
              </>
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
}

const TableData = styled(Typography)`
  display: flex;
  justify-content: left;
  align-items: self-start;
`;

const TableCellStyle = styled(TableCell)`
  background: #f3f6f9;
  color: #687182 !important;
  font-size: 14px;
  font-weight: bold;
`;
