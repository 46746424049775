import React, { useEffect, Dispatch, SetStateAction } from 'react';

// UI
import {
  DialogTitle,
  DialogContent,
  Grid,
  Typography,
  Button,
  FormLabel,
  TextField,
  FormControl,
  RadioGroup,
  FormControlLabel,
  Radio,
  Select,
  MenuItem,
  DialogActions,
  Dialog,
  Stack,
  Checkbox,
} from '@mui/material';

import DatePicker from '@mui/lab/DatePicker';

import { LoadingIndicator } from 'app/components/LoadingIndicator';
import styled from 'styled-components/macro';
import { Controller, useForm } from 'react-hook-form';

// Redux & Sagas
import { useDispatch, useSelector } from 'react-redux';
import { useProjectsSlice } from '../slice';
import { selectProject } from '../slice/selectors';

import { useCustomerSlice } from 'app/pages/CustomerPage/slice';
import { selectCustomer } from 'app/pages/CustomerPage/slice/selectors';
import _ from 'lodash';
import { IProject } from '../slice/types';
import { RequestStatus } from 'constants/API';
import { PROJECT_SCOPE } from 'constants/ProjectMetadata';
import { convertDate } from 'utils/moment';
import moment from 'moment';
export function CreateDialog({ ...props }) {
  const dispatch = useDispatch();
  const { actions } = useProjectsSlice();
  const projectSlice = useSelector(selectProject);

  const { onClose } = props as {
    onClose: () => Dispatch<SetStateAction<boolean>>;
  };

  const form = useForm();
  const {
    register,
    handleSubmit,
    getValues,
    setValue,
    reset,
    control,
    formState: { errors },
  } = form;

  const { getCustomerListRequest } = useCustomerSlice().actions;
  const { listCustomer } = useSelector(selectCustomer);

  React.useEffect(() => {
    dispatch(getCustomerListRequest({}));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (projectSlice.createProjectStatus === RequestStatus.SUCCESS) {
      onClose();
      reset();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [projectSlice.createProjectStatus, onClose]);
  const handleSelect = (e: any) => {
    const { value, checked } = e.target;
    if (checked) {
      setValue(
        'scope',
        _.uniq([...getValues('scope'), _.toInteger(value)].filter(o => o)),
      );
    } else {
      const remaining = _.uniq(
        getValues('scope').filter(
          (item: number) => item !== _.toInteger(value),
        ),
      );
      setValue('scope', remaining);
    }
  };

  const onSave = formData => {
    formData.start_date = convertDate(
      formData.start_date ? formData.start_date : moment(),
    );
    formData.end_date = convertDate(
      formData.end_date ? formData.end_date : moment(),
    );
    formData.customer = {
      id: _.toInteger(formData.customer),
    };
    dispatch(actions.createProjectRequest(formData));
  };

  return (
    <ProjectFormContainer
      open={props.open}
      maxWidth="md"
      fullWidth
      scroll="paper"
    >
      <DialogTitle>ADD NEW PROJECT</DialogTitle>
      <DialogContent dividers>
        <Grid
          container
          mb={2}
          pt={2}
          spacing={2}
          component="form"
          id="customer-form"
          onSubmit={handleSubmit(onSave)}
        >
          <Grid item xs={6}>
            <FormLabel>Code</FormLabel>
            <TextField
              margin="dense"
              placeholder="Enter Code"
              variant="outlined"
              size="small"
              fullWidth
              error={errors.code ? true : false}
              {...register<keyof IProject>('code', {
                required: 'This is required.',
                maxLength: 255,
              })}
              helperText={errors.code?.message}
            />
          </Grid>

          <Grid item xs={6}>
            <FormLabel>Project Type</FormLabel>
            <FormControl fullWidth margin="dense">
              <Controller
                control={control}
                defaultValue="1"
                name="project_type"
                render={({ field }) => {
                  const { onBlur, onChange, value } = field;
                  return (
                    <RadioGroup
                      row
                      value={value}
                      onBlur={onBlur}
                      onChange={onChange}
                    >
                      <FormControlLabel
                        value="1"
                        control={<Radio />}
                        label="Labo"
                      />
                      <FormControlLabel
                        value="2"
                        control={<Radio />}
                        label="Project base"
                      />
                    </RadioGroup>
                  );
                }}
              />
            </FormControl>
          </Grid>

          <Grid item xs={6}>
            <FormLabel>Project Name</FormLabel>
            <TextField
              margin="dense"
              placeholder="Enter name"
              variant="outlined"
              size="small"
              fullWidth
              error={errors.name ? true : false}
              {...register<keyof IProject>('name', {
                required: 'This is required.',
                maxLength: 200,
              })}
              helperText={errors.name?.message}
            />
          </Grid>

          <Grid item xs={6}>
            <FormLabel>Customer</FormLabel>
            <FormControl fullWidth margin="dense">
              <Controller
                control={control}
                name="customer"
                rules={{ required: 'This is required.' }}
                render={({ field }) => {
                  const { onBlur, onChange, value } = field;
                  return (
                    <Select
                      fullWidth
                      size="small"
                      onBlur={onBlur}
                      onChange={onChange}
                      value={`${value}`}
                    >
                      {listCustomer?.data.map(it => (
                        <MenuItem
                          key={`prj_customer_${it.id}`}
                          value={`${it.id}`}
                        >
                          {it.name}
                        </MenuItem>
                      ))}
                    </Select>
                  );
                }}
              />
            </FormControl>
          </Grid>

          <Grid item xs={6}>
            <FormLabel>Size</FormLabel>
            <TextField
              margin="dense"
              placeholder="Enter size"
              variant="outlined"
              size="small"
              fullWidth
              error={errors.size ? true : false}
              {...register<keyof IProject>('size', {
                required: 'This is required.',
                validate: value => !isNaN(value),
                maxLength: 255,
              })}
              helperText={errors.size?.message}
            />
          </Grid>

          <Grid item xs={6}>
            <FormLabel>Time</FormLabel>
            <FormControl fullWidth margin="dense">
              <Grid container>
                <Grid item xs>
                  <Controller
                    control={control}
                    name="start_date"
                    render={({ field: { onChange, value } }) => (
                      <DatePicker
                        value={value}
                        onChange={onChange}
                        inputFormat="DD-MM-YYYY"
                        renderInput={params => (
                          <TextField
                            {...params}
                            fullWidth
                            hiddenLabel
                            size="small"
                          />
                        )}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs>
                  <Controller
                    control={control}
                    name="end_date"
                    render={({ field: { onChange, value } }) => (
                      <DatePicker
                        value={value}
                        onChange={onChange}
                        inputFormat="DD-MM-YYYY"
                        renderInput={params => (
                          <TextField
                            {...params}
                            fullWidth
                            hiddenLabel
                            size="small"
                          />
                        )}
                      />
                    )}
                  />
                </Grid>
              </Grid>
            </FormControl>
          </Grid>
          <Grid item xs={12}>
            <FormLabel>Scope</FormLabel>
            <FormControl fullWidth margin="dense">
              <Stack direction="row">
                {PROJECT_SCOPE.map((it, idx) => {
                  return (
                    <FormControlLabel
                      control={
                        <Controller
                          name={`scope.${idx}`}
                          render={({ field }) => {
                            return (
                              <Checkbox
                                {...field}
                                value={it.id}
                                onChange={handleSelect}
                              />
                            );
                          }}
                          control={control}
                        />
                      }
                      label={it.name}
                      key={it.id}
                    />
                  );
                })}
              </Stack>
            </FormControl>
          </Grid>
          <Grid item xs={6}>
            <FormLabel>Status</FormLabel>
            <FormControl fullWidth margin="dense">
              <Controller
                control={control}
                name="status"
                render={({ field }) => {
                  const { onBlur, onChange, value } = field;
                  return (
                    <RadioGroup
                      row
                      value={`${value}`}
                      onBlur={onBlur}
                      onChange={onChange}
                    >
                      <FormControlLabel
                        value={`1`}
                        control={<Radio />}
                        label="Active"
                      />
                      <FormControlLabel
                        value={`2`}
                        control={<Radio />}
                        label="Deactive"
                      />
                    </RadioGroup>
                  );
                }}
              />
            </FormControl>
          </Grid>

          <Grid item xs={12}>
            <FormLabel>Slack Hook URL</FormLabel>
            <TextField
              variant="outlined"
              fullWidth
              hiddenLabel
              size="small"
              error={errors.report_slack_hook_url ? true : false}
              {...register<keyof IProject>('report_slack_hook_url', {
                maxLength: 2000,
              })}
              helperText={errors.report_slack_hook_url?.message}
            />
          </Grid>

          <Grid item xs={12}>
            <FormLabel>Description</FormLabel>
            <TextField
              margin="dense"
              variant="outlined"
              size="small"
              fullWidth
              multiline
              rows={2}
              error={errors.description ? true : false}
              {...register<keyof IProject>('description')}
              helperText={errors.description?.message}
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} variant="outlined">
          Cancel
        </Button>
        <Button
          variant="contained"
          color="primary"
          type="submit"
          form="customer-form"
          disabled={
            projectSlice.createProjectStatus === RequestStatus.REQUESTING
          }
        >
          {projectSlice.createProjectStatus === RequestStatus.REQUESTING && (
            <Typography component="span" mr={1}>
              <LoadingIndicator small />
            </Typography>
          )}
          Create
        </Button>
      </DialogActions>
    </ProjectFormContainer>
  );
}

const ProjectFormContainer = styled(Dialog)`
  .MuiDialogContent-root {
    padding: 0px 24px !important;
  }
`;

export default CreateDialog;
