import { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from 'utils/@reduxjs/toolkit';
import { useInjectReducer, useInjectSaga } from 'utils/redux-injectors';
import assignmentSummarySaga from './saga';
import { AssignmentState } from './types';
import { RequestStatus } from 'constants/API';

export const initialState: AssignmentState = {
  getAssignmentDetailStatus: RequestStatus.IDLE,
  getAssignmentSummaryStatus: RequestStatus.IDLE,
  updateCommitEffortStatus: RequestStatus.IDLE,
  assignProSummaryStatus: RequestStatus.IDLE,
  assignmentSummary: null,
  assignmentDetail: null,
  assignProSummary: null,
};

const slice = createSlice({
  name: 'assignment_summary',
  initialState,
  reducers: {
    updateCommitEffortRequest: (state, action: PayloadAction<any>) => {
      if (action) state.updateCommitEffortStatus = RequestStatus.REQUESTING;
    },
    updateCommitEffortSuccess: (state, action: PayloadAction<any>) => {
      if (action) {
        state.updateCommitEffortStatus = RequestStatus.SUCCESS;
      }
    },
    updateCommitEffortFail: (state, action: PayloadAction<any>) => {
      state.updateCommitEffortStatus = RequestStatus.ERROR;
    },

    getAssignmentDetailRequest: (state, action: PayloadAction<any>) => {
      if (action) state.getAssignmentDetailStatus = RequestStatus.REQUESTING;
    },
    getAssignmentDetailSuccess: (state, action: PayloadAction<any>) => {
      if (action) {
        state.getAssignmentDetailStatus = RequestStatus.SUCCESS;
        state.assignmentDetail = action.payload;
      }
    },
    getAssignmentDetailFail: (state, action: PayloadAction<any>) => {
      state.getAssignmentDetailStatus = RequestStatus.ERROR;
    },

    getAssignmentSummaryRequest: (state, action: PayloadAction<any>) => {
      if (action) state.getAssignmentSummaryStatus = RequestStatus.REQUESTING;
    },
    getAssignmentSummarySuccess: (state, action: PayloadAction<any>) => {
      if (action) {
        state.getAssignmentSummaryStatus = RequestStatus.SUCCESS;
        state.assignmentSummary = action.payload;
      }
    },
    getAssignmentSummaryFail: (state, action: PayloadAction<any>) => {
      state.getAssignmentSummaryStatus = RequestStatus.ERROR;
    },

    assignProSummaryRequest: (state, action: PayloadAction<any>) => {
      if (action) state.assignProSummaryStatus = RequestStatus.REQUESTING;
    },
    assignProSummarySuccess: (state, action: PayloadAction<any>) => {
      if (action) {
        state.assignProSummaryStatus = RequestStatus.SUCCESS;
        state.assignProSummary = action.payload;
      }
    },
    assignProSummaryFail: (state, action: PayloadAction<any>) => {
      state.assignProSummaryStatus = RequestStatus.ERROR;
    },

    resetAssignmentSummaryStatus: state => {
      state.getAssignmentSummaryStatus = RequestStatus.IDLE;
      state.getAssignmentDetailStatus = RequestStatus.IDLE;
      state.updateCommitEffortStatus = RequestStatus.IDLE;
      state.assignProSummaryStatus = RequestStatus.IDLE;
      state.assignmentDetail = null;
    },
    resetAssignmentSummaryState: state => {
      state.assignmentSummary = null;
      state.assignmentDetail = null;
      state.assignProSummary = null;
    },
  },
});

export default slice.actions;

export const useAssignmentSummarySlice = () => {
  useInjectReducer({ key: slice.name, reducer: slice.reducer });
  useInjectSaga({ key: slice.name, saga: assignmentSummarySaga });
  return { actions: slice.actions };
};
