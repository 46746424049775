import { StatusCode } from 'constants/API';
import { call, put, takeLatest } from 'redux-saga/effects';
import APIs from 'APIs';
import Slice from '.';
import actions from 'app/pages/GlobalContainer/slice/';
import roles from 'constants/roles';
import _ from 'lodash';
import { USER_ROLES } from 'constants/common';

function* handleLogin(response, successAction, failAction) {
  let errorMess = ['Ops..Login failed!'];
  try {
    if (response.status === StatusCode.SUCCESS_COMMON) {
      let sessionData = response.data;
      let userRole = roles.IS_NORMAL_USER;
      _.forEach(USER_ROLES, role => {
        if (_.includes(sessionData.roles, roles.IS_ADMIN)) {
          userRole = roles.IS_ADMIN;
          return;
        }
        if (_.includes(sessionData.roles, role)) {
          userRole = role;
          return;
        }
      });
      sessionData = { ...sessionData, userRole };

      yield put({
        type: actions.setUserSessionData.type,
        payload: sessionData,
      });
      yield put({
        type: successAction,
        payload: { access: response.data.auth.access, userRole },
      });
    } else {
      const { data, status } = response;
      if (status === StatusCode.BAD_REQUEST) {
        errorMess = Object.keys(data).map(k => {
          return Array.isArray(data) ? data[k]?.join(', ') : data[k];
        });
      }
      yield put({
        type: failAction,
        payload: { errorMess },
      });
    }
  } catch (error) {
    yield put({
      type: failAction,
      payload: { errorMess },
    });
  }
}

function* loginFlow({ payload }) {
  const response = yield call(APIs.login, payload);
  yield call(handleLogin, response, Slice.loginSuccess, Slice.loginFail.type);
}

function* loginWithGgFlow({ payload }) {
  const response = yield call(APIs.loginWithgoogle, payload);
  yield call(
    handleLogin,
    response,
    Slice.loginWithGgSuccess,
    Slice.loginWithGgFail.type,
  );
}

function* loginWatcher() {
  yield takeLatest(Slice.loginRequest, loginFlow);
  yield takeLatest(Slice.loginWithGgRequest, loginWithGgFlow);
}

export default loginWatcher;
