// UI
import { Box, Typography } from '@mui/material';
import { ReactComponent as DotIcon } from 'assets/icons/dot.svg';
import styled from 'styled-components';

import { device } from './Variables';

interface IHeading {
  children: any;
}

export function Heading(props: IHeading) {
  return (
    <MainHeading
      variant="h3"
      display="flex"
      alignItems="center"
      justifyContent="center"
      className="heading-text"
    >
      <DotIcon />
      <Box component="span" style={{ paddingLeft: '15px' }}>
        {props.children}
      </Box>
    </MainHeading>
  );
}

const MainHeading = styled(Typography)`
  &.css-h05oy3-MuiTypography-root {
    font-size: 48px;
    font-weight: 500;
    padding: 20px 20px 50px;
    @media ${device.tablet} {
      font-size: 36px;
      padding: 20px 0;
    }
    @media ${device.mobileL} {
      font-size: 24px;
      padding: 20px 0;
    }
    svg {
      width: 16px;
    }
  }
`;
