import { useEffect, useState } from 'react';
import _ from 'lodash';
import moment from 'moment';

import {
  Box,
  Button,
  FormControl,
  FormHelperText,
  FormLabel,
  Grid,
  MenuItem,
  Select,
  Stack,
  Typography,
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
import SaveIcon from '@mui/icons-material/Save';

import useMember from '../useMember';
import {
  ILevelSchema,
  IMemberSchema,
  IRoleSchema,
} from 'app/pages/MemberPage/slice/types';
import { useGlobalSlice } from '../../GlobalContainer/slice';
import { useDispatch } from 'react-redux';

import InputImage from 'app/components/InputImage';
import { Controller, UseFormReturn, useForm } from 'react-hook-form';
import {
  LANGUAGE_PAGE,
  REGEX_CHECK_NUMBER,
  REGEX_VALIDATE_EMAIL,
  STATUS_OPTION,
  TYPE_MEMBER,
} from 'constants/common';
import Path from 'config/clientPath';
import { RequestStatus } from 'constants/API';
import { useHistory, useParams } from 'react-router-dom';
import UISettings from 'styles/setting';
import { TextFieldRegister } from 'app/components/TextFieldRegister';
import { DatePickerFiled } from 'app/components/DatePickerField';
import { convertDate } from 'utils/moment';

interface IProps {
  memberForm: UseFormReturn;
  type?: string;
  handleSaveData?: Function;
  resetForm?: Function;
}

export default function BasicInfo(props: IProps) {
  const { type, memberForm, handleSaveData } = props;
  const [correspondingRoles, setCorrespondingRoles] = useState<
    IRoleSchema[] | null
  >();
  const [correspondingLevel, setCorrespondingLevel] = useState<
    ILevelSchema[] | null
  >();
  const dispatch = useDispatch();
  const history = useHistory();
  const { id }: { id: string } = useParams();

  const params = useParams<{ id: string }>();
  const createForm = useForm();
  const {
    reset,
    register,
    getValues,
    setValue,
    control,
    handleSubmit,
    watch,
    formState: { errors },
  } = type ? memberForm : createForm;

  const {
    departments,
    levels,
    addMember,
    addMemberStatus,
    messAddMember,
    resetStatus,
    updateMemberInfoStatus,
    forgotPass,
    forgotPasswordStatus,
    resetForgotPass,
    getMemberInfo,
    member_role_list,
    uploadMemberAvatar,
    memberInfo,
  } = useMember();

  const { setErrorMessages, setSuccessMessages } = useGlobalSlice().actions;

  const handleAddMember = () => {
    let formData = new FormData();

    const dateFieldToAppend = [
      'birthday',
      'probation_start',
      'probation_end',
      'official_start',
      'termination_date',
    ];
    const fieldsToAppend = [
      'full_name',
      'email',
      'phone',
      'address',
      'identify_number',
      'sys_language',
      'department',
      'status',
      'code',
      'type',
      'education',
      'tax_info',
      'bank_name',
      'bank_branch',
      'bank_account_number',
      'role',
      'level',
    ];

    fieldsToAppend.forEach(field => {
      formData.append(field, getValues(field) ? getValues(field) : '');
    });
    dateFieldToAppend.forEach(field => {
      formData.append(
        field,
        getValues(field)
          ? moment(getValues(field)).format(UISettings.dateFormat)
          : '',
      );
    });

    if (typeof memberForm.getValues('avatar') !== 'string') {
      formData.append(
        'avatar',
        memberForm.getValues('avatar') ? memberForm.getValues('avatar')[0] : '',
      );
    }

    addMember(formData);
  };

  const handleMember = () => {
    if (id && handleSaveData) {
      const convertFieldsIfExists = (fields: string[]) => {
        const values = getValues();
        return fields.reduce((acc, field) => {
          acc[field] = values[field] ? convertDate(values[field]) : null;
          return acc;
        }, {});
      };

      handleSaveData({
        ...getValues(),
        ...convertFieldsIfExists([
          'birthday',
          'probation_start',
          'probation_end',
          'official_start',
          'termination_date',
        ]),
      });
    } else {
      handleAddMember();
    }
  };
  const callAPIUploadImage = () => {
    let avatar = memberForm.getValues('avatar');
    if (avatar) {
      uploadMemberAvatar(id, avatar);
    }
  };

  useEffect(() => {
    if (updateMemberInfoStatus === RequestStatus.SUCCESS) {
      getMemberInfo(params.id);
      resetStatus();
    }
    if (updateMemberInfoStatus === RequestStatus.ERROR) {
      dispatch(setErrorMessages(['Oops.. Something went wrong!']));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updateMemberInfoStatus]);

  useEffect(() => {
    resetStatus();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (addMemberStatus === RequestStatus.SUCCESS) {
      dispatch(setSuccessMessages([messAddMember]));
      reset();
      resetStatus();
      history.push(`${Path.MEMBERS}`);
    }
    if (addMemberStatus === RequestStatus.ERROR) {
      dispatch(
        setErrorMessages([
          messAddMember.detail ||
            messAddMember[Object.keys(messAddMember)[0]][0],
        ]),
      );
      resetStatus();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [addMemberStatus]);

  useEffect(() => {
    if (forgotPasswordStatus === RequestStatus.SUCCESS) {
      dispatch(setSuccessMessages(['Send email reset password success']));
    }
    if (forgotPasswordStatus === RequestStatus.ERROR) {
      dispatch(setErrorMessages(['Send email reset password have errors']));
    }
    resetForgotPass();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [forgotPasswordStatus]);

  useEffect(() => {
    if (!type) {
      setValue('sys_language', 'en');
      setValue('status', '1');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [type]);

  useEffect(() => {
    if (id || type) {
      setCorrespondingRoles(
        watch('department') &&
          _.filter(member_role_list, {
            department: _.find(departments, {
              id: watch('department'),
            })?.root.id,
          }),
      );
      setCorrespondingLevel(
        watch('role')
          ? _.filter(levels, {
              role: watch('role'),
            })
          : [],
      );
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    id,
    type,
    watch('department'),
    watch('role'),
    member_role_list,
    departments,
  ]);

  useEffect(
    () => () => {
      if (props?.resetForm) {
        props.resetForm();
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    ['componentWillUnMount', memberInfo],
  );

  return (
    <Stack
      direction="row"
      spacing={3}
      component="form"
      method={type ? 'PUT' : 'POST'}
      onSubmit={handleSubmit(handleMember)}
    >
      <Box>
        <InputImage
          form={memberForm}
          inputName="avatar"
          size={256}
          callAPIUploadImage={callAPIUploadImage}
        />
      </Box>
      <Stack spacing="50px">
        <Box>
          <Typography variant="h5" component="h5" mb={2}>
            Personal Information
          </Typography>
          <Grid container spacing="20px">
            <Grid item xs={6}>
              <FormLabel required>Full Name</FormLabel>
              <TextFieldRegister
                register={register<keyof IMemberSchema>('full_name', {
                  required: 'This field is required',
                  maxLength: {
                    value: 60,
                    message: 'Please enter no more than 60 characters.',
                  },
                })}
                error={!!errors.full_name}
                helperText={errors.full_name?.message}
              />
            </Grid>
            <Grid item xs={6}>
              <FormLabel>Tax Number</FormLabel>
              <TextFieldRegister
                register={register<keyof IMemberSchema>('tax_info', {
                  maxLength: {
                    value: 13,
                    message: 'Please enter no more than 13 characters.',
                  },
                  pattern: {
                    value: REGEX_CHECK_NUMBER,
                    message: 'Please enter an integer',
                  },
                })}
                error={!!errors.tax_info}
                helperText={errors.tax_info?.message}
              />
            </Grid>

            <Grid item xs={6}>
              <FormLabel required>Phone</FormLabel>
              <TextFieldRegister
                register={register<keyof IMemberSchema>('phone', {
                  required: 'This field is required',
                  maxLength: {
                    value: 12,
                    message: 'Please enter no more than 12 characters.',
                  },
                  pattern: {
                    value: REGEX_CHECK_NUMBER,
                    message: 'Please enter an integer',
                  },
                })}
                error={!!errors.phone}
                helperText={errors.phone?.message}
              />
            </Grid>
            <Grid item xs={6}>
              <FormLabel required>Email</FormLabel>
              <TextFieldRegister
                register={register<keyof IMemberSchema>('email', {
                  required: 'This field is required',
                  pattern: {
                    value: REGEX_VALIDATE_EMAIL,
                    message: 'Email is invalid',
                  },
                })}
                type="text"
                error={!!errors.email}
                helperText={errors.email?.message}
              />
            </Grid>

            <Grid item xs={12}>
              <FormLabel required>Address</FormLabel>
              <TextFieldRegister
                register={register<keyof IMemberSchema>('address', {
                  required: 'This field is required',
                })}
                error={!!errors.address}
                helperText={errors.address?.message}
              />
            </Grid>

            <Grid item xs={6}>
              <FormLabel>Identify Number</FormLabel>
              <TextFieldRegister
                register={register<keyof IMemberSchema>('identify_number', {
                  pattern: {
                    value: REGEX_CHECK_NUMBER,
                    message: 'Please enter an integer',
                  },
                })}
                error={!!errors.identify_number}
                helperText={errors.identify_number?.message}
              />
            </Grid>
            <Grid item xs={6}>
              <FormLabel>DOB</FormLabel>
              <FormControl fullWidth margin="dense" error={!!errors.birthday}>
                <Controller
                  control={control}
                  name="birthday"
                  render={({ field: { onChange, value } }) => (
                    <DatePickerFiled
                      disableFuture={true}
                      value={value}
                      onChange={onChange}
                      noEntry={true}
                    />
                  )}
                />
              </FormControl>
            </Grid>

            <Grid item xs={12}>
              <FormLabel>Bank Info</FormLabel>
              <Stack direction="row" spacing="20px">
                <Grid item xs={3}>
                  <TextFieldRegister
                    placeholder="Name"
                    register={register<keyof IMemberSchema>('bank_name')}
                  />
                </Grid>
                <Grid item xs={3}>
                  <TextFieldRegister
                    placeholder="Branch"
                    register={register<keyof IMemberSchema>('bank_branch')}
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextFieldRegister
                    placeholder="Account Number"
                    register={register<keyof IMemberSchema>(
                      'bank_account_number',
                      {
                        pattern: {
                          value: REGEX_CHECK_NUMBER,
                          message: 'Please enter an integer',
                        },
                      },
                    )}
                    error={!!errors.bank_account_number}
                    helperText={errors.bank_account_number?.message}
                  />
                </Grid>
              </Stack>
            </Grid>

            <Grid item xs={9}>
              <FormLabel>Education</FormLabel>
              <TextFieldRegister
                register={register<keyof IMemberSchema>('education')}
              />
            </Grid>
            <Grid item xs={3}>
              <FormLabel>Language</FormLabel>
              <FormControl fullWidth margin="dense">
                <Controller
                  control={control}
                  name="sys_language"
                  render={({ field }) => {
                    const { onBlur, onChange, value } = field;
                    return (
                      <Select
                        fullWidth
                        displayEmpty
                        size="small"
                        onBlur={onBlur}
                        onChange={onChange}
                        value={value || 'en'}
                      >
                        {_.map(LANGUAGE_PAGE, (label, code) => (
                          <MenuItem key={code} value={code}>
                            {label}
                          </MenuItem>
                        ))}
                      </Select>
                    );
                  }}
                />
              </FormControl>
            </Grid>
          </Grid>
        </Box>

        <Box>
          <Typography variant="h5" component="h5" mb={2}>
            Employment Information
          </Typography>
          <Grid container spacing="20px">
            <Grid item xs={6}>
              <FormLabel>Status</FormLabel>
              <FormControl fullWidth margin="dense">
                <Controller
                  control={control}
                  name="status"
                  render={({ field }) => {
                    const { onBlur, onChange, value } = field;
                    return (
                      <Select
                        fullWidth
                        displayEmpty
                        size="small"
                        onBlur={onBlur}
                        onChange={onChange}
                        value={value || '1'}
                      >
                        {_.map(STATUS_OPTION, (label, code) => (
                          <MenuItem key={code} value={code}>
                            {label}
                          </MenuItem>
                        ))}
                      </Select>
                    );
                  }}
                />
              </FormControl>
            </Grid>
            <Grid item xs={6}></Grid>
            <Grid item xs={6}>
              <FormLabel required>Contract Type</FormLabel>
              <FormControl fullWidth margin="dense" error={!!errors.type}>
                <Controller
                  control={control}
                  name="type"
                  rules={{
                    required: 'This field is required',
                  }}
                  render={({ field }) => {
                    const { onBlur, onChange, value } = field;
                    return (
                      <Select
                        fullWidth
                        displayEmpty
                        size="small"
                        onBlur={onBlur}
                        onChange={onChange}
                        value={value || ''}
                      >
                        {_.map(TYPE_MEMBER, (label, code) => (
                          <MenuItem key={code} value={code}>
                            {label}
                          </MenuItem>
                        ))}
                      </Select>
                    );
                  }}
                />
                <FormHelperText error>{errors.type?.message}</FormHelperText>
              </FormControl>
            </Grid>
            <Grid item xs={6}>
              <FormLabel required>Employee Code</FormLabel>
              <TextFieldRegister
                register={register<keyof IMemberSchema>('code', {
                  required: 'This field is required',
                  maxLength: {
                    value: 5,
                    message: 'Ensure this field has no more than 5 characters',
                  },
                })}
                error={!!errors.code}
                helperText={errors.code?.message}
              />
            </Grid>
            <Grid item xs={6}>
              <FormLabel>Probation Start</FormLabel>
              <FormControl fullWidth margin="dense">
                <Controller
                  control={control}
                  name="probation_start"
                  render={({ field: { onChange, value } }) => (
                    <DatePickerFiled
                      value={value}
                      onChange={onChange}
                      shouldDisableDate={date => {
                        if (watch('probation_end')) {
                          return (
                            moment(date).format(UISettings.dateFormat) >
                            moment(watch('probation_end')).format(
                              UISettings.dateFormat,
                            )
                          );
                        }
                        return false;
                      }}
                      noEntry={true}
                    />
                  )}
                />
              </FormControl>
            </Grid>
            <Grid item xs={6}>
              <FormLabel>Probation End</FormLabel>
              <FormControl fullWidth margin="dense">
                <Controller
                  control={control}
                  name="probation_end"
                  render={({ field: { onChange, value } }) => (
                    <DatePickerFiled
                      value={value}
                      onChange={onChange}
                      shouldDisableDate={date => {
                        const probationStart = getValues('probation_start');
                        if (probationStart) {
                          return (
                            moment(date).format(UISettings.dateFormat) <
                            moment(probationStart).format(UISettings.dateFormat)
                          );
                        }
                        return false;
                      }}
                      noEntry={true}
                    />
                  )}
                />
              </FormControl>
            </Grid>
            <Grid item xs={6}>
              <FormLabel>Official Date</FormLabel>
              <FormControl fullWidth margin="dense">
                <Controller
                  control={control}
                  name="official_start"
                  render={({ field: { onChange, value } }) => (
                    <DatePickerFiled
                      value={value}
                      onChange={onChange}
                      shouldDisableDate={date => {
                        if (watch('termination_date')) {
                          return (
                            moment(date).format(UISettings.dateFormat) >
                            moment(watch('termination_date')).format(
                              UISettings.dateFormat,
                            )
                          );
                        }
                        return false;
                      }}
                      noEntry={true}
                    />
                  )}
                />
              </FormControl>
            </Grid>
            <Grid item xs={6}>
              <FormLabel>Termination Date</FormLabel>
              <FormControl fullWidth margin="dense">
                <Controller
                  control={control}
                  name="termination_date"
                  render={({ field: { onChange, value } }) => (
                    <DatePickerFiled
                      value={value}
                      onChange={onChange}
                      shouldDisableDate={date => {
                        const officialStart = getValues('official_start');
                        if (officialStart) {
                          return (
                            moment(date).format(UISettings.dateFormat) <
                            moment(officialStart).format(UISettings.dateFormat)
                          );
                        }
                        return false;
                      }}
                      noEntry={true}
                    />
                  )}
                />
              </FormControl>
            </Grid>
          </Grid>
        </Box>

        <Box>
          <Typography variant="h5" component="h5" mb={2}>
            Role & Level
          </Typography>
          <Grid container spacing="20px">
            <Grid item xs={6}>
              <FormLabel required>Department</FormLabel>
              <FormControl fullWidth margin="dense" error={!!errors.department}>
                <Controller
                  control={control}
                  name="department"
                  rules={{ required: 'This field is required.' }}
                  render={({ field }) => {
                    const { onBlur, onChange, value } = field;
                    return (
                      <Select
                        fullWidth
                        displayEmpty
                        size="small"
                        onBlur={onBlur}
                        onChange={e => {
                          onChange(e);
                          setCorrespondingRoles(
                            getValues('department') &&
                              _.filter(member_role_list, {
                                department: _.find(departments, {
                                  id: getValues('department'),
                                }).root.id,
                              }),
                          );
                          setValue('role', null);
                          setValue('level', null);
                          setCorrespondingLevel([]);
                        }}
                        value={`${value}`}
                      >
                        {_.map(departments, (i, k) => (
                          <MenuItem key={k} value={i.id}>
                            {i.full_name}
                          </MenuItem>
                        ))}
                      </Select>
                    );
                  }}
                />
                <FormHelperText error>
                  {errors.department?.message}
                </FormHelperText>
              </FormControl>
            </Grid>
            <Grid item xs={6}></Grid>

            <Grid item xs={6}>
              <FormLabel required>Role</FormLabel>
              <FormControl fullWidth margin="dense" error={!!errors.role}>
                <Controller
                  control={control}
                  name="role"
                  rules={{
                    required: {
                      value: true,
                      message: 'This field is required',
                    },
                  }}
                  render={({ field }) => {
                    const { onBlur, onChange, value } = field;
                    return (
                      <Select
                        fullWidth
                        displayEmpty
                        size="small"
                        onBlur={onBlur}
                        onChange={e => {
                          onChange(e);
                          setValue('level', '');
                          setCorrespondingLevel(
                            getValues('role')
                              ? _.filter(levels, {
                                  role: getValues('role'),
                                })
                              : _.filter(levels, {
                                  department: getValues('department'),
                                }),
                          );
                        }}
                        value={`${value}`}
                        MenuProps={{
                          anchorOrigin: {
                            vertical: 'top',
                            horizontal: 'left',
                          },
                          transformOrigin: {
                            vertical: 'bottom',
                            horizontal: 'left',
                          },
                        }}
                      >
                        {_.map(correspondingRoles, (i, k) => (
                          <MenuItem key={k} value={i.id}>
                            {i.name}
                          </MenuItem>
                        ))}
                      </Select>
                    );
                  }}
                />
                <FormHelperText error>{errors.role?.message}</FormHelperText>
              </FormControl>
            </Grid>
            <Grid item xs={6}>
              <FormLabel>Level</FormLabel>
              <FormControl fullWidth margin="dense">
                <Controller
                  control={control}
                  name="level"
                  render={({ field }) => {
                    const { onBlur, onChange, value } = field;
                    return (
                      <Select
                        fullWidth
                        displayEmpty
                        size="small"
                        onBlur={onBlur}
                        onChange={onChange}
                        value={`${value}`}
                        MenuProps={{
                          anchorOrigin: {
                            vertical: 'top',
                            horizontal: 'left',
                          },
                          transformOrigin: {
                            vertical: 'bottom',
                            horizontal: 'left',
                          },
                        }}
                      >
                        {_.map(correspondingLevel, (label, index) => (
                          <MenuItem key={index} value={label.id}>
                            {label.name}
                          </MenuItem>
                        ))}
                      </Select>
                    );
                  }}
                />
                <FormHelperText error>{errors.level?.message}</FormHelperText>
              </FormControl>
            </Grid>
          </Grid>
        </Box>

        <Stack direction="row-reverse" spacing={1}>
          <LoadingButton
            variant="contained"
            color="primary"
            loadingPosition="start"
            startIcon={<SaveIcon />}
            loading={
              addMemberStatus === RequestStatus.REQUESTING ||
              updateMemberInfoStatus === RequestStatus.REQUESTING
            }
            type="submit"
          >
            {type ? 'Save change' : 'Add new member'}
          </LoadingButton>
          {type && (
            <Button
              variant="contained"
              style={{ backgroundColor: '#F9973B' }}
              onClick={() => forgotPass(getValues(['email'].toString()))}
            >
              Send Reset Password
            </Button>
          )}
          <Button
            variant="contained"
            color="inherit"
            onClick={() => reset()}
            type="button"
            disabled={
              addMemberStatus === RequestStatus.REQUESTING ||
              updateMemberInfoStatus === RequestStatus.REQUESTING
            }
          >
            Reset
          </Button>
        </Stack>
      </Stack>
    </Stack>
  );
}
