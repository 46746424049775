import React, { useState } from 'react';
import { Modal, Tab } from '@mui/material';
import styled from 'styled-components';
import { Box } from '@mui/system';
import { TabContext, TabList, TabPanel } from '@mui/lab';
import ModalUploadCvByManual from './ModalUpload/ModalUploadCvByManual';
import { ModalUploadByAI } from './ModalUpload/ModalUploadByAI';
import { useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';

export default function ModalUpload({
  isOpenModal,
  setIsOpenModal,
  statusJob,
}) {
  const [tabIndex, setTabIndex] = useState('1');
  const { t } = useTranslation();
  const handleClose = () => {
    setIsOpenModal(false);
    reset();
    setValue('cv_file', []);
    setValue('source', '');
  };
  const { setValue, reset } = useForm();
  const handleChangeTabIndex = (
    event: React.SyntheticEvent,
    newValue: string,
  ) => {
    setTabIndex(newValue);
  };

  return (
    <Modal open={isOpenModal}>
      <StyledDiv>
        <TabContext value={tabIndex}>
          <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
            <TabList
              onChange={handleChangeTabIndex}
              aria-label="lab API tabs example"
            >
              <Tab label={t('Upload cv by manual')} value="1" />
              <Tab label={t('Upload cv by AI')} value="2" />
            </TabList>
          </Box>
          <TabPanel sx={{ padding: 0, overflow: 'auto' }} value="1">
            <ModalUploadCvByManual
              statusJob={statusJob}
              setCloseModal={handleClose}
            />
          </TabPanel>
          <TabPanel sx={{ padding: 0 }} value="2">
            <ModalUploadByAI
              statusJob={statusJob}
              setCloseModal={handleClose}
            />
          </TabPanel>
        </TabContext>
      </StyledDiv>
    </Modal>
  );
}

const StyledDiv = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 50%;
  background-color: #fff;
  box-shadow: 0 0 24px rgba(0, 0, 0, 0.16);
  min-width: 500px;
  border-radius: 4px;
`;
