import React, { useState, useEffect } from 'react';

// UI
import { Helmet } from 'react-helmet-async';

import {
  Box,
  Typography,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  TableContainer,
  Link,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Stack,
  IconButton,
  Paper,
  Pagination,
} from '@mui/material';

import { Container } from 'app/components/Container';
import { Wrapper, Content } from 'app/components/Wrapper';

import { DialogForm } from './components/Form';

// Redux & Sagas
import { useDispatch, useSelector } from 'react-redux';
import { RequestStatus } from 'constants/API';
import { selectCustomer } from './slice/selectors';
import { useCustomerSlice } from './slice';
import { ICustomerSchema } from './slice/types';
import { useGlobalSlice } from 'app/pages/GlobalContainer/slice';
import { AddCircle } from '@mui/icons-material';
import { ReactComponent as EditIcon } from 'assets/icons/button/edit.svg';
import { ReactComponent as DeleteIcon } from 'assets/icons/button/delete.svg';
// Library
import { useHistory, useLocation } from 'react-router';
import Path from 'config/clientPath';
import _ from 'lodash';
import { Loader } from 'app/components/Loader';
import { checkIsRequesting } from 'utils/helper';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

export function CustomerPage() {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const {
    actions: {
      getCustomerListRequest,
      deleteCustomerInfoRequest,
      resetCustomerInfoRequestStatus,
    },
  } = useCustomerSlice();
  const {
    listCustomer,
    getCustomerListStatus,
    createCustomerInfoStatus,
    updateCustomerInfoStatus,
    deleteCustomerInfoStatus,
  } = useSelector(selectCustomer);

  const { setBreadcumbs, setSuccessMessages } = useGlobalSlice().actions;

  // dialog
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);

  const [showConfirm, setShowConfirm] = useState(false);
  const [deleteCustomerInfo, setDeleteCustomerInfo] =
    useState<ICustomerSchema | null>();

  const [page, setPage] = useState<number>(1);
  const history = useHistory();
  const location = useLocation();

  useEffect(() => {
    if (
      createCustomerInfoStatus === RequestStatus.SUCCESS ||
      updateCustomerInfoStatus === RequestStatus.SUCCESS ||
      deleteCustomerInfoStatus === RequestStatus.SUCCESS
    ) {
      dispatch(resetCustomerInfoRequestStatus());
      const params = new URLSearchParams(window.location.search);
      dispatch(
        getCustomerListRequest({
          page: Math.abs(_.toInteger(params.get('page')) || 1),
        }),
      );
    }
    if (createCustomerInfoStatus === RequestStatus.SUCCESS) {
      dispatch(setSuccessMessages(['Customer create Succesful!']));
    }
    if (updateCustomerInfoStatus === RequestStatus.SUCCESS) {
      dispatch(setSuccessMessages(['Customer update Succesful!']));
    }
    if (deleteCustomerInfoStatus === RequestStatus.SUCCESS) {
      dispatch(setSuccessMessages(['Customer delete Succesful!']));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    getCustomerListRequest,
    createCustomerInfoStatus,
    updateCustomerInfoStatus,
    deleteCustomerInfoStatus,
    resetCustomerInfoRequestStatus,
    dispatch,
  ]);

  const isLoading = checkIsRequesting([getCustomerListStatus]);

  useEffect(() => {
    dispatch(setBreadcumbs([{ title: 'Customers' }]));
    const params = new URLSearchParams(window.location.search);
    setPage(_.toInteger(params.get('page') || 1));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    dispatch(
      getCustomerListRequest({
        page: Math.abs(_.toInteger(params.get('page')) || 1),
      }),
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.search]);

  const [dataItem, setDataItem] = useState<ICustomerSchema | null>();

  return (
    <>
      <Helmet>
        <title>{t('Customer Management')}</title>
        <meta name="description" content={t('Customer Management')} />
      </Helmet>
      <Container>
        <Wrapper>
          <Loader open={isLoading} />
          <Content spacing={2}>
            <Box
              display="flex"
              flexDirection="row"
              justifyContent="space-between"
            >
              <Typography variant="h6" component="h6">
                {t('Customer list')}
              </Typography>
              <Button
                variant="contained"
                color="primary"
                onClick={() => {
                  setDataItem(null);
                  setShow(true);
                }}
                startIcon={<AddCircle />}
              >
                {t('Add new')}
              </Button>
            </Box>

            <DialogForm open={show} onClose={handleClose} data={dataItem} />

            <Dialog open={showConfirm}>
              <DialogTitle>{t('Delete customer info')}</DialogTitle>

              <DialogContent>
                <DialogContentText>
                  {t('Do you want to delete customer info bellow?')}
                </DialogContentText>
                <Typography variant="subtitle1" component="div">
                  {t('Name')}: {deleteCustomerInfo?.name}
                </Typography>
                <Typography variant="subtitle1" component="div">
                  {t('URL')}: {deleteCustomerInfo?.url}
                </Typography>
                <Typography variant="subtitle1" component="div">
                  {t('ADDRESS')}: {deleteCustomerInfo?.address}
                </Typography>
              </DialogContent>
              <DialogActions>
                <Button onClick={() => setShowConfirm(false)}>
                  {t('Close')}
                </Button>
                <Button
                  color="error"
                  onClick={() => {
                    setShowConfirm(false);
                    dispatch(deleteCustomerInfoRequest(deleteCustomerInfo?.id));
                  }}
                >
                  {t('Delete')}
                </Button>
              </DialogActions>
            </Dialog>

            <WrapTable>
              <TableContainer component={Paper}>
                <Table stickyHeader>
                  <TableHead>
                    <TableRow>
                      <TableCell sx={{ minWidth: 60 }} width={60}>
                        {t('ID')}
                      </TableCell>
                      <TableCell sx={{ minWidth: 90 }} width={100}>
                        {t('Code')}
                      </TableCell>
                      <TableCell sx={{ minWidth: 130 }}>Name</TableCell>
                      <TableCell sx={{ minWidth: 130 }} width={300}>
                        {t('URL')}
                      </TableCell>
                      <TableCell sx={{ minWidth: 120 }} width={120}>
                        {t('Phone')}
                      </TableCell>
                      <TableCell sx={{ minWidth: 120 }} width={300}>
                        {t('Address')}
                      </TableCell>
                      <TableCell width={90}>{t('Action')}</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {listCustomer?.data.map((item, idx) => {
                      return (
                        <TableRow key={item.id}>
                          <TableCell>{item.id}</TableCell>
                          <TableCell>{item.code}</TableCell>
                          <TableCell>{item.name}</TableCell>
                          <TableCell>
                            <Link href={item.url} target={'_blank'}>
                              {item.url}
                            </Link>
                          </TableCell>
                          <TableCell>{item.phone}</TableCell>
                          <TableCell>{item.address}</TableCell>
                          <TableCell align="right">
                            <Stack direction="row" spacing={1}>
                              <IconButton
                                onClick={() => {
                                  setDataItem(item);
                                  setShow(true);
                                }}
                              >
                                <EditIcon />
                              </IconButton>
                              <IconButton
                                onClick={() => {
                                  setShowConfirm(true);
                                  setDeleteCustomerInfo(item);
                                }}
                              >
                                <DeleteIcon />
                              </IconButton>
                            </Stack>
                          </TableCell>
                        </TableRow>
                      );
                    })}
                  </TableBody>
                </Table>
              </TableContainer>
            </WrapTable>
            <Stack spacing={2} alignItems={'flex-end'}>
              <Pagination
                page={page}
                color="primary"
                count={listCustomer?.total_page || 0}
                variant="outlined"
                shape="rounded"
                onChange={(
                  event: React.ChangeEvent<unknown> | null,
                  page: number,
                ) => {
                  history.push(`${Path.CUSTOMERS}?page=${page}`);
                  setPage(page);
                }}
              />
            </Stack>
          </Content>
        </Wrapper>
      </Container>
    </>
  );
}

const WrapTable = styled(Box)`
  & .MuiTableContainer-root {
    max-height: calc(100vh - 175px);
  }
`;
