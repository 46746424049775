import {
  InputLabel,
  Stack,
  ToggleButton,
  ToggleButtonGroup,
} from '@mui/material';
import { List, Dehaze } from '@mui/icons-material';
import { useEffect } from 'react';
import queryString from 'query-string';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import Path from 'config/clientPath';
import { useTranslation } from 'react-i18next';

interface IFilterBarProps {
  currentTab: string | null;
  setCurrentTab: any;
}

export function FilterBar(props: IFilterBarProps) {
  const history = useHistory();
  const location = useLocation();
  const { currentTab, setCurrentTab } = props;
  const { id }: { id: string } = useParams();
  const { t } = useTranslation();

  const handleChangeTab = (event, currentMemberTab) => {
    let params = queryString.parse(window.location.search);
    if (currentMemberTab) {
      params = { tab: currentMemberTab };
      setCurrentTab(currentMemberTab);
    }
    const qs = queryString.stringify(params);
    history.push(`${Path.JOB_LIST}/${id}?${qs}`);
  };

  useEffect(() => {
    let params = queryString.parse(window.location.search);
    if (params.tab) {
      setCurrentTab(params.tab);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.search]);

  return (
    <Stack
      direction="row"
      spacing={2}
      alignItems="center"
      justifyContent="flex-end"
    >
      <InputLabel>{t('View mode')}:</InputLabel>
      <ToggleButtonGroup
        value={currentTab}
        exclusive
        onChange={handleChangeTab}
      >
        <ToggleButton value="DND_TAB" aria-label="dnd">
          <Dehaze sx={{ transform: 'rotate(90deg)' }} />
        </ToggleButton>
        <ToggleButton value="LIST_TAB" aria-label="table">
          <List />
        </ToggleButton>
      </ToggleButtonGroup>
    </Stack>
  );
}

export default FilterBar;
