import {
  Box,
  IconButton,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
  Typography,
} from '@mui/material';
import { AvatarMember } from 'app/components/Avatar';
import { MemberLink } from 'app/components/MemberLink';
import Path from 'config/clientPath';
import { STATUS_LIST, TYPE_MEMBER } from 'constants/common';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { ReactComponent as EditIcon } from 'assets/icons/button/edit.svg';
import { selectMember } from '../slice/selectors';
import { useSelector } from 'react-redux';

export function ViewMemberModeTable() {
  const { listMember } = useSelector(selectMember);
  const { t } = useTranslation();

  const getTechnicalSkillName = (item: any) => {
    return item?.technical_skills?.map((it: any) => it.name).join(', ');
  };
  return (
    <>
      <WrapTable>
        <TableContainer component={Paper}>
          <Table stickyHeader>
            <TableHead>
              <TableRow>
                <TableCell sx={{ minWidth: 60 }} width={60}>
                  {t('No.')}
                </TableCell>
                <TableCell sx={{ minWidth: 240 }} width={240}>
                  {t('Name')}
                </TableCell>
                <TableCell sx={{ minWidth: 100 }} width={100}>
                  {t('Code')}
                </TableCell>
                <TableCell sx={{ minWidth: 100 }} width={100}>
                  {t('Type')}
                </TableCell>
                <TableCell sx={{ minWidth: 180 }} width={180}>
                  {t('Department')}
                </TableCell>
                <TableCell sx={{ minWidth: 100 }} width={100}>
                  {t('Role')}
                </TableCell>
                <TableCell sx={{ minWidth: 100 }} width={100}>
                  {t('Level')}
                </TableCell>
                <TableCell
                  className="nowrap"
                  sx={{
                    minWidth: 200,
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                  }}
                  width={200}
                >
                  {t('Skills')}
                </TableCell>
                <TableCell sx={{ minWidth: 240 }} width={240}>
                  {t('Email')}
                </TableCell>
                <TableCell sx={{ minWidth: 120 }} width={120}>
                  {t('Status')}
                </TableCell>
                <TableCell sx={{ minWidth: 80 }} width={80}>
                  {t('Action')}
                </TableCell>
              </TableRow>
            </TableHead>

            <TableBody>
              {listMember?.data?.map((item, idx) => {
                return (
                  <TableRow key={`Member_row_${idx}`}>
                    <TableCell>
                      {listMember?.page_size
                        ? listMember?.page_size *
                            (listMember?.current_page - 1) +
                          idx +
                          1
                        : idx + 1}
                    </TableCell>
                    <TableCell>
                      <Stack
                        direction="row"
                        spacing={'6px'}
                        alignItems="center"
                      >
                        <AvatarMember
                          avatar={`${item?.avatar || ''}`}
                          title={`${item?.full_name || ''}`}
                          sx={{ width: 24, height: 24 }}
                          member_id={item?.id}
                        ></AvatarMember>
                        <MemberLink
                          member_id={item?.id}
                          member_name={item?.full_name}
                        ></MemberLink>
                      </Stack>
                    </TableCell>
                    <TableCell>{item.member_code}</TableCell>
                    <TableCell>{TYPE_MEMBER[item.type]}</TableCell>
                    <TableCell>{item.department_name}</TableCell>
                    <TableCell>{item?.role_name}</TableCell>
                    <TableCell>{item?.level_name}</TableCell>
                    <TableCell>
                      <Tooltip title={getTechnicalSkillName(item)}>
                        <Typography
                          sx={{
                            whiteSpace: 'nowrap',
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                          }}
                          width={200}
                        >
                          {getTechnicalSkillName(item)}
                        </Typography>
                      </Tooltip>
                    </TableCell>
                    <TableCell>{item.email}</TableCell>
                    <TableCell>{STATUS_LIST[item.status]}</TableCell>
                    <TableCell align="right">
                      <Stack
                        direction="row"
                        justifyContent="center"
                        spacing={1}
                      >
                        <IconButton
                          size="small"
                          component={Link}
                          to={`${Path.MEMBERS}/edit/${item.id}`}
                        >
                          <EditIcon />
                        </IconButton>
                      </Stack>
                    </TableCell>
                  </TableRow>
                );
              })}
              {listMember?.data?.length === 0 && (
                <TableRow>
                  <TableCell colSpan={8}>{t('No data')}</TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </WrapTable>
    </>
  );
}

const WrapTable = styled(Box)`
  & .MuiTableContainer-root {
    max-height: calc(100vh - 230px);
  }
`;
