import { Box, Button, Modal, Typography } from '@mui/material';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

export default function ModalDetailNote({ showDetail, setDetailShow, data }) {
  const { t } = useTranslation();

  return (
    <>
      <Modal
        keepMounted
        open={showDetail}
        aria-labelledby="keep-mounted-modal-title"
        aria-describedby="keep-mounted-modal-description"
      >
        <StyledDiv>
          <Typography
            variant="body1"
            component="p"
            sx={{
              p: '15px',
              borderBottom: '1px solid #ccc',
              fontWeight: '500',
              fontSize: '18px',
            }}
          >
            {t('NOTE')}
          </Typography>

          <Box sx={{ maxHeight: '300px', overflow: 'auto', p: '10px' }}>
            <div
              style={{ paddingLeft: '5px', fontSize: 15, lineHeight: '25px' }}
            >
              <span style={{ width: '15%', fontWeight: 'bold' }}>
                {t('Created by ')}:
              </span>
              <span
                style={{
                  color: '#3699FF',
                  marginLeft: '4px',
                  fontWeight: 'bold',
                }}
              >
                {data?.created_by?.full_name}
              </span>
              <br />
              <span style={{ fontWeight: 'bold' }}>{t('Created at ')}:</span>
              <span style={{ marginLeft: '4px' }}>{data?.created_at}</span>
              <br />
              <span style={{ fontWeight: 'bold' }}>{t('Content ')}:</span>
              <div style={{ marginLeft: '20px' }}>
                <span
                  style={{ fontWeight: 'initial' }}
                  dangerouslySetInnerHTML={{
                    __html: data?.content.replace(/\n/g, '<br />'),
                  }}
                ></span>
              </div>
            </div>
          </Box>
          <Box sx={displayflexend}>
            <Button
              variant="outlined"
              type="button"
              onClick={() => {
                setDetailShow(false);
              }}
            >
              {t('Close')}
            </Button>
          </Box>
        </StyledDiv>
      </Modal>
    </>
  );
}

const StyledDiv = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 50%;
  background-color: #fff;
  box-shadow: 0 0 24px rgba(0, 0, 0, 0.16);
  min-width: 500px;
  border-radius: 5px;
`;

const displayContent = {
  display: 'flex',
};

const displayflexend = {
  display: 'flex',
  justifyContent: 'flex-end',
  alignItems: 'center',
  height: '50%',
  gap: '5%',
  background: 'white',
  px: 2,
  py: 2,
  borderRadius: '0 0 10px 10px ',
  borderTop: '1px solid #bdbdbd',
};
