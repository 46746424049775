// UI
import { Typography, Box } from '@mui/material';
import { ReactComponent as AngleLeftIcon } from 'assets/icons/angle_left.svg';
import { useHistory } from 'react-router-dom';

interface IPageTitle {
  children: any;
}

export function PageTitle(props: IPageTitle) {
  let history = useHistory();
  return (
    <Typography
      variant="h6"
      component="span"
      sx={{ paddingLeft: '26px', height: '30px' }}
      display="flex"
      alignItems="center"
    >
      <AngleLeftIcon onClick={history.goBack} style={{ cursor: 'pointer' }} />
      <Box component="span" style={{ paddingLeft: '6px' }}>
        {props.children}
      </Box>
    </Typography>
  );
}
