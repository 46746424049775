import { useEffect } from 'react';

import { Container } from 'app/components/Container';
import { Content, Wrapper } from 'app/components/Wrapper';
import { Loader } from 'app/components/Loader';
import { Helmet } from 'react-helmet-async';

import useMember from '../MemberPage/useMember';

import { useGlobalSlice } from '../GlobalContainer/slice';
import { useDispatch } from 'react-redux';

import { checkIsRequesting } from 'utils/helper';
import { useForm } from 'react-hook-form';

import Path from 'config/clientPath';
import { RequestStatus } from 'constants/API';
import { useHistory } from 'react-router-dom';
import BasicInfo from '../MemberPage/components/BasicInfo';
import { Paper } from '@mui/material';

export function MemberCreatePage() {
  const dispatch = useDispatch();
  const history = useHistory();
  const { reset } = useForm();
  const {
    getDepartmentStatus,
    getProjectRoleListStatus,
    getLevelsStatus,
    addMemberStatus,
    messAddMember,
    resetStatus,
    resetCreateStatusRequest,
    getDepartment,
    getRoles,
    getLevels,
  } = useMember();

  const { setBreadcumbs, setErrorMessages, setSuccessMessages } =
    useGlobalSlice().actions;

  const isLoading = checkIsRequesting([
    getDepartmentStatus,
    getProjectRoleListStatus,
    getLevelsStatus,
    addMemberStatus,
  ]);

  useEffect(() => {
    dispatch(
      setBreadcumbs([
        { title: 'Members', path: Path.MEMBERS },
        { title: 'Add' },
      ]),
    );
    getDepartment();
    getRoles();
    getLevels();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (addMemberStatus === RequestStatus.SUCCESS) {
      dispatch(setSuccessMessages([messAddMember]));
      reset();
      resetStatus();
      history.push(`${Path.MEMBERS}`);
    }
    if (addMemberStatus === RequestStatus.ERROR) {
      dispatch(
        setErrorMessages([
          messAddMember.detail ||
            messAddMember[Object.keys(messAddMember)[0]][0],
        ]),
      );
      resetStatus();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [addMemberStatus]);

  useEffect(
    () => () => {
      dispatch(resetCreateStatusRequest());
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    ['componentWillUnMount'],
  );

  return (
    <>
      <Helmet>
        <title>Create Member</title>
        <meta name="description" content="Create Member" />
      </Helmet>
      <Container>
        <Wrapper>
          <Loader open={isLoading} />
          <Content spacing={2}>
            <Paper
              id="add-member-form"
              sx={{ padding: '24px 100px', borderRadius: '12px' }}
            >
              <BasicInfo memberForm={useForm()} />
            </Paper>
          </Content>
        </Wrapper>
      </Container>
    </>
  );
}
