import React, { useState } from 'react';

// UI
import {
  Box,
  Stack,
  TextField,
  InputAdornment,
  InputLabel,
  FormControl,
  ToggleButtonGroup,
  ToggleButton,
  IconButton,
  FormLabel,
} from '@mui/material';
import styled from 'styled-components';
import { DatePicker } from '@mui/lab';

import {
  Search as SearchIcon,
  ChevronLeft,
  ChevronRight,
} from '@mui/icons-material';
import {
  CalendarViewMonth,
  ShowChart as ShowChartIcon,
} from '@mui/icons-material';

// Redux & Sagas
import useMember from '../../MemberPage/useMember';
// Library
import { Controller, UseFormReturn } from 'react-hook-form';
import { useHistory, useLocation } from 'react-router-dom';
import Path from 'config/clientPath';
import _ from 'lodash';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import { AutoCompleteField } from 'app/components/AutocompleteField';

export function FilterBar(props: {
  isCustomer: boolean;
  months: any[];
  changeMonth: (diff_month) => void;
  viewMode: string | null;
  setViewMode: any;
  handleToggle: any;
  FilterAssignForm: UseFormReturn;
}) {
  const { isCustomer, viewMode, handleToggle, FilterAssignForm, setViewMode } =
    props;
  const { t } = useTranslation();
  const location = useLocation();
  const { handleSubmit, reset, watch, control, setValue, getValues } =
    FilterAssignForm;
  const params = new URLSearchParams(window.location.search);
  const [keyword, setKeyword] = useState<string>('');

  const { departments, member_role_list } = useMember();

  const searchForm = React.useRef<HTMLFormElement>();
  const history = useHistory();

  const handSearch = formData => {
    const _formData = {
      ...formData,
      tab: params.get('tab'),
      roles: formData.roles?.map(v => v.id),
      departments: formData.departments?.map(v => v.id),
      month: formData?.month?.format('YYYY-MM'),
    };
    let qs = Object.keys(_formData)
      .map(key => (_formData[key] ? key + '=' + _formData[key] : ''))
      .filter(o => o)
      .join('&');
    history.push(
      `${isCustomer ? Path.ASSIGNABLE : Path.ASSIGNMENT_SUMMARY}?${qs}`,
    );
  };

  const changeMonth = diff_month => {
    setValue(
      'month',
      (getValues('month') || moment()).clone().add(diff_month, 'month'),
    );
  };

  React.useEffect(() => {
    setKeyword(params.get('search') || '');
    setViewMode(params?.get('tab') ?? 'NORMAL_TAB');
    if (!location.search) {
      FilterAssignForm.setValue('roles', []);
      FilterAssignForm.setValue('departments', []);
    }
    if (member_role_list && departments) {
      reset({
        search: encodeURIComponent(params.get('search') || ''),
        roles: _.filter(member_role_list, item =>
          _.includes(params.get('roles')?.split(','), item.id.toString()),
        ),
        departments: _.filter(departments, item =>
          _.includes(params.get('departments')?.split(','), item.id.toString()),
        ),
        month: watch('month'),
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [member_role_list, departments, location.search]);

  React.useEffect(() => {
    const subscription = watch((value, { name, type }) => {
      if (name === 'month') {
        requestSubmit();
      }
    });
    return () => subscription.unsubscribe();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [watch]);

  const requestSubmit = () => {
    setTimeout(() => searchForm.current?.requestSubmit(), 10);
  };

  return (
    <Box
      flexDirection="row"
      component="form"
      method="GET"
      ref={searchForm}
      onSubmit={handleSubmit(handSearch)}
      paddingTop={2}
    >
      <Stack
        direction="row"
        spacing={2}
        alignItems="center"
        position="absolute"
        top={0}
        right={0}
      >
        <InputLabel>{t('View mode')}:</InputLabel>
        <ToggleButtonGroup value={viewMode} exclusive onChange={handleToggle}>
          <ToggleButton value="NORMAL_TAB" aria-label="account">
            <ShowChartIcon />
          </ToggleButton>
          <ToggleButton value="PROJECT_TAB" aria-label="table">
            <CalendarViewMonth />
          </ToggleButton>
        </ToggleButtonGroup>
      </Stack>
      <Box display="flex" justifyContent="space-between">
        <Stack direction="row" spacing={2} alignItems="center">
          <Stack direction="row" alignItems="center" spacing={1}>
            <InputLabel>{t('Roles')}: </InputLabel>
            <BoxSearch>
              <AutoCompleteField
                nameField="roles"
                multiple
                limitTags={1}
                options={member_role_list}
                useForm={FilterAssignForm}
                isFieldDiff="id"
                callApi={() => searchForm.current?.requestSubmit()}
              />
            </BoxSearch>
          </Stack>

          <Stack direction="row" alignItems="center" spacing={2}>
            <FormLabel>{t('Departments')}: </FormLabel>
            <BoxSearch>
              <AutoCompleteField
                nameField="departments"
                multiple
                limitTags={1}
                options={departments}
                useForm={FilterAssignForm}
                isFieldDiff="id"
                callApi={() => searchForm.current?.requestSubmit()}
              />
            </BoxSearch>
          </Stack>
          {viewMode === 'PROJECT_TAB' && (
            <Stack direction="row" alignItems="center">
              <InputLabel>{t('Month')}: </InputLabel>
              <IconButton
                onClick={() => {
                  changeMonth(-1);
                }}
              >
                <ChevronLeft />
              </IconButton>
              <FormControl margin="dense" sx={{ width: 115 }}>
                <Controller
                  control={control}
                  name="month"
                  render={({ field: { onChange, value } }) => {
                    return (
                      <DatePicker
                        views={['month', 'year']}
                        value={value}
                        onChange={e => {
                          onChange(e);
                        }}
                        inputFormat="YYYY/MM"
                        renderInput={params => (
                          <TextField
                            {...params}
                            fullWidth
                            hiddenLabel
                            size="small"
                            inputProps={{
                              ...params.inputProps,
                              readOnly: true,
                            }}
                          />
                        )}
                      />
                    );
                  }}
                />
              </FormControl>
              <IconButton
                onClick={() => {
                  changeMonth(1);
                }}
              >
                <ChevronRight />
              </IconButton>
            </Stack>
          )}
        </Stack>

        <Stack direction="row" alignItems="center" spacing={2}>
          <Stack direction="row" alignItems="center">
            <TextField
              type="search"
              placeholder={t('Name')}
              aria-label="Search"
              size="small"
              sx={{
                background: '#fff',
              }}
              onChange={e => setKeyword(e?.target.value)}
              defaultValue={params.get('search')}
              value={keyword}
              onKeyUp={event => {
                if (event.key === 'Enter') {
                  params.set('search', keyword);
                  history.push(
                    `${
                      isCustomer ? Path.ASSIGNABLE : Path.ASSIGNMENT_SUMMARY
                    }?${params.toString()}`,
                  );
                }
              }}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon />
                  </InputAdornment>
                ),
              }}
            />
          </Stack>
        </Stack>
      </Box>
    </Box>
  );
}

export default FilterBar;

const BoxSearch = styled(Box)`
  .MuiOutlinedInput-root {
    width: 200px;
  }
`;
