import { RequestStatus } from 'constants/API';
import { DepartmentState } from './types';
import { createSlice } from 'utils/@reduxjs/toolkit';
import { PayloadAction } from '@reduxjs/toolkit';
import { useInjectReducer, useInjectSaga } from 'utils/redux-injectors';
import DepartmentSaga from './saga';

export const initialState: DepartmentState = {
  departments: null,
  treeDepartments: null,
  getDepartmentStatus: RequestStatus.IDLE,
  getTreeDepartmentStatus: RequestStatus.IDLE,
  createDepartmentStatus: RequestStatus.IDLE,
  updateDepartmentStatus: RequestStatus.IDLE,
  deleteDepartmentStatus: RequestStatus.IDLE,
  errorMess: [],
};

const slice = createSlice({
  name: 'department',
  initialState,
  reducers: {
    getDepartmentRequest: (state, action: PayloadAction<any>) => {
      if (action) state.getDepartmentStatus = RequestStatus.REQUESTING;
    },
    getDepartmentSuccess: (state, action: PayloadAction<any>) => {
      state.getDepartmentStatus = RequestStatus.SUCCESS;
      state.departments = action.payload;
    },
    getDepartmentFail: (state, action: PayloadAction<any>) => {
      state.getDepartmentStatus = RequestStatus.ERROR;
    },

    getTreeDepartmentRequest: (state, action: PayloadAction<any>) => {
      if (action) state.getTreeDepartmentStatus = RequestStatus.REQUESTING;
    },
    getTreeDepartmentSuccess: (state, action: PayloadAction<any>) => {
      state.getTreeDepartmentStatus = RequestStatus.SUCCESS;
      state.treeDepartments = action.payload;
    },
    getTreeDepartmentFail: (state, action: PayloadAction<any>) => {
      state.getTreeDepartmentStatus = RequestStatus.ERROR;
    },
    // create
    createDepartmentRequest: (state, action: PayloadAction<any>) => {
      if (action) state.createDepartmentStatus = RequestStatus.REQUESTING;
    },
    createDepartmentSuccess: (state, action: PayloadAction<any>) => {
      if (action) state.createDepartmentStatus = RequestStatus.SUCCESS;
    },
    createDepartmentFail: (state, action: PayloadAction<any>) => {
      state.createDepartmentStatus = RequestStatus.ERROR;
    },
    // update
    updateDepartmentRequest: (state, action: PayloadAction<any>) => {
      if (action) state.updateDepartmentStatus = RequestStatus.REQUESTING;
    },
    updateDepartmentSuccess: (state, action: PayloadAction<any>) => {
      if (action) state.updateDepartmentStatus = RequestStatus.SUCCESS;
    },
    updateDepartmentFail: (state, action: PayloadAction<any>) => {
      state.updateDepartmentStatus = RequestStatus.ERROR;
    },
    // delete
    deleteDepartmentRequest: (state, action: PayloadAction<any>) => {
      if (action) state.deleteDepartmentStatus = RequestStatus.REQUESTING;
    },
    deleteDepartmentSuccess: (state, action: PayloadAction<any>) => {
      state.deleteDepartmentStatus = RequestStatus.SUCCESS;
    },
    deleteDepartmentFail: (state, action: PayloadAction<any>) => {
      state.deleteDepartmentStatus = RequestStatus.ERROR;
    },

    // reset status
    resetDepartmentRequestStatus: state => {
      state.departments = null;
      state.treeDepartments = null;
      state.getDepartmentStatus = RequestStatus.IDLE;
      state.getTreeDepartmentStatus = RequestStatus.IDLE;
      state.createDepartmentStatus = RequestStatus.IDLE;
      state.updateDepartmentStatus = RequestStatus.IDLE;
      state.deleteDepartmentStatus = RequestStatus.IDLE;
      state.errorMess = [];
    },
  },
});

export default slice.actions;

export const useDepartmentSlice = () => {
  useInjectReducer({ key: slice.name, reducer: slice.reducer });
  useInjectSaga({ key: slice.name, saga: DepartmentSaga });
  return { actions: slice.actions };
};
