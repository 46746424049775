import * as React from 'react';

// UI
import { styled as MUIstyled, Theme, CSSObject } from '@mui/material/styles';
import {
  IconButton,
  Divider,
  Toolbar,
  Typography,
  Stack,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Collapse,
  Drawer as MuiDrawer,
} from '@mui/material';

import { ExpandLess, ExpandMore } from '@mui/icons-material';

import { ReactComponent as Logo } from 'assets/logo.svg';
import { ReactComponent as MenuOpenIcon } from 'assets/icons/sidebar/menu-open.svg';
import { ReactComponent as MenuCloseIcon } from 'assets/icons/sidebar/menu-close.svg';

// Redux & Sagas
import { useDispatch, useSelector } from 'react-redux';
import { useGlobalSlice } from 'app/pages/GlobalContainer/slice';
import { selectGlobal } from 'app/pages/GlobalContainer/slice/selectors';
import { useRole } from 'utils/role-hook';

// Library
import styled from 'styled-components/macro';
import MENU_LIST from 'constants/menu';
import UISettings from 'styles/setting';
import Path from 'config/clientPath';
import { NavLink } from 'react-router-dom';

const openedMixin = (theme: Theme): CSSObject => ({
  width: UISettings.drawerWidth,
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: 'hidden',
  '.brand-logo': {
    width: 136,
    transition: theme.transitions.create(['width', 'opacity'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
});

const closedMixin = (theme: Theme): CSSObject => ({
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: 'hidden',
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up('sm')]: {
    width: `calc(${theme.spacing(9)} + 1px)`,
  },
  '.brand-logo': {
    transition: theme.transitions.create(['width', 'opacity'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    width: 0,
    opacity: 0,
  },
});

const Drawer = MUIstyled(MuiDrawer, {
  shouldForwardProp: prop => prop !== 'open',
})(({ theme, open }) => ({
  width: UISettings.drawerWidth,
  flexShrink: 0,
  whiteSpace: 'nowrap',
  boxSizing: 'border-box',
  ...(open && {
    ...openedMixin(theme),
    '& .MuiDrawer-paper': openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    '& .MuiDrawer-paper': closedMixin(theme),
  }),
}));

export function Sidebar() {
  const dispatch = useDispatch();
  const { setIsShowSidebar } = useGlobalSlice().actions;

  const { isShowSidebar } = useSelector(selectGlobal);

  const { hasRole, hasOneRole } = useRole();

  const [expandStatus, setExpandStatus] = React.useState<{
    [key: string]: boolean;
  }>({});

  const toggleSidebar = () => {
    dispatch(setIsShowSidebar(!isShowSidebar));
  };

  const renderECIcon = (key: string) => {
    return expandStatus[key] ? <ExpandLess /> : <ExpandMore />;
  };

  const renderListMenuProps = (it, key: string) => {
    const inNormalProps = {
      component: NavLink,
      to: it.path,
    };

    const inChildProps = {
      onClick: () => {
        setExpandStatus(prev => {
          return {
            ...prev,
            [key]: !prev[key],
          };
        });
      },
    };

    if (!isShowSidebar) {
      inChildProps['component'] = inNormalProps.component;
      inChildProps['to'] = inNormalProps.to;
    }

    return it.items ? inChildProps : inNormalProps;
  };

  const isActiveMenu = it => {
    return (
      (it.useHasOneRole && hasOneRole(it.roles, it.useRegex)) ||
      (!it.useHasOneRole && hasRole(it.role, it.useRegex))
    );
  };

  return (
    <SidebarContainer
      variant="permanent"
      open={isShowSidebar}
      sx={{
        flexShrink: 0,
        '& .MuiDrawer-paper': {
          width: isShowSidebar
            ? UISettings.drawerWidth
            : UISettings.drawerCollapsedWidth,
          overflowX: 'hidden',
        },
      }}
    >
      <Toolbar
        component={Stack}
        direction="row"
        className="toolbar"
        variant="dense"
        justifyContent="space-between"
      >
        <Typography
          component={NavLink}
          to={Path.HOME}
          className="brand-logo"
          color="inherit"
        >
          <Logo />
        </Typography>
        <IconButton onClick={toggleSidebar}>
          {isShowSidebar ? <MenuCloseIcon /> : <MenuOpenIcon />}
        </IconButton>
      </Toolbar>
      <Divider />
      <List>
        {MENU_LIST.map((it, idx) => {
          const key = `sidebar_listitem_${idx}`;
          let menu: any = null;
          if (isActiveMenu(it)) {
            menu = (
              <ListItem key={key} button {...renderListMenuProps(it, key)}>
                <ListItemIcon>{it.icon}</ListItemIcon>
                <ListItemText primary={it.menuName}></ListItemText>
                {it.items && renderECIcon(key)}
              </ListItem>
            );
          }

          const subMenu = it.items?.map((sub, subIdx) => {
            return (
              isActiveMenu(sub) && (
                <ListItem
                  key={`sidebar_listitem_${idx}_${subIdx}`}
                  button
                  component={NavLink}
                  to={sub.path}
                >
                  <ListItemText primary={sub.menuName}></ListItemText>
                </ListItem>
              )
            );
          });

          const components = [menu];

          if (isShowSidebar && subMenu?.length) {
            components.push(
              <Collapse in={!expandStatus[key]} timeout="auto" unmountOnExit>
                <List component="div" disablePadding>
                  {subMenu}
                </List>
              </Collapse>,
            );
          }

          return components;
        })}
      </List>
    </SidebarContainer>
  );
}

const SidebarContainer = styled(Drawer)`
  .toolbar {
    color: #fff !important;
    padding: 0 10px;
    .brand-logo {
      svg {
        height: 36px;
      }
    }
    .MuiIconButton-root {
      svg path {
        fill: #fff;
      }
    }
  }

  & .MuiDrawer-paper {
    background: #1e1e2d;
    color: #bfbfbf;
    .MuiListItem-button {
      &:hover,
      &.active {
        background-color: #2e3343;
        color: #fff;
        svg path {
          fill: #fff;
        }
      }
      .MuiListItemIcon-root {
        color: inherit;
        min-width: 36px;
      }
    }
  }
  .MuiCollapse-wrapper .MuiListItem-root {
    padding-left: 48px;
  }
`;

export default Sidebar;
