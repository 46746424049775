import {
  Autocomplete,
  FormControl,
  IconButton,
  TableCell,
  TableRow,
  TextField,
} from '@mui/material';
import { Controller } from 'react-hook-form';
import { ReactComponent as DeleteIcon } from 'assets/icons/button/delete.svg';
import { selectTag } from 'app/pages/TagPage/slice/selectors';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { selectTimesheet } from '../slice/selectors';
import { parseNumber } from 'utils/helper';

interface ITaskRow {
  taskIdx: number;
  control: any;
  errors: any;
  removeTaskLog: (index: number) => void;
  watch: any;
  reset: any;
}

export default function TaskRow(props: ITaskRow) {
  const { taskIdx, control, errors, removeTaskLog, watch, reset } = props;
  const tagSlice = useSelector(selectTag);
  const { assignProjects } = useSelector(selectTimesheet);
  const { t } = useTranslation();
  const disableButtonDelete = watch().logs.length <= 1;

  const handleTotalHours = async (
    e: React.ChangeEvent<HTMLInputElement>,
    onChange: Function,
  ) => {
    await onChange(e);
    let formData = watch();
    const totalHours = formData?.logs?.reduce(
      (acc, log) => acc + Number(log.hours || 0),
      0,
    );

    reset(
      {
        ...formData,
        totalHours: Number.isInteger(totalHours)
          ? totalHours
          : parseNumber(totalHours),
      },
      {
        keepErrors: true,
        keepDirty: true,
        keepIsSubmitted: false,
        keepTouched: false,
        keepIsValid: false,
        keepSubmitCount: false,
      },
    );
  };

  return (
    <>
      <TableRow>
        <TableCell sx={{ minWidth: 20 }} width={20}>
          {taskIdx + 1}
        </TableCell>
        <TableCell sx={{ minWidth: 100, maxWidth: 100 }} width={100}>
          <FormControl fullWidth>
            <Controller
              control={control}
              name={`logs[${taskIdx}].project`}
              rules={{
                required: t('This field is required') as string,
              }}
              render={({ field }) => {
                const { onChange } = field;
                return (
                  <Autocomplete
                    {...field}
                    isOptionEqualToValue={(option, value) => {
                      return value?.id && value?.id === option?.id;
                    }}
                    options={assignProjects || []}
                    autoSelect
                    getOptionLabel={it => it?.name || ''}
                    onChange={(e, newValue) => {
                      onChange(newValue);
                    }}
                    renderInput={params => (
                      <TextField
                        {...params}
                        size="small"
                        variant="outlined"
                        error={errors?.logs && errors?.logs[taskIdx]?.project}
                        helperText={
                          errors?.logs &&
                          errors?.logs[taskIdx]?.project?.message
                        }
                        InputProps={{
                          ...params.InputProps,
                          readOnly: true,
                        }}
                      />
                    )}
                  />
                );
              }}
            />
          </FormControl>
        </TableCell>
        <TableCell sx={{ minWidth: 100 }} width={100}>
          <FormControl fullWidth>
            <FormControl fullWidth>
              <Controller
                control={control}
                name={`logs[${taskIdx}].ticketId`}
                rules={{
                  required: t('This field is required') as string,
                  maxLength: {
                    value: 255,
                    message: t('Maximum length is 255 characters'),
                  },
                }}
                render={({ field }) => {
                  const { onChange, value } = field;
                  return (
                    <TextField
                      size="small"
                      fullWidth
                      variant="outlined"
                      onChange={onChange}
                      value={value}
                      error={errors?.logs && errors?.logs[taskIdx]?.ticketId}
                      helperText={
                        errors?.logs && errors?.logs[taskIdx]?.ticketId?.message
                      }
                    />
                  );
                }}
              />
            </FormControl>
          </FormControl>
        </TableCell>
        <TableCell sx={{ minWidth: 200 }} width={200}>
          <FormControl fullWidth>
            <Controller
              control={control}
              name={`logs[${taskIdx}].description`}
              rules={{
                required: t('This field is required') as string,
                maxLength: {
                  value: 255,
                  message: t('Maximum length is 255 characters'),
                },
              }}
              render={({ field }) => {
                const { onChange, value } = field;
                return (
                  <TextField
                    rows={2}
                    multiline
                    size="small"
                    type="text"
                    fullWidth
                    variant="outlined"
                    onChange={onChange}
                    value={value}
                    error={errors?.logs && errors?.logs[taskIdx]?.description}
                    helperText={
                      errors?.logs &&
                      errors?.logs[taskIdx]?.description?.message
                    }
                  />
                );
              }}
            />
          </FormControl>
        </TableCell>
        <TableCell sx={{ minWidth: 100, maxWidth: 100 }} width={100}>
          <FormControl fullWidth error={'tags' in (errors || {})}>
            <Controller
              control={control}
              name={`logs[${taskIdx}].tags`}
              rules={{
                required: t('This field is required') as string,
              }}
              render={({ field }) => {
                const { onChange } = field;
                return (
                  <Autocomplete
                    {...field}
                    isOptionEqualToValue={(option, value) => {
                      return value?.id && value?.id === option?.id
                        ? true
                        : false;
                    }}
                    size="small"
                    options={tagSlice?.tagList?.data || []}
                    filterSelectedOptions
                    getOptionLabel={it => it.name}
                    onChange={(e, newValue) => {
                      onChange(newValue);
                    }}
                    renderInput={params => (
                      <TextField
                        {...params}
                        size="small"
                        margin="dense"
                        fullWidth
                        variant="outlined"
                        error={errors?.logs && errors?.logs[taskIdx]?.tags}
                        helperText={
                          errors?.logs && errors?.logs[taskIdx]?.tags?.message
                        }
                        InputProps={{
                          ...params.InputProps,
                          readOnly: true,
                        }}
                      />
                    )}
                  />
                );
              }}
            />
          </FormControl>
        </TableCell>
        <TableCell sx={{ minWidth: 100 }} width={100}>
          <FormControl fullWidth>
            <Controller
              control={control}
              name={`logs[${taskIdx}].hours`}
              rules={{
                required: t('This field is required') as string,
                validate: (value: any) => {
                  const floatValue = parseFloat(value);
                  if (isNaN(floatValue)) {
                    return t('Value must be a number') as string;
                  }
                  if (floatValue < 0.1) {
                    return t(
                      'Value must be greater than or equal to 0.1',
                    ) as string;
                  }
                  if (floatValue > 20) {
                    return t(
                      'Value must be less than or equal to 20',
                    ) as string;
                  }

                  const regex = /^\d+(\.\d{0,2})?$/; // Regex cho phép tối đa 2 chữ số sau dấu phẩy
                  if (!regex.test(value)) {
                    return t(
                      'Value must have at most 2 decimal places',
                    ) as string;
                  }
                  return true;
                },
              }}
              render={({ field }) => {
                const { onChange, value } = field;
                return (
                  <TextField
                    size="small"
                    margin="dense"
                    variant="outlined"
                    inputProps={{
                      type: 'number',
                      inputMode: 'numeric',
                      step: 0.1,
                      min: 0.1,
                    }}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                      handleTotalHours(e, onChange);
                    }}
                    value={`${value}`}
                    error={errors?.logs && errors?.logs[taskIdx]?.hours}
                    helperText={
                      errors?.logs && errors?.logs[taskIdx]?.hours?.message
                    }
                  />
                );
              }}
            />
          </FormControl>
        </TableCell>
        <TableCell
          sx={{ minWidth: 80 }}
          width={80}
          style={{ textAlign: 'center' }}
        >
          <IconButton
            disabled={disableButtonDelete}
            sx={{
              opacity: disableButtonDelete ? 0.5 : 1,
            }}
            onClick={() => {
              removeTaskLog(taskIdx);
            }}
          >
            <DeleteIcon />
          </IconButton>
        </TableCell>
      </TableRow>
    </>
  );
}
