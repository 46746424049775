import React from 'react';

// UI
import {
  Box,
  Stack,
  InputLabel,
  FormControl,
  TextField,
  InputAdornment,
  Autocomplete,
  IconButton,
} from '@mui/material';

import { Search as SearchIcon } from '@mui/icons-material';

// Redux & Sagas
import { useSelector } from 'react-redux';

// Library
import { useForm, Controller } from 'react-hook-form';
import { useHistory } from 'react-router-dom';
import Path from 'config/clientPath';
import queryString from 'query-string';
import { ICommonDataSchema } from 'types/common';
import styled from 'styled-components/macro';
import _ from 'lodash';
import DatePicker from '@mui/lab/DatePicker';
import moment from 'moment';
import { ChevronLeft, ChevronRight } from '@mui/icons-material';
import { selectTimesheet } from 'app/pages/TimesheetPage/slice/selectors';
import { RequestStatus } from 'constants/API';
import { useTranslation } from 'react-i18next';

export function FilterBar() {
  const { handleSubmit, control, watch, setValue, getValues } = useForm();
  const { t } = useTranslation();
  const [textSearch, setTextSearch] = React.useState('');
  const { allProjects, getAllProjectsStatus } = useSelector(selectTimesheet);
  const defaultProject = { id: -1, name: 'All' };
  const [selectedProject, setSelectedProject] =
    React.useState<ICommonDataSchema | null>(defaultProject);

  const searchForm = React.useRef<HTMLFormElement>();
  const history = useHistory();

  const handleSearchCommon = (formData: any, textSearch: string) => {
    let newParams = {
      ...formData,
      project: selectedProject?.id || '',
      month: formData?.month.format('YYYY-MM'),
      search: textSearch,
    };
    // remove query param when has not value or value is -1 (all value)
    const qs = queryString.stringify(_.omitBy(newParams, v => !v || v === -1));
    history.push(`${Path.TIMESHEET_REPORT_REVIEW}?${qs}`);
  };

  const handSearch = formData => {
    const params = queryString.parse(window.location.search);
    const text = params.description ?? '';
    setTextSearch(text);
    handleSearchCommon(formData, text);
  };

  const handleSearchByName = (event: React.ChangeEvent<HTMLInputElement>) => {
    const text = event?.target?.value;
    let formData = watch();
    handleSearchCommon(formData, text);
  };

  React.useEffect(() => {
    const params = queryString.parse(window.location.search);
    setValue('month', params?.month ? moment(params?.month) : moment());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  React.useEffect(() => {
    if (getAllProjectsStatus === RequestStatus.SUCCESS) {
      const params = queryString.parse(window.location.search);
      if (params?.project) {
        const project = allProjects?.find(
          v => v.id === _.toNumber(params?.project),
        );
        if (project) {
          setSelectedProject(project);
          setValue('project', project.name);
        } else {
          setSelectedProject(defaultProject);
          setValue('project', defaultProject.name);
        }
      } else {
        setSelectedProject(defaultProject);
        setValue('project', defaultProject.name);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getAllProjectsStatus]);

  const requestSubmit = () => {
    setTimeout(() => searchForm.current?.requestSubmit(), 10);
  };

  const resetProjectAndMember = () => {
    setSelectedProject(defaultProject);
  };

  const changeMonth = diff_month => {
    setValue(
      'month',
      (getValues('month') || moment()).clone().add(diff_month, 'month'),
    );
    resetProjectAndMember();
    requestSubmit();
  };

  return (
    <Box
      display="flex"
      flexDirection="row"
      justifyContent="space-between"
      component="form"
      method="GET"
      ref={searchForm}
      onSubmit={handleSubmit(handSearch)}
    >
      <Stack direction="row" alignItems="center" spacing={1}>
        <InputLabel>{t('Month')}: </InputLabel>
        <IconButton
          onClick={() => {
            changeMonth(-1);
          }}
        >
          <ChevronLeft />
        </IconButton>
        <FormControl margin="dense" sx={{ width: 115 }}>
          <Controller
            control={control}
            name="month"
            render={({ field: { onChange, value } }) => {
              return (
                <DatePicker
                  views={['month', 'year']}
                  value={value}
                  onChange={e => {
                    resetProjectAndMember();
                    onChange(e);
                    requestSubmit();
                  }}
                  inputFormat="YYYY/MM"
                  renderInput={params => (
                    <TextField
                      {...params}
                      fullWidth
                      hiddenLabel
                      size="small"
                      inputProps={{ ...params.inputProps, readOnly: true }}
                    />
                  )}
                />
              );
            }}
          />
        </FormControl>
        <IconButton
          onClick={() => {
            changeMonth(1);
          }}
        >
          <ChevronRight />
        </IconButton>

        <InputLabel>{t('Project')}: </InputLabel>
        <FormControlCustom>
          <Controller
            control={control}
            name="project"
            render={({ field }) => {
              const { onChange } = field;
              return (
                <Autocomplete
                  value={selectedProject || undefined}
                  isOptionEqualToValue={(option, value) => {
                    return value?.id && value?.id === option?.id ? true : false;
                  }}
                  renderOption={(props, option) => (
                    <Box component="li" {...props} key={`prj_${option.id}`}>
                      {option?.name}
                    </Box>
                  )}
                  options={[{ ...defaultProject }, ...allProjects]}
                  getOptionLabel={it => it?.name || ''}
                  onChange={(e, newValue) => {
                    setSelectedProject(newValue);
                    onChange(newValue?.name);
                    requestSubmit();
                  }}
                  renderInput={params => {
                    return (
                      <TextField {...params} size="small" variant="outlined" />
                    );
                  }}
                  disableClearable
                />
              );
            }}
          />
        </FormControlCustom>
      </Stack>

      <Box
        display="flex"
        flexDirection="row"
        justifyContent="space-between"
        sx={{ marginLeft: '20px' }}
      >
        <Stack
          direction="row"
          spacing={2}
          alignItems="center"
          sx={{ flexShrink: 0 }}
        >
          <TextField
            type="search"
            placeholder="Search keyword"
            aria-label="Search"
            size="small"
            value={textSearch}
            onChange={(event: any) => {
              setTextSearch(event?.target?.value);
            }}
            sx={{
              background: '#fff',
            }}
            onKeyUp={(event: any) => {
              if (event.key === 'Enter') {
                handleSearchByName(event);
              }
            }}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon />
                </InputAdornment>
              ),
            }}
          />
        </Stack>
      </Box>
    </Box>
  );
}

export default FilterBar;

const FormControlCustom = styled(FormControl)`
  width: 150px;
  & .MuiAutocomplete-hasPopupIcon.MuiAutocomplete-hasClearIcon,
  .MuiAutocomplete-root {
    .MuiOutlinedInput-root {
      padding-right: 50px !important;
    }
  }
  &.member_form {
    width: 190px;
    & .MuiAutocomplete-hasPopupIcon.MuiAutocomplete-hasClearIcon,
    .MuiAutocomplete-root {
      .MuiOutlinedInput-root {
        padding-right: 50px !important;
      }
    }
  }
`;
