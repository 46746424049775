import {
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';
import { selectRecruitments } from 'app/pages/RecruitmentsPage/slice/selectors';
import { useRecruitmentSlice } from 'app/pages/RecruitmentsPage/slice';
import { useEffect, useState } from 'react';
import { ReactComponent as EyesIcon } from 'assets/icons/candidates/eyes_icon.svg';
import { Loader } from 'app/components/Loader';
import { checkIsRequesting } from 'utils/helper';
import { RequestStatus } from 'constants/API';
import {
  retrieveUserCachedDataFromStorage,
  useGlobalSlice,
} from 'app/pages/GlobalContainer/slice';
import { INotes } from 'app/pages/RecruitmentsPage/slice/type';
import { Delete, Edit } from '@mui/icons-material';
import { COLOR_BUTTON } from 'constants/common';
import ModalDetailNote from './ModalDetailNote';
import ConfirmDelete from 'app/components/ConfirmDelete';

interface ITab4 {
  id: string;
  setIsShowModalRequest: (value: boolean) => void;
  selectedData?: INotes | null;
  setSelectedData: (value: INotes | null) => void;
  isShowModalRequest?: boolean;
}

export function Tab4(props: ITab4) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { id, setIsShowModalRequest, selectedData, setSelectedData } = props;
  const person = retrieveUserCachedDataFromStorage().profile ?? null;
  const [isShowModalDelete, setIsShowModalDelete] = useState<boolean>(false);
  const [isShowModalDetail, setIsShowModalDetail] = useState<boolean>(false);
  const { notes, getNotesStatus, updateNoteStatus, deleteNoteStatus } =
    useSelector(selectRecruitments);
  const {
    actions: { getNotesRequest, deleteNoteRequest },
  } = useRecruitmentSlice();
  const {
    actions: { setErrorMessages, setSuccessMessages },
  } = useGlobalSlice();

  const handleDeleteNote = () => {
    if (id && selectedData) {
      const formData = {
        id: id,
        note_id: selectedData?.id,
      };
      setSelectedData(null);
      setIsShowModalDelete(false);
      dispatch(deleteNoteRequest(formData));
    }
  };

  const handleCancelDeleteNote = () => {
    setSelectedData(null);
    setIsShowModalDelete(false);
  };

  useEffect(() => {
    if (updateNoteStatus === RequestStatus.SUCCESS) {
      dispatch(getNotesRequest({ id: id }));
      dispatch(setSuccessMessages([t('Update Note Success!')]));
    }
    if (updateNoteStatus === RequestStatus.ERROR) {
      dispatch(setErrorMessages([t('Update Note Error!')]));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updateNoteStatus]);

  useEffect(() => {
    if (deleteNoteStatus === RequestStatus.SUCCESS) {
      dispatch(getNotesRequest({ id: id }));
      dispatch(setSuccessMessages([t('Delete Note Success!')]));
    }
    if (deleteNoteStatus === RequestStatus.ERROR) {
      dispatch(setErrorMessages([t('Delete Note Error!')]));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [deleteNoteStatus]);

  const isLoading = checkIsRequesting([getNotesStatus, deleteNoteStatus]);

  return (
    <>
      <Loader open={isLoading} />
      <TableContainer
        sx={{ height: 'calc(100vh - 350px)', margin: '0px !important' }}
      >
        <Table stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow>
              <TableCellStyle>{t('DATE')}</TableCellStyle>
              <TableCellStyle>{t('CONTENT')}</TableCellStyle>
              <TableCellStyle>{t('CREATED BY')}</TableCellStyle>
              <TableCellStyle sx={{ textAlign: 'center' }}>
                {t('ACTION')}
              </TableCellStyle>
            </TableRow>
          </TableHead>
          <TableBody sx={{ bgcolor: 'white' }}>
            {notes?.length === 0 && (
              <TableRow>
                <TableCell colSpan={4} sx={{ textAlign: 'left' }}>
                  {t('No data')}
                </TableCell>
              </TableRow>
            )}
            {notes?.length > 0 && (
              <>
                {notes?.map((item, key) => (
                  <TableRow key={`recruitmentNote_${key}`}>
                    <TableCell>{item?.created_at}</TableCell>
                    <TableCell>
                      <Typography
                        whiteSpace="pre-line"
                        sx={{
                          maxWidth: '450px',
                          overflow: 'hidden',
                          textOverflow: 'ellipsis',
                        }}
                      >
                        {item?.content}
                      </Typography>
                    </TableCell>
                    <TableCell>{item.created_by?.full_name}</TableCell>
                    <TableCell
                      sx={{
                        textAlign: 'center',
                      }}
                    >
                      {person.id === item.created_by.id ? (
                        <>
                          <Button
                            sx={{ minWidth: 'auto' }}
                            onClick={() => {
                              setSelectedData(item);
                              setIsShowModalRequest(true);
                            }}
                          >
                            <Edit
                              sx={{
                                color: COLOR_BUTTON.DELETE_EDIT_ICON,
                              }}
                            />
                          </Button>
                          <Button
                            sx={{
                              minWidth: 'auto',
                              opacity:
                                person.id !== item.created_by.id ? 0.5 : 1,
                              pointerEvents:
                                person.id !== item.created_by.id
                                  ? 'none'
                                  : 'auto',
                              cursor:
                                person.id !== item.created_by.id
                                  ? 'not-allowed'
                                  : 'pointer',
                            }}
                            onClick={() => {
                              setSelectedData(item);
                              setIsShowModalDelete(true);
                            }}
                          >
                            <Delete
                              sx={{
                                color: COLOR_BUTTON.DELETE_EDIT_ICON,
                              }}
                            />
                          </Button>
                        </>
                      ) : (
                        <>
                          <Button
                            sx={{ minWidth: 'auto' }}
                            onClick={() => {
                              setSelectedData(item);
                              setIsShowModalDetail(true);
                            }}
                          >
                            <EyesIcon />
                          </Button>
                          <Button
                            sx={{
                              minWidth: 'auto',
                              opacity:
                                person.id !== item.created_by.id ? 0.5 : 1,
                              pointerEvents:
                                person.id !== item.created_by.id
                                  ? 'none'
                                  : 'auto',
                              cursor:
                                person.id !== item.created_by.id
                                  ? 'not-allowed'
                                  : 'pointer',
                            }}
                            onClick={() => {
                              setSelectedData(item);
                              setIsShowModalDelete(true);
                            }}
                          >
                            <Delete
                              sx={{
                                color: COLOR_BUTTON.DELETE_EDIT_ICON,
                              }}
                            />
                          </Button>
                        </>
                      )}
                    </TableCell>
                  </TableRow>
                ))}
              </>
            )}
          </TableBody>
        </Table>

        <ConfirmDelete
          open={isShowModalDelete}
          onClose={handleCancelDeleteNote}
          onConfirm={handleDeleteNote}
          title="note"
          name={''}
        />

        <ModalDetailNote
          showDetail={isShowModalDetail}
          setDetailShow={setIsShowModalDetail}
          data={selectedData}
        />
      </TableContainer>
    </>
  );
}

const TableCellStyle = styled(TableCell)`
  background: #f3f6f9;
  color: #687182 !important;
  font-size: 14px;
  font-weight: bold;
`;
