import createMuiTheme from '@mui/material/styles/createTheme';
import { Colors } from 'app/pages/HomePage/Components/Variables';

const MuiTheme = createMuiTheme({
  components: {
    // MuiInputBase: {
    //   styleOverrides: {
    //     input: {
    //       padding: '10px',
    //     },
    //     multiline: {
    //       '& .MuiInputBase-inputMultiline': {
    //         padding: '0',
    //       },
    //     },
    //   },
    // },
    // MuiOutlinedInput: {
    //   styleOverrides: {
    //     input: {
    //       padding: '10px',
    //     },
    //   },
    // },
    // MuiButton: {
    //   variants: [
    //     {
    //       props: { variant: 'contained', color: 'secondary' },
    //       style: {
    //         backgroundColor: '#EFEFEF',
    //         color: '#000',
    //         '&:hover': {
    //           backgroundColor: '#02020278',
    //         },
    //       },
    //     },
    //   ],
    // },
    MuiFormLabel: {
      styleOverrides: {
        asterisk: { color: Colors.red },
      },
    },
  },
});

export default MuiTheme;
