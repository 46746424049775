import React, { useEffect, useState } from 'react';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import styled from 'styled-components/macro';
import _ from 'lodash';

import {
  Box,
  IconButton,
  Menu,
  MenuItem,
  Stack,
  Typography,
} from '@mui/material';
import { Helmet } from 'react-helmet-async';
import { Container } from 'app/components/Container';
import { Content, Wrapper } from 'app/components/Wrapper';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import { Loader, LoaderGenCV } from 'app/components/Loader';

import { useDispatch } from 'react-redux';
import { useGlobalSlice } from '../GlobalContainer/slice';

import Path from 'config/clientPath';
import { AvatarMember } from 'app/components/Avatar';
import { CANDIDATE_STATUS, SPACING_ORDER } from 'constants/common';
import { checkIsRequesting, saveFile } from 'utils/helper';
import { RequestStatus } from 'constants/API';
import { useTranslation } from 'react-i18next';
import useRecruitment from '../RecruitmentsPage/useRecruitment';
import FilterBar from './components/FilterBar';
import { ListViewMode } from './components/ListViewMode';
import ModalUpload from './components/ModalUploadIndex';
import ConfirmDelete from 'app/components/ConfirmDelete';
import { RecruitmentCandidateSchema } from '../RecruitmentsPage/slice/type';
import { Colors } from '../HomePage/Components/Variables';

interface ColumnType {
  name: string;
  items: RecruitmentCandidateSchema[];
}
interface ColumnsType {
  [columnId: string]: ColumnType;
}

export function JobDetail() {
  const [currentTab, setCurrentTab] = useState<string | null>('DND_TAB');
  const dispatch = useDispatch();
  const history = useHistory();
  const { setBreadcumbs, setSuccessMessages, setErrorMessages } =
    useGlobalSlice().actions;
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const { id }: { id: string } = useParams();
  const [isOpen, setIsOpen] = useState(false);
  const [cvInfo, setCVInfo] = useState<RecruitmentCandidateSchema>();
  const [sourceCV, setSourceCV] = useState<string>();
  const [isOpenModal, setIsOpenModal] = useState(false);
  const [statusJob, setStatusJob] = useState<string>();
  const { t } = useTranslation();
  const [isShow, setIsShow] = useState<boolean>(false);
  const [title, setTitle] = useState('');

  const {
    recruitmentCandidate,
    reCandidate,
    recCandidateState,
    updateReCandidate,
    deleteReCandidate,
    deleteReCandidateState,
    resetJobDetail,
    getSourceCVStatus,
    updateReCandidateState,
    uploadCVsStatus,
    errorMess,
    createCandidateStatus,
    updateCandidateStatus,
    getSkillsTechnial,
    getLanguages,
    resetReCandidate,
    getSourceCV,
  } = useRecruitment();

  const columnsFromBackend = Object.keys(CANDIDATE_STATUS).reduce(
    (columns, key) => {
      const status = CANDIDATE_STATUS[key];

      columns[status] = {
        name: status,
        items: recruitmentCandidate?.data?.filter(
          record => record.status === parseInt(key),
        ),
      };

      return columns;
    },
    {},
  );

  const [columns, setColumns] = useState<ColumnsType>(columnsFromBackend);

  const isLoading = checkIsRequesting([
    recCandidateState,
    deleteReCandidateState,
    getSourceCVStatus,
    updateCandidateStatus,
  ]);
  const loadingUploadcv = checkIsRequesting([uploadCVsStatus]);

  const onDragEnd = (result, columns, setColumns) => {
    if (!result.destination) return;
    const { source, destination, draggableId } = result;
    const items = columns[destination.droppableId].items;
    const orderedItems = _.orderBy(items, ['order'], ['desc']);
    const itemDrag = _.omit(
      recruitmentCandidate?.data?.find(v => v.id === +draggableId),
      [
        'job',
        'language_proficiency',
        'uploaded_by',
        'skills',
        'profile_picture',
        'source',
        'cv_file',
      ],
    );

    //check if moving from one column to another
    if (source.droppableId !== destination.droppableId) {
      const sourceColumn = columns[source.droppableId];
      const destColumn = columns[destination.droppableId];
      const sourceItems = [...sourceColumn.items];
      const destItems = [...destColumn.items];
      const [removed] = _.orderBy(sourceItems, ['order'], ['desc']).splice(
        source.index,
        1,
      );
      _.remove(sourceItems, item => item.id === +draggableId);
      destItems.splice(destination.index, 0, removed);

      const des1 = orderedItems[destination.index]?.order;
      const des2 =
        destination.index !== 0
          ? orderedItems[destination.index - 1]?.order
          : null;

      let positionOrder: number = 0;
      if (!_.isEqual(destination, source)) {
        if (des1 & des2) {
          positionOrder = _.random(des1 + 1, des2 - 1);
        } else if (des1 && !des2) {
          positionOrder = _.random(des1 + 1, des1 + SPACING_ORDER);
        } else if (!des1 && des2) {
          positionOrder = _.random(des2 - SPACING_ORDER, des2);
        } else if (!des1 && !des2) {
          positionOrder = removed?.order;
        }
      }

      const backupColumns = { ...columns };
      const upadteItems = destItems.map(item =>
        item.id === +draggableId
          ? {
              ...item,
              order: positionOrder,
            }
          : item,
      );
      setColumns({
        ...columns,
        [source.droppableId]: {
          ...sourceColumn,
          items: sourceItems,
        },
        [destination.droppableId]: {
          ...destColumn,
          items: upadteItems,
        },
      });

      updateReCandidate({
        ...itemDrag,
        id: result.draggableId,
        status: Object.keys(CANDIDATE_STATUS).find(
          key => CANDIDATE_STATUS[key] === destination.droppableId,
        ),
        order: positionOrder,
      });
      if (updateReCandidateState === RequestStatus.ERROR) {
        setColumns(backupColumns);
      }
    } else {
      // Handles drag and drop in the same column
      const column = columns[source.droppableId];
      const copiedItems = [...column.items];
      const [removed] = copiedItems.splice(source.index, 1);
      copiedItems.splice(destination.index, 0, removed);
      const des1 = orderedItems[destination.index]?.order;
      const des2 =
        destination.index !== 0
          ? source.index > destination.index
            ? orderedItems[destination.index - 1]?.order
            : orderedItems[destination.index + 1]?.order
          : null;
      const orderSource = orderedItems[source.index]?.order;
      let positionOrder: number = 0;
      let positionSource: number = 0;
      if (!_.isEqual(destination, source)) {
        if (des1 & des2) {
          const min = Math.min(des1, des2);
          const max = Math.max(des1, des2);
          if (Math.abs(des1 - des2) === 1) {
            positionOrder = des1;
            positionSource = orderSource;
          } else positionOrder = _.random(min + 1, max - 1);
        } else if (des1 && !des2) {
          positionOrder =
            destination.index !== 0
              ? _.random(des1 - SPACING_ORDER, des1)
              : _.random(des1 + 1, des1 + SPACING_ORDER);
        }
      }

      let upadteItems = _.clone(copiedItems);
      if (positionSource === 0) {
        upadteItems = copiedItems.map(item =>
          item.id === +draggableId
            ? {
                ...item,
                order: positionOrder,
              }
            : item,
        );
      } else {
        upadteItems = _.map(copiedItems, item => {
          if (item.id === +draggableId) {
            return _.assign({}, item, { order: positionOrder });
          }
          if (item.id === orderedItems[destination.index]?.id) {
            return _.assign({}, item, { order: positionSource });
          }
          return item;
        });
      }

      if (!_.isEqual(destination, source)) {
        setColumns({
          ...columns,
          [source.droppableId]: {
            ...column,
            items: upadteItems,
          },
        });

        updateReCandidate({
          id: draggableId,
          order: positionOrder,
        });
        if (positionSource !== 0) {
          updateReCandidate({
            id: orderedItems[destination.index]?.id,
            order: positionSource,
          });
        }
      }
    }
  };

  useEffect(() => {
    if (title) {
      dispatch(
        setBreadcumbs([
          { title: t('Jobs List'), path: Path.JOB_LIST },
          { title: title || '' },
        ]),
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [title]);

  useEffect(() => {
    getSkillsTechnial();
    getLanguages();
    getSourceCV();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    reCandidate({
      job: id,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentTab]);

  useEffect(() => {
    if (!anchorEl || isShow) {
      setSourceCV('');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [anchorEl]);

  useEffect(() => {
    if (recruitmentCandidate) {
      setColumns(columnsFromBackend);
      setTitle(recruitmentCandidate?.job.title);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [recruitmentCandidate]);

  useEffect(() => {
    if (
      createCandidateStatus === RequestStatus.SUCCESS ||
      updateCandidateStatus === RequestStatus.SUCCESS
    ) {
      setIsShow(false);
      dispatch(
        setSuccessMessages([
          createCandidateStatus === RequestStatus.SUCCESS
            ? 'Create Candidate Success!'
            : 'Update Candidate Success!',
        ]),
      );
      setIsOpenModal(false);
      reCandidate({
        job: id,
      });
      resetJobDetail();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [createCandidateStatus, updateCandidateStatus]);

  useEffect(() => {
    if (deleteReCandidateState === RequestStatus.SUCCESS) {
      dispatch(setSuccessMessages(['Delete Recruitment Candidate Success!']));
      reCandidate({
        job: id,
      });
    }
    if (deleteReCandidateState === RequestStatus.ERROR) {
      dispatch(setErrorMessages(['Oops..Something error!']));
    }

    if (updateReCandidateState === RequestStatus.SUCCESS) {
      dispatch(setSuccessMessages(['Update status candidate successful!']));
      if (currentTab === 'LIST_TAB') {
        reCandidate({
          job: id,
        });
      }
    }
    if (updateReCandidateState === RequestStatus.ERROR) {
      dispatch(setErrorMessages(['Oops..Something error!']));
    }
    if (uploadCVsStatus === RequestStatus.ERROR) {
      dispatch(
        setErrorMessages([errorMess?.detail || 'Oops..Something error!']),
      );
    }
    if (uploadCVsStatus === RequestStatus.SUCCESS) {
      setIsOpenModal(false);
      setIsShow(true);
    }

    if (
      updateReCandidateState === RequestStatus.SUCCESS ||
      deleteReCandidateState === RequestStatus.SUCCESS ||
      uploadCVsStatus === RequestStatus.SUCCESS
    ) {
      resetJobDetail();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [deleteReCandidateState, updateReCandidateState, uploadCVsStatus]);

  React.useEffect(
    () => () => {
      resetReCandidate();
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    ['componentWillUnMount'],
  );

  return (
    <>
      <Helmet>
        <title>{title}</title>
        <meta name="description" content="Job Detail" />
      </Helmet>
      <Container>
        <Wrapper>
          <Loader open={isLoading} />
          <LoaderGenCV open={loadingUploadcv} />
          <Content spacing={2}>
            <Typography variant="h6" component="h6">
              {title}
            </Typography>
            <FilterBar currentTab={currentTab} setCurrentTab={setCurrentTab} />
            {currentTab === 'DND_TAB' && (
              <>
                <Stack
                  direction="row"
                  overflow="auto"
                  height="calc(100vh - 200px)"
                >
                  <DragDropContext
                    onDragEnd={result => onDragEnd(result, columns, setColumns)}
                  >
                    {Object.entries(columns).map(
                      ([columnId, column], index: number) => {
                        return (
                          <Box
                            display="flex"
                            flexDirection="column"
                            alignItems="center"
                            key={columnId}
                            width="100%"
                          >
                            <TextDefault
                              className={
                                index === 0
                                  ? 'first'
                                  : index === Object.keys(columns).length - 1
                                  ? 'last'
                                  : ''
                              }
                              position="relative"
                            >
                              <Box
                                display="flex"
                                justifyContent="space-between"
                              >
                                <Stack
                                  direction="row"
                                  spacing={1}
                                  alignItems="center"
                                >
                                  <Typography fontSize="14px" fontWeight="500">
                                    {index + 1}. {CANDIDATE_STATUS[index]}
                                  </Typography>
                                  {!_.isEmpty(column.items) && (
                                    <Box
                                      display="flex"
                                      alignItems="center"
                                      justifyContent="center"
                                      width={16}
                                      height={16}
                                      borderRadius="50%"
                                      bgcolor="red"
                                    >
                                      <Typography
                                        fontSize="11px"
                                        fontWeight="600"
                                        color={Colors.white}
                                        lineHeight="16px"
                                      >
                                        {column.items?.length}
                                      </Typography>
                                    </Box>
                                  )}
                                </Stack>
                                <CloudUploadIcon
                                  style={{
                                    fill: Colors.spanishGray,
                                    cursor: 'pointer',
                                  }}
                                  onClick={() => {
                                    setIsOpenModal(true);
                                    setStatusJob(index.toString());
                                  }}
                                />
                              </Box>
                            </TextDefault>

                            <Droppable
                              droppableId={columnId}
                              key={`droppable_${columnId}`}
                            >
                              {(provided, snapshot) => {
                                return (
                                  <Box
                                    {...provided.droppableProps}
                                    ref={provided.innerRef}
                                    padding="4px"
                                    minWidth="250px"
                                    width={`calc(100% / 7)`}
                                    height="675px"
                                    style={{
                                      background: snapshot.isDraggingOver
                                        ? Colors.lightGrey
                                        : '',
                                      overflowY: 'auto',
                                    }}
                                    sx={{
                                      overflowY: 'auto',
                                      '&::-webkit-scrollbar': {
                                        width: '3px',
                                      },
                                      '&::-webkit-scrollbar-thumb': {
                                        backgroundColor: Colors.spanishGray,
                                        borderRadius: '3px',
                                      },
                                    }}
                                  >
                                    {!_.isEmpty(column?.items) &&
                                      _.orderBy(
                                        column?.items,
                                        ['order'],
                                        ['desc'],
                                      ).map((item, index) => {
                                        return (
                                          <Draggable
                                            key={`draggable_${item?.id}`}
                                            draggableId={item.id.toString()}
                                            index={index}
                                            sx={{ width: 'auto' }}
                                            style={{ width: 'auto' }}
                                          >
                                            {(provided, snapshot) => {
                                              return (
                                                <Box
                                                  padding="10px"
                                                  mb="8px"
                                                  width="max-content"
                                                  minHeight="50px"
                                                  color="black"
                                                  boxShadow="0px 4px 4px 0px rgba(0, 0, 0, 0.25)"
                                                  ref={provided.innerRef}
                                                  {...provided.draggableProps}
                                                  {...provided.dragHandleProps}
                                                  style={{
                                                    userSelect: 'none',
                                                    backgroundColor:
                                                      snapshot.isDragging
                                                        ? '#f4e9e98a'
                                                        : Colors.white,
                                                    ...provided.draggableProps
                                                      .style,
                                                  }}
                                                >
                                                  <Stack
                                                    direction="row"
                                                    spacing={1}
                                                  >
                                                    <AvatarMember
                                                      avatar={`${
                                                        item.profile_picture ||
                                                        ''
                                                      }`}
                                                      title={`${
                                                        item.full_name || ''
                                                      }`}
                                                      sx={{
                                                        width: 38,
                                                        height: 38,
                                                      }}
                                                    />
                                                    <Stack
                                                      spacing="5px"
                                                      width="100%"
                                                    >
                                                      <Box
                                                        display="flex"
                                                        justifyContent="space-between"
                                                      >
                                                        <TextSmall
                                                          color={
                                                            Colors.blueText
                                                          }
                                                        >
                                                          {item.full_name ||
                                                            '-'}
                                                        </TextSmall>

                                                        <Box
                                                          component="button"
                                                          onClick={e => {
                                                            handleClick(e);
                                                            setSourceCV(
                                                              item.cv_file,
                                                            );
                                                            setCVInfo(item);
                                                          }}
                                                          p={0}
                                                          border="none"
                                                          bgcolor="inherit"
                                                          sx={{
                                                            cursor: 'pointer',
                                                          }}
                                                        >
                                                          <IconButton>
                                                            <MoreVertIcon />
                                                          </IconButton>
                                                        </Box>
                                                      </Box>
                                                      <TextSmall>
                                                        {item.current_level ||
                                                          '-'}
                                                      </TextSmall>
                                                      <TextSmall>
                                                        {t('Applied')}:{' '}
                                                        {item.applied_date}
                                                      </TextSmall>
                                                      <TextSmall
                                                        color={Colors.blueText}
                                                      >
                                                        {item.source.name}
                                                      </TextSmall>
                                                    </Stack>
                                                  </Stack>
                                                </Box>
                                              );
                                            }}
                                          </Draggable>
                                        );
                                      })}
                                    {provided.placeholder}
                                  </Box>
                                );
                              }}
                            </Droppable>
                          </Box>
                        );
                      },
                    )}
                  </DragDropContext>

                  <Menu
                    id="basic-menu"
                    anchorEl={anchorEl}
                    open={open}
                    onClose={handleClose}
                    MenuListProps={{
                      'aria-labelledby': 'basic-button',
                    }}
                    PaperProps={{
                      style: {
                        width: 140,
                      },
                    }}
                  >
                    <MenuItem
                      onClick={() =>
                        history.push(`${Path.CANDIDATES}/${cvInfo?.id}`)
                      }
                      sx={{
                        borderBottom: `1px solid ${Colors.spanishGray}`,
                      }}
                    >
                      {t('Detail')}
                    </MenuItem>
                    <MenuItem
                      onClick={() => {
                        setIsOpen(true);
                        handleClose();
                      }}
                      sx={{
                        borderBottom: `1px solid ${Colors.spanishGray}`,
                      }}
                    >
                      {t('Delete')}
                    </MenuItem>
                    <MenuItem onClick={() => saveFile(sourceCV)}>
                      {t('Download')}
                    </MenuItem>
                  </Menu>
                </Stack>
              </>
            )}

            {currentTab === 'LIST_TAB' && (
              <ListViewMode
                setStatusJob={setStatusJob}
                setIsOpenModal={setIsOpenModal}
                setIsOpen={setIsOpen}
                handleClose={handleClose}
                setCVInfo={setCVInfo}
              />
            )}

            <ConfirmDelete
              open={isOpen}
              onClose={() => setIsOpen(false)}
              onConfirm={() => {
                deleteReCandidate({ id: cvInfo?.id });
                setIsOpen(false);
              }}
              title="candidate"
              name={cvInfo?.full_name}
            />
            <ModalUpload
              setIsOpenModal={setIsOpenModal}
              isOpenModal={isOpenModal}
              statusJob={statusJob}
            />
          </Content>
        </Wrapper>
      </Container>
    </>
  );
}

const TextDefault = styled(Typography)`
  font-size: 14px !important;
  font-weight: 500 !important;
  line-height: 20px;
  background-color: #fff;
  border: 1px solid #c1c9d2;
  width: -webkit-fill-available;
  padding: 15px 24px;
  stroke-width: 1px;
  stroke: var(--Gray-200, #c1c9d2);
  filter: drop-shadow(0px 1px 2px #fff);
  border-right: none;

  :not(.last):before {
    content: '';
    width: 30px;
    height: 23px;
    background: transparent;
    position: absolute;
    -moz-transform: rotate(65deg) skewX(40deg);
    -webkit-transform: rotate(65deg) skewX(40deg);
    transform: rotate(65deg) skewX(40deg);
    transform-origin: top right;
    top: 25px;
    margin: 0;
    right: 0;
    border-right: #c1c9d2 solid 1px;
    border-top: #c1c9d2 solid 1px;
  }

  :not(.first) {
    border-left: none;
  }

  &.last {
    border-right: 1px solid #c1c9d2;
  }
`;

const TextSmall = styled(Typography)`
  font-size: 11px !important;
  line-height: 14px;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 140px;
  white-space: nowrap;
`;
