export const PROJECT_TYPE = [
  {
    id: 1,
    name: 'LABO',
  },
  {
    id: 2,
    name: 'Project Base',
  },
];

export const PROJECT_SCOPE = [
  {
    id: 1,
    name: 'Define Spec',
  },
  {
    id: 2,
    name: 'Design',
  },
  {
    id: 3,
    name: 'Development',
  },
  {
    id: 4,
    name: 'Test',
  },
  {
    id: 5,
    name: 'Deploy',
  },
  {
    id: 6,
    name: 'Maintenance',
  },
];
