import React, { useState } from 'react';

// UI
import {
  Toolbar,
  Breadcrumbs,
  Typography,
  Stack,
  IconButton,
  Box,
  Chip,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  // InputAdornment,
  AppBar,
} from '@mui/material';
import { AvatarMember } from 'app/components/Avatar';
import {
  ChevronRight as ChevronRightIcon,
  // Search as SearchIcon,
} from '@mui/icons-material';

import { ProfileBar } from 'app/components/ProfileBar';
// import { LanguageSwitch } from 'app/components/LanguageSwitch';

// Redux & Sagas
import { useSelector } from 'react-redux';
import { selectGlobal } from 'app/pages/GlobalContainer/slice/selectors';
import { StopCircle } from '@mui/icons-material';

// Library
import _ from 'lodash';
import moment from 'moment';
import styled from 'styled-components/macro';
import { NavLink } from 'react-router-dom';
import UISettings from 'styles/setting';

// Utils
import {
  getRecordStartTime,
  removeRecordStartTime,
  setRecordDuration,
} from 'utils/localStorage';

interface ITimeCounter {
  hours: number;
  minutes: number;
  seconds: number;
}

interface INavBar {
  subToolbar?: React.ReactNode;
}

export function NavBar(props: INavBar) {
  const [isShowProfileBar, setIsShowProfileBar] = useState<boolean>(false);
  const [
    isShowConfirmCreateNewTaskDialog,
    setIsShowConfirmCreateNewTaskDialog,
  ] = useState<boolean>(false);

  const { userSessionData, breadcumbs, isShowSidebar } =
    useSelector(selectGlobal);

  const { full_name, avatar } = userSessionData.profile;

  const [timeCounter, setTimeCounter] = React.useState<ITimeCounter>({
    hours: 0,
    minutes: 0,
    seconds: 0,
  });

  const [duration, setDuration] = React.useState<moment.Duration>(
    moment.duration(),
  );

  const tick = () => {
    const duration = moment.duration(moment().diff(getRecordStartTime()));
    const formatedTimeCounter = moment.utc(duration.asMilliseconds());

    setDuration(duration);

    setTimeCounter({
      hours: _.toNumber(formatedTimeCounter.format('H')),
      minutes: _.toNumber(formatedTimeCounter.format('m')),
      seconds: _.toNumber(formatedTimeCounter.format('s')),
    });
  };

  React.useEffect(() => {
    const timerId = setInterval(() => tick(), 1000);
    return () => clearInterval(timerId);
  });

  const handCreateNewTask = () => {
    const duration = moment.duration(moment().diff(getRecordStartTime()));
    setRecordDuration(duration.asHours().toString());
    removeRecordStartTime();
    setIsShowConfirmCreateNewTaskDialog(false);

    window.dispatchEvent(new Event('storage'));
  };

  return (
    <NavBarRoot
      square
      color="inherit"
      elevation={2}
      className={`${isShowSidebar ? 'open' : ''}`}
    >
      <Toolbar sx={{ justifyContent: 'space-between' }} variant="dense">
        <Breadcrumbs
          aria-label="breadcrumb"
          className="breadcumbs"
          separator={<ChevronRightIcon />}
        >
          {breadcumbs.map(it => {
            if (it.path) {
              return (
                <NavLink key={it.title} to={it.path}>
                  {it.title}
                </NavLink>
              );
            }
            return (
              <Typography key={it.title} color="text.primary">
                {it.title}
              </Typography>
            );
          })}
        </Breadcrumbs>

        {(timeCounter.hours > 0 ||
          timeCounter.minutes > 0 ||
          timeCounter.seconds > 0) && (
          <>
            <Stack
              direction="row"
              flexGrow={1}
              spacing={1}
              alignItems="center"
              justifyContent="center"
            >
              <IconButton
                color="error"
                size="large"
                onClick={() => setIsShowConfirmCreateNewTaskDialog(true)}
              >
                <StopCircle fontSize="large" />
              </IconButton>

              {timeCounter.hours > 0 && (
                <>
                  <Typography variant="h3">{timeCounter.hours}</Typography>
                  <Box component="small">h</Box>
                </>
              )}
              <Typography variant="h3">{timeCounter.minutes}</Typography>
              <Box component="small">m</Box>
              <Typography variant="h3">{timeCounter.seconds}</Typography>
              <Box component="small">s</Box>
              <Chip
                variant="outlined"
                label={`Duration: ${duration.asHours().toFixed(1)}`}
              ></Chip>
            </Stack>
            <Dialog open={isShowConfirmCreateNewTaskDialog}>
              <DialogTitle>
                <Typography variant="h5" component="div">
                  Create new task?
                </Typography>
              </DialogTitle>
              <DialogContent>
                Do you want to use this duration to make new task?
              </DialogContent>
              <DialogActions>
                <Stack
                  direction="row"
                  justifyContent="space-between"
                  flexGrow={1}
                >
                  <Button
                    onClick={() => setIsShowConfirmCreateNewTaskDialog(false)}
                  >
                    Cancel
                  </Button>
                  <Box>
                    <Button
                      onClick={() => {
                        removeRecordStartTime();
                        window.dispatchEvent(new Event('storage'));
                        setIsShowConfirmCreateNewTaskDialog(false);
                      }}
                    >
                      No
                    </Button>
                    <Button onClick={handCreateNewTask}>Yes</Button>
                  </Box>
                </Stack>
              </DialogActions>
            </Dialog>
          </>
        )}
        <Stack flexDirection="row">
          {/* <SearchField
            type="search"
            placeholder="Search"
            aria-label="Search"
            size="small"
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon />
                </InputAdornment>
              ),
            }}
          />
          <LanguageSwitch></LanguageSwitch> */}
          <Box
            onClick={() => setIsShowProfileBar(true)}
            display="inline-flex"
            alignItems="center"
          >
            <label className="welcome-name">
              Hi, <span>{full_name}</span>
            </label>
            <AvatarMember
              title={full_name}
              avatar={avatar}
              sx={{ width: 24, height: 24, fontSize: 12 }}
            ></AvatarMember>
          </Box>
        </Stack>
      </Toolbar>
      {props.subToolbar}
      <ProfileBar
        userSessionData={userSessionData}
        anchor="right"
        open={isShowProfileBar}
        onClose={() => setIsShowProfileBar(false)}
      />
    </NavBarRoot>
  );
}

const NavBarRoot = styled(AppBar)`
  transition: width 225ms cubic-bezier(0.4, 0, 0.6, 1) 0ms,
    padding 225ms cubic-bezier(0.4, 0, 0.6, 1) 0ms !important;

  padding-left: ${UISettings.drawerCollapsedWidth}px;

  &.open {
    padding-left: ${UISettings.drawerWidth}px;

    .nav-toogle {
      display: none;
    }
  }
  & > div {
    padding: 0 10px;
  }
  .page-title {
    line-height: ${UISettings.navHeight};
  }
  .breadcumbs {
    li {
      &:before {
        content: '';
      }
      a {
        text-decoration: none;
        color: rgba(0, 0, 0, 0.45);
      }
    }
  }
  .welcome-name {
    color: #b5b5c3;
    font-weight: 600;
    margin-right: 10px;
    display: inline-block;
    span {
      color: #7e8299;
    }
  }
  .avatar {
    width: 24px;
    height: 24px;
  }
`;

export default NavBar;
