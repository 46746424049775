import React, { useEffect } from 'react';

// UI
import {
  Box,
  Stack,
  Dialog,
  DialogContent,
  Paper,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  IconButton,
} from '@mui/material';
import styled from 'styled-components/macro';
import { ReactComponent as ClockIcon } from 'assets/icons/assignment/clock.svg';
import { ReactComponent as TimeIcon } from 'assets/icons/assignment/time.svg';
import { Close as CloseIcon } from '@mui/icons-material';
import { SummaryInfo } from 'app/components/SummaryInfo';
import ProfileInfo from 'app/components/ProfileInfo';
import { selectAssignmentSummary } from '../slice/selectors';
import { useDispatch, useSelector } from 'react-redux';
import { useAssignmentSummarySlice } from '../slice';
import { PartialLoader } from 'app/components/PartialLoader';
import { checkIsRequesting } from 'utils/helper';
import _ from 'lodash';
import { useTranslation } from 'react-i18next';

interface IAssignmentDetail {
  open: boolean;
  activeMonth?: string;
  onClose: Function;
}

export function AssignmentDetail(props: IAssignmentDetail) {
  const dispatch = useDispatch();

  const { t } = useTranslation();

  const { open, onClose, activeMonth } = props;
  const { assignmentDetail, getAssignmentDetailStatus } = useSelector(
    selectAssignmentSummary,
  );

  const {
    actions: { resetAssignmentSummaryStatus },
  } = useAssignmentSummarySlice();

  const isLoading = checkIsRequesting([getAssignmentDetailStatus]);

  useEffect(() => {
    if (!open) {
      dispatch(resetAssignmentSummaryStatus());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open]);

  return (
    <CustomDialog open={open} fullWidth>
      <PartialLoader open={isLoading} />
      <IconButton
        onClick={() => onClose()}
        style={{ position: 'absolute', right: '10px', top: '10px' }}
      >
        <CloseIcon />
      </IconButton>
      <DialogContent>
        <ProfileInfo memberInfo={assignmentDetail}></ProfileInfo>
        <Stack
          my={3}
          direction="row"
          spacing={4.5}
          justifyContent="space-between"
        >
          <SummaryInfo
            icon={<TimeIcon />}
            title={'Time'}
            data={activeMonth}
          ></SummaryInfo>

          <SummaryInfo
            icon={<ClockIcon />}
            title={'Commit Effort'}
            hour={assignmentDetail?.commit_effort}
          ></SummaryInfo>

          <SummaryInfo
            icon={<ClockIcon />}
            title={'Total Assigned'}
            hour={_.sumBy(assignmentDetail?.projects, 'assign_effort')}
          ></SummaryInfo>

          <SummaryInfo
            icon={<ClockIcon />}
            title={'Actual Effort'}
            color="#ff4d4f"
            hour={_.sumBy(assignmentDetail?.projects, 'actual_effort')}
          ></SummaryInfo>
        </Stack>
        <WrapTable>
          <TableContainer component={Paper}>
            <Table stickyHeader>
              <TableHead>
                <TableRow>
                  <TableCell sx={{ minWidth: 50 }} width={60}>
                    {t('No')}
                  </TableCell>
                  <TableCell sx={{ minWidth: 130 }}>{t('Project')}</TableCell>
                  <TableCell sx={{ minWidth: 100 }}>{t('Role')}</TableCell>
                  <TableCell sx={{ minWidth: 80 }} width={150}>
                    {t('Total Assigned')}
                  </TableCell>
                  <TableCell sx={{ minWidth: 80 }} width={130}>
                    {t('Actual Efforts')}
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {assignmentDetail?.projects.map((v, k) => (
                  <TableRow key={k}>
                    <TableCell>{k + 1}</TableCell>
                    <TableCell>{v.project_name}</TableCell>
                    <TableCell>{v.role_name}</TableCell>
                    <TableCell style={{ color: '#2E5ED9' }}>
                      {v.assign_effort}h
                    </TableCell>
                    <TableCell style={{ color: '#FF4D4F' }}>
                      {v.actual_effort}h
                    </TableCell>
                  </TableRow>
                ))}
                {_.isEmpty(assignmentDetail?.projects) && (
                  <TableRow>
                    <TableCell colSpan={5}>{t('No data')}</TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
        </WrapTable>
      </DialogContent>
    </CustomDialog>
  );
}

const WrapTable = styled(Box)`
  & .MuiTableContainer-root {
    max-height: 210px;
  }
  table {
    tr th {
      background: #f0f7ff;
      text-transform: none !important;
      color: rgb(0 0 0 / 85%);
      &.current_month {
        color: #437dff;
        font-weight: bold !important;
      }
      &.other_month {
        color: rgb(0 0 0 / 45%);
        background: #d9d9d9;
      }
    }
    tr th,
    tr td {
    }
    tr td {
      color: rgb(0 0 0 / 85%);
    }
  }
`;

const CustomDialog = styled(Dialog)`
  & .MuiPaper-root.MuiDialog-paper {
    max-width: 727px;
  }
`;
