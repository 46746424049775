import { Autocomplete, FormLabel, TextField } from '@mui/material';
import { TagComponent } from 'app/components/TagComponent';

interface IFilterSelectOptionComponent {
  name: string;
  data: any;
  selectedOptions: any;
  setSelectedOptions: any;
  limitTag?: number;
  fieldLabel?: string;
  handleOption?: any;
}

export function FilterSelectOption(props: IFilterSelectOptionComponent) {
  const {
    name,
    data,
    selectedOptions,
    setSelectedOptions,
    limitTag,
    fieldLabel,
    handleOption,
  } = props;
  return (
    <>
      <FormLabel>{name}</FormLabel>
      <Autocomplete
        value={selectedOptions}
        isOptionEqualToValue={(option, value) => option.id === value.id}
        multiple
        limitTags={limitTag ?? 4}
        size="small"
        options={data || []}
        filterSelectedOptions
        getOptionLabel={it => {
          return fieldLabel ? it[fieldLabel] : it.name;
        }}
        onChange={(e, newValue, reason) => {
          setSelectedOptions(newValue);
          if (typeof handleOption === 'function') {
            handleOption(newValue);
          }
        }}
        renderTags={(value, getTagProps) => {
          return value.map((option: any, index: number) => (
            <TagComponent
              data={{
                name: option.name,
                color: option.color,
              }}
              {...getTagProps({ index })}
            />
          ));
        }}
        renderInput={params => (
          <TextField
            {...params}
            size="small"
            margin="dense"
            fullWidth
            variant="outlined"
          />
        )}
      />
    </>
  );
}
