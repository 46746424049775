import React, { useEffect } from 'react';

// UI
import { Helmet } from 'react-helmet-async';

import Stack from '@mui/material/Stack';
import { Paper, Box, Tab, Typography, Tabs } from '@mui/material';
import { TabContext, TabPanel } from '@mui/lab';
import { PageTitle } from 'app/components/PageTitle';

import { Container } from 'app/components/Container';
import { Wrapper, Content } from 'app/components/Wrapper';

import ProjectSummary from './components/ProjectSummary';
import BasicInfo from './components/BasicInfo';
import Assignment from 'app/components/Assignment';
import TaskList from 'app/components/TaskList';

// Redux & Sagas
import { useDispatch, useSelector } from 'react-redux';
import { RequestStatus } from 'constants/API';
import { selectProject } from '../ProjectPage/slice/selectors';
import { useProjectsSlice } from '../ProjectPage/slice';
import { useGlobalSlice } from 'app/pages/GlobalContainer/slice';
import { useLocation } from 'react-router-dom';
import { useMemberSlice } from 'app/pages/MemberPage/slice';
// Library
import _ from 'lodash';
import queryString from 'query-string';
import { useHistory } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { useParams } from 'react-router-dom';
import Path from 'config/clientPath';
import { checkIsRequesting } from 'utils/helper';
import { selectMember } from '../MemberPage/slice/selectors';
import { Loader } from 'app/components/Loader';
import { selectTimesheet } from '../TimesheetPage/slice/selectors';

export const COUNTRY_LIST = {
  vi: 'Vietnam',
  ja: 'Japan',
  cn: 'China',
  sg: 'Singapore',
};

export const STATUS_LIST = {
  '1': 'Active',
  '2': 'Deactive',
};

interface RouteParams {
  id: string;
}

export function ProjectEditPage() {
  const dispatch = useDispatch();
  const params = useParams<RouteParams>();
  const location = useLocation();
  const [tabIndex, setTabIndex] = React.useState('1');
  const projectForm = useForm();
  const {
    actions: { getMemberListRequest, getProjectRoleListRequest },
  } = useMemberSlice();
  const {
    projectInfo,
    getProjectStatus,
    updateProjectStatus,

    getProjectListStatus,
  } = useSelector(selectProject);
  const { getMemberListStatus, deleteAssignmentInfoStatus } =
    useSelector(selectMember);
  const { getTimesheetStatus } = useSelector(selectTimesheet);

  const { getProjectRequest, resetProjectRequestStatus } =
    useProjectsSlice().actions;

  const { setBreadcumbs, setSuccessMessages, setErrorMessages } =
    useGlobalSlice().actions;
  const history = useHistory();
  const isLoading = checkIsRequesting([
    getProjectStatus,
    getMemberListStatus,
    getProjectListStatus,
    getTimesheetStatus,
  ]);

  useEffect(() => {
    if (params.id) {
      dispatch(
        setBreadcumbs([
          { title: 'Projects', path: Path.PROJECTS },
          { title: 'Project Detail' },
        ]),
      );
      dispatch(
        getProjectRequest({
          id: params.id,
          type: 'all',
        }),
      );
    } else {
      dispatch(
        setBreadcumbs([
          { title: 'Projects', path: Path.PROJECTS },
          { title: 'Add new Project' },
        ]),
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    dispatch(getMemberListRequest({ paging: false }));
    dispatch(getProjectRoleListRequest({}));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  React.useEffect(() => {
    if (deleteAssignmentInfoStatus === RequestStatus.SUCCESS) {
      dispatch(
        getProjectRequest({
          id: params.id,
          type: 'all',
        }),
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [deleteAssignmentInfoStatus]);

  useEffect(() => {
    if (getProjectStatus === RequestStatus.SUCCESS) {
      let cloneProjectInfo = _.cloneDeep(projectInfo);
      cloneProjectInfo.scope = Object.keys(_.cloneDeep(projectInfo?.scope)).map(
        key => _.cloneDeep(projectInfo?.scope)[key],
      );
      projectForm.reset(cloneProjectInfo);
    }

    if (updateProjectStatus === RequestStatus.SUCCESS) {
      dispatch(resetProjectRequestStatus());
      dispatch(setSuccessMessages(['Project update successful!']));
    }
    if (updateProjectStatus === RequestStatus.ERROR) {
      dispatch(resetProjectRequestStatus());
      dispatch(setErrorMessages(['Opps..Project update failed!']));
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getProjectStatus, updateProjectStatus]);

  const handleChangeTabIndex = (
    event: React.SyntheticEvent,
    newValue: string,
  ) => {
    let paramsTab = queryString.parse(window.location.search);
    if (newValue) {
      paramsTab = { ...paramsTab, tab: newValue };
      setTabIndex(newValue);
    }
    const qs = queryString.stringify(paramsTab);
    history.push(`${Path.PROJECTS}/edit/${params.id}?${qs}`);
  };

  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    const newProjectTab = params.get('tab');
    setTabIndex(newProjectTab ? newProjectTab : '1');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.search]);
  return (
    <>
      <Helmet>
        <title>Project Management</title>
        <meta name="description" content="Project Management" />
      </Helmet>
      <Container>
        <Wrapper
          subToolbar={
            <>
              <PageTitle>
                Project Details
                {projectInfo?.name ? ` / ${projectInfo?.name}` : ''}
              </PageTitle>
              <Tabs onChange={handleChangeTabIndex} value={tabIndex}>
                <Tab label="Assigment" value="1" />
                <Tab label="Task List" value="2" />
                <Tab label="Project info" value="3" />
              </Tabs>
            </>
          }
        >
          <Loader open={isLoading} />
          <Content spacing={2}>
            <TabContext value={tabIndex}>
              <Stack component={TabPanel} spacing={3} value="1" p={0}>
                <Box
                  display="flex"
                  flexDirection="row"
                  justifyContent="space-between"
                >
                  <Typography variant="h6" component="h6">
                    Project Summary
                  </Typography>
                </Box>

                <ProjectSummary />
                <Typography variant="h6" component="h6">
                  Assignment
                </Typography>
                <Assignment
                  showHeader={true}
                  showMember={true}
                  showProject={false}
                  projectInfo={projectInfo}
                />
              </Stack>
              <Stack component={TabPanel} spacing={3} value="2" p={0}>
                <Box
                  display="flex"
                  flexDirection="row"
                  justifyContent="space-between"
                ></Box>
                <TaskList project={_.toInteger(params.id)} />
              </Stack>
              <Paper>
                <Box component={TabPanel} value="3">
                  <BasicInfo />
                </Box>
              </Paper>
            </TabContext>
          </Content>
        </Wrapper>
      </Container>
    </>
  );
}
