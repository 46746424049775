import {
  Box,
  Button,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import { useRecruitmentSlice } from 'app/pages/RecruitmentsPage/slice';
import { selectRecruitments } from 'app/pages/RecruitmentsPage/slice/selectors';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import DeleteIcon from '@mui/icons-material/Delete';
import { ReactComponent as CloudIcon } from 'assets/icons/candidates/cloud_icon.svg';
import { ModalUploadFile } from './ModalUploadFile';
import { RequestStatus } from 'constants/API';
import { useGlobalSlice } from 'app/pages/GlobalContainer/slice';
import { checkIsRequesting } from 'utils/helper';
import { Loader } from 'app/components/Loader';
import ConfirmDelete from 'app/components/ConfirmDelete';
interface ITab3 {
  id: string;
}

export function Tab3(props: ITab3) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { id } = props;
  const [isOpenModal, setIsOpenModal] = useState(false);
  const [isOpenModalDelete, setIsOpenModalDelete] = useState(false);
  const [idDelete, setIdDelete] = useState<number | null>(null);
  const { interviewRecords, uploadVideoRecordStatus, deleteVideoRecordStatus } =
    useSelector(selectRecruitments);
  const {
    actions: { getInterviewRecordRequest, deleteVideoRecordRequest },
  } = useRecruitmentSlice();

  const {
    actions: { setErrorMessages, setSuccessMessages },
  } = useGlobalSlice();

  const handleDeleteRecord = async () => {
    if (idDelete) {
      const dataToSend = {
        id: id,
        record_id: idDelete,
      };
      setIsOpenModalDelete(false);
      dispatch(deleteVideoRecordRequest(dataToSend));
      setIdDelete(null);
    }
  };

  useEffect(() => {
    if (uploadVideoRecordStatus === RequestStatus.SUCCESS) {
      dispatch(getInterviewRecordRequest({ id: id }));
      dispatch(setSuccessMessages([t('Upload video Success!')]));
    }
    if (uploadVideoRecordStatus === RequestStatus.ERROR) {
      dispatch(setErrorMessages([t('Upload video Error!')]));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [uploadVideoRecordStatus]);

  useEffect(() => {
    if (deleteVideoRecordStatus === RequestStatus.SUCCESS) {
      dispatch(getInterviewRecordRequest({ id: id }));
      dispatch(setSuccessMessages([t('Delete record Success!')]));
    }
    if (deleteVideoRecordStatus === RequestStatus.ERROR) {
      dispatch(setErrorMessages([t('Delete record Error!')]));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [deleteVideoRecordStatus]);

  const isLoadingUpload = checkIsRequesting([
    uploadVideoRecordStatus,
    deleteVideoRecordStatus,
  ]);

  return (
    <>
      <Box sx={{ margin: '0px !important' }}>
        <Loader open={isLoadingUpload} />
      </Box>
      <TableContainer component={Paper} sx={{ margin: '0px !important' }}>
        <Table stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow sx={{ backgroundColor: '#f3f6f9' }}>
              <TableCellStyle width="40%">{t('Name')}</TableCellStyle>
              <TableCellStyle width="20%">{t('Date')}</TableCellStyle>
              <TableCellStyle width="10%">{t('Type')}</TableCellStyle>
              <TableCellStyle width="20%">{t('Size')}</TableCellStyle>
              <TableCellStyle width="10%">{t('Action')}</TableCellStyle>
            </TableRow>
          </TableHead>
          <TableBody>
            {interviewRecords?.length === 0 ? (
              <TableRow>
                <TableCell colSpan={5} sx={{ textAlign: 'left' }}>
                  {t('No data')}
                </TableCell>
              </TableRow>
            ) : (
              <>
                {interviewRecords?.map((item, index) => (
                  <TableRow key={index}>
                    <TableCell sx={{ color: '#3699FF' }}>{item.name}</TableCell>
                    <TableCell>{item.created_at}</TableCell>
                    <TableCell>{item.type}</TableCell>
                    <TableCell>{item.size} mb</TableCell>
                    <TableCell>
                      <Button sx={{ minWidth: 'auto' }}>
                        <a
                          href={item.file}
                          target="_blank"
                          rel="noopener noreferrer"
                          style={{ color: '#939393' }}
                        >
                          <RemoveRedEyeIcon />
                        </a>
                      </Button>
                      <Button
                        sx={{ minWidth: 'auto' }}
                        style={{ color: '#939393' }}
                        onClick={() => {
                          setIsOpenModalDelete(true);
                          setIdDelete(item.id);
                        }}
                      >
                        <DeleteIcon />
                      </Button>
                    </TableCell>
                  </TableRow>
                ))}
              </>
            )}
          </TableBody>
        </Table>
      </TableContainer>

      <ConfirmDelete
        open={isOpenModalDelete}
        onClose={() => {
          setIsOpenModalDelete(false);
          setIdDelete(null);
        }}
        onConfirm={() => {
          handleDeleteRecord();
        }}
        title="record"
        name={''}
      />

      <Box
        sx={{
          background: 'white',
          mt: '0px !important',
          p: '20px 24px',
        }}
      >
        <Box
          sx={{
            padding: '40px',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            gap: '15px',
          }}
        >
          <Box>
            <div style={{ width: '64px' }}>
              <CloudIcon />
            </div>
          </Box>
          <Typography variant="body1" component="p">
            {t(
              'Please upload file with extensions (.mp3, .wav, .m4a, .mp4) and is smaller than 100MB',
            )}
          </Typography>
          <ButtonBotton
            color="white"
            bgcolor="#3699FF"
            type="button"
            onClick={() => setIsOpenModal(true)}
          >
            {t('Upload')}
          </ButtonBotton>
        </Box>
      </Box>

      <ModalUploadFile
        isOpenModal={isOpenModal}
        setIsOpenModal={setIsOpenModal}
        id={id}
      />
    </>
  );
}

const ButtonBotton = styled.button<{ bgcolor: string }>`
  padding: 10px 20px;
  border-radius: 4px;
  border: none;
  z-index: 2;
  cursor: pointer;
  color: ${props => props.color || 'transparent'};
  background: ${props => props.bgcolor || 'transparent'};
`;

const TableCellStyle = styled(TableCell)`
  background: #f3f6f9 !important;
  color: #687182 !important;
  font-size: 14px;
`;
