import {
  Box,
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  Modal,
  Radio,
  RadioGroup,
  Stack,
  Typography,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import { selectMember } from '../slice/selectors';
import { useEffect, useState } from 'react';
import { TYPE_JOB_MEMBER_FILTER } from 'constants/common';
import { FilterSelectOption } from './FilterSelectOption';
import { useHistory } from 'react-router-dom';
import Path from 'config/clientPath';
import ClearIcon from '@mui/icons-material/Clear';
import { handleParamSearch } from 'utils/helper';
import { IRoleSchema } from '../slice/types';
interface IModalSearch {
  page?: number;
  status?: string | null;
  type__in: string[];
  roles?: string[];
  departments?: string[];
  skills?: string[];
  search?: string;
  tab?: string;
}

export function FilterModal(props: any) {
  const { isOpenFilterModal, setIsOpenFilterModal } = props;
  const { t } = useTranslation();
  const history = useHistory();
  const params = new URLSearchParams(window.location.search);
  const { member_role_list, departments, technicalSkills } =
    useSelector(selectMember);
  // roles
  const [selectedStatus, setSelectedStatus] = useState<string | null>(null);
  // roles
  const [selectedRoles, setSelectedRoles] = useState<any[]>([]);
  // type jobs
  const [selectedTypeJobs, setSelectedTypeJobs] = useState<any[]>([]);
  const [selectAllTypeJobs, setSelectAllTypeJobs] = useState(false);
  // department
  const [selectedDepartments, setSelectedDepartments] = useState<any[]>([]);
  // skills
  const [selectedSkills, setSelectedSkills] = useState<any[]>([]);
  const [roleDataFilter, setRoleDataFilter] = useState<IRoleSchema[] | null>(
    [],
  );

  const convertStingToArray = (valueSearch: string | null) => {
    if (valueSearch) {
      return valueSearch
        .split(',')
        .map((item: any) =>
          isNaN(item.trim()) ? item.trim() : Number(item.trim()),
        );
    }
    return [];
  };

  const filterInputSearchRender = (valueSearch: string | null, data: any) => {
    if (valueSearch) {
      const ids = convertStingToArray(valueSearch);
      return ids
        .map(id => data.find(obj => obj.id === id))
        .filter(obj => obj !== undefined);
    }
    return [];
  };

  const filterOutputSearchRender = (data: any[]) => {
    if (data?.length > 0) {
      return data.map((obj: any) => obj.id);
    }
    return [];
  };

  const handleRadioButton = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.value === selectedStatus) {
      setSelectedStatus('');
    } else {
      setSelectedStatus(e.target.value);
    }
  };

  const handleSelectAll = (
    selectedAllOption: boolean,
    setSelectedAllOption: any,
    setSelectedOption: any,
    optionData: any,
  ) => {
    setSelectedAllOption(!selectedAllOption);
    if (!selectedAllOption && optionData) {
      setSelectedOption(optionData.map((item: any) => item.id));
    } else {
      setSelectedOption([]);
    }
  };

  const handleSelect = (
    roleID: any,
    selectedOption: any[],
    setSelectedOption: any,
    setSelectAllOption: any,
    optionData: any,
  ) => {
    let newSelectedOption: any[] = [];
    if (selectedOption.includes(roleID)) {
      newSelectedOption = selectedOption.filter(role => role !== roleID);
      setSelectedOption(newSelectedOption);
    } else {
      newSelectedOption = [...selectedOption, roleID];
      setSelectedOption(newSelectedOption);
    }
    if (optionData) {
      setSelectAllOption(newSelectedOption.length === optionData.length);
    }
  };

  const handleClear = () => {
    setSelectedStatus(null);
    setSelectedRoles([]);
    setSelectedTypeJobs([]);
    setSelectedDepartments([]);
    setSelectedSkills([]);
    setSelectAllTypeJobs(false);
    setRoleDataFilter(member_role_list);
  };

  const handleSearch = () => {
    setIsOpenFilterModal(false);
    const defaultModalParams: IModalSearch = {
      status: selectedStatus,
      type__in: selectedTypeJobs,
      roles: filterOutputSearchRender(selectedRoles),
      departments: filterOutputSearchRender(selectedDepartments),
      skills: filterOutputSearchRender(selectedSkills),
      search: params.get('search') ?? '',
      page: 1,
      tab: params.get('tab') ?? '',
    };
    history.push(`${Path.MEMBERS}?${handleParamSearch(defaultModalParams)}`);
  };

  const filterRoles = (departmentIds: any) => {
    selectedDepartments?.forEach(department => {
      departmentIds?.push(department.id);
    });
    return member_role_list?.filter(item =>
      departmentIds?.includes(item.department),
    );
  };

  useEffect(() => {
    const typeJobDefaultSelected = convertStingToArray(params.get('type__in'));
    if (typeJobDefaultSelected.length === TYPE_JOB_MEMBER_FILTER.length) {
      setSelectAllTypeJobs(true);
    }
    setSelectedStatus(params.get('status') ?? null);
    setSelectedRoles(
      filterInputSearchRender(params.get('roles'), member_role_list),
    );
    setSelectedTypeJobs(typeJobDefaultSelected);
    setSelectedDepartments(
      filterInputSearchRender(params.get('departments'), departments),
    );
    setSelectedSkills(
      filterInputSearchRender(params.get('skills'), technicalSkills),
    );
    let departmentIds: number[] = [];
    if (selectedDepartments.length === 0) {
      if (params.get('departments')) {
        let ids = params?.get('departments')?.split(',').map(Number) ?? [];
        const filteredData = departments
          ?.filter(obj => ids.includes(obj.id))
          .filter(obj => obj !== undefined);
        filteredData?.forEach((department: any) => {
          departmentIds.push(department?.root?.id);
        });
        const newRole = filterRoles(departmentIds);
        setRoleDataFilter(newRole || []);
      } else {
        setRoleDataFilter(member_role_list);
      }
    } else {
      selectedDepartments.forEach(department => {
        departmentIds.push(department?.root?.id);
      });
      const newRole = filterRoles(departmentIds);
      setRoleDataFilter(newRole || []);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleChooseDepartments = (departments: any) => {
    if (departments?.length === 0) {
      setRoleDataFilter(member_role_list);
    } else {
      let departmentIds: number[] = [];
      departments.forEach(department => {
        departmentIds.push(department?.root?.id);
      });
      const newRole = member_role_list?.filter(item =>
        departmentIds.includes(item.department),
      );
      setRoleDataFilter(newRole || []);
    }
  };

  return (
    <>
      <Modal
        keepMounted
        open={isOpenFilterModal}
        onClose={() => {}}
        aria-labelledby="keep-mounted-modal-title"
        aria-describedby="keep-mounted-modal-description"
      >
        <StyledDiv>
          <Box
            display="flex"
            flexDirection="row"
            justifyContent="space-between"
            alignItems="center"
            borderBottom="1px solid #bdbdbd"
          >
            <Typography
              variant="body1"
              component="p"
              p="15px"
              fontWeight="500"
              fontSize="18px"
            >
              {t('Filter Member')}
            </Typography>

            <ClearIcon
              onClick={() => {
                setIsOpenFilterModal(false);
              }}
              sx={{ cursor: 'pointer', mr: '16px' }}
            />
          </Box>

          <Grid container spacing={2} pb={2} pt={2} px={2}>
            <Grid item xs={12}>
              <FormControl>
                <FormLabel
                  id="demo-controlled-radio-buttons-group"
                  className="status-member"
                >
                  {t('Status')}
                </FormLabel>
                <RadioGroup
                  row
                  aria-labelledby="demo-controlled-radio-buttons-group"
                  name="member-status"
                  value={selectedStatus}
                >
                  <FormControlLabel
                    value="1"
                    control={
                      <Radio
                        onClick={(e: any) => {
                          handleRadioButton(e);
                        }}
                      />
                    }
                    label={t('Active')}
                  />
                  <FormControlLabel
                    value="2"
                    control={
                      <Radio
                        onClick={(e: any) => {
                          handleRadioButton(e);
                        }}
                      />
                    }
                    label={t('Deactivate')}
                  />
                </RadioGroup>
              </FormControl>
            </Grid>
            {/* Type Job */}
            <Grid item xs={12}>
              <Box display="flex" flexDirection="row" alignItems="center">
                <FormLabel sx={{ display: 'flex' }}>{t('Type')}</FormLabel>
              </Box>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={selectAllTypeJobs}
                    onChange={() => {
                      handleSelectAll(
                        selectAllTypeJobs,
                        setSelectAllTypeJobs,
                        setSelectedTypeJobs,
                        TYPE_JOB_MEMBER_FILTER,
                      );
                    }}
                  />
                }
                label="All"
              />
              {TYPE_JOB_MEMBER_FILTER?.map((item, idx) => {
                return (
                  <FormControlLabel
                    key={idx}
                    value={item.id}
                    control={
                      <Checkbox
                        checked={selectedTypeJobs.includes(item.id)}
                        onChange={() => {
                          handleSelect(
                            item.id,
                            selectedTypeJobs,
                            setSelectedTypeJobs,
                            setSelectAllTypeJobs,
                            TYPE_JOB_MEMBER_FILTER,
                          );
                        }}
                      />
                    }
                    label={item.name}
                  />
                );
              })}
            </Grid>
            {/* Type Job */}

            {/* Department */}
            <Grid item xs={12}>
              <FilterSelectOption
                name={t('Departments')}
                limitTag={8}
                data={departments}
                selectedOptions={selectedDepartments}
                setSelectedOptions={setSelectedDepartments}
                fieldLabel="full_name"
                handleOption={handleChooseDepartments}
              />
            </Grid>
            {/* Department */}

            {/* Roles */}
            <Grid item xs={12}>
              <FilterSelectOption
                name={t('Roles')}
                limitTag={8}
                data={roleDataFilter}
                selectedOptions={selectedRoles}
                setSelectedOptions={setSelectedRoles}
              />
            </Grid>
            {/* Roles */}

            {/* Skills */}
            <Grid item xs={12}>
              <FilterSelectOption
                name={t('Technical Skills')}
                limitTag={8}
                data={technicalSkills}
                selectedOptions={selectedSkills}
                setSelectedOptions={setSelectedSkills}
              />
            </Grid>
            {/* Skills */}
          </Grid>
          <Stack
            direction="row"
            display="flex"
            justifyContent="flex-end"
            p="15px"
            borderTop="1px solid #BDBDBD"
            spacing={2}
          >
            <Button
              variant="contained"
              color="inherit"
              type="button"
              onClick={handleClear}
            >
              {t('Clear')}
            </Button>
            <Button
              variant="contained"
              color="primary"
              type="button"
              onClick={handleSearch}
            >
              {t('Filter')}
            </Button>
          </Stack>
        </StyledDiv>
      </Modal>
    </>
  );
}

const StyledDiv = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 50%;
  background-color: #fff;
  box-shadow: 0 0 24px rgba(0, 0, 0, 0.16);
  min-width: 500px;
  border-radius: 5px;

  & .status-member {
    color: rgba(0, 0, 0, 0.6) !important;
  }
`;
