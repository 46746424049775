import { TextField } from '@mui/material';
import { UseFormRegisterReturn } from 'react-hook-form';

interface IParams {
  [key: string]: any;
}
interface IProps {
  register: UseFormRegisterReturn;
  error?: boolean;
  helperText?: string;
  type?: string;
  inputProps?: any;
  disabled?: boolean;
  placeholder?: string;
  textFieldProps?: IParams;
}

export const TextFieldRegister = (props: IProps) => {
  const {
    error,
    helperText,
    register,
    type,
    inputProps,
    disabled,
    placeholder,
    textFieldProps,
  } = props;

  return (
    <TextField
      disabled={disabled}
      placeholder={placeholder}
      variant="outlined"
      fullWidth
      margin="dense"
      size="small"
      type={type}
      error={error}
      {...register}
      helperText={helperText}
      inputProps={inputProps}
      {...textFieldProps}
    />
  );
};
