import { Clear } from '@mui/icons-material';
import { DatePicker, LocalizationProvider } from '@mui/lab';
import { IconButton, TextField } from '@mui/material';
import moment from 'moment';
import UISettings from 'styles/setting';
import DateAdapter from '@mui/lab/AdapterMoment';

interface IProps {
  value: moment.Moment;
  onChange: Function;
  noEntry?: boolean;
  disableFuture?: boolean;
  shouldDisableDate?: any;
  error?: any;
  helperText?: string;
}

export const DatePickerFiled = (props: IProps) => {
  const {
    value,
    onChange,
    noEntry,
    disableFuture,
    shouldDisableDate,
    error,
    helperText,
  } = props;

  return (
    <LocalizationProvider dateAdapter={DateAdapter}>
      {value && (
        <IconButton
          onClick={() => onChange(null)}
          sx={{
            position: 'absolute',
            right: '30px',
            zIndex: 10,
          }}
        >
          <Clear />
        </IconButton>
      )}
      <DatePicker
        disableFuture={disableFuture ? disableFuture : false}
        value={value || null}
        onChange={e => onChange(e)}
        inputFormat={UISettings.dateFormat}
        shouldDisableDate={shouldDisableDate}
        renderInput={params => (
          <TextField
            {...params}
            fullWidth
            hiddenLabel
            size="small"
            onKeyDown={e => noEntry && e.preventDefault()}
            error={error}
            helperText={helperText}
          />
        )}
      />
    </LocalizationProvider>
  );
};
