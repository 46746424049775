import { useEffect, useState } from 'react';

// UI
import {
  TableBody,
  TableRow,
  TableCell,
  FormControl,
  Select,
  MenuItem,
  Typography,
  Stack,
  Button,
} from '@mui/material';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import { Colors } from 'app/pages/HomePage/Components/Variables';

// Redux & Sagas
import { UseFormReturn, Controller, useFieldArray } from 'react-hook-form';

import { ICommonDataSchema } from 'types/common';

// Library
import _ from 'lodash';
import { LEVER_SKILL } from 'constants/common';

interface ISkillTableProps {
  title: string;
  rows?: number;
  memberForm: UseFormReturn;
  dataKey: string;
  startIndex: number;
  data: ICommonDataSchema[];
  key: string;
  idx: number;
}

export default function SkillTable(props: ISkillTableProps) {
  const { control, setValue, watch } = props.memberForm;
  const [skillSheet, setSkillSheet] = useState();
  const [chunkedSkills, setChunkedSkills] = useState<any>();
  const { fields, append } = useFieldArray({
    control,
    name: props.dataKey,
  });

  useEffect(() => {
    setSkillSheet(
      _.filter(fields, {
        category: { name: props.title },
      }),
    );

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fields]);

  useEffect(() => {
    if (skillSheet) {
      setChunkedSkills(_.chunk(skillSheet, 4));
    }
  }, [skillSheet, props.data]);

  return (
    <TableBody>
      <TableRow key={`skilltable_${props.dataKey}_${props.startIndex}`}>
        <TableCell
          rowSpan={props.rows}
          component="th"
          sx={{ width: '12%', minWidth: '120px' }}
        >
          {props.title}
        </TableCell>
        <TableCell
          rowSpan={props.rows}
          component="th"
          sx={{ width: '12%', minWidth: '120px' }}
        >
          <Button
            variant="text"
            onClick={() => {
              append({
                skill: 0,
                level: 0,
                name: '',
                is_main: false,
                category: { name: props.title },
              });
            }}
            type="button"
            disabled={props.data.length === _.size(skillSheet)}
          >
            <AddCircleOutlineIcon
              sx={{
                color: `${
                  props.data.length === _.size(skillSheet)
                    ? Colors.disabledColor
                    : '#3699FF'
                }`,
                paddingRight: '4px',
              }}
            />
            <Typography
              fontSize={'inherit'}
              color={
                props.data.length === _.size(skillSheet)
                  ? Colors.disabledColor
                  : '#3699FF'
              }
            >
              Add Skill
            </Typography>
          </Button>
        </TableCell>
      </TableRow>
      {_.isEmpty(skillSheet) && (
        <TableCell width={'76%'} colSpan={4}>
          <Typography fontSize={'inherit'} padding={1}>
            List your skills: technical, programming languages, software tools,
            and any other expertise.
          </Typography>
        </TableCell>
      )}
      <TableCell width={'76%'} sx={{ paddingX: 0 }}>
        {_.map(chunkedSkills, (skills, index) => {
          return (
            <TableRow key={`chunked_skills_${props.startIndex}_${index}`}>
              {_.map(skills, (skill, k) => {
                const idxSkill = _.findIndex(fields, {
                  id: skill.id,
                });

                return (
                  <TableRow key={`chunked_skills_cell_${k}`}>
                    <TableCell
                      rowSpan={4}
                      sx={{
                        borderBottom:
                          (skills.length === k + 1 &&
                            chunkedSkills?.length === index + 1) ||
                          index === 0
                            ? '0'
                            : undefined,
                        paddingBottom:
                          (skills.length === k + 1 &&
                            chunkedSkills?.length === index + 1) ||
                          index === 0
                            ? '0'
                            : undefined,
                      }}
                    >
                      <Stack
                        direction={'row'}
                        spacing={1}
                        sx={{ width: '300px' }}
                      >
                        <FormControl fullWidth>
                          <Controller
                            control={control}
                            name={`${props.dataKey}[${idxSkill}].skill`}
                            defaultValue="0"
                            render={({ field }) => {
                              const { onBlur, value, onChange } = field;
                              return (
                                <Select
                                  fullWidth
                                  size="small"
                                  onBlur={onBlur}
                                  onChange={onChange}
                                  value={value}
                                >
                                  <MenuItem
                                    value={'0'}
                                    onClick={() =>
                                      setValue(
                                        `${props.dataKey}[${idxSkill}].level`,
                                        0,
                                      )
                                    }
                                  >
                                    {' - '}
                                  </MenuItem>
                                  {props.data.map((it, idx) => {
                                    return (
                                      <MenuItem
                                        key={`${props.dataKey}[${idxSkill}].skill_${idx}`}
                                        value={it.id}
                                        disabled={watch(props.dataKey)?.some(
                                          v => v.skill === it.id,
                                        )}
                                      >
                                        {it.name}
                                      </MenuItem>
                                    );
                                  })}
                                </Select>
                              );
                            }}
                          />
                        </FormControl>

                        <FormControl sx={{ width: '200px' }}>
                          <Controller
                            control={control}
                            name={`${props.dataKey}[${idxSkill}].level`}
                            defaultValue="0"
                            render={({ field }) => {
                              const { onBlur, onChange, value } = field;
                              return (
                                <Select
                                  fullWidth
                                  size="small"
                                  onBlur={onBlur}
                                  onChange={onChange}
                                  value={value}
                                >
                                  {LEVER_SKILL.map(level => (
                                    <MenuItem value={level.id} key={level.id}>
                                      {level.label}
                                    </MenuItem>
                                  ))}
                                </Select>
                              );
                            }}
                          />
                        </FormControl>
                      </Stack>
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableRow>
          );
        })}
      </TableCell>
    </TableBody>
  );
}
