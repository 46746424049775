import React from 'react';

// UI
import { Box, Chip } from '@mui/material';

// Library
import styled from 'styled-components/macro';

interface IHourTag {
  data: {
    label: string;
    backgroundColor?: string;
  };
  size?: string;
}

export function HourTag(props: IHourTag) {
  const { data, size } = props;
  return (
    <Wrapper component="span" className={size ? size : 'big'}>
      <Chip
        style={{ backgroundColor: data.backgroundColor }}
        label={data.label}
      ></Chip>
    </Wrapper>
  );
}

const Wrapper = styled(Box)`
  &.small {
    & .MuiChip-root {
      height: 20px;
    }
    & .MuiChip-label {
      font-size: 12px;
    }
  }
  &.big {
    & .MuiChip-root {
      height: 26px;
    }
    & .MuiChip-label {
      font-size: 14px;
    }
  }
`;
