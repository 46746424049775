import React, { useState, useEffect } from 'react';

// UI
import { Helmet } from 'react-helmet-async';
import {
  Box,
  Typography,
  Stack,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Paper,
  Pagination,
  Checkbox,
  Badge,
  Chip,
  IconButton,
} from '@mui/material';
import { Loader } from 'app/components/Loader';
import { Download } from '@mui/icons-material';
import { Colors } from 'app/pages/HomePage/Components/Variables';
import { ReactComponent as EditIcon } from 'assets/icons/button/edit.svg';
import { ReactComponent as DeleteIcon } from 'assets/icons/button/delete.svg';

import FilterBar from './components/FilterBar';

import { Container } from 'app/components/Container';
import { Wrapper, Content } from 'app/components/Wrapper';
import Path from 'config/clientPath';
import { useLocation } from 'react-router-dom';
// Redux & Sagas
import { useDispatch } from 'react-redux';
import { useGlobalSlice } from 'app/pages/GlobalContainer/slice';

// Library
import { useHistory } from 'react-router-dom';
import queryString from 'query-string';
import _ from 'lodash';
import styled from 'styled-components';
import { checkIsRequesting, saveFile } from 'utils/helper';
// import { formatDateToSplash } from 'app/components/DateProgress';
import { DialogForm } from './components/Form';
import { RequestStatus } from 'constants/API';
import { useTranslation } from 'react-i18next';
import { JOB_STATUS } from 'constants/common';
import { IJobList } from '../RecruitmentsPage/slice/type';
import useRecruitment from '../RecruitmentsPage/useRecruitment';
import ConfirmDelete from 'app/components/ConfirmDelete';

export function JobListPage() {
  const dispatch = useDispatch();
  const location = useLocation();
  const { t } = useTranslation();
  const history = useHistory();

  const {
    getJobList,
    getJobListStatus,
    updateJobShowStatus,
    deleteJobStatus,
    listJob,
    resetJobInfo,
    deleteJob,
    getJobCategories,
    resetJobStateRequesting,
  } = useRecruitment();
  const { setBreadcumbs, setSuccessMessages } = useGlobalSlice().actions;

  const [show, setShow] = useState(false);
  const [showConfirm, setShowConfirm] = useState(false);
  const [dataItem, setDataItem] = useState<IJobList | null>();
  const [selectAll, setSelectAll] = useState<boolean>(false);
  const [showActionStatus, setShowActionStatus] = useState<boolean>(false);
  const [selectedRows, setSelectedRows] = useState<number[]>([]);

  const handleSelectAll = () => {
    setSelectAll(!selectAll);
    const allRows = listJob?.data || [];
    const allRowIds = allRows.map(row => row.id);

    if (!selectAll) {
      setSelectedRows(_.uniq([...selectedRows, ...allRowIds]));
    } else {
      setSelectedRows(selectedRows.filter(rowId => !allRowIds.includes(rowId)));
    }
  };

  const handleCheckboxChange = (rowId: number) => {
    const index = selectedRows.indexOf(rowId);
    if (index === -1) {
      setSelectedRows([...selectedRows, rowId]);
    } else {
      const newSelectedRows = [...selectedRows];
      newSelectedRows.splice(index, 1);
      setSelectedRows(newSelectedRows);
    }
  };

  useEffect(() => {
    const allRows = listJob?.data || [];
    const allRowIds = allRows.map(row => row.id);
    const isAllSelected =
      allRowIds.length > 0 && allRowIds.every(id => selectedRows.includes(id));
    setSelectAll(isAllSelected);
  }, [selectedRows, listJob]);

  const reloadData = () => {
    const params = new URLSearchParams(window.location.search);
    getJobList({
      state: params.get('state') || '',
      page: params.get('page'),
    });
  };

  const handleChangePage = (
    event: React.ChangeEvent<unknown> | null,
    newPage: number,
  ) => {
    const params = queryString.parse(window.location.search);
    const qs = queryString.stringify({ ...params, page: +newPage });
    history.push(`${Path.JOB_LIST}?${qs}`);
  };
  const isLoading = checkIsRequesting([
    getJobListStatus,
    deleteJobStatus,
    updateJobShowStatus,
  ]);

  useEffect(() => {
    dispatch(setBreadcumbs([{ title: t('Jobs List') }]));
    getJobCategories();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    reloadData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.search]);

  useEffect(() => {
    if (deleteJobStatus === RequestStatus.SUCCESS) {
      dispatch(setSuccessMessages(['Job delete successful!']));
      resetJobInfo();
    }
    if (updateJobShowStatus === RequestStatus.SUCCESS) {
      dispatch(setSuccessMessages(['Jobs update successful!']));
      resetJobInfo();
    }

    if (
      deleteJobStatus === RequestStatus.SUCCESS ||
      updateJobShowStatus === RequestStatus.SUCCESS
    ) {
      setSelectedRows([]);
      reloadData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [deleteJobStatus, updateJobShowStatus]);

  useEffect(() => {
    if (selectedRows.length > 0) {
      setShowActionStatus(false);
    } else {
      setShowActionStatus(true);
    }
  }, [selectedRows]);

  useEffect(() => {
    if (listJob) {
      setSelectedRows([]);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [listJob, location.search]);

  useEffect(
    () => () => {
      resetJobInfo();
      resetJobStateRequesting();
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    ['componentWillUnMount'],
  );

  return (
    <>
      <Helmet>
        <title>{t('Jobs List')}</title>
        <meta name="description" content="Jobs List Management" />
      </Helmet>
      <Container>
        <Wrapper>
          <Loader open={isLoading} />
          <Content spacing={2}>
            <DialogForm open={show} data={dataItem} setShow={setShow} />
            <ConfirmDelete
              open={showConfirm}
              onClose={() => {
                setShowConfirm(false);
                setDataItem(null);
              }}
              onConfirm={() => {
                deleteJob(dataItem);
                setShowConfirm(false);
              }}
              title="job"
              name={dataItem?.title}
            />

            <Box
              display="flex"
              flexDirection="row"
              justifyContent="space-between"
            >
              <Typography variant="h6" component="h6">
                {t('Jobs List')}
              </Typography>
            </Box>
            <FilterBar
              onClickAdd={() => {
                setDataItem(null);
                setShow(true);
              }}
              showActionStatus={showActionStatus}
              selectedRows={selectedRows}
            />
            <WrapTable>
              <TableContainer component={Paper}>
                <Table stickyHeader>
                  <TableHead>
                    <TableRow>
                      <TableCell sx={{ minWidth: 60 }} width={60}>
                        <Checkbox
                          checked={selectAll}
                          onChange={handleSelectAll}
                        />
                      </TableCell>
                      <TableCell sx={{ minWidth: 80 }} width={`fill`}>
                        {t('Job Name')}
                      </TableCell>
                      <TableCell sx={{ minWidth: 110 }} width={`fill`}>
                        {t('Time')}
                      </TableCell>
                      <TableCell sx={{ minWidth: 110 }} width={120}>
                        {t('Candidates')}
                      </TableCell>
                      <TableCell>{t('Category')}</TableCell>
                      <TableCell>{t('Status')}</TableCell>
                      <TableCell
                        sx={{ minWidth: 90, textAlign: 'center' }}
                        width={120}
                      >
                        {t('Action')}
                      </TableCell>
                    </TableRow>
                  </TableHead>

                  <TableBody>
                    {_.isEmpty(listJob?.data) ? (
                      <TableRow>
                        <TableCell colSpan={6} sx={{ textAlign: 'center' }}>
                          {t('No data')}
                        </TableCell>
                      </TableRow>
                    ) : (
                      listJob?.data.map((item, idx) => {
                        return (
                          <TableRow key={idx}>
                            <TableCell>
                              <Checkbox
                                checked={selectedRows.includes(item.id)}
                                onChange={() => handleCheckboxChange(item.id)}
                              />
                            </TableCell>
                            <TableCell sx={{ color: Colors.blueText }}>
                              <Typography
                                sx={{ cursor: 'pointer', width: 'fit-content' }}
                                fontSize="inherit"
                                onClick={() => {
                                  history.push({
                                    pathname: `${Path.JOB_LIST}/${item.id}`,
                                  });
                                }}
                              >
                                {item.title}
                              </Typography>
                            </TableCell>
                            <TableCell>
                              {item.start_date + ' ~ ' + item.end_date}
                            </TableCell>
                            <TableCell
                              sx={{
                                textAlign: 'center',
                              }}
                            >
                              <Badge
                                sx={{
                                  '& .MuiBadge-badge': {
                                    width: 'max-content',
                                  },
                                }}
                                color={
                                  item.candidates !== 0 ? 'primary' : 'error'
                                }
                                badgeContent={item.candidates}
                                showZero
                              />
                            </TableCell>
                            <TableCell>{item.category?.name}</TableCell>
                            <TableCell>
                              <Chip
                                label={JOB_STATUS[item.state].name}
                                variant="filled"
                                color={JOB_STATUS[item.state].color}
                                size="small"
                              />
                            </TableCell>
                            <TableCell>
                              <Stack direction="row" spacing={1}>
                                <IconButton
                                  disabled={!item.summary_file}
                                  sx={{ minWidth: 'auto' }}
                                  onClick={() => saveFile(item.summary_file)}
                                >
                                  <Download />
                                </IconButton>

                                <IconButton
                                  sx={{ minWidth: 'auto' }}
                                  onClick={() => {
                                    setDataItem(item);
                                    setShow(true);
                                  }}
                                >
                                  <EditIcon />
                                </IconButton>

                                <IconButton
                                  sx={{ minWidth: 'auto' }}
                                  color="error"
                                  onClick={() => {
                                    setDataItem(item);
                                    setShowConfirm(true);
                                  }}
                                >
                                  <DeleteIcon />
                                </IconButton>
                              </Stack>
                            </TableCell>
                          </TableRow>
                        );
                      })
                    )}
                  </TableBody>
                </Table>
              </TableContainer>
            </WrapTable>
            <Stack spacing={2} alignItems={'flex-end'}>
              <Pagination
                page={listJob?.current_page || 1}
                color="primary"
                count={listJob?.total_page || 0}
                variant="outlined"
                shape="rounded"
                onChange={handleChangePage}
              />
            </Stack>
          </Content>
        </Wrapper>
      </Container>
    </>
  );
}

const WrapTable = styled(Box)`
  & .MuiTableContainer-root {
    max-height: calc(100vh - 230px);
  }
  & .MuiAvatarGroup-avatar {
    background-color: #ffd8bf;
    color: #fa541c;
  }
  & .MuiAvatar-root:last-child {
    margin-left: -8px !important;
  }
  .cursor_pointer {
    cursor: pointer;
  }
`;
