import { TabContext, TabList, TabPanel } from '@mui/lab';
import { Box, Tab } from '@mui/material';
import React from 'react';
import styled, { keyframes } from 'styled-components';
import { Colors, device } from './Variables';

export const TechSlider = ({ ...props }) => {
  const { data } = props;

  const [value, setValue] = React.useState<string>(data[0]?.name ?? '');

  const handleChange = (event: React.SyntheticEvent, newValue: string) => {
    setValue(newValue);
  };

  return (
    <BoxWrapper>
      {data?.length > 0 && (
        <TabContext value={value}>
          <Box className="mb-30">
            <TabContent onChange={handleChange} aria-label="skill">
              {data?.map((it, index) => (
                <Tab key={index} label={it.name} value={it.name} />
              ))}
            </TabContent>
          </Box>
          {data?.map((it, index) => (
            <TabPanel key={index} value={it.name}>
              <Img src={it.image} alt={it.name} />
            </TabPanel>
          ))}
        </TabContext>
      )}
    </BoxWrapper>
  );
};

const fadeIn = keyframes`
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
`;

const TabContent = styled(TabList)`
  margin: 0 auto;
  .MuiTabs-flexContainer {
    flex-wrap: wrap;
    @media ${device.tablet} {
      justify-content: space-between;
    }
  }

  .MuiTabs-indicator {
    display: none;
  }
  .Mui-selected {
    background: ${Colors.skyBlue};
  }
  .MuiButtonBase-root {
    margin: 0 15px 30px 0;
    width: 230px;
    position: relative;
    font-size: 22px;
    color: ${Colors.blueHeading};
    border: 3px solid ${Colors.lightBlue};
    border-bottom: none;
    border-right: none;
    text-transform: inherit;
    &:hover,
    &:focus {
      background: ${Colors.skyBlue};
    }
    &:after {
      content: '';
      display: inline-block;
      position: absolute;
      right: 0;
      top: 0;
      width: 3px;
      height: 30px;
      background: ${Colors.lightBlue};
    }

    &:nth-of-type(4n) {
      border-left: none;
      border-right: 3px solid ${Colors.lightBlue};

      &:after {
        content: '';
        display: inline-block;
        position: absolute;
        left: 0;
        top: 0;
        width: 3px;
        height: 30px;
        background: ${Colors.lightBlue};
      }
    }
    @media ${device.tablet} {
      width: 142px;
      font-size: 14px;
      height: 45px;
      margin: 0 0 20px;
    }
    @media ${device.mobileS} {
      width: 132px;
    }
  }
`;

const Img = styled.img`
  max-width: 100%;
  animation: 2s ${fadeIn} ease-out;
`;

const BoxWrapper = styled(Box)`
  .MuiTabPanel-root {
    transition: height 2s linear;
    transition: width 2s linear;
  }
`;
