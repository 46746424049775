import { Stack, Typography } from '@mui/material';
import React from 'react';
import styled from 'styled-components';
import LanguageMenu from './LanguageMenu';
import { Menu } from './Navigation';
import { Colors, device } from './Variables';

export const Footer = () => {
  const smoothScroll = (e, anchor) => {
    e.preventDefault();
    let offset = () => 0;
    const $anchor = document.getElementById(anchor);
    const offsetTop = $anchor
      ? $anchor.getBoundingClientRect().top + window.pageYOffset
      : 0;
    window.scroll({
      top: offsetTop - offset(),
      behavior: 'smooth',
    });
  };
  return (
    <FooterWrapper>
      <Box>
        <Stack
          direction="row"
          spacing={2}
          justifyContent="space-between"
          alignItems="center"
        >
          <Typography fontSize="16px">© 2021 Internnect Co., Ltd</Typography>
          <FooterMenu>
            {Menu.map(it => (
              <Item key={it.id}>
                <Link
                  href={`#${it.anchor}`}
                  onClick={e => smoothScroll(e, it.anchor)}
                >
                  {it.name}
                </Link>
              </Item>
            ))}
          </FooterMenu>
          <LanguageMenu />
        </Stack>
      </Box>
    </FooterWrapper>
  );
};

const FooterWrapper = styled.div`
  background: ${Colors.lightBlue};
  > section {
    padding: 10px 0;
  }
`;
const Box = styled.div`
  max-width: 1024px;
  margin: 0 auto;
  padding: 20px 0;
  @media ${device.laptop} {
    padding: 20px 24px;
  }
  @media ${device.tablet} {
    padding: 16px 24px;
  }
  @media ${device.tablet} {
    .css-hxrfoi-MuiStack-root {
      justify-content: center;
    }
  }
`;
const FooterMenu = styled.ul`
  position: relative;
  list-style-type: none;
  z-index: 8;
  @media ${device.tablet} {
    display: none;
  }
`;

const Item = styled.li`
  display: inline-block;
  margin-right: 15px;
  &:first-child,
  &:nth-of-type(2) {
    display: none;
  }
`;

const Link = styled.a`
  color: inherit;
  text-decoration: none;
`;
