import { Button, IconButton, Stack, TableCell, TableRow } from '@mui/material';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { Delete, Edit } from '@mui/icons-material';
import { COLOR_BUTTON } from 'constants/common';
import { IHierarchyData } from 'types/common';
import { isChildrenDepartment } from 'utils/helper';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { IDepartmentsSchema } from '../slice/types';

interface IRowCell {
  childs: any;
  isParent: boolean;
  parentId: number;
  cssPaddingLeft: number;
  hierarchyData: IHierarchyData[] | [];
  setHierarchyData: (data: IHierarchyData[] | []) => void;
  setDepartmentDataChange: (data: IDepartmentsSchema | undefined) => void;
  setIsOpenModal: (data: boolean) => void;
  setIsShowConfirm: (data: boolean) => void;
}

export default function RowCell(props: IRowCell) {
  const {
    childs,
    isParent,
    parentId,
    cssPaddingLeft,
    hierarchyData,
    setHierarchyData,
    setDepartmentDataChange,
    setIsOpenModal,
    setIsShowConfirm,
  } = props;
  const handleOpen = (id: Number) => {
    const newHierarchyData = hierarchyData?.map(item => {
      if (item.hierarchy.includes(id)) {
        if (item.isOpen) {
          return {
            ...item,
            isOpen: false,
          };
        } else {
          const lastValue = item.hierarchy[item.hierarchy.length - 1];
          return {
            ...item,
            isOpen: lastValue === id ? true : item.isOpen,
          };
        }
      }
      return {
        ...item,
      };
    });
    setHierarchyData(newHierarchyData);
  };
  return (
    <>
      <TableRow>
        <TableCell sx={{ maxWidth: 60 }} width={60}>
          {isParent && (
            <IconButton
              aria-label="expand row"
              size="small"
              onClick={() => {
                handleOpen(childs?.id);
              }}
            >
              {isChildrenDepartment(hierarchyData, parentId) ? (
                <KeyboardArrowUpIcon />
              ) : (
                <KeyboardArrowDownIcon />
              )}
            </IconButton>
          )}
        </TableCell>
        <TableCell
          sx={{
            fontWeight: isParent ? 'bold' : 'unset',
            paddingLeft: cssPaddingLeft + 'px',
            maxWidth: 500,
          }}
          width={`fill`}
        >
          {!isParent && (
            <IconButton
              aria-label="expand row"
              size="small"
              sx={{ paddingRight: '10px' }}
              onClick={() => {
                handleOpen(childs?.id);
              }}
            >
              {isChildrenDepartment(hierarchyData, parentId) ? (
                <KeyboardArrowUpIcon />
              ) : (
                <KeyboardArrowDownIcon />
              )}
            </IconButton>
          )}
          {childs?.name}
        </TableCell>
        <TableCell
          sx={{
            fontWeight: isParent ? 'bold' : 'unset',
            textAlign: 'center',
            maxWidth: 100,
          }}
          width={100}
        >
          {childs?.members}
        </TableCell>
        <TableCell
          sx={{
            maxWidth: 120,
            textAlign: 'center',
          }}
          width={120}
        >
          <Stack direction="row" spacing="10px">
            <Button
              sx={{ minWidth: 'auto' }}
              onClick={() => {
                setDepartmentDataChange(childs);
                setIsOpenModal(true);
              }}
            >
              <Edit
                sx={{
                  color: COLOR_BUTTON.DELETE_EDIT_ICON,
                }}
              />
            </Button>
            <Button
              sx={{ minWidth: 'auto' }}
              onClick={() => {
                setDepartmentDataChange(childs);
                setIsShowConfirm(true);
              }}
            >
              <Delete
                sx={{
                  color: COLOR_BUTTON.DELETE_EDIT_ICON,
                }}
              />
            </Button>
          </Stack>
        </TableCell>
      </TableRow>
    </>
  );
}
