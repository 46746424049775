import { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from 'utils/@reduxjs/toolkit';
import { useInjectReducer, useInjectSaga } from 'utils/redux-injectors';
import loginSaga from './saga';
import { LoginState } from './types';
import { RequestStatus } from 'constants/API';
import { ISuccessPayload } from './types';

export const initialState: LoginState = {
  loginStatus: RequestStatus.IDLE,
  loginGgStatus: RequestStatus.IDLE,
  accessToken: '',
  userRole: '',
  errorMess: [],
};

const slice = createSlice({
  name: 'login',
  initialState,
  reducers: {
    loginRequest: (state, action: PayloadAction<any>) => {
      if (action) state.loginStatus = RequestStatus.REQUESTING;
    },
    loginSuccess: (state, action: PayloadAction<ISuccessPayload>) => {
      state.accessToken = action.payload.access;
      state.userRole = action.payload.userRole;
      state.loginStatus = RequestStatus.SUCCESS;
    },
    loginFail: (state, action: PayloadAction<any>) => {
      if (action) state.loginStatus = RequestStatus.ERROR;
      state.errorMess = action.payload.errorMess;
    },
    loginWithGgRequest: (state, action: PayloadAction<any>) => {
      if (action) state.loginGgStatus = RequestStatus.REQUESTING;
    },
    loginWithGgSuccess: (state, action: PayloadAction<ISuccessPayload>) => {
      if (action) {
        state.loginGgStatus = RequestStatus.SUCCESS;
        state.accessToken = action.payload.access;
        state.userRole = action.payload.userRole;
      }
    },
    loginWithGgFail: (state, action: PayloadAction<any>) => {
      if (action) {
        state.loginGgStatus = RequestStatus.ERROR;
        state.errorMess = action.payload.errorMess;
      }
    },
    resetLoginStatus: state => {
      state.errorMess = [];
      state.loginStatus = RequestStatus.IDLE;
      state.loginGgStatus = RequestStatus.IDLE;
      state.userRole = '';
    },
  },
});

export default slice.actions;

export const useLoginSlice = () => {
  useInjectReducer({ key: slice.name, reducer: slice.reducer });
  useInjectSaga({ key: slice.name, saga: loginSaga });
  return { actions: slice.actions };
};
