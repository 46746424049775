// UI
import { styled } from '@mui/material/styles';
import { TextField, Stack, Box } from '@mui/material';
import DateRangePicker from '@mui/lab/DateRangePicker';
import MuiDateRangePickerDay from '@mui/lab/DateRangePickerDay';
import React from 'react';
// Redux & Sagas

// Library
const DateRangePickerDay = styled(MuiDateRangePickerDay)``;
export function TimeRangeSelectFilter(props) {
  const { value, setValue } = props;
  const renderWeekPickerDay = (date, dateRangePickerDayProps) => {
    return <DateRangePickerDay {...dateRangePickerDayProps} />;
  };
  return (
    <DateRangePicker
      inputFormat="YYYY/MM/DD"
      startText=""
      endText=""
      value={value}
      onChange={newValue => setValue(newValue)}
      renderDay={renderWeekPickerDay}
      renderInput={(startProps, endProps) => (
        <Stack
          direction="row"
          alignItems="center"
          spacing={1}
          sx={{
            minWidth: 400,
          }}
        >
          <React.Fragment>
            <TextField {...startProps} fullWidth hiddenLabel size="small" />
            <Box sx={{ mx: 2 }}> ~ </Box>
            <TextField {...endProps} fullWidth hiddenLabel size="small" />
          </React.Fragment>
        </Stack>
      )}
    ></DateRangePicker>
  );
}

export default TimeRangeSelectFilter;
