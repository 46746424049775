import {
  Box,
  Card,
  CardContent,
  Divider,
  List,
  ListItem,
  Paper,
  Stack,
  Typography,
} from '@mui/material';
import styled from 'styled-components';

import _ from 'lodash';
import moment from 'moment';
import { HourTag } from 'app/components/HourTag';
import { useSelector } from 'react-redux';
import { selectTimesheet } from '../slice/selectors';
import { useTranslation } from 'react-i18next';
import { ITimesheet } from '../slice/types';
import CollapseTable from './CollapseTable';

interface IViewModeList {
  weekStart: any;
  activeDate: string;
  handleChangePage: Function;
  setSeletedTaskLogData: (value: ITimesheet | undefined) => void;
  setIsShowModalTaskLog: (value: boolean) => void;
  setIsShowDeleteTaskDialog: (value: boolean) => void;
  isShowProject: number[];
  setIsShowProject: (value: number[]) => void;
}

export function ViewModeList(props: IViewModeList) {
  const { t } = useTranslation();
  const {
    weekStart,
    activeDate,
    handleChangePage,
    setSeletedTaskLogData,
    setIsShowModalTaskLog,
    setIsShowDeleteTaskDialog,
    isShowProject,
    setIsShowProject,
  } = props;
  const { timesheetList, dateRangeWorkReport } = useSelector(selectTimesheet);

  const timesheetLogs: { [key: string]: ITimesheet[] } = _.reduce(
    timesheetList,
    (result, value) => {
      (
        result[_.get(value, 'project.id')] ||
        (result[_.get(value, 'project.id')] = [])
      ).push(value);
      return result;
    },
    {},
  );
  return (
    <>
      <Paper>
        <TimesheetPanel className="align-self-center">
          <Box>
            <TimeSeries>
              {_.times(7).map((i, idx) => {
                const d = moment(weekStart).add(i, 'days');
                const d_text = d.format('YYYY/MM/DD');
                const _class = [
                  'd-flex',
                  'flex-column',
                  'align-items-center',
                  'justify-content-center',
                ];
                if (activeDate === d_text) {
                  _class.push('active');
                }
                return (
                  <ListItem
                    key={`item_${idx}`}
                    className={_class.join(' ')}
                    onClick={() => {
                      setIsShowProject([]);
                      handleChangePage('', d);
                    }}
                  >
                    <p>{d_text}</p>
                    <HourTag
                      data={{
                        backgroundColor:
                          activeDate === d_text ? '#fff' : '#BDD8FF',
                        label: `${
                          dateRangeWorkReport
                            ? dateRangeWorkReport[i]?.duration || 0
                            : 0
                        }h`,
                      }}
                      size="small"
                    ></HourTag>
                  </ListItem>
                );
              })}
            </TimeSeries>
          </Box>
        </TimesheetPanel>
      </Paper>
      <Stack direction="column" spacing={1}>
        {_.isEmpty(timesheetLogs) ? (
          <Card>
            <CardContent>
              <Typography>{t('Nothing here')}!</Typography>
            </CardContent>
          </Card>
        ) : (
          <>
            <HeaderTaskLog>
              <CardContent style={{ paddingRight: '0px' }}>
                <StackTaskLog direction="row">
                  <WrapTaskLog pl={0}>
                    {t('Task description')} <Divider orientation="vertical" />
                  </WrapTaskLog>
                  <WrapTaskLog>
                    {t('Tag')}
                    <Divider orientation="vertical" />
                  </WrapTaskLog>
                  <WrapTaskLog>
                    {t('Duration')}
                    <Divider orientation="vertical" />
                  </WrapTaskLog>
                  <WrapTaskLog>{t('Action')}</WrapTaskLog>
                </StackTaskLog>
              </CardContent>
            </HeaderTaskLog>

            {_.map(timesheetLogs, (project, key) => {
              const totalHours = _.sumBy(project, o => _.toNumber(o.hours));
              return (
                <CollapseTable
                  key={`prj_${key}`}
                  totalHours={totalHours}
                  project={project}
                  setSeletedTaskLogData={setSeletedTaskLogData}
                  setIsShowModalTaskLog={setIsShowModalTaskLog}
                  setIsShowDeleteTaskDialog={setIsShowDeleteTaskDialog}
                  isShowProject={isShowProject}
                  setIsShowProject={setIsShowProject}
                ></CollapseTable>
              );
            })}
          </>
        )}
      </Stack>{' '}
    </>
  );
}

const TimesheetPanel = styled.div``;

const TimeSeries = styled(List)`
  position: relative;
  text-align: center;
  padding: 0 !important;
  .MuiListItem-root {
    &:not(:last-child) {
      border-right: 1px solid #e2e3e4;
    }
    display: inline-flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: calc(100% / 7);
    height: 68px;
    margin-left: -1px;
    p {
      margin: 0;
    }
    .MuiChip-root {
      margin-top: 10px;
      color: #181c32;
    }
    &:hover {
      cursor: pointer;
      background: rgba(0, 0, 0, 0.02);
    }
    &.active {
      -webkit-border-radius: 5px;
      -moz-border-radius: 5px;
      border-radius: 1px;
      box-shadow: 0px 0px 0px 10px #437dff;
      background: #437dff;
      font-size: 1.15rem;
      font-weight: 600;
      z-index: 96;
      border: 0;
      p {
        color: #fff;
      }
      .MuiChip-root {
        background-color: #fff;
        border-color: #fff;
      }
    }
  }
`;

const HeaderTaskLog = styled(Card)`
  background-color: #f0f7ff !important;
  & .MuiCardContent-root:last-child {
    padding-bottom: 16px;
  }
`;

const StackTaskLog = styled(Stack)`
  & div {
    font-size: 14px;
    font-weight: 500;
    &:first-child {
      flex: 1;
      padding-left: 23.5px;
      min-width: 130px;
    }
    &:nth-child(2) {
      width: 180px;
      min-width: 70px;
    }
    &:nth-child(3) {
      width: 180px;
      min-width: 70px;
    }
    &:nth-child(4) {
      width: 150px;
      min-width: 70px;
    }
  }
`;

const WrapTaskLog = styled(Box)`
  display: flex;
  justify-content: space-between;
  padding-left: 16px;
`;
