import { Avatar, Box, Card, CardContent, Typography } from '@mui/material';
import MemberImg from 'assets/img/mem.png';
import _ from 'lodash';
import React from 'react';
import Slider from 'react-slick';
import styled from 'styled-components';
import { IMember } from '../slice/types';
import { Heading } from './Heading';
import { Colors, device } from './Variables';

interface Props {
  members: IMember[] | null;
}

const backgrounds = ['orange', 'blue', 'violet', 'pink', 'green'];

export const Members = (props: Props) => {
  const { members } = props;
  const memberLength = members ? members.length : 0;
  const boundClass = memberLength < 4 ? 'max-80' : '';
  const settings = {
    centerMode: _.toInteger(window.innerWidth) > 425 ? true : false,
    centerPadding: memberLength > 3 ? '60px' : '0px',
    infinite: true,
    slidesToShow: memberLength > 3 ? memberLength - 1 : 3,
    speed: 500,
    initialSlide: 0,
    adaptiveHeight: false,
    dots: false,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };
  return (
    <Section id="Members">
      <Heading>Our team</Heading>
      <SliderWrapper className={boundClass}>
        <Slider {...settings}>
          {members != null
            ? members.map((it, index) => (
                <Box padding="20px" key={index}>
                  <ImgBox className={backgrounds[index % backgrounds.length]}>
                    <Img src={it.avatar ?? MemberImg} alt={it.full_name} />
                    <CardContent>
                      <Typography
                        variant="h5"
                        component="h5"
                        fontSize="16px"
                        paddingBottom="10px"
                        textAlign="center"
                        color={Colors.darkBlue}
                      >
                        {it.role}
                      </Typography>
                      <Typography
                        variant="h4"
                        component="h4"
                        fontSize="24px"
                        textAlign="center"
                        color={Colors.blueHeading}
                      >
                        {it.full_name}
                      </Typography>
                    </CardContent>
                  </ImgBox>
                </Box>
              ))
            : ''}
        </Slider>
      </SliderWrapper>
    </Section>
  );
};

const Section = styled.section`
  margin-bottom: 200px;
  @media ${device.laptop} {
    margin-bottom: 50px;
  }
  @media ${device.tablet} {
    margin-bottom: 30px;
  }
`;
const Img = styled(Avatar)`
  &.MuiAvatar-root {
    width: 200px;
    height: 200px;
    display: block;
    margin: 0 auto;

    @media (max-width: 950px) {
      width: 180px;
      height: 180px;
    }
  }
  @media ${device.mobileL} {
    width: auto;
    height: auto;
  }
`;
const SliderWrapper = styled.div`
  position: relative;
  &.max-80 .slick-slider {
    max-width: 80%;
    margin: 0 auto;
    @media (max-width: 1200px) {
      max-width: 100%;
    }
  }

  .slick-slide {
    max-width: 330px;
    height: inherit;
    > div {
      height: 100%;
    }
    .MuiBox-root {
      height: 100%;
    }
    @media (max-width: 1200px) {
      max-width: none;
    }
  }
  .slick-prev,
  .slick-next {
    visibility: hidden;
    right: 0;
    left: 0;
  }
  .slick-dots {
    visibility: hidden;
  }
  .slick-track {
    display: flex;
    align-items: stretch;
  }

  @media ${device.tablet} {
    .MuiBox-root {
      padding: 0 10px;
    }
    .slick-prev {
      visibility: visible;
      left: 0;
      z-index: 9;
      top: 50%;
      width: 40px;
      height: 40px;
      background: ${Colors.lightBlue};
    }
    .slick-next {
      visibility: visible;
      right: 0;
      left: auto;
      z-index: 9;
      top: 50%;
      width: 40px;
      height: 40px;
      background: ${Colors.blueHeading};
    }
    .slick-next:before,
    .slick-prev:before {
      font-family: inherit;
    }
    .slick-prev:before {
      color: ${Colors.blueHeading};
    }
    .slick-next:focus,
    .slick-next:hover {
      background: ${Colors.blueHeading};
    }
    .slick-prev:focus,
    .slick-prev:hover {
      background: ${Colors.lightBlue};
    }
  }
`;

const ImgBox = styled(Card)`
  padding: 32px 0;
  justify-content: center;
  height: 100%;
  /* max-width: 330px; */
  &.css-bhp9pd-MuiPaper-root-MuiCard-root {
    border-radius: 0;
  }
  .css-46bh2p-MuiCardContent-root {
    padding: 24px 15px;
  }
  &.orange {
    background: linear-gradient(
      180deg,
      rgba(255, 210, 235, 0.17) 0%,
      rgba(255, 206, 233, 0.17) 0%,
      rgba(255, 206, 233, 0.17) 0%,
      rgba(255, 206, 233, 0.17) 0%,
      rgba(235, 137, 244, 0.17) 0%,
      rgba(235, 137, 244, 0.17) 0%,
      rgba(236, 138, 244, 0.178524) 0.01%,
      rgba(236, 138, 244, 0.182661) 0.02%,
      rgba(236, 138, 244, 0.186696) 0.03%,
      rgba(253, 238, 229, 0.47) 0.04%,
      #fedec9 102.01%,
      rgba(255, 206, 233, 0.98804) 102.02%,
      rgba(229, 138, 244, 0.992159) 102.03%,
      #89def4 102.04%,
      #df89f4 102.05%,
      #ffcbed 102.05%,
      #ffcbed 102.05%,
      #fee5e9 102.05%,
      #fee5e9 102.05%,
      #fee5e9 102.05%,
      #fee5e9 102.05%,
      #fadab4 102.05%,
      #fadab4 102.05%,
      #fadab4 102.05%,
      #fadab4 102.05%,
      #fadab4 102.05%,
      #fadab4 102.05%
    );
  }
  &.blue {
    background: linear-gradient(
      180deg,
      rgba(137, 222, 244, 0.24) 0%,
      rgba(137, 222, 244, 0.6) 99.96%,
      rgba(137, 222, 244, 0.99233) 99.97%,
      rgba(137, 222, 244, 0.996117) 99.98%,
      #89def4 99.99%,
      rgba(187, 241, 255, 0.76) 100%,
      rgba(187, 241, 255, 0.76) 100%,
      rgba(187, 241, 255, 0.76) 100%,
      rgba(187, 241, 255, 0.76) 100%
    );
  }
  &.violet {
    background: linear-gradient(
      180deg,
      #eaf7fe 0%,
      rgba(255, 206, 233, 0.17) 0%,
      rgba(255, 206, 233, 0.17) 0%,
      rgba(255, 206, 233, 0.17) 0%,
      rgba(235, 137, 244, 0.17) 0%,
      rgba(235, 137, 244, 0.17) 0%,
      rgba(236, 138, 244, 0.178524) 0.01%,
      rgba(236, 138, 244, 0.182661) 0.02%,
      rgba(236, 138, 244, 0.186696) 0.03%,
      #e9effe 0.04%,
      #d9e2ff 100.53%,
      rgba(255, 206, 233, 0.98804) 100.54%,
      rgba(229, 138, 244, 0.992159) 100.55%,
      #89def4 100.56%,
      #df89f4 100.57%,
      #ffcbed 100.57%,
      #ffcbed 100.57%,
      #eaf0fe 100.57%,
      #eaf2fe 100.57%,
      #eaf2fe 100.57%,
      #eaf2fe 100.57%
    );
  }
  &.pink {
    background: linear-gradient(
      180deg,
      rgba(249, 193, 204, 0.46) 0%,
      #f9c1cc 99.97%,
      rgba(249, 194, 206, 0.24) 99.98%,
      rgba(255, 210, 219, 0.0104167) 99.99%,
      rgba(196, 196, 196, 0) 100%,
      rgba(196, 196, 196, 0) 100%,
      rgba(196, 196, 196, 0) 100%,
      rgba(254, 200, 196, 0.8) 100%,
      rgba(254, 200, 196, 0.8) 100%,
      rgba(254, 200, 196, 0.8) 100%
    );
  }
  &.green {
    background: linear-gradient(
      180deg,
      rgba(176, 217, 192, 0.51) 0%,
      #b0d9c0 99.99%,
      rgba(176, 217, 192, 0) 100%
    );
  }
`;
