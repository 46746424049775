import React from 'react';

// UI
import { Box, Stack, Typography } from '@mui/material';

// Library
import styled from 'styled-components/macro';

interface ISummaryInfo {
  icon: any;
  title: string;
  color?: string;
  hour?: string | number;
  data?: string;
}

export function SummaryInfo(props: ISummaryInfo) {
  const { icon, title, hour, data } = props;
  const color = props.color ?? '#2E5ED9';
  return (
    <Stack direction="row" spacing={1}>
      <Box display="flex" justifyContent="center" alignItems="center">
        {icon}
      </Box>
      <Box>
        <SummaryTitle>{title}</SummaryTitle>
        <Typography component="div" variant="h6" color={color}>
          {data ? data : `${hour}h`}
        </Typography>
      </Box>
    </Stack>
  );
}

const SummaryTitle = styled.div`
  font-size: 12px;
  color: rgb(0 0 0 / 65%);
  line-height: 20px;
`;
