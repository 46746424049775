import { call, put, takeLatest } from 'redux-saga/effects';
import Slice from '.';
import { StatusCode } from 'constants/API';
import APIs from 'APIs';

function* getDepartmentsFlow({ payload }) {
  try {
    const response = yield call(APIs.getDepartments, payload);
    if (response.status === StatusCode.SUCCESS_COMMON) {
      yield put({
        type: Slice.getDepartmentSuccess.type,
        payload: response.data,
      });
    } else {
      yield put({ type: Slice.getDepartmentFail.type });
    }
  } catch (error) {
    yield put({ type: Slice.getDepartmentFail.type });
  }
}

function* getTreeDepartmentsFlow({ payload }) {
  try {
    const response = yield call(APIs.getTreeDepartments, payload);
    if (response.status === StatusCode.SUCCESS_COMMON) {
      yield put({
        type: Slice.getTreeDepartmentSuccess.type,
        payload: response.data,
      });
    } else {
      yield put({ type: Slice.getTreeDepartmentFail.type });
    }
  } catch (error) {
    yield put({ type: Slice.getTreeDepartmentFail.type });
  }
}

function* createDepartmentsFlow({ payload }) {
  try {
    const response = yield call(APIs.createDepartments, payload);
    if (response.status === StatusCode.SUCCESS_CREATED) {
      yield put({
        type: Slice.createDepartmentSuccess.type,
        payload: response.data,
      });
    } else {
      yield put({ type: Slice.createDepartmentFail.type });
    }
  } catch (error) {
    yield put({ type: Slice.createDepartmentFail.type });
  }
}

function* updateDepartmentsFlow({ payload }) {
  try {
    const { id, formData } = payload;
    const response = yield call(APIs.updateDepartments, id, formData);
    if (response.status === StatusCode.SUCCESS_COMMON) {
      yield put({
        type: Slice.updateDepartmentSuccess,
        payload: response.data,
      });
    } else {
      yield put({ type: Slice.updateDepartmentFail.type });
    }
  } catch (error) {
    yield put({ type: Slice.updateDepartmentFail.type });
  }
}

function* deleteDepartmentsFlow({ payload }) {
  try {
    const response = yield call(APIs.deleteDepartments, payload);
    if (response.status === StatusCode.NO_CONTENT) {
      yield put({
        type: Slice.deleteDepartmentSuccess.type,
        payload: response.data,
      });
    } else {
      yield put({ type: Slice.deleteDepartmentFail.type });
    }
  } catch (error) {
    yield put({ type: Slice.deleteDepartmentFail.type });
  }
}

function* DepartmentWatcher() {
  yield takeLatest(Slice.getDepartmentRequest, getDepartmentsFlow);
  yield takeLatest(Slice.getTreeDepartmentRequest, getTreeDepartmentsFlow);
  yield takeLatest(Slice.createDepartmentRequest, createDepartmentsFlow);
  yield takeLatest(Slice.updateDepartmentRequest, updateDepartmentsFlow);
  yield takeLatest(Slice.deleteDepartmentRequest, deleteDepartmentsFlow);
}

export default DepartmentWatcher;
