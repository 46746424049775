import { Visibility, VisibilityOff } from '@mui/icons-material';
import { IconButton, InputAdornment, TextField } from '@mui/material';
import { useState } from 'react';
import { UseFormRegisterReturn, UseFormReturn } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

interface IProps {
  form: UseFormReturn;
  fieldName: string;
  register: UseFormRegisterReturn;
}

export const TextFieldPassword = (props: IProps) => {
  const { form, fieldName, register } = props;
  const [showPassword, setShowPassword] = useState(false);
  const { t } = useTranslation();
  const {
    formState: { errors },
  } = form;

  const togglePasswordVisibility = () => {
    setShowPassword(prev => !prev);
  };

  return (
    <TextField
      variant="outlined"
      fullWidth
      margin="dense"
      size="small"
      {...register}
      placeholder={t('Enter password')}
      error={!!errors[fieldName]}
      helperText={errors[fieldName]?.message}
      type={showPassword ? 'text' : 'password'}
      inputProps={{
        autoComplete: 'cc-csc',
      }}
      InputProps={{
        endAdornment: (
          <InputAdornment position="end">
            <IconButton
              aria-label="toggle password visibility"
              onClick={togglePasswordVisibility}
              edge="end"
            >
              {showPassword ? <VisibilityOff /> : <Visibility />}
            </IconButton>
          </InputAdornment>
        ),
      }}
    />
  );
};
