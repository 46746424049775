import { useDispatch, useSelector } from 'react-redux';

import { useRecruitmentSlice } from './slice';
import { selectRecruitments } from './slice/selectors';
import { useMemberSlice } from '../MemberPage/slice';
import { selectMember } from '../MemberPage/slice/selectors';

const useRecruitment = () => {
  const dispatch = useDispatch();

  const {
    actions: { getSkillListRequest, getLanguageListRequest },
  } = useMemberSlice();
  const { skillsList, language_metadata } = useSelector(selectMember);

  const {
    getJobCategoriesRequest,
    getJobListRequest,
    createJobRequest,
    deleteJobRequest,
    updateJobRequest,
    resetJobInfoRequestStatus,
    updateJobStatusRequest,
    resetJobState,
    reCandidateRequest,
    updateReCandidateRequest,
    deleteReCandidateRequest,
    resetJobDetailState,
    getSourceCVRequest,
    uploadCVsRequest,
    createCandidateRequest,
    updateCandidateRequest,
    resetReCandidateState,
    getCandidateListRequest,
    resetCandidatesStatus,
    resetCandiddatesState,
  } = useRecruitmentSlice().actions;
  const {
    getJobListStatus,
    createJobStatus,
    updateJobStatus,
    updateJobShowStatus,
    deleteJobStatus,
    listJob,
    listError,
    jobCategories,
    getJobCategoriesStatus,
    recCandidateState,
    recruitmentCandidate,
    updateReCandidateState,
    deleteReCandidateState,
    sourceCV,
    getSourceCVStatus,
    uploadCVsState,
    uploadCVsStatus,
    errorMess,
    languageProficiency,
    createCandidateStatus,
    updateCandidateStatus,
    listCandidate,
    getCandidateListStatus,
  } = useSelector(selectRecruitments);

  const getSkillsTechnial = () => {
    dispatch(getSkillListRequest({}));
  };
  const getLanguages = () => {
    dispatch(getLanguageListRequest({}));
  };

  const getJobList = params => {
    dispatch(getJobListRequest(params));
  };
  const updateStatusJobs = (job_ids, state) => {
    dispatch(updateJobStatusRequest({ job_ids, state }));
  };
  const getJobCategories = () => {
    dispatch(getJobCategoriesRequest({}));
  };
  const createNewJob = formData => {
    dispatch(createJobRequest(formData));
  };
  const updateJob = (objectId, formData) => {
    dispatch(updateJobRequest({ objectId, formData }));
  };
  const deleteJob = data => {
    dispatch(deleteJobRequest(data));
  };
  const resetJobInfo = () => {
    dispatch(resetJobInfoRequestStatus());
  };
  const resetJobStateRequesting = () => {
    dispatch(resetJobState());
  };

  // JOB DETAIL
  const reCandidate = params => {
    dispatch(reCandidateRequest(params));
  };
  const updateReCandidate = params => {
    dispatch(updateReCandidateRequest(params));
  };
  const deleteReCandidate = params => {
    dispatch(deleteReCandidateRequest(params));
  };
  const resetJobDetail = () => {
    dispatch(resetJobDetailState());
  };
  const getSourceCV = () => {
    dispatch(getSourceCVRequest({}));
  };
  const uploadCVs = ({ id, payload }) => {
    dispatch(uploadCVsRequest({ id, payload }));
  };
  const createCandidate = params => {
    dispatch(createCandidateRequest(params));
  };
  const updateCandidate = ({ id, payload }) => {
    dispatch(updateCandidateRequest({ id, payload }));
  };
  const resetReCandidate = () => {
    dispatch(resetReCandidateState());
  };

  // Candidate list
  const getCandidateHistory = params => {
    dispatch(getCandidateListRequest(params));
  };
  const resetCandidateListStatus = () => {
    dispatch(resetCandidatesStatus());
  };
  const resetCandiddatesListState = () => {
    dispatch(resetCandiddatesState());
  };

  return {
    getJobList,
    getJobListStatus,
    createJobStatus,
    updateJobStatus,
    updateJobShowStatus,
    deleteJobStatus,
    listJob,
    listError,
    updateStatusJobs,
    jobCategories,
    getJobCategoriesStatus,
    getJobCategories,
    resetJobInfo,
    updateJob,
    createNewJob,
    deleteJob,
    resetJobStateRequesting,
    reCandidate,
    recCandidateState,
    recruitmentCandidate,
    updateReCandidate,
    updateReCandidateState,
    deleteReCandidate,
    deleteReCandidateState,
    resetJobDetail,
    getSourceCVStatus,
    sourceCV,
    getSourceCV,
    uploadCVs,
    uploadCVsState,
    uploadCVsStatus,
    errorMess,
    languageProficiency,
    createCandidate,
    createCandidateStatus,
    updateCandidate,
    updateCandidateStatus,
    skillsList,
    language_metadata,
    getSkillsTechnial,
    getLanguages,
    resetReCandidate,
    getCandidateHistory,
    listCandidate,
    getCandidateListStatus,
    resetCandidateListStatus,
    resetCandiddatesListState,
  };
};

export default useRecruitment;
