import React from 'react';

// UI
import {
  TableBody,
  TableRow,
  TableCell,
  Typography,
  FormControl,
  Select,
  MenuItem,
} from '@mui/material';

// Library
import { UseFormReturn, Controller } from 'react-hook-form';

interface ISkillLanguageProps {
  dataId: number;
  title: string;
  rows?: number;
  memberForm: UseFormReturn;
  startIndex?: number;
}

export default function SkillLanguage(props: ISkillLanguageProps) {
  const { setValue, control } = props.memberForm;

  const baseIndex = props.startIndex;
  React.useEffect(() => {
    setValue(`languages[${props.startIndex}].language`, props.dataId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <TableBody>
      <TableRow key={`languages_${baseIndex}`}>
        <TableCell rowSpan={props.rows} component="th">
          {props.title}
        </TableCell>
        <TableCell width={200}>
          <Typography>Reading</Typography>
        </TableCell>
        <TableCell width={100}>
          <FormControl fullWidth>
            <Controller
              control={control}
              name={`languages[${baseIndex}].reading_level`}
              defaultValue=""
              render={({ field }) => {
                const { onBlur, onChange, value } = field;
                return (
                  <Select
                    fullWidth
                    size="small"
                    onBlur={onBlur}
                    onChange={onChange}
                    value={value}
                  >
                    <MenuItem value={'3'}>◎</MenuItem>
                    <MenuItem value={'2'}>◯</MenuItem>
                    <MenuItem value={'1'}>△</MenuItem>
                    <MenuItem value={'0'}>-</MenuItem>
                  </Select>
                );
              }}
            />
          </FormControl>
        </TableCell>
        <TableCell width={200}>
          <Typography>Writing</Typography>
        </TableCell>
        <TableCell width={100}>
          <FormControl fullWidth>
            <Controller
              control={control}
              name={`languages[${baseIndex}].writing_level`}
              defaultValue=""
              render={({ field }) => {
                const { onBlur, onChange, value } = field;
                return (
                  <Select
                    fullWidth
                    size="small"
                    onBlur={onBlur}
                    onChange={onChange}
                    value={value}
                  >
                    <MenuItem value={'3'}>◎</MenuItem>
                    <MenuItem value={'2'}>◯</MenuItem>
                    <MenuItem value={'1'}>△</MenuItem>
                    <MenuItem value={'0'}>-</MenuItem>
                  </Select>
                );
              }}
            />
          </FormControl>
        </TableCell>
        <TableCell width={200}>
          <Typography>Speaking</Typography>
        </TableCell>
        <TableCell width={100}>
          <FormControl fullWidth>
            <Controller
              control={control}
              name={`languages[${baseIndex}].speaking_level`}
              defaultValue=""
              render={({ field }) => {
                const { onBlur, onChange, value } = field;
                return (
                  <Select
                    fullWidth
                    size="small"
                    onBlur={onBlur}
                    onChange={onChange}
                    value={value}
                  >
                    <MenuItem value={'3'}>◎</MenuItem>
                    <MenuItem value={'2'}>◯</MenuItem>
                    <MenuItem value={'1'}>△</MenuItem>
                    <MenuItem value={'0'}>-</MenuItem>
                  </Select>
                );
              }}
            />
          </FormControl>
        </TableCell>
        <TableCell width={200}>
          <Typography>Listening</Typography>
        </TableCell>
        <TableCell width={100}>
          <FormControl fullWidth>
            <Controller
              control={control}
              name={`languages[${baseIndex}].listening_level`}
              defaultValue=""
              render={({ field }) => {
                const { onBlur, onChange, value } = field;
                return (
                  <Select
                    fullWidth
                    size="small"
                    onBlur={onBlur}
                    onChange={onChange}
                    value={value}
                  >
                    <MenuItem value={'3'}>◎</MenuItem>
                    <MenuItem value={'2'}>◯</MenuItem>
                    <MenuItem value={'1'}>△</MenuItem>
                    <MenuItem value={'0'}>-</MenuItem>
                  </Select>
                );
              }}
            />
          </FormControl>
        </TableCell>
      </TableRow>
    </TableBody>
  );
}
