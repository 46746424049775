import { Button } from '@mui/material';
import path from 'config/clientPath';
import React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { isHavingToken } from 'utils/localStorage';
import { Colors, device } from './Variables';
import { useSelector } from 'react-redux';
import { selectGlobal } from 'app/pages/GlobalContainer/slice/selectors';
import roles from 'constants/roles';

interface INavigation {
  open: boolean;
  isMobile: boolean;
  update: (boolean) => void;
}
export const Menu = [
  { id: 1, name: 'Menu', anchor: '' },
  { id: 2, name: 'Home', anchor: '' },
  { id: 3, name: 'About', anchor: 'About' },
  { id: 4, name: 'Members', anchor: 'Members' },
  { id: 5, name: 'Technology', anchor: 'Technology' },
  { id: 6, name: 'Projects', anchor: 'Projects' },
  { id: 7, name: 'Customers', anchor: 'Customers' },
];

const Navigation = (props: INavigation) => {
  const { open, isMobile, update } = props;

  const {
    userSessionData: { userRole },
  } = useSelector(selectGlobal);

  let offset = () => 0;

  const smoothScroll = (e, anchor) => {
    update(false);
    e.preventDefault();
    const $anchor = document.getElementById(anchor);
    const offsetTop = $anchor
      ? $anchor.getBoundingClientRect().top + window.pageYOffset
      : 0;
    window.scroll({
      top: offsetTop - offset(),
      behavior: 'smooth',
    });
  };
  return (
    <>
      <NavigationContainer
        open={open}
        isMobile={isMobile}
        className="overlay"
        onClick={() => update(false)}
      />

      <NavigationContainer open={open} isMobile={isMobile}>
        <ul>
          {Menu.map(it => (
            <li key={it.id}>
              <Link
                to={`#${it.anchor}`}
                onClick={e => smoothScroll(e, it.anchor)}
              >
                {it.name}
              </Link>
            </li>
          ))}
          <li>
            {isHavingToken() ? (
              <Button>
                <Link
                  to={
                    userRole === roles.IS_ADMIN
                      ? path.CUSTOMERS
                      : path.TIMESHEET
                  }
                >
                  View Admin Site
                </Link>
              </Button>
            ) : (
              <Button>
                <Link to={path.LOGIN}>Login</Link>
              </Button>
            )}
          </li>
        </ul>
      </NavigationContainer>
    </>
  );
};

export default Navigation;

const NavigationContainer = styled.div<Partial<INavigation>>`
  &.overlay {
    display: none;
    z-index: 3;
    opacity: 0.8;
    background: transparent;
  }
  transition: width 0.2s ease-in-out;
  @media ${device.tablet} {
    display: none;
    display: ${props => (props.open ? 'block' : 'none')};
    position: fixed;
    top: 0;
    left: 20px;
    opacity: 1;
    width: 100%;
    height: 100vh;
    background: ${Colors.white};
    z-index: 5;
    box-shadow: 0px 6px 12px rgba(0, 0, 0, 0.6);
    &.overlay {
      display: ${props => (props.open ? 'block' : 'none')};
      left: 0;
    }
  }

  ul {
    position: relative;
    list-style-type: none;
    z-index: 8;
    @media ${device.tablet} {
      margin: 50px 20px;
    }
    li {
      &:first-child,
      &:nth-child(2) {
        display: none;
        @media ${device.tablet} {
          display: block;
        }
      }

      display: inline-block;
      margin-right: 25px;

      .MuiButton-root {
        text-transform: capitalize;
        background: rgba(25, 118, 210, 0.14);

        a {
          padding-bottom: 0;
        }
      }
      @media ${device.tablet} {
        display: block;
        margin-right: 0;
        margin-bottom: 20px;
        &:first-child a {
          font-weight: 300;
        }
      }

      a {
        text-decoration: none;
        font-size: 15px;
        color: ${Colors.blueHeading};
        padding-bottom: 4px;
        transition: all 0.3s ease;
        @media ${device.tablet} {
          font-size: 18px;
          font-weight: 600;
          color: ${Colors.darkBlue};
        }
      }
    }
  }
`;
