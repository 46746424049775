import React from 'react';
import { MainSectionWrapper } from './About';
import { Heading } from './Heading';
import { TechSlider } from './TechSlider';

export const Technology = ({ ...props }) => {
  const { technologies } = props;
  return (
    <MainSectionWrapper id="Technology">
      <Heading>What can we do ?</Heading>
      {technologies && <TechSlider data={technologies} />}
    </MainSectionWrapper>
  );
};
