import React from 'react';

// UI
import { Stack, Box } from '@mui/material';

import SideBar from '../Sidebar';
import NavBar from '../NavBar';

// Redux & Sagas
import { useSelector } from 'react-redux';
import { selectGlobal } from 'app/pages/GlobalContainer/slice/selectors';

// Library
import styled from 'styled-components/macro';
import UISettings from 'styles/setting';

interface IWraper {
  children: any;
  subToolbar?: React.ReactNode;
}

export function Wrapper(props: IWraper) {
  const { isShowSidebar } = useSelector(selectGlobal);
  return (
    <WrapperRoot
      style={{
        paddingLeft: isShowSidebar
          ? UISettings.drawerWidth
          : UISettings.drawerCollapsedWidth,
      }}
      sx={{
        paddingTop: props.subToolbar ? '142px' : 8,
      }}
    >
      <SideBar />
      <NavBar subToolbar={props.subToolbar} />
      {props.children}
    </WrapperRoot>
  );
}

export function Content({ children, ...props }) {
  return <ContentRoot {...props}>{children}</ContentRoot>;
}

const ContentRoot = styled(Stack)`
  margin: 0 15px 15px;
`;

const WrapperRoot = styled(Box)`
  transition: width 225ms cubic-bezier(0.4, 0, 0.6, 1) 0ms,
    padding 225ms cubic-bezier(0.4, 0, 0.6, 1) 0ms;
`;
