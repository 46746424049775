import {
  Box,
  Card,
  CardContent,
  CardHeader,
  Grid,
  IconButton,
  Typography,
} from '@mui/material';
import Path from 'config/clientPath';
import { STATUS_LIST, TYPE_MEMBER } from 'constants/common';
import styled from 'styled-components';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import { AvatarMember } from 'app/components/Avatar';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { Email as EmailIcon } from '@mui/icons-material';
import { PhoneInTalk as PhoneIcon } from '@mui/icons-material';
import { selectMember } from '../slice/selectors';
import { useSelector } from 'react-redux';

export function ViewMemberModeGrid() {
  const { listMember } = useSelector(selectMember);
  const { t } = useTranslation();
  const history = useHistory();
  return (
    <>
      <Grid container spacing={2} sx={styling.container}>
        {listMember?.data.map((item, idx) => {
          return (
            <Grid item lg={2} md={3} sm={4} xs={6} key={`Member_Grid_${idx}`}>
              <CardUser
                sx={styling.fullHeightCard}
                onClick={() => history.push(`${Path.MEMBERS}/edit/${item.id}`)}
              >
                <CardHeader
                  disableTypography
                  title={STATUS_LIST[item.status]}
                  action={
                    <IconButton aria-label="settings">
                      <MoreHorizIcon />
                    </IconButton>
                  }
                />
                <AvatarMember
                  avatar={`${item?.avatar || ''}`}
                  title={`${item?.full_name || ''}`}
                  sx={styling.cardImage}
                  member_id={item?.id}
                ></AvatarMember>
                <CardContent>
                  <Typography
                    gutterBottom
                    variant="h5"
                    component="h2"
                    align="center"
                  >
                    {item.full_name}
                  </Typography>
                  {item?.role ? (
                    <Typography
                      variant="body2"
                      color="textSecondary"
                      component="p"
                      align="center"
                    >
                      {item?.role_name}
                    </Typography>
                  ) : (
                    <br />
                  )}
                </CardContent>
                <Box>
                  <Grid display="flex">
                    <Grid xs={6}>
                      <Typography
                        color="textSecondary"
                        component="p"
                        align="center"
                        variant="body2"
                      >
                        {t('Code Member')}
                      </Typography>
                      <Typography color="info" component="p" align="center">
                        {item.member_code}
                      </Typography>
                    </Grid>
                    <Grid xs={6}>
                      <Typography
                        color="textSecondary"
                        component="p"
                        align="center"
                        variant="body2"
                      >
                        {t('Type member')}
                      </Typography>
                      <Typography color="info" component="p" align="center">
                        {TYPE_MEMBER[item.type]}
                      </Typography>
                    </Grid>
                  </Grid>
                  <Grid xs={12} display="flex" alignItems="center">
                    <EmailIcon />
                    <Typography
                      variant="body2"
                      color="textSecondary"
                      component="p"
                    >
                      {item.email || ''}
                    </Typography>
                  </Grid>
                  <Grid xs={12} display="flex" alignItems="center">
                    {item?.phone && (
                      <>
                        <PhoneIcon />
                        <Typography
                          variant="body2"
                          color="textSecondary"
                          component="p"
                        >
                          {item.phone || ''}
                        </Typography>
                      </>
                    )}
                  </Grid>
                </Box>
              </CardUser>
            </Grid>
          );
        })}
      </Grid>
    </>
  );
}

const styling = {
  container: {
    paddingRight: 2,
  },
  fullHeightCard: {
    height: '100%',
    cursor: 'pointer',
  },
  cardImage: {
    borderRadius: '50%',
    height: '100px',
    width: '100px',
    margin: '0 auto',
    '@media (max-width: 425px)': {
      height: '50px',
      width: '50px',
    },
  },
};

const CardUser = styled(Card)`
  & .MuiCardMedia-root {
    border-radius: 50%;
    height: 100px;
    width: 100px;
    margin: 0 auto;
  }
  & .MuiTypography-root {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
  & .MuiBox-root {
    background: #f0f2f5;
    margin: 5px;
    padding: 10px;
    height: 100px;
    & .MuiGrid-root:first-child {
      padding-bottom: 2px;
    }
    & .MuiGrid-root:nth-child(2) {
      padding-bottom: 5px;
    }
    & .MuiTypography-root {
      padding-left: 5px;
    }
  }
`;
