import React from 'react';

// UI
import {
  Grid,
  Stack,
  Typography,
  FormLabel,
  TextField,
  FormControl,
  Select,
  FormHelperText,
  MenuItem,
  Button,
  useMediaQuery,
  Box,
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { selectForgotPassword } from 'app/pages/ForgotPasswordPage/slice/selectors';
import DatePicker from '@mui/lab/DatePicker';
import InputImage from 'app/components/InputImage';
import { LANGUAGE_DEFAULT, REGEX_CHECK_NUMBER } from 'constants/common';
import SaveIcon from '@mui/icons-material/Save';
import UISettings from 'styles/setting';
import { Colors, device } from 'app/pages/HomePage/Components/Variables';
// Redux & Sagas
import { RequestStatus } from 'constants/API';
import { useDispatch, useSelector } from 'react-redux';
import {
  Controller,
  UseFormReturn,
  SubmitHandler,
  FieldValues,
} from 'react-hook-form';
import { IMemberSchema } from 'app/pages/MemberPage/slice/types';
import { selectMember } from 'app/pages/MemberPage/slice/selectors';
import { selectGlobal } from 'app/pages/GlobalContainer/slice/selectors';
import _ from 'lodash';
import { useGlobalSlice } from 'app/pages/GlobalContainer/slice';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { TextFieldPassword } from 'app/components/TextFieldPassword';

interface IBasicInfoProps {
  memberForm: UseFormReturn;
  changePasswordForm: UseFormReturn;
  handleSaveData: SubmitHandler<FieldValues>;
  handleChangePassword: SubmitHandler<FieldValues>;
  callAPIUploadImage: any;
}
interface IResetPasswordForm {
  old_password: string;
  new_password: string;
  confirm_password: string;
}

export default function BasicInfo(props: IBasicInfoProps) {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const { setUserSessionData } = useGlobalSlice().actions;
  const { changePasswordStatus } = useSelector(selectForgotPassword);
  const { updateMemberInfoStatus, memberInfo, uploadMemberAvatarStatus } =
    useSelector(selectMember);
  const { userSessionData } = useSelector(selectGlobal);

  const {
    register,
    getValues,
    control,
    handleSubmit,
    formState: { errors },
  } = props.memberForm;
  const changePasswordForm = props.changePasswordForm;

  const updateNewMemberInfo = () => {
    const newMemberInfo = {
      ...userSessionData,
      profile: {
        ...userSessionData.profile,
        avatar: memberInfo?.avatar || '',
        email: memberInfo?.email || '',
        full_name: memberInfo?.full_name || '',
        gender: _.toNumber(memberInfo?.gender) || 0,
        sys_language: memberInfo?.sys_language || '',
      },
    };

    dispatch(setUserSessionData(newMemberInfo));
  };

  React.useEffect(() => {
    if (updateMemberInfoStatus === RequestStatus.SUCCESS) {
      if (memberInfo) {
        props.memberForm.reset(memberInfo);
      }
      updateNewMemberInfo();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updateMemberInfoStatus]);

  return (
    <Grid container spacing={6}>
      <Grid item lg={8} md={12}>
        <Stack>
          <Typography variant="h5" component="h5">
            {t('Personal Information')}
          </Typography>

          <Box component="form" id="profile-form">
            <InfoWrapper container>
              <Grid item lg={8} md={8}>
                <Grid container spacing={2} pb={4} pt={2}>
                  <Grid item xs={5}>
                    <FormLabel>{t('Full Name')}</FormLabel>
                    <TextField
                      variant="outlined"
                      fullWidth
                      margin="dense"
                      size="small"
                      {...register<keyof IMemberSchema>('full_name')}
                      disabled
                      sx={{ backgroundColor: Colors.lightGrey }}
                    />
                  </Grid>

                  <Grid item xs={5}>
                    <FormLabel>{t('Employee Code')}</FormLabel>
                    <TextField
                      variant="outlined"
                      fullWidth
                      margin="dense"
                      size="small"
                      disabled
                      {...register<keyof IMemberSchema>('code')}
                      sx={{ backgroundColor: Colors.lightGrey }}
                    />
                  </Grid>

                  <Grid item xs={10}>
                    <FormLabel>{t('Email')}</FormLabel>
                    <TextField
                      value={getValues('email')}
                      fullWidth
                      size="small"
                      margin="dense"
                      disabled
                      sx={{ backgroundColor: Colors.lightGrey }}
                    />
                  </Grid>

                  <Grid item xs={5}>
                    <FormLabel required>{t('Phone')}</FormLabel>
                    <TextField
                      variant="outlined"
                      fullWidth
                      margin="dense"
                      size="small"
                      error={!!errors.phone}
                      {...register<keyof IMemberSchema>('phone', {
                        pattern: {
                          value: REGEX_CHECK_NUMBER,
                          message: t('Please enter an integer'),
                        },
                        required: t('This field is required') as string,
                        maxLength: {
                          value: 12,
                          message: t(
                            'Please enter no more than {{count}} characters.',
                            { count: 12 },
                          ),
                        },
                      })}
                      helperText={errors.phone?.message}
                    />
                  </Grid>
                  <Grid item xs={5}>
                    <FormLabel>{t('DOB')}</FormLabel>
                    <FormControl fullWidth margin="dense">
                      <Controller
                        control={control}
                        name="birthday"
                        render={({ field: { onChange, value } }) => (
                          <DatePicker
                            disabled
                            value={value}
                            onChange={onChange}
                            inputFormat={UISettings.dateFormat}
                            renderInput={params => (
                              <TextField
                                {...params}
                                size="small"
                                fullWidth
                                sx={{ backgroundColor: Colors.lightGrey }}
                              />
                            )}
                          />
                        )}
                      />
                    </FormControl>
                  </Grid>

                  <Grid item xs={10}>
                    <FormLabel required>{t('Address')}</FormLabel>
                    <TextField
                      variant="outlined"
                      fullWidth
                      margin="dense"
                      size="small"
                      error={!!errors.address}
                      {...register<keyof IMemberSchema>('address', {
                        required: t('This field is required') as string,
                        maxLength: {
                          value: 255,
                          message: t(
                            'Please enter no more than {{count}} characters.',
                            { count: 255 },
                          ),
                        },
                      })}
                      helperText={errors.address?.message}
                    />
                  </Grid>

                  <Grid item xs={7}>
                    <FormLabel>{t('Education')}</FormLabel>
                    <TextField
                      variant="outlined"
                      fullWidth
                      margin="dense"
                      size="small"
                      error={!!errors.education}
                      {...register<keyof IMemberSchema>('education', {
                        maxLength: {
                          value: 255,
                          message: t(
                            'Please enter no more than {{count}} characters.',
                            { count: 255 },
                          ),
                        },
                      })}
                      helperText={errors.education?.message}
                    />
                  </Grid>

                  <Grid item xs={3}>
                    <FormLabel>{t('Language')}</FormLabel>
                    <FormControl fullWidth margin="dense">
                      <Controller
                        control={control}
                        name="sys_language"
                        render={({ field }) => {
                          const { onBlur, onChange, value } = field;
                          return (
                            <Select
                              fullWidth
                              displayEmpty
                              size="small"
                              onBlur={onBlur}
                              onChange={onChange}
                              value={`${value}`}
                            >
                              {_.map(LANGUAGE_DEFAULT, (label, code) => (
                                <MenuItem key={code} value={code}>
                                  {t(label)}
                                </MenuItem>
                              ))}
                            </Select>
                          );
                        }}
                      />
                      <FormHelperText error>
                        {errors.type?.message}
                      </FormHelperText>
                    </FormControl>
                  </Grid>
                </Grid>
              </Grid>
            </InfoWrapper>
            <Grid lg={5}>
              <Stack direction="row" spacing={2}>
                <LoadingButton
                  variant="contained"
                  color="primary"
                  loadingPosition="start"
                  startIcon={<SaveIcon />}
                  loading={updateMemberInfoStatus === RequestStatus.REQUESTING}
                  onClick={() => handleSubmit(props.handleSaveData)()}
                >
                  {t('Save change')}
                </LoadingButton>
                <ResetBtn
                  variant="contained"
                  onClick={() => {
                    if (memberInfo) props.memberForm.reset(memberInfo);
                  }}
                >
                  {t('Reset')}
                </ResetBtn>
              </Stack>
            </Grid>
          </Box>

          <Typography variant="h5" pt={5} component="h5">
            {t('Change password')}
          </Typography>

          <Box component="form" id="change-password-form">
            <Grid container spacing={2} pb={4} pt={2} lg={5}>
              <Grid item xs={7}>
                <Grid item xs={12}>
                  <FormLabel required>{t('Current Password')}</FormLabel>
                  <TextFieldPassword
                    form={changePasswordForm}
                    fieldName="old_password"
                    register={changePasswordForm.register<
                      keyof IResetPasswordForm
                    >('old_password', {
                      required: t('This field is required') as string,
                    })}
                  />
                </Grid>

                <Grid item xs={12}>
                  <FormLabel required>{t('New Password')}</FormLabel>
                  <TextFieldPassword
                    form={changePasswordForm}
                    fieldName="new_password"
                    register={changePasswordForm.register<
                      keyof IResetPasswordForm
                    >('new_password', {
                      required: t('This field is required') as string,
                    })}
                  />
                </Grid>
                <Grid item xs={12}>
                  <FormLabel required>{t('Confirm Password')}</FormLabel>
                  <TextFieldPassword
                    form={changePasswordForm}
                    fieldName="confirm_password"
                    register={changePasswordForm.register<
                      keyof IResetPasswordForm
                    >('confirm_password', {
                      required: t('This field is required') as string,
                      validate: value =>
                        value === changePasswordForm.watch('new_password') ||
                        (t('Passwords do not match') as string),
                    })}
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid lg={4}>
              <Stack direction="row" spacing={2}>
                <LoadingButton
                  variant="contained"
                  color="primary"
                  loadingPosition="start"
                  startIcon={<SaveIcon />}
                  loading={changePasswordStatus === RequestStatus.REQUESTING}
                  onClick={() =>
                    changePasswordForm.handleSubmit(
                      props.handleChangePassword,
                    )()
                  }
                >
                  {t('Update')}
                </LoadingButton>
                <ResetBtn
                  variant="contained"
                  onClick={() => props.changePasswordForm.reset()}
                >
                  {t('Reset')}
                </ResetBtn>
              </Stack>
            </Grid>
          </Box>
        </Stack>
      </Grid>
      <Grid
        item
        xs={4}
        lg={4}
        md={12}
        display="flex"
        sx={{
          justifyContent: {
            sm: 'flex-start',
            md: 'center',
          },
        }}
      >
        <InputImage
          form={props.memberForm}
          inputName="avatar"
          size={useMediaQuery(device.laptop) ? 256 : 300}
          callAPIUploadImage={props.callAPIUploadImage}
        />
      </Grid>
    </Grid>
  );
}

const ResetBtn = styled(Button)`
  background-color: ${Colors.white} !important;
  color: ${Colors.black} !important;
`;

const InfoWrapper = styled(Grid)`
  @media ${device.tablet} {
    display: flex;
    flex-direction: column-reverse !important;
  }
`;
