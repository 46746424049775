import React, { useEffect } from 'react';

// UI
import { Helmet } from 'react-helmet-async';
import {
  Grid,
  Stack,
  Typography,
  Divider,
  TextField,
  FormControl,
  RadioGroup,
  FormControlLabel,
  Button,
  Paper,
} from '@mui/material';

import { CheckCircleOutline as CheckCircleOutlineIcon } from '@mui/icons-material';

import Radio from '@mui/material/Radio';
import { Container } from 'app/components/Container';

// Redux & Sagas
import { useDispatch, useSelector } from 'react-redux';
import { RequestStatus } from 'constants/API';
import { IInviteMemberSchema } from './slice/types';
import { useMemberSlice } from './slice';
import { selectInviteMember } from './slice/selectors';
import { useGlobalSlice } from 'app/pages/GlobalContainer/slice';
import { NotFoundPage } from 'app/components/NotFoundPage';
import { useForm, Controller } from 'react-hook-form';

// Library
import _ from 'lodash';
import styled from 'styled-components/macro';
import { Link, useParams } from 'react-router-dom';
import Path from 'config/clientPath';
import { useHistory } from 'react-router';
import { removeStorageData } from 'utils/localStorage';

interface RouteParams {
  invite_token: string;
}

const leftSideWidth = 292;

export function InviteMemberPage() {
  const dispatch = useDispatch();
  const inviteMemberSlice = useSelector(selectInviteMember);

  const {
    actions: {
      createMemberRequest,
      resetMemberRequestStatus,
      getInviteMetaDataRequest,
    },
  } = useMemberSlice();

  const {
    actions: { setErrorMessages },
  } = useGlobalSlice();

  const {
    reset,
    setValue,
    register,
    control,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm();

  const params = useParams<RouteParams>();

  const [isCreated, setIsCreated] = React.useState<boolean>(false);
  const [countdown, setCoutDown] = React.useState<number>(5);
  const [isLinkValid, setIsLinkValid] = React.useState(false);
  const history = useHistory();

  const handleLogout = () => {
    removeStorageData();
    history.push(Path.LOGIN);
  };

  useEffect(() => {
    if (inviteMemberSlice.createMemberStatus === RequestStatus.SUCCESS) {
      reset({
        full_name: '',
        gender: 1,
        email: '',
        phone: '',
        address: '',
        bio: '',
        password: '',
        password_confirm: '',
      });
      setIsCreated(true);
      const interval = setInterval(() => {
        setCoutDown(countdown => countdown - 1);
      }, 1000);
      return () => clearInterval(interval);
    } else if (inviteMemberSlice.createMemberStatus === RequestStatus.ERROR) {
      dispatch(setErrorMessages([inviteMemberSlice.error_message]));
    }

    dispatch(resetMemberRequestStatus());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inviteMemberSlice.createMemberStatus]);

  useEffect(() => {
    dispatch(getInviteMetaDataRequest({ token: params.invite_token }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (inviteMemberSlice.getInviteMetaDataStatus === RequestStatus.SUCCESS) {
      setValue('email', _.get(inviteMemberSlice, 'inviteMedataData.email'));
    }
    if (inviteMemberSlice.getInviteMetaDataStatus === RequestStatus.ERROR) {
      setIsLinkValid(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inviteMemberSlice.getInviteMetaDataStatus]);

  const handleRegisterNewNember = formData => {
    formData['invite_token'] = params.invite_token;
    dispatch(createMemberRequest(formData));
  };

  useEffect(() => {
    if (countdown <= 0) {
      handleLogout();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [countdown]);

  return (
    <>
      {isLinkValid ? (
        NotFoundPage()
      ) : (
        <>
          <Helmet>
            <title>Join to DigiTran</title>
            <meta name="description" content="Join to DigiTran" />
          </Helmet>
          <Container>
            <InviteMemberContainer elevation={16}>
              <Grid container height="100%" style={{ position: 'relative' }}>
                <Grid item width={leftSideWidth}></Grid>
                <Grid item xs px={4}>
                  <Stack
                    direction="column"
                    justifyContent="space-between"
                    height="100%"
                  >
                    <Stack>
                      <Typography variant="h4" component="h2" py={3}>
                        Basic Info
                      </Typography>
                      <Divider />
                      <form
                        id="register-form"
                        onSubmit={handleSubmit(handleRegisterNewNember)}
                      >
                        <Stack spacing={4} mt={2}>
                          <Grid container alignItems="center">
                            <Grid item xs>
                              <TextField
                                label="Full name"
                                variant="standard"
                                fullWidth
                                autoFocus
                                error={errors.full_name ? true : false}
                                {...register<keyof IInviteMemberSchema>(
                                  'full_name',
                                  {
                                    required: 'This is required.',
                                    maxLength: 60,
                                  },
                                )}
                                helperText={errors.full_name?.message}
                              />
                            </Grid>
                            <Grid
                              item
                              xs={5}
                              ml={2}
                              alignItems="flex-end"
                              display="flex"
                            >
                              <FormControl
                                component="fieldset"
                                variant="standard"
                              >
                                <Controller
                                  control={control}
                                  defaultValue="1"
                                  name="gender"
                                  render={({ field }) => {
                                    const { onBlur, onChange, value } = field;
                                    return (
                                      <RadioGroup
                                        row
                                        value={value}
                                        onBlur={onBlur}
                                        onChange={onChange}
                                      >
                                        <FormControlLabel
                                          value="1"
                                          control={<Radio />}
                                          label="Female"
                                        />
                                        <FormControlLabel
                                          value="2"
                                          control={<Radio />}
                                          label="Male"
                                        />
                                      </RadioGroup>
                                    );
                                  }}
                                />
                              </FormControl>
                            </Grid>
                          </Grid>
                          <Grid container>
                            <Grid item xs>
                              <Controller
                                control={control}
                                defaultValue=""
                                rules={{ required: 'This field is required' }}
                                name="email"
                                render={({ field }) => {
                                  const { onBlur, onChange, value } = field;
                                  return (
                                    <TextField
                                      label="Email address"
                                      type="email"
                                      variant="standard"
                                      disabled
                                      fullWidth
                                      value={value}
                                      onBlur={onBlur}
                                      onChange={onChange}
                                    />
                                  );
                                }}
                              />
                            </Grid>
                            <Grid item xs ml={2}>
                              <TextField
                                label="Phone number"
                                variant="standard"
                                fullWidth
                                error={errors.phone ? true : false}
                                {...register<keyof IInviteMemberSchema>(
                                  'phone',
                                  {
                                    required: 'This is required.',
                                    maxLength: 60,
                                  },
                                )}
                                helperText={errors.phone?.message}
                              />
                            </Grid>
                          </Grid>

                          <TextField
                            id="outlined-basic"
                            label="Outlined"
                            variant="outlined"
                            className="disable_autofill"
                          />

                          <Grid container>
                            <Grid item xs>
                              <TextField
                                {...register<keyof IInviteMemberSchema>(
                                  'password',
                                  {
                                    required: 'This field is required.',
                                  },
                                )}
                                label="Password"
                                fullWidth
                                variant="standard"
                                size="small"
                                type="password"
                                error={errors.password ? true : false}
                                helperText={errors.password?.message}
                              />
                            </Grid>
                            <Grid item xs ml={2}>
                              <TextField
                                {...register<keyof IInviteMemberSchema>(
                                  'password_confirm',
                                  {
                                    required: 'This field is required.',
                                    validate: value =>
                                      value === watch('password') ||
                                      'The passwords do not match',
                                  },
                                )}
                                label="Password confirm"
                                fullWidth
                                variant="standard"
                                size="small"
                                type="password"
                                error={errors.password_confirm ? true : false}
                                helperText={errors.password_confirm?.message}
                              />
                            </Grid>
                          </Grid>

                          <TextField
                            label="Address"
                            variant="standard"
                            error={errors.address ? true : false}
                            {...register<keyof IInviteMemberSchema>('address', {
                              maxLength: 255,
                            })}
                            helperText={errors.address?.message}
                          />
                          <TextField
                            label="Biography"
                            variant="standard"
                            multiline
                            rows={3}
                            error={errors.bio ? true : false}
                            {...register<keyof IInviteMemberSchema>('bio', {
                              maxLength: 1000,
                            })}
                            helperText={errors.bio?.message}
                          />
                        </Stack>
                      </form>
                    </Stack>
                    <Stack
                      spacing={2}
                      justifyContent="flex-end"
                      direction="row"
                      alignSelf="flex-end"
                      width="100%"
                    >
                      <Button
                        variant="outlined"
                        color="primary"
                        size="large"
                        type="submit"
                        form="register-form"
                      >
                        Join us
                      </Button>
                    </Stack>
                  </Stack>
                </Grid>

                <LeftSide
                  container
                  padding={2}
                  direction="column"
                  className={isCreated ? 'expand' : ''}
                >
                  <Grid item xs alignItems="center" display="flex">
                    {isCreated ? (
                      <Stack spacing={2} alignItems="center">
                        <Typography component="div">
                          <CheckCircleOutlineIcon className="success-icon" />
                        </Typography>
                        <Typography variant="h4">Successfull</Typography>
                        <Typography variant="subtitle1">
                          Please check your registered email for more detail.
                          <br />
                          You will be automatic redirected to a Login Page in{' '}
                          {countdown > 0 ? countdown : 0} seconds, or you can
                          click Log In bellow
                        </Typography>
                        <Button
                          onClick={() => handleLogout()}
                          variant="contained"
                        >
                          Log In
                        </Button>
                      </Stack>
                    ) : (
                      <Stack spacing={2}>
                        <Typography variant="h4">Sign up</Typography>
                        <Typography component="div">
                          Get started in a few clicks
                          <br />
                          and enjoy new works from Digitran.
                        </Typography>
                      </Stack>
                    )}
                  </Grid>
                  <LoginBlock item xs={2} className="login-block">
                    <Typography component="p" pb={1}>
                      Have an account ?
                    </Typography>
                    <Link to={Path.LOGIN}>Login</Link>
                  </LoginBlock>
                </LeftSide>
              </Grid>
            </InviteMemberContainer>
          </Container>
        </>
      )}
    </>
  );
}

const contWidth = 1000;
const contHeight = 600;

const InviteMemberContainer = styled(Paper)`
  position: absolute;
  top: 50%;
  left: 50%;
  margin-left: -${contWidth / 2}px;
  margin-top: -${contHeight / 2}px;
  width: ${contWidth}px;
  height: ${contHeight}px;

  padding: 1em;

  button[type='submit'] {
    width: 100px;
  }
`;

const LeftSide = styled(Grid)`
  position: absolute;
  top: 0;
  left: 0;
  width: ${leftSideWidth}px !important;
  height: 100%;
  // #009EFA -> #00C3FE
  background-image: linear-gradient(
    to top,
    #009efa,
    #00a8fc,
    #00b1fe,
    #00bafe,
    #00c3fe
  );
  border-radius: 10px;
  color: #fff;

  &.expand {
    width: 100% !important;
    text-align: center;
    align-items: center;
    .login-block {
      visibility: hidden;
    }
    .success-icon {
      font-size: 84px;
    }
    .MuiButton-root {
      margin-top: 40px;
      width: 50%;
    }
  }

  transition: width 500ms ease;
`;

const LoginBlock = styled(Grid)`
  background: rgba(255, 255, 255, 0.15);
  border-radius: 10px;
  padding: 20px;
  a {
    text-decoration: none;
    color: inherit;
    font-weight: bold;
  }
`;
