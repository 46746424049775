import { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from 'utils/@reduxjs/toolkit';
import { useInjectReducer, useInjectSaga } from 'utils/redux-injectors';
import timesheetSaga from './saga';
import { TimesheetState } from './types';
import { RequestStatus } from 'constants/API';

export const initialState: TimesheetState = {
  getAllProjectsStatus: RequestStatus.IDLE,
  getAllMembersStatus: RequestStatus.IDLE,
  getAssignProjectsStatus: RequestStatus.IDLE,

  requestApprovalStatus: RequestStatus.IDLE,
  cancelRequestStatus: RequestStatus.IDLE,
  approveRequestStatus: RequestStatus.IDLE,

  getApprovalListStatus: RequestStatus.IDLE,
  getTimesheetStatus: RequestStatus.IDLE,
  getDateRangeWorkReportStatus: RequestStatus.IDLE,

  addTimesheetStatus: RequestStatus.IDLE,
  updateTimesheetStatus: RequestStatus.IDLE,
  deleteTimesheetStatus: RequestStatus.IDLE,

  getTagStatus: RequestStatus.IDLE,
  addTagStatus: RequestStatus.IDLE,

  approveReviewRequestStatus: RequestStatus.IDLE,
  getExportCSVStatus: RequestStatus.IDLE,

  requestReportApprovalStatus: RequestStatus.IDLE,
  requestReportCancelStatus: RequestStatus.IDLE,

  allProjects: [],
  allMembers: [],
  assignProjects: null,
  approvalList: null,
  timesheetList: [],
  dateRangeWorkReport: null,
  monthWorkReport: null,
  errorMess: [],
  csv: '',
};

const slice = createSlice({
  name: 'timesheet',
  initialState,
  reducers: {
    getAllMembersRequest: (state, action: PayloadAction<any>) => {
      state.getAllMembersStatus = RequestStatus.REQUESTING;
    },
    getAllMembersSuccess: (state, action: PayloadAction<any>) => {
      if (action) {
        state.getAllMembersStatus = RequestStatus.SUCCESS;
        state.allMembers = action.payload;
      }
    },
    getAllMembersFail: (state, action: PayloadAction<any>) => {
      if (action) state.getAllMembersStatus = RequestStatus.ERROR;
    },

    getAllProjectsRequest: (state, action: PayloadAction<any>) => {
      state.getAllProjectsStatus = RequestStatus.REQUESTING;
    },
    getAllProjectsSuccess: (state, action: PayloadAction<any>) => {
      if (action) {
        state.getAllProjectsStatus = RequestStatus.SUCCESS;
        state.allProjects = action.payload;
      }
    },
    getAllProjectsFail: (state, action: PayloadAction<any>) => {
      if (action) state.getAllProjectsStatus = RequestStatus.ERROR;
    },

    getAssignProjectsRequest: (state, action: PayloadAction<any>) => {
      state.getAssignProjectsStatus = RequestStatus.REQUESTING;
    },
    getAssignProjectsSuccess: (state, action: PayloadAction<any>) => {
      if (action) {
        state.getAssignProjectsStatus = RequestStatus.SUCCESS;
        state.assignProjects = action.payload;
      }
    },
    getAssignProjectsFail: (state, action: PayloadAction<any>) => {
      if (action) state.getAssignProjectsStatus = RequestStatus.ERROR;
    },
    resetGetAssignProjectStatus: state => {
      state.getAssignProjectsStatus = RequestStatus.IDLE;
      state.assignProjects = null;
    },

    getApprovalListRequest: (state, action: PayloadAction<any>) => {
      state.getApprovalListStatus = RequestStatus.REQUESTING;
    },
    getApprovalListSuccess: (state, action: PayloadAction<any>) => {
      if (action) {
        state.getApprovalListStatus = RequestStatus.SUCCESS;
        let data = action?.payload?.data;
        data = data.map(v => {
          return {
            ...v,
            id: v?.project?.id + '_' + v?.member?.id,
          };
        });
        state.approvalList = { ...action?.payload, data };
      }
    },
    getApprovalListFail: (state, action: PayloadAction<any>) => {
      if (action) state.getApprovalListStatus = RequestStatus.ERROR;
    },

    approveRequestRequest: (state, action: PayloadAction<any>) => {
      state.approveRequestStatus = RequestStatus.REQUESTING;
    },
    approveRequestSuccess: (state, action: PayloadAction<any>) => {
      if (action) {
        state.approveRequestStatus = RequestStatus.SUCCESS;
      }
    },
    approveRequestFail: (state, action: PayloadAction<any>) => {
      if (action) state.approveRequestStatus = RequestStatus.ERROR;
    },

    requestApprovalRequest: (state, action: PayloadAction<any>) => {
      state.requestApprovalStatus = RequestStatus.REQUESTING;
    },
    requestApprovalSuccess: (state, action: PayloadAction<any>) => {
      if (action) {
        state.requestApprovalStatus = RequestStatus.SUCCESS;
      }
    },
    requestApprovalFail: (state, action: PayloadAction<any>) => {
      if (action) state.requestApprovalStatus = RequestStatus.ERROR;
    },

    requestReportApprovalRequest: (state, action: PayloadAction<any>) => {
      state.requestReportApprovalStatus = RequestStatus.REQUESTING;
    },

    requestReportApprovalSuccess: (state, action: PayloadAction<any>) => {
      if (action) {
        state.requestReportApprovalStatus = RequestStatus.SUCCESS;
      }
    },
    requestReportApprovalFail: (state, action: PayloadAction<any>) => {
      if (action) state.requestReportApprovalStatus = RequestStatus.ERROR;
    },

    cancelRequestRequest: (state, action: PayloadAction<any>) => {
      state.cancelRequestStatus = RequestStatus.REQUESTING;
    },
    cancelRequestSuccess: (state, action: PayloadAction<any>) => {
      if (action) {
        state.cancelRequestStatus = RequestStatus.SUCCESS;
      }
    },
    cancelRequestFail: (state, action: PayloadAction<any>) => {
      if (action) state.cancelRequestStatus = RequestStatus.ERROR;
    },

    requestReportCancelRequest: (state, action: PayloadAction<any>) => {
      state.requestReportCancelStatus = RequestStatus.REQUESTING;
    },

    requestReportCancelSuccess: (state, action: PayloadAction<any>) => {
      if (action) {
        state.requestReportCancelStatus = RequestStatus.SUCCESS;
      }
    },
    requestReportCancelFail: (state, action: PayloadAction<any>) => {
      if (action) state.requestReportCancelStatus = RequestStatus.ERROR;
    },

    getDateRangeWorkReportRequest: (state, action: PayloadAction<any>) => {
      if (action) state.getDateRangeWorkReportStatus = RequestStatus.REQUESTING;
    },
    getDateRangeWorkReportSuccess: (state, action: PayloadAction<any>) => {
      if (action) {
        state.getDateRangeWorkReportStatus = RequestStatus.SUCCESS;
        state.dateRangeWorkReport = action?.payload?.days;
      }
    },
    getDateRangeWorkReportFail: (state, action: PayloadAction<any>) => {
      if (action) state.getDateRangeWorkReportStatus = RequestStatus.ERROR;
    },

    getTimesheetRequest: (state, action: PayloadAction<any>) => {
      if (action) state.getTimesheetStatus = RequestStatus.REQUESTING;
    },
    getTimesheetSuccess: (state, action: PayloadAction<any>) => {
      if (action) {
        state.getTimesheetStatus = RequestStatus.SUCCESS;
        state.timesheetList = action.payload;
      }
    },
    getTimesheetFail: (state, action: PayloadAction<any>) => {
      if (action) state.getTimesheetStatus = RequestStatus.ERROR;
    },

    addTimesheetRequest: (state, action: PayloadAction<any>) => {
      if (action) {
        state.addTimesheetStatus = RequestStatus.REQUESTING;
        state.errorMess = [];
      }
    },
    addTimesheetSuccess: (state, action: PayloadAction<any>) => {
      if (action) {
        state.addTimesheetStatus = RequestStatus.SUCCESS;
      }
    },
    addTimesheetFail: (state, action: PayloadAction<any>) => {
      if (action) {
        state.addTimesheetStatus = RequestStatus.ERROR;
        state.errorMess = action.payload.errorMess;
      }
    },

    updateTimesheetRequest: (state, action: PayloadAction<any>) => {
      if (action) state.updateTimesheetStatus = RequestStatus.REQUESTING;
    },
    updateTimesheetSuccess: (state, action: PayloadAction<any>) => {
      if (action) {
        state.updateTimesheetStatus = RequestStatus.SUCCESS;
      }
    },
    updateTimesheetFail: (state, action: PayloadAction<any>) => {
      if (action) state.updateTimesheetStatus = RequestStatus.ERROR;
    },

    deleteTimesheetRequest: (state, action: PayloadAction<any>) => {
      if (action) state.deleteTimesheetStatus = RequestStatus.REQUESTING;
    },
    deleteTimesheetSuccess: (state, action: PayloadAction<any>) => {
      if (action) state.deleteTimesheetStatus = RequestStatus.SUCCESS;
    },
    deleteTimesheetFail: (state, action: PayloadAction<any>) => {
      if (action) state.deleteTimesheetStatus = RequestStatus.ERROR;
    },

    approveReviewRequestRequest: (state, action: PayloadAction<any>) => {
      state.approveReviewRequestStatus = RequestStatus.REQUESTING;
    },
    approveReviewRequestSuccess: (state, action: PayloadAction<any>) => {
      if (action) {
        state.approveReviewRequestStatus = RequestStatus.SUCCESS;
      }
    },
    approveReviewRequestFail: (state, action: PayloadAction<any>) => {
      if (action) state.approveReviewRequestStatus = RequestStatus.ERROR;
    },

    getExportCSVRequest: (state, action: PayloadAction<any>) => {
      state.getExportCSVStatus = RequestStatus.REQUESTING;
    },
    getExportCSVSuccess: (state, action: PayloadAction<any>) => {
      if (action) {
        state.getExportCSVStatus = RequestStatus.SUCCESS;
        state.csv = action.payload;
      }
    },
    getExportCSVFail: (state, action: PayloadAction<any>) => {
      if (action) state.getExportCSVStatus = RequestStatus.ERROR;
    },

    resetTimesheetRequestStatus: state => {
      state.getTimesheetStatus = RequestStatus.IDLE;
      state.addTimesheetStatus = RequestStatus.IDLE;
      state.deleteTimesheetStatus = RequestStatus.IDLE;
      state.updateTimesheetStatus = RequestStatus.IDLE;
      state.getDateRangeWorkReportStatus = RequestStatus.IDLE;
      state.getAssignProjectsStatus = RequestStatus.IDLE;
      state.requestReportApprovalStatus = RequestStatus.IDLE;
      state.requestReportCancelStatus = RequestStatus.IDLE;
      state.assignProjects = null;
      state.dateRangeWorkReport = null;
      state.timesheetList = [];
      state.errorMess = [];
    },

    resetTimesheetOverviewStatus: state => {
      state.getAllProjectsStatus = RequestStatus.IDLE;
    },
    resetTimesheetApprovalStatus: state => {
      state.approvalList = null;
      state.allProjects = [];
      state.allMembers = [];
      state.csv = '';
      state.getAllProjectsStatus = RequestStatus.IDLE;
      state.getAllMembersStatus = RequestStatus.IDLE;
      state.getApprovalListStatus = RequestStatus.IDLE;
      state.getExportCSVStatus = RequestStatus.IDLE;
    },
    resetTimesheetApprovalReviewStatus: state => {
      state.timesheetList = null;
      state.allProjects = [];
      state.getTimesheetStatus = RequestStatus.IDLE;
      state.approveReviewRequestStatus = RequestStatus.IDLE;
      state.getAllProjectsStatus = RequestStatus.IDLE;
    },
    resetTimesheetReportStatus: state => {
      state.requestApprovalStatus = RequestStatus.IDLE;
      state.cancelRequestStatus = RequestStatus.IDLE;
      state.getApprovalListStatus = RequestStatus.IDLE;
      state.cancelRequestStatus = RequestStatus.IDLE;
      state.approvalList = null;
    },
  },
});

export default slice.actions;

export const useTimesheetSlice = () => {
  useInjectReducer({ key: slice.name, reducer: slice.reducer });
  useInjectSaga({ key: slice.name, saga: timesheetSaga });
  return { actions: slice.actions };
};
